import { withFormik } from 'formik';
import { Form } from './Form';
import { produceDefaultValidation } from '../../../../services/utils';
import { validationSchema } from './validationSchema';
import { PersonFunctions } from './PersonFunctions';

export const PersonForm = withFormik({
  validate: produceDefaultValidation(validationSchema),
  mapPropsToValues: props => PersonFunctions.prepareInitialValues(props.person),
  handleSubmit: PersonFunctions.handleSubmit,
})(Form);
