import get from 'lodash/get';
import {
  fieldValue,
  prepareCustomFieldsAnswers,
  showBackendErrorMessage,
} from '../../../services/utils';
import { API } from '../../../services/api';

const t = key => I18n.t(`requests.waste_collection_candidates.new_edit.${key}`);

export const setCollectionTypeData = async (newSelection, setFieldValue) => {
  await setFieldValue(
    'include_waste_types_sp',
    fieldValue(get(newSelection, 'data.include_waste_types_sp')),
  );
  await setFieldValue('wasteCollectionTypeName', get(newSelection, 'data.text'));

  const { ok, data } = await API.requests
    .wasteCollectionTypes(newSelection.value)
    .fieldDefinitions()
    .index();

  if (ok) {
    const definitions = await data.data.map(fieldDefinition => fieldDefinition.attributes);
    const answers = prepareCustomFieldsAnswers('waste_chemical_item_candidate', definitions);
    await setFieldValue('wasteChemicalItemCandidateFieldDefinitions', definitions);
    await setFieldValue('waste_chemical_item_candidate_field_answers_attributes', answers);
  } else {
    await showBackendErrorMessage(t('flash.error.unexpected_error'));
  }
};
