import yup from '../../../components/form/CustomYupValidator';

export const validationSchema = yup.object().shape({
  rad_permit_name: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  holder_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  rad_license_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});
