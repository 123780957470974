import React from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/styles/colors';

export const Badge = styled.div`
  display: flex;
  width: 50px;
  height: 50px;
  font-size: 36px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: white;
  background: ${colors.badge};
`;

const PlaceholderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PlaceholderMessage = styled.p`
  margin-top: 10px;
  text-align: center;
`;

export const EmptyListPlaceholder = () => (
  <PlaceholderContainer>
    <Badge>i</Badge>
    <PlaceholderMessage>{I18n.t('empty_list')}</PlaceholderMessage>
  </PlaceholderContainer>
);
