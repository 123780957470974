import React from 'react';
import { SmallLightButton } from '../../../components/buttons/SmallLightButton';
import { showBackendErrorMessage } from '../../../services/utils';
import { API } from '../../../services/api';

const t = key => I18n.t(`hsds.hazard_assessments.new_edit.${key}`);

export const GenerateDoorSignButton = ({ spaceRiskAssessmentId }) => {
  return (
    <SmallLightButton
      extended
      onClick={async () => {
        const { ok } = await API.spaceRiskAssessments.generateDoorSign(spaceRiskAssessmentId);
        if (ok) {
          window.open(`/hsds/hazard_assessments/${spaceRiskAssessmentId}.pdf`, '_blank');
        } else {
          showBackendErrorMessage(t('flash.error.generate_door_sign'));
        }
      }}
    >
      {t('header.generate_door_sign')}
    </SmallLightButton>
  );
};
