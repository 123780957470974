import React, { useState } from 'react';
import _ from 'lodash';
import { handleSubmitWithAfterSave, isDefined } from '../../../services/utils';
import { FormHeader } from '../../../components/form/FormHeader';
import { Overview } from './Overview';
import { Footer } from './Footer';
import { RadInventoryItemsTable } from './RadInventoryItemsTable';
import { RadPurchasesTable } from './RadPurchasesTable';
import { RadLicenseItemAssignments } from './RadLicenseItemAssignments/RadLicenseItemAssignments';
import { RadUsers } from './RadUsers';
import { RelatedEquipment } from '../../../components/other/relatedEquipment';
import { API } from '../../../services/api';
import { Locations } from '../../../components/other/locations/Locations';

const t = key => I18n.t(`radiation_safety.rad_permits.new_edit.${key}`);

export const Form = props => {
  const {
    setFieldValue,
    handleSubmit,
    values: { id, identifier },
  } = props;
  const [selectedEquipmentIds, setSelectedEquipmentIds] = useState([]);
  const [selectedPfEIds, setSelectedPfEIds] = useState([]);

  const equipmentableIdsByType = (data, type) =>
    _.uniq(
      data
        .filter(_equipment => _equipment.attributes.equipmentable.type === type)
        .map(_equipment => _equipment.attributes.equipmentable.id),
    );

  const setSelectedEquipmentableIds = async () => {
    if (isDefined(id)) {
      const { ok, data } = await API.radiationSafety.radPermits.equipment(id).index();

      if (ok) {
        await setSelectedEquipmentIds(equipmentableIdsByType(data.data, 'General Equipment'));
        await setSelectedPfEIds(equipmentableIdsByType(data.data, 'Portable Fire Extinguisher'));
      }
    }
  };

  return (
    <form>
      <FormHeader
        title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)}
        investigable={
          isDefined(id) && {
            id,
            identifier,
            type: 'RadPermit',
          }
        }
        auditsLink={isDefined(id) ? `/radiation_safety/rad_permits/${id}/audits` : ''}
      />
      <Overview {...props} />
      <Footer {...props} />
      <RadLicenseItemAssignments {...props} />
      <RadUsers {...props} />
      <RadInventoryItemsTable {...props} />
      <RadPurchasesTable {...props} />
      <RelatedEquipment
        empty={!id}
        api={API.radiationSafety.radPermits.equipment(id)}
        handleAdditionLinkOnClick={async open => {
          await handleSubmitWithAfterSave(setFieldValue, handleSubmit, open);
          await setSelectedEquipmentableIds();
        }}
        prepareValuesToSubmit={values => ({
          rad_permit_equipment: values,
        })}
        additionalApiParams={{
          without_equipment_ids: selectedEquipmentIds,
          without_pfe_ids: selectedPfEIds,
        }}
      />
      <Locations
        api={API.radiationSafety.radPermits.locations}
        id={id}
        title={t('sections.related_locations')}
        resourceName={I18n.t('resource_names.rad_permit_location')}
        humanizedResourceName={I18n.t('activerecord.models.rad_permit_location')}
        handleAdditionLinkOnClick={open =>
          handleSubmitWithAfterSave(setFieldValue, handleSubmit, open)
        }
        prepareValuesToSubmit={values => ({
          rad_permit_location: values,
        })}
      />
    </form>
  );
};
