import React, { useEffect, useState } from 'react';
import { API } from '../../services/api';
import { isDefined } from '../../services/utils';
import { Loader } from '../../components/Loader';
import { DocumentForm } from './Form';

export const Document = props => {
  const { id } = props;

  const [fetching, setFetching] = useState(true);
  const [document, setDocument] = useState({});
  useEffect(() => {
    (async () => {
      const api = isDefined(id) ? API.documents.show : API.documents.new;
      const { ok, data } = await api(id);
      if (ok) {
        await setDocument(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <DocumentForm {...props} document={document} />;
};
export default Document;
