import React from 'react';
import { FormSection } from '../../../components/form/FormSection';
import { FormSectionHeader } from '../../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../../components/form/FormSectionTitle';
import { SimpleList } from '../../../components/lists/SimpleList';

const t = key => I18n.t(`administration.waste_generators.new_edit.lists.buildings.${key}`);

export const Buildings = props => {
  const {
    values: { buildings },
  } = props;

  return (
    <FormSection>
      <FormSectionHeader>
        <FormSectionTitle>{t('title')}</FormSectionTitle>
      </FormSectionHeader>
      <SimpleList
        rowHrefs={buildings.map(building => building.path)}
        resourceName={I18n.t('resource_names.building')}
        data={buildings.map(building => [
          [t('building_code'), building.building_code],
          [t('building_name'), building.building_name],
        ])}
      />
    </FormSection>
  );
};
