import { getConvertedMoment, newMoment } from '../../../services/dateFormatUtils';
import { isDefined, saluteRound } from '../../services/utils';

export const applyHalfLife = (baseValue, halfLifeDays, startedAt, endedAt) => {
  if (!isDefined(baseValue) || !isDefined(startedAt)) return '';
  if (halfLifeDays === 0) return 0.0;
  if (!halfLifeDays) return baseValue;

  const endMoment = isDefined(endedAt) ? getConvertedMoment(endedAt) : newMoment();
  const duration = endMoment.diff(getConvertedMoment(startedAt), 'seconds');
  return saluteRound(baseValue * 0.5 ** (duration / (halfLifeDays * 60 * 60 * 24)));
};
