import { withFormik } from 'formik';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { Form } from './Form';
import { validationSchema } from './validationSchema';
import { API } from '../../../services/api';

const t = key => I18n.t(`inventory.locations.campuses.new_edit.${key}`);

const CampusFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const campus = CampusFunctions.prepareValuesToSubmit({ ...values });
    CampusFunctions[isDefined(campus.id) ? 'update' : 'create'](
      campus,
      formikProps,
      values.afterSave,
    );
  },
  create: async (campus, formikProps, afterSave) => {
    const { ok, data } = await API.locations.campuses.create({ campus });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/inventory/locations/campuses/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.inventory.locations.campuses.edit.title'));
      formikProps.resetForm({
        values: CampusFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (campus, formikProps, afterSave) => {
    const { ok, data } = await API.locations.campuses.update(campus.id, { campus });
    if (ok) {
      formikProps.resetForm({
        values: CampusFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: {
    afterSave: () => {},
  },
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'name',
    'address',
    'city',
    'state',
    'zip_code',
    'responsible_person_id',
    'attachments',
    'links',
  ],
});

export const CampusForm = withFormik({
  mapPropsToValues: props => CampusFunctions.prepareInitialValues(props.campus),
  handleSubmit: CampusFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
