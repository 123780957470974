import React from 'react';
import { FastField } from 'formik';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import { getUtcMomentIgnoringTime, dateFormatter } from '../../../../services/dateFormatUtils';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import { TimeField } from '../../../components/fields/TimeField';
import { FormFooter, FormFooterRightPart } from '../../../components/form/FormFooter';
import { CancellationLink } from '../../../components/links/CancellationLink';
import { BrandButton } from '../../../components/buttons/BrandButton';
import { ModalSection } from '../../../components/modals/ModalSection';
import { DefaultLink } from '../../../components/links/DefaultLink';
import { FormRowWithoutField } from '../../../components/form/FormRowWithoutField';
import { fontSize } from '../../../assets/styles/typography';
import { defaultShouldUpdate, produceShouldUpdate } from '../../../services/fieldUtils';
import { hasKeyStartingWith } from '../../../services/utils';

const t = key => I18n.t(`safe_work_authorization_measures.modals.dates_and_times.${key}`);

export const CustomDateAndTimeModalContent = ({
  modalRef,
  onReset,
  errors,
  setFieldTouched,
  resourceName,
  values: { swa_measure_date_time_entries_attributes: entries },
}) => {
  // we should create html elements before sorting to ensure proper values for index
  const entryElements = entries.map((entry, index) => {
    let resultElement = null;
    // eslint-disable-next-line no-underscore-dangle
    if (!entry._destroy) {
      const namePrefix = `swa_measure_date_time_entries_attributes[${index}]`;
      const centeredClasses = 'd-flex justify-content-center align-items-center';
      const dayOfTheWeek = getUtcMomentIgnoringTime(entries[index].date).isoWeekday();
      const dayOfTheWeekI18n = t(`days_of_the_week.${dayOfTheWeek}`);
      resultElement = (
        <div key={entry.date} className="d-flex flex-wrap pb-3 align-items-start">
          <div className="d-flex justify-content-start align-items-center flex-grow-1 pb-1 pb-md-0">
            <div className="d-flex align-items-center">
              <FastField
                name={`${namePrefix}.checked`}
                resourceName={resourceName}
                component={CheckBoxField}
                displayMode={fieldDisplayModes.INPUT_FIRST_GROWING_WIDTH_INPUT}
                label={`${dateFormatter.date(entries[index].date)} (${dayOfTheWeekI18n})`}
                shouldUpdate={defaultShouldUpdate}
              />
            </div>
          </div>
          <div className={`${centeredClasses} col-12 col-md-3 col-lg-4 p-0`}>
            <FastField
              name={`${namePrefix}.start_time`}
              resourceName={resourceName}
              hideValue={!entry.checked}
              disabled={!entry.checked}
              component={TimeField}
              displayMode={fieldDisplayModes.GROWING_WIDTH_INPUT}
              shouldUpdate={produceShouldUpdate([], [], [`${namePrefix}.checked`])}
            />
          </div>
          <div
            className={`${centeredClasses} py-1 px-0 py-md-0 px-md-4`}
            style={{ fontSize: fontSize.textSm, height: '36px' }}
          >
            {t('to_end_time')}
          </div>
          <div className={`${centeredClasses} col-12 col-md-3 col-lg-4 p-0`}>
            <FastField
              name={`${namePrefix}.end_time`}
              resourceName={resourceName}
              hideValue={!entry.checked}
              disabled={!entry.checked}
              component={TimeField}
              displayMode={fieldDisplayModes.GROWING_WIDTH_INPUT}
              onChange={async (event, onChange) => {
                await onChange(event);
                await setFieldTouched(`${namePrefix}.start_time`);
              }}
              shouldUpdate={produceShouldUpdate([], [], [`${namePrefix}.checked`])}
            />
          </div>
        </div>
      );
    }
    return { date: entry.date, element: resultElement };
  });
  const isEntriesListEmpty = isEmpty(compact(entryElements.map(ee => ee.element)));
  return (
    <ModalSection>
      {isEntriesListEmpty ? (
        t('no_entries')
      ) : (
        <>
          <FormRowWithoutField>
            <DefaultLink onClick={onReset}>{t('reset')}</DefaultLink>
          </FormRowWithoutField>
          {sortBy(entryElements, [ee => getUtcMomentIgnoringTime(ee.date)]).map(
            ({ element }) => element,
          )}
        </>
      )}
      <FormFooter>
        <FormFooterRightPart>
          <CancellationLink onClick={() => modalRef.current.hide()} />
          <BrandButton
            onClick={async () => {
              modalRef.current.hide({ shouldPersistChanges: true });
            }}
            disabled={hasKeyStartingWith(errors, 'swa_measure_date_time_entries_attributes')}
          >
            {t('confirm')}
          </BrandButton>
        </FormFooterRightPart>
      </FormFooter>
    </ModalSection>
  );
};
