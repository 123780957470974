import React from 'react';
import { FormSection } from '../../../components/form/FormSection';
import { FormSectionHeader } from '../../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../../components/form/FormSectionTitle';
import { FormSectionHeaderLink } from '../../../components/form/FormSectionHeaderLinks';
import { SimpleList } from '../../../components/lists/SimpleList';
import { handleSubmitWithAfterSave } from '../../../services/utils';

const t = key => I18n.t(`biological_safeties.new_edit.lists.assessments.${key}`);

export const Assessments = props => {
  const {
    isSubmitting,
    handleSubmit,
    setFieldValue,
    values: { id, inspections },
    access_to_assessments,
  } = props;

  return (
    <FormSection>
      <FormSectionHeader>
        <FormSectionTitle>{t('title')}</FormSectionTitle>
        <FormSectionHeaderLink
          hidden={!access_to_assessments}
          disabled={isSubmitting}
          onClick={async () => {
            const redirect = () => {
              window.location.href = `/inspections/new?investigable_id=${id}&investigable_type=BiologicalSafety`;
            };
            const afterSave = () => setTimeout(redirect, 1000);
            await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
          }}
        >
          {t('add_assessment')}
        </FormSectionHeaderLink>
      </FormSectionHeader>
      <SimpleList
        rowHrefs={
          access_to_assessments &&
          inspections.map(inspection => `/inspections/${inspection.id}/edit`)
        }
        resourceName={I18n.t('resource_names.biological_safety_assessment')}
        data={inspections.map(inspection => [
          [t('identifier'), inspection.identifier],
          [t('assessment_type'), inspection.assessment_type],
          [t('ehs_inspector'), inspection.ehs_inspector],
          [t('responsible_person'), inspection.responsible_person],
          [t('status'), inspection.status],
        ])}
      />
    </FormSection>
  );
};
