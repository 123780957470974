import React, { useEffect, useState } from 'react';
import { isDefined } from '../../services/utils';
import { Loader } from '../../components/Loader';
import { WasteCollectionCandidateForm } from './Form';
import { API } from '../../services/api';

export const WasteCollectionCandidate = props => {
  const { waste_request_id: wasteRequestId, id } = props;
  const [fetching, setFetching] = useState(true);
  const [candidate, setCandidate] = useState({});
  useEffect(() => {
    (async () => {
      const api = isDefined(id)
        ? API.requests.wasteRequests.wasteCollectionCandidates(wasteRequestId).show
        : API.requests.wasteRequests.wasteCollectionCandidates(wasteRequestId).new;
      const { ok, data } = await api(id);
      if (ok) {
        await setCandidate(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return (
    <WasteCollectionCandidateForm
      {...props}
      wasteRequestId={wasteRequestId}
      candidate={candidate}
    />
  );
};
export default WasteCollectionCandidate;
