import get from 'lodash/get';
import { API } from '../../../services/api';
import { prepareCustomFieldsAnswers, showBackendErrorMessage } from '../../../services/utils';

const t = key => I18n.t(`inventory.permits.lab_permits.new_edit.${key}`);

export const setPermitTypeData = async (newSelection, setFieldValue) => {
  await setFieldValue('permitTypeName', get(newSelection, 'data.text'));

  const { ok, data } = await API.permitTypes(newSelection.value)
    .fieldDefinitions()
    .index();

  if (ok) {
    const definitions = await data.data.map(fieldDefinition => fieldDefinition.attributes);
    const answers = await prepareCustomFieldsAnswers('permits_fdny', definitions);
    await setFieldValue('permitsFdnyFieldDefinitions', definitions);
    await setFieldValue('permits_fdny_field_answers_attributes', answers);
  } else {
    await showBackendErrorMessage(t('flash.error.unexpected_error'));
  }
};
