import React from 'react';
import { FastField } from 'formik';
import { FormHeader } from '../../../components/form/FormHeader';
import { handleSubmitWithAfterSave, isDefined } from '../../../services/utils';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { LocationFields } from '../../../components/fields/compositeFields/LocationFields';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { DateField } from '../../../components/fields/DateField';
import { DateTimeField } from '../../../components/fields/DateTimeField';
import { Footer } from './Footer';
import { GenerateDoorSignButton } from './GenerateDoorSignButton';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';
import { AsyncMultiSelectorField } from '../../../components/fields/AsyncMultiSelectorField';
import { TextAreaField } from '../../../components/fields/TextAreaField';
import { EmergencyContactProfileButtons } from './EmergencyContactProfile/EmergencyContactProfileButtons';
import { LogoProfileButtons } from './LogoProfile/LogoProfilesButtons';
import { HsdsEmergencyContactsList } from './HsdsEmergencyContacts/HsdsEmergencyContactsList';
import { Locations } from '../../../components/other/locations/Locations';

const t = key => I18n.t(`hsds.hazard_assessments.new_edit.${key}`);

export const Form = props => {
  const {
    isSubmitting,
    values: { id, access_restriction_3, permit_comments, additional_information },
    setFieldValue,
    handleSubmit,
    enums,
  } = props;
  const tooLongTextWarning = text => {
    const maxLengthOfText = 45;
    return text?.length > maxLengthOfText ? t('hints.too_long_text') : null;
  };
  return (
    <form>
      <FormHeader
        title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)}
        additionalActions={[isDefined(id) && <GenerateDoorSignButton spaceRiskAssessmentId={id} />]}
      />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.hsds_hazard_assessment',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.hsds_hazard_assessment'),
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField name="identifier" disabled component={TextField} />
          <LocationFields
            buildingSelectorAttributes={{ name: 'primary_building_id', required: true }}
            floorSelectorAttributes={{ name: 'primary_buildings_floor_id' }}
            spaceSelectorAttributes={{ name: 'primary_lab_id', required: true }}
            {...props}
          />
          <FastField
            name="space_name"
            required
            component={TextField}
            hint={t('hints.space_name')}
          />
          <FastField
            name="emergency_contact_profile_id"
            required
            api={API.selectors.emergencyContactProfiles.index}
            selectionApi={API.selectors.emergencyContactProfiles.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
            additionalContent={
              <EmergencyContactProfileButtons name="emergency_contact_profile_id" {...props} />
            }
          />
          <FastField name="date_posted" component={DateField} />
          <FastField name="updated_at" disabled component={DateTimeField} />
          <FastField name="include_emergency_contacts" component={CheckBoxField} />
        </FormSection>
        <FormSection title={t('sections.assessment')}>
          {enums.hazardRatingCategory.map(category => (
            <FastField
              name={`${category.value}_rating_id`}
              api={API.selectors.hazardRatings.index}
              apiParams={{ category: category.value }}
              selectionApi={API.selectors.hazardRatings.show}
              optionRenderer={htmlOptionRenderer('text')}
              component={AsyncSelectorField}
              isClearable
            />
          ))}
        </FormSection>
        <FormSection title={t('sections.additional_information')}>
          <FastField
            name="logo_profile_id"
            api={API.selectors.logoProfiles.index}
            selectionApi={API.selectors.logoProfiles.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
            additionalContent={<LogoProfileButtons name="logo_profile_id" {...props} />}
          />
          <FastField
            name="hsds_ppe_ids"
            api={API.selectors.ppes.index}
            selectionApi={API.selectors.ppes.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncMultiSelectorField}
          />
          <FastField
            name="hsds_access_restriction_ids"
            api={API.selectors.accessRestrictions.index}
            selectionApi={API.selectors.accessRestrictions.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncMultiSelectorField}
          />
          <FastField
            name="access_restriction_3"
            component={TextAreaField}
            rows={3}
            warning={tooLongTextWarning(access_restriction_3)}
          />
          <FastField
            name="permit_comments"
            component={TextAreaField}
            rows={3}
            warning={tooLongTextWarning(permit_comments)}
          />
          <FastField
            name="additional_information"
            hint={t('hints.additional_information')}
            component={TextAreaField}
            rows={3}
            warning={tooLongTextWarning(additional_information)}
          />
          <FastField
            name="internal_notes"
            hint={t('hints.internal_notes')}
            component={TextAreaField}
            rows={3}
          />
        </FormSection>
      </DefaultAttributesGroup>
      <Footer {...props} />
      <HsdsEmergencyContactsList {...props} />
      <Locations
        api={API.spaceRiskAssessments.locations}
        id={id}
        disabled={isSubmitting}
        title={t('lists.locations.title')}
        resourceName={I18n.t('resource_names.hsds_hazard_assessment_location')}
        humanizedResourceName={I18n.t('activerecord.models.hsds_hazard_assessment_location')}
        spaceSelectorAttributes={{ required: true }}
        handleAdditionLinkOnClick={open =>
          handleSubmitWithAfterSave(setFieldValue, handleSubmit, open)
        }
        prepareValuesToSubmit={values => ({
          space_risk_assessment_location: values,
        })}
      />
    </form>
  );
};
