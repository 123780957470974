import React from 'react';
import { Field, withFormik } from 'formik';
import { ModalSection } from '../../modals/ModalSection';
import { TextField } from '../TextField';
import { FormFooter, FormFooterRightPart } from '../../form/FormFooter';
import { CancellationLink } from '../../links/CancellationLink';
import { BrandButton } from '../../buttons/BrandButton';
import {
  defaultHandleSubmit,
  filterValues,
  produceDefaultValidation,
} from '../../../services/utils';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import { DefaultAttributesGroup } from '../../form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import yup from '../../form/CustomYupValidator';
import { httpPrefixedLink, isValidHttpLink } from '../../../services/linksUtils';

const t = key => I18n.t(`links_field.add_modal.${key}`);

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  url: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required'))
    .test('invalid_link', I18n.t('validations.invalid_link'), value =>
      isValidHttpLink(httpPrefixedLink(value)),
    ),
});

const defaultValues = { name: '', url: '' };

const Form = props => {
  const { hide, isSubmitting, setFieldValue, handleSubmit, resourceName } = props;
  return (
    <ModalSection>
      <form>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
            labelI18nKeyPrefix: 'links_field.add_modal',
            shouldUpdate: defaultShouldUpdate,
            required: true,
            resourceName,
          }}
        >
          <Field name="name" component={TextField} />
          <Field name="url" component={TextField} />
        </DefaultAttributesGroup>
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={hide} />
            <BrandButton onClick={e => defaultHandleSubmit(setFieldValue, handleSubmit, e)}>
              {t('save')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </form>
    </ModalSection>
  );
};

export const AddLinksModalContent = withFormik({
  mapPropsToValues: () => defaultValues,
  handleSubmit: async (values, formikProps) => {
    const { onPush, hide } = formikProps.props;
    const valuesToPush = filterValues(values, Object.keys(defaultValues));
    onPush(valuesToPush);
    hide();
  },
  validate: values => produceDefaultValidation(validationSchema)(values),
})(Form);
