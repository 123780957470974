import React, { useRef } from 'react';
import { FastField } from 'formik';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../components/form/FormFooter';
import { LightButton } from '../../../components/buttons/LightButton';
import { BrandButton } from '../../../components/buttons/BrandButton';
import { DeletionLink } from '../../../components/links/DeletionLink';
import { Modal } from '../../../components/modals/Modal';
import { ModalSection } from '../../../components/modals/ModalSection';
import { DefaultLink } from '../../../components/links/DefaultLink';
import { API } from '../../../services/api';
import {
  defaultHandleSubmit,
  isDefined,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../services/utils';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import { DateTimeField } from '../../../components/fields/DateTimeField';
import { mapToSelectorOptions } from '../../../services/fieldUtils';
import { SelectorField } from '../../../components/fields/SelectorField';
import { currentDateTime } from '../../../../services/dateFormatUtils';

const t = (key, params = {}) => I18n.t(`requests.general_requests.new_edit.${key}`, params);
const resolutionModalT = (key, params) => t(`modals.resolution.${key}`, params);

export const Footer = props => {
  const {
    isSubmitting,
    handleSubmit,
    setFieldValue,
    currentUser,
    enums: { completed_sub_status },
    values: { id, status },
    resource_name,
  } = props;

  const resolutionModalRef = useRef(null);
  const isCompleted = status === 'completed';
  const userIsAdmin = currentUser.app_admin || currentUser.org_admin;

  return (
    <>
      <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting, hidden: isCompleted }}>
        <FormFooterLeftPart>
          <DeletionLink
            withIcon
            hidden={!(id && userIsAdmin)}
            modelName={resource_name}
            onSubmit={async () => {
              const { ok, data } = await API.requests.generalRequests.destroy(id);
              if (ok) {
                setInitialFlashMessageForNextPage(t('flash.success.delete', { resource_name }));
                window.location.href = `/requests/general_requests?status=open`;
              } else {
                showBackendErrorMessage(t('flash.error.delete', { resource_name }), data);
              }
            }}
          />
        </FormFooterLeftPart>
        <FormFooterRightPart>
          <LightButton
            hidden={isCompleted}
            onClick={async () => {
              await setFieldValue('submitType', 'save');
              await defaultHandleSubmit(setFieldValue, handleSubmit);
            }}
          >
            {t('footer.save')}
          </LightButton>
          <BrandButton
            hidden={!isDefined(id) || isCompleted}
            onClick={async () => {
              await setFieldValue('auxiliaryResolutionDate', currentDateTime());
              resolutionModalRef.current.open();
            }}
          >
            {t('footer.resolve')}
          </BrandButton>
          <LightButton
            hidden={!isCompleted}
            onClick={async () => {
              await setFieldValue('submitType', 'reopen');
              await defaultHandleSubmit(setFieldValue, handleSubmit);
            }}
          >
            {t('footer.reopen')}
          </LightButton>
        </FormFooterRightPart>
      </FormFooter>
      <Modal title={resolutionModalT('title', { resource_name })} ref={resolutionModalRef}>
        <ModalSection>
          <FastField
            required
            name="auxiliaryCompletedSubStatus"
            label={I18n.t('activerecord.attributes.general_request.auxiliary_completed_sub_status')}
            displayMode={fieldDisplayModes.FULL_WIDTH_FORM_ROW}
            options={mapToSelectorOptions(completed_sub_status, 'header', 'value')}
            component={SelectorField}
          />
          <FastField
            required
            name="auxiliaryResolutionDate"
            label={I18n.t('activerecord.attributes.general_request.auxiliary_resolution_date')}
            displayMode={fieldDisplayModes.FULL_WIDTH_FORM_ROW}
            component={DateTimeField}
          />
          <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
            <FormFooterRightPart>
              <DefaultLink onClick={() => resolutionModalRef.current.hide()}>
                {resolutionModalT('footer.cancel')}
              </DefaultLink>
              <BrandButton
                onClick={async () => {
                  resolutionModalRef.current.hide();
                  await setFieldValue('submitType', 'resolve');
                  await defaultHandleSubmit(setFieldValue, handleSubmit);
                }}
              >
                {resolutionModalT('footer.resolve')}
              </BrandButton>
            </FormFooterRightPart>
          </FormFooter>
        </ModalSection>
      </Modal>
    </>
  );
};
