import { withFormik } from 'formik';
import { Form } from './Form';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`documents.new_edit.${key}`);

const DocumentFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const document = DocumentFunctions.prepareValuesToSubmit({ ...values });
    if (isDefined(document.id)) {
      DocumentFunctions.update(document, formikProps, values.afterSave);
    } else {
      DocumentFunctions.create(document, formikProps, values.afterSave);
    }
  },
  create: async (document, formikProps, afterSave) => {
    const { ok, data } = await API.documents.create({
      document,
    });
    if (ok) {
      window.history.pushState('', '', `/documents/${data.data.id}/edit${window.location.search}`);
      updateBreadcrumbs(I18n.t('breadcrumbs.documents.edit.title'));
      formikProps.resetForm({
        values: DocumentFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (document, formikProps, afterSave) => {
    const { ok, data } = await API.documents.update(document.id, { document });
    if (ok) {
      formikProps.resetForm({
        values: DocumentFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t(`flash.success.save`));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t(`flash.error.save`), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: {
    afterSave: () => {},
  },
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'identifier',
    'name',
    'show_on_community_portal',
    'document_type_id',
    'label_ids',
    'updated_at',
    'attachments',
    'links',
  ],
});

export const DocumentForm = withFormik({
  validate: produceDefaultValidation(validationSchema),
  mapPropsToValues: props => DocumentFunctions.prepareInitialValues(props.document),
  handleSubmit: DocumentFunctions.handleSubmit,
})(Form);
