import React, { useCallback } from 'react';
import { FastField } from 'formik';
import { FormSection } from '../../../../components/form/FormSection';
import { produceShouldFieldBeVisible } from './helpers';
import { RichTextField } from '../../../../components/fields/RichTextField';

const t = key => I18n.t(`requests.incident_requests.new_edit.${key}`);

const IncidentSummary = props => {
  const {
    defaultChildrenAttributes,
    values: { incidentFieldsConfig },
  } = props;

  const shouldFieldBeVisible = useCallback(
    field => produceShouldFieldBeVisible('summary', incidentFieldsConfig)(field),
    [incidentFieldsConfig],
  );

  return (
    <FormSection
      title={t('sections.incident_summary')}
      defaultChildrenAttributes={defaultChildrenAttributes}
    >
      {shouldFieldBeVisible('executive_summary') ? (
        <FastField
          name="executive_summary"
          hint={t('hints.executive_summary')}
          component={RichTextField}
        />
      ) : (
        <></>
      )}
    </FormSection>
  );
};

export default IncidentSummary;
