import React, { useRef } from 'react';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../components/form/FormFooter';
import { BrandButton } from '../../../components/buttons/BrandButton';
import {
  defaultHandleSubmit,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../services/utils';
import { DeletionLink } from '../../../components/links/DeletionLink';
import { API } from '../../../services/api';
import { Modal } from '../../../components/modals/Modal';
import { ConfirmationModalContent } from '../../../components/modals/ConfirmationModalContent';

const t = key => I18n.t(`administration.inspection_types.new_edit.${key}`);
const t_external = key => I18n.t(`administration.external_inspection_types.new_edit.${key}`);

export const Footer = ({
  isSubmitting,
  setFieldValue,
  handleSubmit,
  values: { id, anyRelatedInspections },
  currentUser,
  internalInspectionType,
}) => {
  const relatedInspectionsWarningModalRef = useRef(null);
  const translator = key => (internalInspectionType ? t(key) : t_external(key));
  const apiPath = internalInspectionType
    ? API.administration.assessmentTypes
    : API.administration.externalInspectionTypes;

  return (
    <>
      <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
        <FormFooterLeftPart>
          <DeletionLink
            withIcon
            hidden={!(id && (currentUser.app_admin || currentUser.org_admin))}
            modelName={I18n.t(
              `activerecord.models.${internalInspectionType ? '' : 'external_'}inspection_type`,
            )}
            onSubmit={async () => {
              const { ok, data } = await apiPath.destroy(id);
              if (ok) {
                const urlParams = `internal=${internalInspectionType ? 'true' : 'false'}`;
                setInitialFlashMessageForNextPage(translator('flash.success.remove'));
                window.location.href = `/administration/${
                  internalInspectionType ? 'inspection' : 'external_inspection'
                }_types?${urlParams}`;
              } else {
                showBackendErrorMessage(translator('flash.error.remove'), data);
              }
            }}
          />
        </FormFooterLeftPart>
        <FormFooterRightPart>
          <BrandButton
            onClick={async () => {
              if (anyRelatedInspections) {
                relatedInspectionsWarningModalRef.current.open();
              } else {
                await setFieldValue('submitType', 'save');
                await defaultHandleSubmit(setFieldValue, handleSubmit);
              }
            }}
          >
            {translator('footer.save')}
          </BrandButton>
        </FormFooterRightPart>
      </FormFooter>
      <Modal
        ref={relatedInspectionsWarningModalRef}
        title={translator('footer.related_inspection_warning_modal.title')}
      >
        <ConfirmationModalContent
          modalRef={relatedInspectionsWarningModalRef}
          hint={translator('footer.related_inspection_warning_modal.warning')}
          confirm={translator('footer.save')}
          onConfirm={async () => {
            await setFieldValue('submitType', 'save');
            await defaultHandleSubmit(setFieldValue, handleSubmit);
          }}
        />
      </Modal>
    </>
  );
};
