import React from 'react';
import { FastField } from 'formik';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate, sortFilesZA } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { FilesField } from '../../../components/fields/FilesField';
import { LinksField } from '../../../components/fields/LinksField';
import { DateField } from '../../../components/fields/DateField';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';

const t = key => I18n.t(`radiation_safety.rad_licenses.new_edit.${key}`);

export const Overview = () => {
  return (
    <DefaultAttributesGroup
      defaultChildrenAttributes={{
        labelI18nKeyPrefix: 'activerecord.attributes.rad_license',
        shouldUpdate: defaultShouldUpdate,
        resourceName: I18n.t('resource_names.rad_license'),
      }}
    >
      <FormSection title={t('sections.overview')}>
        <FastField name="identifier" disabled component={TextField} />
        <FastField name="license_number" required component={TextField} />
        <FastField name="description" component={TextField} />
        <FastField name="institution" component={TextField} />
        <FastField name="address" component={TextField} />
        <FastField name="issue_date" component={DateField} />
        <FastField name="expiration_date" component={DateField} />
        <FastField name="active" component={CheckBoxField} />
        <FastField name="attachments" component={FilesField} sortPredicate={sortFilesZA} />
        <FastField name="links" component={LinksField} />
      </FormSection>
    </DefaultAttributesGroup>
  );
};
