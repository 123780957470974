import React, { useRef, useState } from 'react';
import { FormSection } from '../../../../components/form/FormSection';
import { FormSectionHeader } from '../../../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../../../components/form/FormSectionTitle';
import { FormSectionHeaderLink } from '../../../../components/form/FormSectionHeaderLinks';
import { AsyncSimpleList } from '../../../../components/lists/AsyncSimpleList';
import { Modal } from '../../../../components/modals/Modal';
import { API } from '../../../../services/api';
import {
  SimpleListDeletionLink,
  SimpleListLink,
} from '../../../../components/lists/SimpleListLinks';
import {
  handleSubmitWithAfterSave,
  isDefined,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { EmptyListPlaceholder } from '../../../../components/lists/EmptyListPlaceholder';
import { GroupMembershipModalContent } from './GroupMembershipsModalContent';

const t = key => I18n.t(`inventory.people.people.${key}`);

export const GroupMemberships = props => {
  const {
    isSubmitting,
    setFieldValue,
    handleSubmit,
    values: { id },
  } = props;

  const groupMembershipModalRef = useRef(null);
  const listRef = useRef(null);
  const [groupMembership, setGroupMembership] = useState({});
  const [modalTitle, setModalTitle] = useState('');

  const openModal = async groupMembershipId => {
    const afterSave = async personId => {
      const { ok, data } = await API.people.people
        .group_memberships(personId)
        [groupMembershipId ? 'show' : 'new'](groupMembershipId);
      if (ok) {
        await setGroupMembership(data.data.attributes);
        await setModalTitle(
          t(`modals.group_memberships.title.${groupMembershipId ? 'edit' : 'new'}`),
        );
        await groupMembershipModalRef.current.open();
      }
    };
    await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
  };

  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{t('lists.group_memberships.title')}</FormSectionTitle>
          <FormSectionHeaderLink disabled={isSubmitting} onClick={() => openModal()}>
            {t('lists.group_memberships.add_group_membership')}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        {!isDefined(id) && <EmptyListPlaceholder />}
        {isDefined(id) && (
          <AsyncSimpleList
            api={API.people.people.group_memberships(id).index}
            ref={listRef}
            dataKey="data"
            resourceName={I18n.t('resource_names.group_memberships')}
            mapRowHrefs={({ attributes }) =>
              `/administration/people_groups/${attributes.people_group_id}/edit?active_tab=group_memberships`
            }
            mapData={({ attributes }) => [
              [t('lists.group_memberships.name'), attributes.group_name],
              [t('lists.group_memberships.role'), attributes.role_label],
              <SimpleListLink
                key="edit"
                disabled={isSubmitting}
                onClick={() => openModal(attributes.id)}
              >
                {t('lists.group_memberships.edit')}
              </SimpleListLink>,
              <SimpleListDeletionLink
                key="delete"
                disabled={isSubmitting}
                modelName={t('lists.group_memberships.model_name')}
                onSubmit={async () => {
                  const { ok, data, status } = await API.people.people
                    .group_memberships(id)
                    .destroy(attributes.id);
                  if (ok) {
                    listRef.current.refresh();
                    showSuccessMessage(t('lists.group_memberships.flash.delete.success'));
                  } else if (status === 403) {
                    showBackendErrorMessage(
                      t('lists.group_memberships.flash.delete.not_authorized'),
                      data,
                    );
                  } else {
                    showBackendErrorMessage(t('lists.group_memberships.flash.delete.error'), data);
                  }
                }}
              >
                {t('lists.group_memberships.delete')}
              </SimpleListDeletionLink>,
            ]}
          />
        )}
      </FormSection>
      <Modal title={modalTitle} ref={groupMembershipModalRef}>
        {modalProps => (
          <GroupMembershipModalContent
            groupMembership={groupMembership}
            listRef={listRef}
            personId={id}
            {...modalProps}
            {...props}
          />
        )}
      </Modal>
    </>
  );
};
