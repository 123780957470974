import React, { PureComponent } from 'react';
import debounce from 'lodash/fp/debounce';
import isNil from 'lodash/isNil';
import Select, { components } from 'react-select-next';
import { FieldWrapper } from './auxiliary/FieldWrapper';
import { fieldWrapperProps } from '../../services/fieldUtils';
import { selectorCustomStyles } from './auxiliary/selectorCustomStyles';
import { defaultOptionRenderer } from './selectorOptionRenderers/defaultOptionRenderer';
import { Loader } from '../Loader';
import { isDefined } from '../../services/utils';

export class AsyncSelectorField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      nextPage: 1,
      fetching: false,
      selectionFetching: false,
      firstPageLoaded: false,
      selection: '',
      needle: '',
    };
    this.fetchNextPage = this.fetchNextPage.bind(this);
  }

  componentDidMount() {
    this.prepareSelection();
  }

  componentDidUpdate(prevProps) {
    const {
      api,
      apiParams,
      field: { value },
    } = this.props;
    if (
      prevProps.api !== api ||
      JSON.stringify(prevProps.apiParams) !== JSON.stringify(apiParams)
    ) {
      this.resetOptions();
    }
    if (prevProps.field.value !== value) {
      this.prepareSelection();
    }
  }

  // needed to fix a bug regarding first pagination of react-select Selector
  MenuRenderer = props => {
    const { firstPageLoaded } = this.state;
    const key = firstPageLoaded ? 'menu-without-options' : 'menu-with-options';
    return <components.Menu {...props} key={key} />;
  };

  resetOptions(needle = '', cb = () => {}) {
    this.setState(
      {
        options: [],
        nextPage: 1,
        fetching: false,
        firstPageLoaded: false,
        needle,
      },
      cb,
    );
  }

  prepareSelection() {
    const {
      field: { name, value },
      form: { initialValues },
      initialSelectionData,
      selectionApi,
      loadSelectionFromInitialSelectionData,
    } = this.props;
    const { selection } = this.state;
    const initialValue = initialValues[name];
    if (!selection || selection.value !== value) {
      if (
        (loadSelectionFromInitialSelectionData || initialValue === value) &&
        initialSelectionData
      ) {
        this.setState({ selection: { value, data: initialSelectionData } });
      } else if (selectionApi && isDefined(value)) {
        this.setState({ selectionFetching: true });
        selectionApi(value).then(({ ok, data }) => {
          this.setState(ok ? { selection: { value, data } } : { selection: '' });
          this.setState({ selectionFetching: false });
        });
      } else if (!value) {
        this.setState({ selection: '' });
      }
    }
  }

  fetchNextPage() {
    const { api, apiParams, valueKey } = this.props;
    const { nextPage, options, fetching, needle } = this.state;
    if (!nextPage || fetching) {
      return;
    }
    this.setState({ fetching: true }, async () => {
      const { ok, data } = await api({ ...apiParams, page: nextPage, q: needle });
      if (ok) {
        const newOptions = data.results.map(r => ({
          value: r[valueKey],
          data: r,
        }));
        this.setState({
          firstPageLoaded: true,
          nextPage: data.pagination.more ? nextPage + 1 : undefined,
          options: options.concat(newOptions),
          fetching: false,
        });
      }
    });
  }

  render() {
    const {
      field: { name },
      form: { setFieldValue, setFieldTouched },
      disabled,
      isClearable = false,
      onChange,
      onBlur,
      placeholder,
      optionRenderer = defaultOptionRenderer('label'),
      headerRenderer = optionRenderer,
      resourceName,
      id,
    } = this.props;
    const { nextPage, options, fetching, selection, selectionFetching } = this.state;

    const inputId = id || `${resourceName}-${name}`;

    const defaultOnChange = async newSelection => {
      const value = isNil(newSelection) && isClearable ? null : newSelection.value;
      await setFieldValue(name, value);
      await setFieldTouched(name, true);
    };
    const defaultOnBlur = async () => {
      await setFieldTouched(name, true);
    };

    const onInputChange = needle => this.resetOptions(needle, this.fetchNextPage);

    const debouncedOnInputChange = debounce(600, onInputChange);

    return (
      <FieldWrapper {...fieldWrapperProps({ inputId, ...this.props })}>
        <div style={{ width: '100%' }} id={inputId}>
          {selectionFetching ? (
            <Loader />
          ) : (
            <Select
              classNamePrefix={inputId}
              value={selection}
              options={options}
              styles={selectorCustomStyles}
              isDisabled={disabled}
              isClearable={isClearable}
              placeholder={placeholder}
              menuPlacement="auto"
              onChange={newSelection => {
                this.setState({ selection: newSelection }, () => {
                  // eslint-disable-next-line no-unused-expressions
                  onChange
                    ? onChange(newSelection, defaultOnChange)
                    : defaultOnChange(newSelection);
                });
              }}
              onBlur={e => {
                // eslint-disable-next-line no-unused-expressions
                onBlur ? onBlur(e, defaultOnBlur) : defaultOnBlur();
              }}
              isLoading={fetching}
              filterOption={() => true}
              onInputChange={debouncedOnInputChange}
              onMenuOpen={() => nextPage === 1 && this.fetchNextPage()}
              onMenuScrollToBottom={this.fetchNextPage}
              formatOptionLabel={(option, { context }) =>
                context === 'value' ? headerRenderer(option.data) : optionRenderer(option.data)
              }
              components={{ Menu: this.MenuRenderer }}
            />
          )}
        </div>
      </FieldWrapper>
    );
  }
}

AsyncSelectorField.defaultProps = {
  valueKey: 'id',
};
