import {
  isDefined,
  prepareFormFunctions,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../../services/utils';
import { API } from '../../../../services/api';

const t = key => I18n.t(`inventory.people.people.new_edit.${key}`);

export const PersonFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const person = PersonFunctions.prepareValuesToSubmit({ ...values });
    if (isDefined(person.id)) {
      PersonFunctions.update(person, formikProps, values.afterSave, values.submitType);
    } else {
      PersonFunctions.create(person, formikProps, values.afterSave);
    }
  },
  prepareValuesToSubmit: values => {
    values.admin_ehs_portal_access = values.adminEhsPortalAccess;
    return PersonFunctions.applyBackendValuesWhitelist(values);
  },
  create: async (person, formikProps, afterSave) => {
    const { ok, data } = await API.people.people.create({
      person,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/inventory/people/people/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.inventory.people.people.edit.title'));
      formikProps.resetForm({
        values: PersonFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (person, formikProps, afterSave, submitType) => {
    const { org_admin } = formikProps.props.currentUser;
    const api = org_admin ? API.people.people.update : API.people.people.update_ehs_info;
    const { ok, data } = await api(person.id, { person });
    if (ok) {
      formikProps.resetForm({
        values: PersonFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      if (submitType === 'switchType' && !person.sso_based)
        showSuccessMessage(t(`flash.success.reset_password`));
      else showSuccessMessage(t(`flash.success.save`));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t(`flash.error.save`), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: person => ({
    submitType: 'save',
    hasUser: person.has_user,
    fromImport: person.from_import,
    relatedItems: person.related_items,
    fullName: person.full_name,
    accountType: I18n.t(`activerecord.attributes.person.account_types.${person.account_type}`),
    afterSave: () => {},
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'first_name',
    'last_name',
    'show_on_community_portal',
    'job_title',
    'department_id',
    'division_id',
    'uid',
    'email',
    'phone_office',
    'direct_manager_id',
    'campus_id',
    'building_id',
    'ehspoi_type_id',
    'active',
    'receive_notifications',
    'account_type',
    'org_admin',
    'safety_coordinator_ids',
    'sso_based',
  ],
});
