import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { colors } from '../../assets/styles/colors';
import { fontSize, fontWeights } from '../../assets/styles/typography';

ReactModal.defaultStyles = {
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
  },
  overlay: {
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '1060',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
};

ReactModal.setAppElement('body');

const Wrapper = styled.div`
  background: ${colors.grayBackgroundColor};
  width: 90%;
  max-width: 900px;
  margin: 2.75rem auto;
`;

const CloseButtonWrapper = styled.div`
  right: 10px;
  top: 5px;
  position: relative;
`;

const ModalHeader = styled.div`
  padding-left: 25px;
  padding-top: 1.7rem;
  padding-bottom: 1.7rem;
  color: ${colors.grayDark};
`;

const ModalTitle = styled.h1`
  font-size: ${fontSize.textXlg};
  font-weight: ${fontWeights.headers};
  margin: 0;
`;

const ModalSubtitle = styled.div`
  font-size: ${fontSize.textMd};
  padding-top: 16px;
`;

export class Modal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      onOpen: () => {},
      onHide: () => {},
      additionalProps: {},
    };
    this.setOnOpen = this.setOnOpen.bind(this);
    this.setOnHide = this.setOnHide.bind(this);
    this.open = this.open.bind(this);
    this.hide = this.hide.bind(this);
  }

  setOnOpen(onOpen) {
    this.setState({ onOpen });
  }

  setOnHide(onHide) {
    this.setState({ onHide });
  }

  setAdditionalProps(additionalProps) {
    this.setState({ additionalProps });
  }

  open(onOpenProps = undefined) {
    const { onOpen: onOpenEffect = () => {} } = this.props;
    const { onOpen } = this.state;
    onOpen();
    onOpenEffect(onOpenProps);
    this.setState({ isOpen: true });
  }

  hide(onHideProps = undefined) {
    const { onHide: onHideEffect = () => {} } = this.props;
    const { onHide } = this.state;
    onHide();
    onHideEffect(onHideProps);
    this.setState({ isOpen: false });
  }

  render() {
    const { isOpen, additionalProps } = this.state;
    const { children, title, subTitle, wrapperStyle = {}, ...props } = this.props;

    return (
      <ReactModal {...props} isOpen={isOpen} onRequestClose={this.hide}>
        <Wrapper style={wrapperStyle}>
          <CloseButtonWrapper>
            <button type="button" onClick={this.hide} className="btn-close close no-outline">
              &times;
            </button>
          </CloseButtonWrapper>
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
            {subTitle ? <ModalSubtitle>{subTitle}</ModalSubtitle> : <></>}
          </ModalHeader>
          {typeof children === 'function'
            ? children({
                setOnOpen: this.setOnOpen,
                setOnHide: this.setOnHide,
                open: this.open,
                hide: this.hide,
                ...additionalProps,
              })
            : children}
        </Wrapper>
      </ReactModal>
    );
  }
}
