import React from 'react';
import styled from 'styled-components';
import { fontWeights } from '../../assets/styles/typography';
import { DefaultLink } from '../links/DefaultLink';

const Wrapper = styled.div`
  font-size: 14px;
  margin-top: 0.75rem;
  &:not(:last-child) {
    padding-right: 16px;
  }
  ${props => (props.boldText ? `font-weight: ${fontWeights.headings}` : '')}
`;

export const DivJustifyEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SimpleListRowAdditionalText = props => {
  const { children, linkProps } = props;

  return (
    <Wrapper>
      <DivJustifyEnd>{children}</DivJustifyEnd>
      {linkProps ? (
        <DivJustifyEnd style={{ fontSize: '16px' }}>
          <DefaultLink {...linkProps} />
        </DivJustifyEnd>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};
