import yup from '../../../../components/form/CustomYupValidator';

export const validationSchema = yup.object().shape({
  biological_agent_type_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  biological_bug_species_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  biological_strain_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  bsl_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  risk_group_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  nih_applicability_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});
