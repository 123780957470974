import { withFormik } from 'formik';
import snakeCase from 'lodash/snakeCase';
import { Form } from './Form';
import {
  isDefined,
  produceDefaultValidation,
  showSuccessMessage,
  showBackendErrorMessage,
  updateBreadcrumbs,
  prepareFormFunctions,
  prepareCustomFieldsAnswers,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { validationSchema } from './validationSchema';

const t = (key, params = {}) => I18n.t(`requests.general_requests.new_edit.${key}`, params);

const GeneralRequestFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const generalRequest = GeneralRequestFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(generalRequest.id)) {
      GeneralRequestFunctions.create(generalRequest, formikProps);
    } else {
      GeneralRequestFunctions.update(generalRequest, formikProps, values.submitType);
    }
  },
  prepareValuesToSubmit: values => {
    if (values.submitType === 'resolve') {
      values.resolution_date = values.auxiliaryResolutionDate;
      values.sub_status = values.auxiliaryCompletedSubStatus;
    }

    return GeneralRequestFunctions.applyBackendValuesWhitelist(values);
  },
  create: async (generalRequest, formikProps) => {
    const { resource_name } = formikProps.props;
    const { ok, data } = await API.requests.generalRequests.create({
      general_request: generalRequest,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/requests/general_requests/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(t('title.edit', { resource_name }));
      formikProps.resetForm({
        values: GeneralRequestFunctions.prepareInitialValues(data.data.attributes),
      });
      showSuccessMessage(t('flash.success.save', { resource_name }));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save', { resource_name }), data);
    }
  },
  update: async (generalRequest, formikProps, submitType) => {
    const { resource_name } = formikProps.props;
    const { ok, data } = await API.requests.generalRequests[
      submitType === 'save' ? 'update' : submitType
    ](generalRequest.id, { general_request: generalRequest });
    if (ok) {
      formikProps.resetForm({
        values: GeneralRequestFunctions.prepareInitialValues(data.data.attributes),
      });
      showSuccessMessage(t(`flash.success.${snakeCase(submitType)}`, { resource_name }));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save', { resource_name }), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: generalRequest => {
    const isCompleted = generalRequest.status === 'completed';

    return {
      auxiliaryResolutionDate: isCompleted ? generalRequest.resolution_date : null,
      auxiliaryCompletedSubStatus: isCompleted ? generalRequest.sub_status : 'resolved_by_ehs',
      submitType: 'save',
      status: generalRequest.status,
      resourceName: generalRequest.resourceName,
      generalRequestTypeName: generalRequest.general_request_type_name,
      generalRequestFieldDefinitions: generalRequest.general_request_field_definitions,
    };
  },
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'identifier',
    'general_request_type_id',
    'requested_date',
    'creator_id',
    'also_notified_person_ids',
    'building_id',
    'buildings_floor_id',
    'lab_id',
    'location_details',
    'description',
    'request_attachments',
    'request_links',
    'internal_notes',
    'label_ids',
    'priority',
    'ehs_assignee_id',
    'due_date',
    'sub_status',
    'actions_taken',
    'resolution_attachments',
    'resolution_links',
    'resolution_date',
    'general_request_field_answers_attributes',
  ],
  preprocessBackendValues: backendValues => ({
    ...backendValues,
    general_request_field_answers_attributes: prepareCustomFieldsAnswers(
      'general_request',
      backendValues.general_request_field_definitions,
      backendValues.general_request_field_answers_attributes,
    ),
  }),
});

export const GeneralRequestForm = withFormik({
  validate: produceDefaultValidation(validationSchema),
  mapPropsToValues: props => GeneralRequestFunctions.prepareInitialValues(props.generalRequest),
  handleSubmit: GeneralRequestFunctions.handleSubmit,
})(Form);
