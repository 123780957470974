import React, { useRef } from 'react';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../../components/form/FormFooter';
import { DeletionLink } from '../../../../components/links/DeletionLink';
import {
  defaultHandleSubmit,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../../services/utils';
import { API } from '../../../../services/api';
import { BrandButton } from '../../../../components/buttons/BrandButton';
import { Modal } from '../../../../components/modals/Modal';
import { ManageUsersModalContent } from './ManageUsersModalContent';

const t = key => I18n.t(`inventory.people.people.new_edit.${key}`);

export const Footer = props => {
  const modalRef = useRef();

  const {
    isSubmitting,
    handleSubmit,
    setFieldValue,
    currentUser,
    values: { id, fromImport },
  } = props;
  const userIsAdmin = currentUser.app_admin || currentUser.org_admin;

  return (
    <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
      <FormFooterLeftPart>
        <DeletionLink
          withIcon
          disabled={isSubmitting || fromImport}
          hidden={!(id && userIsAdmin)}
          modelName={I18n.t('activerecord.models.person')}
          onSubmit={async () => {
            const { ok, data } = await API.people.people.destroy(id);
            if (ok) {
              setInitialFlashMessageForNextPage(t('flash.success.destroy'));
              window.location.href = '/inventory/people/people';
            } else {
              showBackendErrorMessage(t('flash.error.destroy'), data);
            }
          }}
        />
      </FormFooterLeftPart>
      <FormFooterRightPart>
        <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
          {t('footer.save')}
        </BrandButton>
      </FormFooterRightPart>
      <Modal title={t('modals.manage_user.title.create')} ref={modalRef}>
        <ManageUsersModalContent modalRef={modalRef} personId={id} {...props} />
      </Modal>
    </FormFooter>
  );
};
