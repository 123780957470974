import React, { useState } from 'react';
import Select from 'react-select-next';
import { FieldWrapper } from './auxiliary/FieldWrapper';
import { fieldWrapperProps } from '../../services/fieldUtils';
import { selectorCustomStyles } from './auxiliary/selectorCustomStyles';
import { colors } from '../../assets/styles/colors';

export const MultiTextSelectorField = props => {
  const {
    field: { name, value },
    form: { setFieldValue, setFieldTouched },
    disabled,
    onChange,
    onBlur,
    placeholder,
    headerRenderer = option => option,
    resourceName,
    id,
  } = props;

  const [currentInput, setCurrentInput] = useState('');
  const inputId = id || `${resourceName}-${name}`;

  const defaultOnChange = async (selections, { action, removedValue }) => {
    if (action === 'remove-value') {
      const newSelections = value.filter(v => v !== removedValue);
      await setFieldValue(name, newSelections);
      await setFieldTouched(name, true);
    } else {
      // handle clear all
      await setFieldValue(name, selections);
      await setFieldTouched(name, true);
    }
  };
  const defaultOnBlur = async () => {
    const selections = [...value, currentInput];
    // eslint-disable-next-line no-unused-expressions
    onChange ? await onChange(selections, defaultOnChange) : await defaultOnChange(selections, {});
    setCurrentInput('');
    await setFieldTouched(name, true);
  };

  return (
    <FieldWrapper {...fieldWrapperProps({ inputId, ...props })}>
      <div style={{ width: '100%' }}>
        <Select
          inputId={inputId}
          classNamePrefix={inputId}
          value={value}
          options={[]}
          noOptionsMessage={() => null}
          styles={{
            ...selectorCustomStyles,
            control: (provided, state) => ({
              display: 'flex',
              padding: '0 8px',
              border: state.menuIsOpen
                ? `1px solid ${colors.outline}`
                : `1px solid ${colors.inputBorderColor}`,
              borderRadius: '4px',
              background: state.isDisabled ? colors.disabled : colors.white,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            dropdownIndicator: () => ({
              display: 'none',
            }),
          }}
          isDisabled={disabled}
          placeholder={placeholder}
          menuPlacement="auto"
          onKeyDown={async e => {
            const enterCode = 13;
            if (e.key === 'Enter' || e.which === enterCode || e.keyCode === enterCode) {
              e.preventDefault();
              e.stopPropagation();
              const selections = value ? [...value, currentInput] : [currentInput];
              // eslint-disable-next-line no-unused-expressions
              onChange ? onChange(selections, defaultOnChange) : defaultOnChange(selections, {});
              setCurrentInput('');
            }
          }}
          inputValue={currentInput}
          onInputChange={setCurrentInput}
          onChange={(selections, e) =>
            onChange ? onChange(selections, e, defaultOnChange) : defaultOnChange(selections, e)
          }
          onBlur={e => {
            // eslint-disable-next-line no-unused-expressions
            onBlur ? onBlur(e, defaultOnBlur) : defaultOnBlur();
          }}
          formatOptionLabel={option => headerRenderer(option)}
          isMulti
        />
      </div>
    </FieldWrapper>
  );
};
