import React from 'react';
import { FastField } from 'formik';
import { DefaultAttributesGroup } from '../../../../components/form/DefaultAttributesGroup';
import { fieldDisplayModes } from '../../../../constants/fieldDisplayModes';
import { ModalSection } from '../../../../components/modals/ModalSection';
import { defaultShouldUpdate } from '../../../../services/fieldUtils';
import { API } from '../../../../services/api';
import { htmlOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../../components/fields/AsyncSelectorField';
import { FormFooter, FormFooterRightPart } from '../../../../components/form/FormFooter';
import { defaultOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { BrandButton } from '../../../../components/buttons/BrandButton';
import { defaultHandleSubmit } from '../../../../services/utils';
import { CancellationLink } from '../../../../components/links/CancellationLink';

const t = key => I18n.t(`training.employee_trainings.new_edit.modals.attendee.${key}`);

export const AttendeeForm = props => {
  const { setFieldValue, handleSubmit, isSubmitting, hide } = props;
  return (
    <form>
      <ModalSection>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
            labelI18nKeyPrefix: 'activerecord.attributes.employee_training_person',
            shouldUpdate: defaultShouldUpdate,
            resourceName: I18n.t('resource_names.employee_training_person'),
          }}
        >
          <FastField
            required
            name="attendee_id"
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
        </DefaultAttributesGroup>
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={hide} />
            <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
              {t('footer.save')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </ModalSection>
    </form>
  );
};
