import React from 'react';
import { Field } from 'formik';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../selectorOptionRenderers/defaultOptionRenderer';
import { AsyncSelectorField } from '../AsyncSelectorField';

const t = (key, props = {}) => I18n.t(`locations_section.${key}`, props);

export const LocationFields = ({
  defaultChildrenAttributes = {},
  defaultAttributes = defaultChildrenAttributes,
  buildingSelectorAttributes = {},
  floorSelectorAttributes = {},
  spaceSelectorAttributes = {},
  values,
  setFieldValue,
  apiParams,
  afterBuildingChange = () => {},
  afterFloorChange = () => {},
  afterSpaceChange = () => {},
}) => {
  const allDefaultAttributes = {
    ...defaultChildrenAttributes,
    ...defaultAttributes,
  };
  const buildingSelectorFinalAttributes = {
    name: 'building_id',
    label: t('building'),
    ...allDefaultAttributes,
    ...buildingSelectorAttributes,
  };
  const buildingIdName = buildingSelectorFinalAttributes.name;
  const floorSelectorFinalAttributes = {
    name: 'buildings_floor_id',
    label: t('floor'),
    ...allDefaultAttributes,
    ...floorSelectorAttributes,
  };
  const floorIdName = floorSelectorFinalAttributes.name;
  const spaceSelectorFinalAttributes = {
    name: 'lab_id',
    label: t('space'),
    ...allDefaultAttributes,
    ...spaceSelectorAttributes,
  };
  const spaceIdName = spaceSelectorFinalAttributes.name;

  return (
    <>
      <Field
        {...buildingSelectorFinalAttributes}
        api={API.selectors.buildings.index}
        apiParams={{ ...apiParams }}
        selectionApi={API.selectors.buildings.show}
        optionRenderer={htmlOptionRenderer('text')}
        headerRenderer={htmlOptionRenderer('extended_header')}
        onChange={async (event, onChange) => {
          await onChange(event);
          await setFieldValue(floorIdName, '');
          await setFieldValue(spaceIdName, '');
          afterBuildingChange(event);
        }}
        component={AsyncSelectorField}
      />
      <Field
        {...floorSelectorFinalAttributes}
        api={API.selectors.buildingsFloors.index}
        apiParams={{
          building_id: values[buildingIdName],
          ...apiParams,
        }}
        selectionApi={API.selectors.buildingsFloors.show}
        optionRenderer={htmlOptionRenderer('text')}
        headerRenderer={defaultOptionRenderer('header')}
        onChange={async (event, onChange) => {
          await onChange(event);
          if (values[buildingIdName] !== event.data.building_id) {
            await setFieldValue(buildingIdName, event.data.building_id);
          }
          await setFieldValue(spaceIdName, '');
          afterFloorChange(event);
        }}
        component={AsyncSelectorField}
      />
      <Field
        {...spaceSelectorFinalAttributes}
        api={API.selectors.labs.index}
        apiParams={{
          building_id: values[buildingIdName],
          building_floor: values[floorIdName],
          ...apiParams,
        }}
        selectionApi={API.selectors.labs.show}
        optionRenderer={htmlOptionRenderer('text')}
        headerRenderer={defaultOptionRenderer('header')}
        onChange={async (event, onChange) => {
          await onChange(event);
          if (values[buildingIdName] !== event.data.building_id) {
            await setFieldValue(buildingIdName, event.data.building_id);
          }
          if (values[floorIdName] !== event.data.buildings_floor_id) {
            await setFieldValue(floorIdName, event.data.buildings_floor_id);
          }
          afterSpaceChange(event);
        }}
        component={AsyncSelectorField}
      />
    </>
  );
};
