import yup from '../../../components/form/CustomYupValidator';

export const validationSchema = yup.object().shape({
  requested_date: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  creator_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  waste_generator_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  building_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  ehs_assignee_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});
