import React from 'react';
import styled from 'styled-components';
import { getFormikErrors } from '../../../services/formHelper';
import { FormSectionHeader } from './FormSectionHeader';
import { FormSectionTitle } from './FormSectionTitle';

const Wrapper = styled.div`
  margin-top: 1rem;
`;

export const FormSubtitle = ({ children, required, name, errors, touched }) => (
  <Wrapper>
    <FormSectionHeader>
      <FormSectionTitle required={required} error={getFormikErrors(name, touched, errors)}>
        {children}
      </FormSectionTitle>
    </FormSectionHeader>
  </Wrapper>
);
