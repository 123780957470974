import React, { useCallback } from 'react';
import { FastField } from 'formik';
import { FormSection } from '../../../../components/form/FormSection';
import { produceShouldFieldBeVisible } from './helpers';
import { API } from '../../../../services/api';
import { htmlOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncSelectorField } from '../../../../components/fields/AsyncSelectorField';
import { DateField } from '../../../../components/fields/DateField';
import { RichTextField } from '../../../../components/fields/RichTextField';

const t = key => I18n.t(`requests.incident_requests.new_edit.${key}`);

const IncidentCorrectiveActions = props => {
  const {
    defaultChildrenAttributes,
    values: { incidentFieldsConfig },
  } = props;

  const shouldFieldBeVisible = useCallback(
    field => produceShouldFieldBeVisible('corrective_actions', incidentFieldsConfig)(field),
    [incidentFieldsConfig],
  );

  return (
    <FormSection
      title={t('sections.incident_corrective_actions')}
      defaultChildrenAttributes={defaultChildrenAttributes}
    >
      {shouldFieldBeVisible('corrective_actions_required') ? (
        <FastField name="corrective_actions_required" component={RichTextField} />
      ) : (
        <></>
      )}
      {shouldFieldBeVisible('corrective_actions_due_date') ? (
        <FastField
          name="corrective_actions_due_date"
          hint={t('hints.corrective_actions_due_date')}
          component={DateField}
        />
      ) : (
        <></>
      )}
      {shouldFieldBeVisible('actions_taken') ? (
        <FastField name="actions_taken" component={RichTextField} />
      ) : (
        <></>
      )}
      {shouldFieldBeVisible('corrective_actions_completion_date') ? (
        <FastField name="corrective_actions_completion_date" component={DateField} />
      ) : (
        <></>
      )}
      {shouldFieldBeVisible('corrective_actions_recorded_by_person_id') ? (
        <FastField
          name="corrective_actions_recorded_by_person_id"
          api={API.selectors.people.index}
          selectionApi={API.selectors.people.show}
          optionRenderer={htmlOptionRenderer('text')}
          headerRenderer={defaultOptionRenderer('full_name')}
          component={AsyncSelectorField}
        />
      ) : (
        <></>
      )}
    </FormSection>
  );
};

export default IncidentCorrectiveActions;
