import React from 'react';
import { FastField } from 'formik';
import { FormHeader } from '../../../components/form/FormHeader';
import { isDefined } from '../../../services/utils';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate, mapToSelectorOptions } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { SelectorField } from '../../../components/fields/SelectorField';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';
import { ChecklistBuilder } from './ChecklistBuilder/ChecklistBuilder';
import { Footer } from './Footer';
import { RichTextField } from '../../../components/fields/RichTextField';

const t = key => I18n.t(`administration.inspection_types.new_edit.${key}`);
const t_external = key => I18n.t(`administration.external_inspection_types.new_edit.${key}`);

export const Form = props => {
  const {
    errors,
    touched,
    values: { id, anyRelatedInspections, objectTypeConfiguration },
    enums: { info_box_type },
    resourceCustomNames,
    internalInspectionType,
  } = props;

  const translator = key => (internalInspectionType ? t(key) : t_external(key));
  const labelKeyPrefix = `activerecord.attributes.${
    internalInspectionType ? '' : 'external_'
  }inspection_type`;

  return (
    <form>
      <FormHeader title={translator(`title.${isDefined(id) ? 'edit' : 'new'}`)} />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: labelKeyPrefix,
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.inspection_type'),
        }}
      >
        <FormSection title={translator('sections.overview')}>
          <FastField name="identifier" disabled component={TextField} />
          <FastField
            name="inspection_type_name"
            required
            component={TextField}
            hint={translator('hints.inspection_type_name')}
          />
          {internalInspectionType && (
            <FastField
              name="people_group_id"
              required={internalInspectionType}
              disabled={anyRelatedInspections}
              api={API.selectors.peopleGroups.index}
              selectionApi={API.selectors.peopleGroups.show}
              optionRenderer={htmlOptionRenderer('text')}
              component={AsyncSelectorField}
            />
          )}
          <FastField
            name="archive_it"
            required
            options={[
              { value: false, data: I18n.t('activerecord.attributes.inspection_type.active') },
              { value: true, data: I18n.t('activerecord.attributes.inspection_type.inactive') },
            ]}
            component={SelectorField}
          />
          {internalInspectionType && (
            <FastField name="allow_cp_findings" component={CheckBoxField} />
          )}
          {internalInspectionType && (
            <FastField
              name="should_capture_investigable_snapshot"
              component={CheckBoxField}
              hint={translator('hints.should_capture_investigable_snapshot')}
            />
          )}
          <FastField
            name="should_prepopulate_answers"
            component={CheckBoxField}
            hint={translator('hints.should_prepopulate_answers')}
          />
        </FormSection>
        <FormSection
          title={translator('sections.object_types')}
          required
          name="objectTypeConfiguration"
          errors={errors}
          touched={touched}
        >
          <DefaultAttributesGroup
            defaultChildrenAttributes={{
              shouldUpdate: defaultShouldUpdate,
              resourceName: I18n.t('resource_names.inspection_type'),
            }}
          >
            {Object.keys(objectTypeConfiguration).map(objectType => (
              <FastField
                name={objectType}
                label={I18n.t(`${labelKeyPrefix}.object_types.${objectType}`, {
                  resource_name:
                    objectType in resourceCustomNames ? resourceCustomNames[objectType] : null,
                })}
                value={objectTypeConfiguration[objectType]}
                component={CheckBoxField}
              />
            ))}
          </DefaultAttributesGroup>
        </FormSection>
        <FormSection title={translator('sections.info_box')}>
          <FastField name="info_box_show" component={CheckBoxField} />
          <FastField
            name="info_box_type"
            component={SelectorField}
            options={mapToSelectorOptions(info_box_type, 'header', 'value')}
          />
          <FastField name="info_box_content" component={RichTextField} />
        </FormSection>
        <FormSection
          title={translator('sections.checklist')}
          required
          name="inspectionFindingDefinitionsPerCategory"
          errors={errors}
          touched={touched}
        >
          <ChecklistBuilder {...props} />
        </FormSection>
      </DefaultAttributesGroup>
      <Footer {...props} />
    </form>
  );
};
