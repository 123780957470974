import { withFormik } from 'formik';
import {
  prepareFormFunctions,
  prepareHasManyRelationToSubmit,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../../services/utils';
import { API } from '../../../../../services/api';
import { Form } from './Form';
import { validationSchema } from './validationSchema';

const t = key =>
  I18n.t(`administration.inspection_types.new_edit.checklist.question_details_modal.${key}`);

const QuestionDetailsFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const question = QuestionDetailsFunctions.prepareValuesToSubmit({ ...values });
    QuestionDetailsFunctions.update(question, formikProps, values.afterSave);
  },
  prepareValuesToSubmit: values => {
    values.visibility_conditions_attributes = prepareHasManyRelationToSubmit(
      values.initialVisibilityConditions,
      values.visibilityConditions,
    );
    return QuestionDetailsFunctions.applyBackendValuesWhitelist(values);
  },
  update: async (question, formikProps, afterSave) => {
    const { ok, data } = await API.administration.inspectionFindingDefinitionsTypes.update(
      question.id,
      {
        inspection_finding_definitions_type: question,
      },
    );
    if (ok) {
      formikProps.resetForm({
        values: QuestionDetailsFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      formikProps.props.updateQuestion(data.data.id, data.data.attributes);
      formikProps.props.hide();
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: question => ({
    initialVisibilityConditions: question.visibility_conditions || [],
    visibilityConditions: question.visibility_conditions || [],
    afterSave: () => {},
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'inspection_type_id',
    'inspection_finding_definition_name',
    'logical_operator_type',
    'visibility_conditions_attributes',
  ],
});

export const QuestionDetailsModalContent = withFormik({
  mapPropsToValues: props => QuestionDetailsFunctions.prepareInitialValues(props.question),
  handleSubmit: QuestionDetailsFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
