import React, { useRef } from 'react';
import { FastField } from 'formik';
import { FormHeader } from '../../../../components/form/FormHeader';
import { isDefined } from '../../../../services/utils';
import { DefaultAttributesGroup } from '../../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate, mapToSelectorOptions } from '../../../../services/fieldUtils';
import { FormSection } from '../../../../components/form/FormSection';
import { TextField } from '../../../../components/fields/TextField';
import { Footer } from './Footer';
import { CheckBoxField } from '../../../../components/fields/CheckBoxField';
import { API } from '../../../../services/api';
import { htmlOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../../components/fields/AsyncSelectorField';
import { AsyncMultiSelectorField } from '../../../../components/fields/AsyncMultiSelectorField';
import { defaultOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { SelectorField } from '../../../../components/fields/SelectorField';
import { GroupMemberships } from './GroupMemberships';
import { RelatedItems } from './RelatedItems';
import { Modal } from '../../../../components/modals/Modal';
import { ResetPasswordModalContent } from './ResetPasswordModalContent';
import { ManageUsersModalContent } from './ManageUsersModalContent';
import { AdditionalButtonsSection } from './AdditionalButtonsSection';

const t = (key, params = {}) => I18n.t(`inventory.people.people.new_edit.${key}`, params);
const personT = (key, params = {}) => I18n.t(`activerecord.attributes.person.${key}`, params);

const personStatuses = [
  { header: personT('statuses.active'), value: true, id: 'active' },
  { header: personT('statuses.inactive'), value: false, id: 'inactive' },
];

export const Form = props => {
  const resetPasswordModalRef = useRef();
  const manageUserModalRef = useRef();

  const {
    values: { id, hasUser, fromImport },
    currentUser: { org_admin },
  } = props;

  const showSaluteAdminWarning = () => {
    if (hasUser) return false;
    if (fromImport) return I18n.t('activerecord.attributes.person.admin_ehs_portal_access_warning');

    return I18n.t('activerecord.attributes.person.admin_ehs_portal_access_warning_no_account');
  };

  return (
    <form>
      <FormHeader
        title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)}
        pdfExportLink={
          isDefined(id) ? `/inventory/people/people/${id}/safety_summary_report.js` : ''
        }
      />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.person',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.person'),
          disabled: !org_admin || fromImport,
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField name="id" disabled component={TextField} />
          <FastField name="first_name" required component={TextField} />
          <FastField name="last_name" required component={TextField} />
          <FastField name="job_title" component={TextField} />
          <FastField
            name="department_id"
            api={API.selectors.departments.index}
            selectionApi={API.selectors.departments.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField
            name="division_id"
            api={API.selectors.divisions.index}
            selectionApi={API.selectors.divisions.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField
            name="uid"
            required
            component={TextField}
            hint={I18n.t('activerecord.attributes.person.hints.uid_hint')}
          />
          <FastField name="email" required component={TextField} />
          <FastField name="phone_office" component={TextField} />
          <FastField
            name="direct_manager_id"
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField
            name="campus_id"
            api={API.selectors.campuses.index}
            selectionApi={API.selectors.campuses.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField
            name="building_id"
            api={API.selectors.buildings.index}
            selectionApi={API.selectors.buildings.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
        </FormSection>
        <FormSection title={t('sections.account')}>
          <FastField
            name="accountType"
            disabled
            component={TextField}
            label={I18n.t(`activerecord.attributes.person.account_type`)}
          />
          <AdditionalButtonsSection
            resetPasswordModalRef={resetPasswordModalRef}
            manageUserModalRef={manageUserModalRef}
            {...props}
          />
          <FastField
            name="active"
            required
            options={mapToSelectorOptions(personStatuses, 'header', 'value')}
            component={SelectorField}
          />
          <FastField
            name="org_admin"
            disabled={!org_admin || !isDefined(id) || !hasUser}
            component={CheckBoxField}
            warning={showSaluteAdminWarning()}
          />
        </FormSection>
      </DefaultAttributesGroup>
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.person',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.person'),
        }}
      >
        <FormSection title={t('sections.ehs_info')}>
          <FastField
            name="safety_coordinator_ids"
            label={I18n.t('activerecord.attributes.person.safety_coordinator_ids')}
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncMultiSelectorField}
          />
          <FastField
            name="ehspoi_type_id"
            api={API.selectors.ehspoiTypes.index}
            selectionApi={API.selectors.ehspoiTypes.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField name="receive_notifications" required component={CheckBoxField} />
        </FormSection>
      </DefaultAttributesGroup>
      <GroupMemberships {...props} />
      <Footer {...props} />
      <RelatedItems {...props} />
      <Modal title={t('modals.reset_password.title.reset_password')} ref={resetPasswordModalRef}>
        <ResetPasswordModalContent modalRef={resetPasswordModalRef} {...props} />
      </Modal>
      <Modal title={t('modals.manage_user.title.create')} ref={manageUserModalRef}>
        <ManageUsersModalContent modalRef={manageUserModalRef} personId={id} {...props} />
      </Modal>
    </form>
  );
};
