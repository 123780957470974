import React from 'react';

export const YesNoNaInput = props => {
  const { id, disabled, onChange, answer } = props;

  // eslint-disable-next-line consistent-return
  const onClick = value => {
    if (disabled) return false;
    onChange(value);
  };

  return (
    <div className="checklist_questions_table">
      <div className="row row-yes-no row-no-margin">
        <div
          className="col yes-no-container btn-group-toggle"
          data-toggle={disabled ? '' : 'buttons'}
        >
          <button
            id={`${id}-yes_answer`}
            type="button"
            disabled={disabled}
            onClick={() => onClick(1)}
            className={`btn btn-sm yes-no-btn yes-btn ${answer === '1' ? 'active' : ''}`}
          >
            <input
              autoComplete="off"
              type="radio"
              disabled={disabled}
              value="1"
              checked={answer === '1'}
            />
            <span className="glyphicon glyphicon-ok" />
          </button>
          <button
            id={`${id}-no_answer`}
            type="button"
            disabled={disabled}
            onClick={() => onClick(2)}
            className={`btn btn-sm yes-no-btn no-btn ${answer === '2' ? 'active' : ''}`}
          >
            <input
              autoComplete="off"
              type="radio"
              disabled={disabled}
              value="2"
              checked={answer === '2'}
            />
            <span className="glyphicon glyphicon-remove" />
          </button>
          <button
            id={`${id}-na_answer`}
            type="button"
            onClick={() => onClick(3)}
            className={`btn btn-sm yes-no-btn NA-btn ${answer === '3' ? 'active' : ''}`}
            disabled={disabled}
          >
            <input
              autoComplete="off"
              type="radio"
              disabled={disabled}
              value="3"
              checked={answer === '3'}
            />
            <span className="txt">N/A</span>
          </button>
        </div>
      </div>
    </div>
  );
};
