import { withFormik } from 'formik';
import { Form } from './Form';
import { API } from '../../../services/api';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`training.employee_trainings.new_edit.${key}`);

const EmployeeTrainingFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const employeeTraining = EmployeeTrainingFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(employeeTraining.id)) {
      EmployeeTrainingFunctions.create(employeeTraining, formikProps, values.afterSave);
    } else {
      EmployeeTrainingFunctions.update(employeeTraining, formikProps, values.afterSave);
    }
  },
  create: async (employeeTraining, formikProps, afterSave) => {
    const { ok, data } = await API.employeeTrainings.employeeTrainings.create({
      employee_training: employeeTraining,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/training/employee_trainings/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.training.employee_trainings.edit.title'));
      formikProps.resetForm({
        values: EmployeeTrainingFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (employeeTraining, formikProps, afterSave) => {
    const { ok, data } = await API.employeeTrainings.employeeTrainings.update(employeeTraining.id, {
      employee_training: employeeTraining,
    });
    if (ok) {
      formikProps.resetForm({
        values: EmployeeTrainingFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: employeeTraining => ({
    afterSave: () => {},
    attendees: employeeTraining.attendees,
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'identifier',
    'training_type_id',
    'training_date',
    'trainer_name',
    'link',
    'attachments',
    'source',
    'expiration_date',
    'investigation_links',
    'details_links',
    'internal_links',
    'training_format_id',
  ],
});

export const EmployeeTrainingForm = withFormik({
  mapPropsToValues: props => EmployeeTrainingFunctions.prepareInitialValues(props.employeeTraining),
  handleSubmit: EmployeeTrainingFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
