import { API } from './api';
import { htmlOptionRenderer } from '../components/fields/selectorOptionRenderers/htmlOptionRenderer';

export const asyncSelectorDefaultConfig = type => {
  switch (type) {
    case 'bsl':
      return {
        api: API.selectors.bsls.index,
        selectionApi: API.selectors.bsls.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'building':
      return {
        api: API.selectors.buildings.index,
        selectionApi: API.selectors.buildings.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'building_floor':
      return {
        api: API.selectors.buildingsFloors.index,
        selectionApi: API.selectors.buildingsFloors.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'campus':
      return {
        api: API.selectors.campuses.index,
        selectionApi: API.selectors.campuses.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'division':
      return {
        api: API.selectors.divisions.index,
        selectionApi: API.selectors.divisions.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'department':
      return {
        api: API.selectors.departments.index,
        selectionApi: API.selectors.departments.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'floor':
      return {
        api: API.selectors.floors.index,
        selectionApi: API.selectors.floors.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'lab_use_type':
      return {
        api: API.selectors.labUseTypes.index,
        selectionApi: API.selectors.labUseTypes.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'person':
      return {
        api: API.selectors.people.index,
        selectionApi: API.selectors.people.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'biological_agent_type':
      return {
        api: API.selectors.biologicalAgentTypes.index,
        selectionApi: API.selectors.biologicalAgentTypes.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'biological_bug_species':
      return {
        api: API.selectors.biologicalBugSpecies.index,
        selectionApi: API.selectors.biologicalBugSpecies.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'biological_strain':
      return {
        api: API.selectors.biologicalStrains.index,
        selectionApi: API.selectors.biologicalStrains.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'absl':
      return {
        api: API.selectors.absls.index,
        selectionApi: API.selectors.absls.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'replication_ability':
      return {
        api: API.selectors.replicationAbilities.index,
        selectionApi: API.selectors.replicationAbilities.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'risk_group':
      return {
        api: API.selectors.riskGroups.index,
        selectionApi: API.selectors.riskGroups.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'ibc_status':
      return {
        api: API.selectors.ibcStatuses.index,
        selectionApi: API.selectors.ibcStatuses.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'nih_applicability':
      return {
        api: API.selectors.nihApplicabilities.index,
        selectionApi: API.selectors.nihApplicabilities.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'rad_isotope':
      return {
        api: API.selectors.radIsotopes.index,
        selectionApi: API.selectors.radIsotopes.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'rad_compound':
      return {
        api: API.selectors.radCompounds.index,
        selectionApi: API.selectors.radCompounds.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'rad_item_type':
      return {
        api: API.selectors.radItemTypes.index,
        selectionApi: API.selectors.radItemTypes.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'rad_inventory_item_status':
      return {
        api: API.selectors.radInventoryItemStatuses.index,
        selectionApi: API.selectors.radInventoryItemStatuses.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'rad_form':
      return {
        api: API.selectors.radForms.index,
        selectionApi: API.selectors.radForms.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'rad_inventory':
      return {
        api: API.selectors.radInventories.index,
        selectionApi: API.selectors.radInventories.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'vendor':
      return {
        api: API.selectors.vendors.index,
        selectionApi: API.selectors.vendors.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'rad_user':
      return {
        api: API.selectors.radUsers.index,
        selectionApi: API.selectors.radUsers.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'rad_license_item_assignment':
      return {
        api: API.selectors.radLicenseItemAssignments.index,
        selectionApi: API.selectors.radLicenseItemAssignments.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    case 'rad_purchase_status':
      return {
        api: API.selectors.radPurchaseStatuses.index,
        selectionApi: API.selectors.radPurchaseStatuses.show,
        optionRenderer: htmlOptionRenderer('text'),
      };
    default:
      throw new TypeError('Unknown key passed to asyncSelectorConfig');
  }
};
