import { withFormik } from 'formik';
import { Form } from './Form';
import {
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';
import { API } from '../../../services/api';
import yup from '../../../components/form/CustomYupValidator';

const t = key => I18n.t(`settings.index.${key}`);

const updateTimeZoneInHeader = timeZone => {
  // eslint-disable-next-line no-undef
  $('.current-user-time-zone').text(timeZone);
};

const SettingsFunctions = prepareFormFunctions({
  handleSubmit: async (values, formikProps) => {
    const settings = SettingsFunctions.prepareValuesToSubmit({ ...values });
    const { ok, data } = await API.settings.update({ settings });
    if (ok) {
      formikProps.resetForm({
        values: SettingsFunctions.prepareInitialValues(data),
      });
      showSuccessMessage(t('flash.success.save'));
      updateTimeZoneInHeader(data.time_zone_label);
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: settings => ({
    browserTimeZone: settings.browser_time_zone,
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: ['use_browser_time_zone', 'fixed_time_zone'],
});

export const SettingsForm = withFormik({
  mapPropsToValues: props => SettingsFunctions.prepareInitialValues(props.settings),
  handleSubmit: SettingsFunctions.handleSubmit,
  validate: produceDefaultValidation(yup.object()),
})(Form);
