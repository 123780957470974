import React, { useRef } from 'react';
import { Modal } from '../../../../../components/modals/Modal';
import { NewCategoryModalContent } from './NewCategoryModalContent';
import { NewChecklistElementBtn } from './NewChecklistElementButton';

export const NewCategorySectionBtn = props => {
  const addCategoryModalRef = useRef(null);

  return (
    <div className="px-3">
      <NewChecklistElementBtn
        elementType="category_section"
        addElement={e => {
          addCategoryModalRef.current.open();
          e.preventDefault();
        }}
      >
        {I18n.t('administration.inspection_types.new_edit.checklist.new_section')}
      </NewChecklistElementBtn>
      <Modal
        title={I18n.t(
          'administration.inspection_types.new_edit.checklist.add_category_modal.title',
        )}
        ref={addCategoryModalRef}
      >
        {modalProps => <NewCategoryModalContent {...modalProps} {...props} />}
      </Modal>
    </div>
  );
};
