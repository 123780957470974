import React from 'react';
import { FastField, withFormik } from 'formik';
import yup from '../../../components/form/CustomYupValidator';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import { API } from '../../../services/api';
import {
  defaultHandleSubmit,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';
import { ModalSection } from '../../../components/modals/ModalSection';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate, produceShouldUpdate } from '../../../services/fieldUtils';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { FormFooter, FormFooterRightPart } from '../../../components/form/FormFooter';
import { CancellationLink } from '../../../components/links/CancellationLink';
import { BrandButton } from '../../../components/buttons/BrandButton';

const t = (key, props = {}) => I18n.t(`radiation_safety.rad_inventories.new_edit.${key}`, props);

const Form = props => {
  const { hide, isSubmitting, setFieldValue, handleSubmit, currentInventoryId, values } = props;
  const { id, target_rad_inventory_id, auxiliary_rad_permit_id } = values;

  return (
    <ModalSection>
      <form>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
            shouldUpdate: defaultShouldUpdate,
            resourceName: I18n.t('resource_names.rad_inventory'),
          }}
        >
          <FastField
            name="target_rad_inventory_id"
            label={t('modals.move_to_another_inventory.target_rad_inventory_id')}
            required
            api={API.selectors.radInventories.index}
            apiParams={{ without_id: currentInventoryId }}
            selectionApi={API.selectors.radInventories.show}
            onChange={async (event, onChange) => {
              await setFieldValue('auxiliary_rad_permit_id', event?.data?.rad_permit_id);
              await onChange(event);
            }}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField
            name="target_rad_license_item_assignment_id"
            label={t('modals.move_to_another_inventory.target_rad_license_item_assignment_id')}
            disabled={!(target_rad_inventory_id && auxiliary_rad_permit_id)}
            required
            api={API.selectors.radLicenseItemAssignments.index}
            apiParams={{
              rad_permit_id: auxiliary_rad_permit_id,
              with_rad_inventory_item_id_compatibility: id,
            }}
            shouldUpdate={produceShouldUpdate([], [], ['id', 'auxiliary_rad_permit_id'])}
            hint={t('modals.move_to_another_inventory.target_rad_license_item_assignment_id_hint')}
            selectionApi={API.selectors.radLicenseItemAssignments.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
        </DefaultAttributesGroup>
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={hide} />
            <BrandButton onClick={e => defaultHandleSubmit(setFieldValue, handleSubmit, e)}>
              {t('modals.move_to_another_inventory.move')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </form>
    </ModalSection>
  );
};

export const MoveItemToAnotherInventory = withFormik({
  mapPropsToValues: props => ({
    id: props.id,
    target_rad_inventory_id: null,
    auxiliary_rad_permit_id: null,
    target_rad_license_item_assignment_id: null,
  }),
  validationSchema: yup.object().shape({
    target_rad_inventory_id: yup
      .string()
      .nullable()
      .required(I18n.t('validations.required')),
    target_rad_license_item_assignment_id: yup
      .string()
      .nullable()
      .required(I18n.t('validations.required')),
  }),
  handleSubmit: async (values, formikProps) => {
    const { hide, onSuccess, currentInventoryId } = formikProps.props;
    const api = API.radiationSafety.radInventoryItems(currentInventoryId);
    const { ok, data } = await api.moveToAnotherInventory(values.id, values);
    if (ok) {
      formikProps.resetForm();
      hide();
      onSuccess();
      showSuccessMessage(t('flash.success.move_to_another_inventory'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.move_to_another_inventory'), data);
    }
  },
})(Form);
