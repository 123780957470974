import React, { useState, useEffect, PureComponent, useMemo, useCallback } from 'react';
import get from 'lodash/get';
import { arrayMove } from '@dnd-kit/sortable';
import { SimpleList } from './SimpleList';
import { Loader } from '../Loader';
import { getUuid } from '../../../services/uuidHelper';

const AsyncSimpleListContent = props => {
  const {
    api,
    apiParams,
    dataKey = '',
    idKey = 'id',
    mapRowHrefs,
    refreshUuid,
    innerRefreshUuid,
    remapDataUuid,
    innerRemapDataUuid,
    onDataFetched = () => {},
    mapData = data => data,
  } = props;
  const [fetching, setFetching] = useState(true);
  const [fetchedData, setFetchedData] = useState([]);
  useEffect(() => {
    (async () => {
      if (!api) {
        setFetchedData([]);
        setFetching(false);
        return;
      }
      const { ok, data } = await api(apiParams);
      if (ok) {
        const newFetchedData = get(data, dataKey, []);
        setFetchedData(newFetchedData);
        onDataFetched(newFetchedData);
        setFetching(false);
      }
    })();
    // api && api.toString() - no need refresh the list on the reference change,
    // it is required only if the actual body of the function is affected
  }, [api && api.toString(), apiParams, refreshUuid, innerRefreshUuid]);

  const ids = useMemo(() => fetchedData.map(d => get(d, idKey)), [fetchedData]);
  const rowHrefs = useMemo(() => mapRowHrefs && fetchedData.map(mapRowHrefs), [
    fetchedData,
    mapRowHrefs && mapRowHrefs.toString(),
  ]);
  const data = useMemo(() => fetchedData.map(mapData), [
    remapDataUuid,
    innerRemapDataUuid,
    fetchedData,
    mapData.toString(),
  ]);
  const onReorderItems = useCallback(
    ({ oldIndex, newIndex }) => {
      setFetchedData(arrayMove(fetchedData, oldIndex, newIndex));
    },
    [fetchedData],
  );

  return (
    <>
      {fetching && <Loader />}
      {!fetching && (
        <SimpleList
          {...props}
          ids={ids}
          rowHrefs={rowHrefs}
          data={data}
          onReorderItems={onReorderItems}
        />
      )}
    </>
  );
};

// One can force AsyncSimple list to fetch data again by changing a value of refreshUuid prop
// or by using refresh function explicitly on the component reference.
// Similarly with respectively remapDataUuid prop and remapData function to rerender list rows
// (but without fetching data again).
export class AsyncSimpleList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      refreshUuid: getUuid(),
      remapDataUuid: getUuid(),
    };
  }

  refresh = () => this.setState({ refreshUuid: getUuid() });

  remapData = () => this.setState({ remapDataUuid: getUuid() });

  render = () => {
    const { refreshUuid, remapDataUuid } = this.state;
    return (
      <AsyncSimpleListContent
        {...this.props}
        innerRefreshUuid={refreshUuid}
        innerRemapDataUuid={remapDataUuid}
      />
    );
  };
}
