import React from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/styles/colors';
import { passDefaultAttributesToChildren } from '../../services/utils';

const StyledModalSection = styled.div`
  padding: 0.5rem 25px;
  border-bottom: 1px solid ${colors.sectionSeparatorColor};
`;

export const ModalSection = ({ children, defaultChildrenAttributes = false }) => (
  <StyledModalSection>
    {defaultChildrenAttributes
      ? passDefaultAttributesToChildren(children, defaultChildrenAttributes)
      : children}
  </StyledModalSection>
);
