import React, { useState, useEffect } from 'react';
import { isDefined } from '../../services/utils';
import { API } from '../../services/api';
import { Loader } from '../../components/Loader';
import { ConstructionProjectForm } from './Form';

export const ConstructionProject = props => {
  const { id } = props;

  const [fetching, setFetching] = useState(true);
  const [constructionProject, setConstructionProject] = useState({});
  useEffect(() => {
    (async () => {
      const api = isDefined(id) ? API.constructionProjects.show : API.constructionProjects.new;
      const { ok, data } = await api(id);
      if (ok) {
        await setConstructionProject(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <ConstructionProjectForm {...props} constructionProject={constructionProject} />;
};
export default ConstructionProject;
