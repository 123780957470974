import React from 'react';
import styled from 'styled-components';
import every from 'lodash/every';
import { IndexTablePaginator } from './IndexTablePaginator';
import { IndexTableEntriesCount } from './IndexTableEntriesCount';
import { IndexTablePageLimitSelection } from './IndexTablePageLimitSelection';
import { isDefined } from '../../services/utils';

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  align-items: center;
`;

const LeftPart = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > * {
    margin-left: 0.5rem;
  }
  & > *:first-child {
    margin-left: 0;
  }
`;

const RightPart = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > * {
    margin-right: 0.5rem;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

export const IndexTableFooter = props => {
  const {
    limitValue,
    onLimitValueChange,
    currentPage,
    totalPages,
    count,
    totalCount,
    onPageChange,
  } = props;
  const allDefined = requiredProps => every(requiredProps.map(prop => isDefined(prop)));
  const renderPageLimitSelection = allDefined([limitValue, onLimitValueChange]);
  const renderEntriesCount = allDefined([currentPage, totalPages, count, totalCount, limitValue]);
  const renderPaginator = allDefined([currentPage, totalPages, onPageChange]);
  const renderFooter = renderPageLimitSelection || renderEntriesCount || renderPaginator;
  if (!renderFooter) return <></>;
  return (
    <Footer>
      <LeftPart>
        {renderPageLimitSelection && <IndexTablePageLimitSelection {...props} />}
        {renderEntriesCount && <IndexTableEntriesCount {...props} />}
      </LeftPart>
      <RightPart>{renderPaginator && <IndexTablePaginator {...props} />}</RightPart>
    </Footer>
  );
};
