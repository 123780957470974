import { withFormik } from 'formik';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { validationSchema } from './validationSchema';
import { Form } from './Form';

const t = key => I18n.t(`radiation_safety.rad_inventories.new_edit.${key}`);

const RadInventoryFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const radInventory = RadInventoryFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(radInventory.id))
      RadInventoryFunctions.create(radInventory, formikProps, values.afterSave);
    else RadInventoryFunctions.update(radInventory, formikProps, values.afterSave);
  },
  create: async (radInventory, formikProps, afterSave) => {
    const { ok, data } = await API.radiationSafety.radInventories.create({
      rad_inventory: radInventory,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/radiation_safety/rad_inventories/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.radiation_safety.rad_inventories.edit.title'));
      formikProps.resetForm({
        values: RadInventoryFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (radInventory, formikProps, afterSave) => {
    const { ok, data } = await API.radiationSafety.radInventories.update(radInventory.id, {
      rad_inventory: radInventory,
    });
    if (ok) {
      formikProps.resetForm({
        values: RadInventoryFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: {
    afterSave: () => {},
  },
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'identifier',
    'name',
    'updated_at',
    'owner_ids',
    'building_id',
    'buildings_floor_id',
    'lab_id',
    'rad_permit_id',
    'rad_license_id',
    'location_comments',
  ],
});

export const RadInventoryForm = withFormik({
  mapPropsToValues: props => RadInventoryFunctions.prepareInitialValues(props.radInventory),
  handleSubmit: RadInventoryFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
