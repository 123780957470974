import React from 'react';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../components/form/FormFooter';
import { DeletionLink } from '../../../components/links/DeletionLink';
import {
  defaultHandleSubmit,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { BrandButton } from '../../../components/buttons/BrandButton';

const t = key => I18n.t(`divisions.new_edit.${key}`);

export const Footer = props => {
  const {
    isSubmitting,
    handleSubmit,
    setFieldValue,
    currentUser: { org_admin: userIsAdmin },
    values: { id, from_import },
  } = props;

  return (
    <>
      <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
        <FormFooterLeftPart>
          <DeletionLink
            withIcon
            hidden={!(id && userIsAdmin) || from_import}
            modelName={I18n.t('activerecord.models.division')}
            onSubmit={async () => {
              const { ok, data } = await API.administration.divisions.destroy(id);
              if (ok) {
                setInitialFlashMessageForNextPage(t('flash.success.destroy'));
                window.location.href = '/administration/divisions';
              } else {
                showBackendErrorMessage(t('flash.error.destroy'), data);
              }
            }}
          />
        </FormFooterLeftPart>
        <FormFooterRightPart>
          <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
            {t('footer.save')}
          </BrandButton>
        </FormFooterRightPart>
      </FormFooter>
    </>
  );
};
