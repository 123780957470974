import React, { useCallback } from 'react';
import { FastField } from 'formik';
import { FormSection } from '../../../../components/form/FormSection';
import { produceShouldFieldBeVisible } from './helpers';
import { mapToSelectorOptions } from '../../../../services/fieldUtils';
import { RichTextField } from '../../../../components/fields/RichTextField';
import { API } from '../../../../services/api';
import { htmlOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../../components/fields/AsyncSelectorField';
import { NumericField } from '../../../../components/fields/NumericField';
import { SelectorField } from '../../../../components/fields/SelectorField';

const t = key => I18n.t(`requests.incident_requests.new_edit.${key}`);

const IncidentInternalInformation = props => {
  const {
    defaultChildrenAttributes,
    enums: { cost_implication },
    values: { incidentFieldsConfig },
  } = props;

  const shouldFieldBeVisible = useCallback(
    field => produceShouldFieldBeVisible('internal_information', incidentFieldsConfig)(field),
    [incidentFieldsConfig],
  );

  return (
    <FormSection
      title={t('sections.incident_internal_information')}
      defaultChildrenAttributes={defaultChildrenAttributes}
    >
      {shouldFieldBeVisible('other_information') ? (
        <FastField name="other_information" component={RichTextField} />
      ) : (
        <></>
      )}
      {shouldFieldBeVisible('impact_type_id') ? (
        <FastField
          name="impact_type_id"
          api={API.selectors.impactTypes.index}
          selectionApi={API.selectors.impactTypes.show}
          optionRenderer={htmlOptionRenderer('text')}
          component={AsyncSelectorField}
        />
      ) : (
        <></>
      )}
      {shouldFieldBeVisible('impact_length') ? (
        <FastField name="impact_length" component={NumericField} />
      ) : (
        <></>
      )}
      {shouldFieldBeVisible('incident_risk_rating_id') ? (
        <FastField
          name="incident_risk_rating_id"
          api={API.selectors.incidentRiskRatings.index}
          selectionApi={API.selectors.incidentRiskRatings.show}
          optionRenderer={htmlOptionRenderer('text')}
          component={AsyncSelectorField}
        />
      ) : (
        <></>
      )}
      {shouldFieldBeVisible('cost_implication') ? (
        <FastField
          name="cost_implication"
          options={mapToSelectorOptions(cost_implication, 'header', 'value')}
          hint={t('hints.cost_implication')}
          component={SelectorField}
        />
      ) : (
        <></>
      )}
      {shouldFieldBeVisible('report_to_regulatory_agency') ? (
        <FastField
          name="report_to_regulatory_agency"
          options={[
            { value: true, data: I18n.t('true') },
            { value: false, data: I18n.t('false') },
          ]}
          component={SelectorField}
        />
      ) : (
        <></>
      )}
    </FormSection>
  );
};

export default IncidentInternalInformation;
