import React from 'react';
import { FastField } from 'formik';
import { FormHeader } from '../../../components/form/FormHeader';
import { handleSubmitWithAfterSave, isDefined } from '../../../services/utils';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { Footer } from './Footer';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';
import { DateTimeField } from '../../../components/fields/DateTimeField';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { FilesField } from '../../../components/fields/FilesField';
import { LinksField } from '../../../components/fields/LinksField';
import { AsyncMultiSelectorField } from '../../../components/fields/AsyncMultiSelectorField';
import { Locations } from '../../../components/other/locations/Locations';

const t = key => I18n.t(`documents.new_edit.${key}`);

export const Form = props => {
  const {
    setFieldValue,
    handleSubmit,
    values: { id },
  } = props;

  return (
    <form>
      <FormHeader title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)} />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.document',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.document'),
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField name="identifier" disabled component={TextField} />
          <FastField name="name" required component={TextField} />
          <FastField name="updated_at" disabled component={DateTimeField} />
          <FastField
            name="document_type_id"
            api={API.selectors.document_types.index}
            selectionApi={API.selectors.document_types.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
            required
          />
          <FastField
            name="label_ids"
            api={API.selectors.labels.index}
            selectionApi={API.selectors.labels.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncMultiSelectorField}
          />
          <FastField name="links" component={LinksField} />
          <FastField name="show_on_community_portal" component={CheckBoxField} />
          <FastField name="attachments" component={FilesField} />
        </FormSection>
      </DefaultAttributesGroup>
      <Locations
        api={API.documents.locations}
        id={id}
        title={t('sections.locations')}
        resourceName={I18n.t('resource_names.document_location')}
        humanizedResourceName={I18n.t('activerecord.models.document_location')}
        handleAdditionLinkOnClick={open =>
          handleSubmitWithAfterSave(setFieldValue, handleSubmit, open)
        }
        prepareValuesToSubmit={values => ({
          document_location: values,
        })}
      />
      <Footer {...props} />
    </form>
  );
};
