import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../../assets/styles/colors';
import { countLmsTrainings, initializeLmsApiIntegration } from '../../LmsApiUtils';

const Wrapper = styled.a`
  height: 70px;
  width: 100%;
  text-decoration: none !important;
  color: inherit;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  position: relative;
  font-size: 15px;
  &:not(:last-child) {
    padding-right: 10px;
  }
`;

const BadgeWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
`;

const Badge = styled.div`
  background-color: ${colors.badge};
  padding: 1px 4px;
  border-radius: 0.2rem;
  min-width: 21px;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
`;

export const RelatedItem = props => {
  const { item } = props;
  const {
    href,
    label,
    sub_label,
    should_render_sub_label,
    should_highlight_sub_label,
    className,
    external_count_source,
  } = item;
  const [count, setCount] = useState(item.count || 0);

  // eslint-disable-next-line consistent-return
  const setCountFromExternalSource = async (key, data) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case 'lms_trainings_api':
        await initializeLmsApiIntegration();
        setCount(await countLmsTrainings(data.lms_trainings_api_base_url, data.person_id));
        break;
    }
  };

  useEffect(async () => {
    if (Object.keys(external_count_source).length !== 0) {
      const { key, data } = external_count_source;
      await setCountFromExternalSource(key, data);
    }
  }, []);

  return (
    <Wrapper className={className} href={href}>
      <BadgeWrapper>
        <Badge>{count}</Badge>
      </BadgeWrapper>
      <div className="w-100 d-flex flex-wrap align-items-center">
        <p className="my-0 mx-4 w-100 text-center text-truncate">{label}</p>
        {should_render_sub_label && (
          <p className="my-0 mx-4 w-100 text-center text-truncate">
            <strong
              className={should_highlight_sub_label ? 'text-danger' : ''}
              dangerouslySetInnerHTML={{ __html: sub_label }}
            />
          </p>
        )}
      </div>
    </Wrapper>
  );
};
