import React from 'react';
import { FastField } from 'formik';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate, mapToSelectorOptions } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { TextAreaField } from '../../../components/fields/TextAreaField';
import { DateField } from '../../../components/fields/DateField';
import { LocationFields } from '../../../components/fields/compositeFields/LocationFields';
import { FilesField } from '../../../components/fields/FilesField';
import { SelectorField } from '../../../components/fields/SelectorField';
import { LinksField } from '../../../components/fields/LinksField';

const t = key => I18n.t(`construction_projects.new_edit.${key}`);

export const Overview = props => {
  const { enums } = props;
  return (
    <DefaultAttributesGroup
      defaultChildrenAttributes={{
        labelI18nKeyPrefix: 'activerecord.attributes.construction_project',
        shouldUpdate: defaultShouldUpdate,
        resourceName: I18n.t('resource_names.construction_project'),
      }}
    >
      <FormSection title={t('sections.administration')}>
        <FastField name="identifier" required component={TextField} />
        <FastField
          name="status"
          required
          options={mapToSelectorOptions(enums.status, 'header', 'value')}
          component={SelectorField}
        />
        <FastField name="project_name" required component={TextField} />
        <FastField
          name="project_type"
          required
          options={mapToSelectorOptions(enums.projectType, 'header', 'value')}
          component={SelectorField}
        />
        <FastField
          name="project_manager_id"
          required
          api={API.selectors.people.index}
          selectionApi={API.selectors.people.show}
          optionRenderer={htmlOptionRenderer('text')}
          headerRenderer={defaultOptionRenderer('full_name')}
          component={AsyncSelectorField}
        />
        <FastField name="budget" component={TextAreaField} />
        <FastField name="project_schedule" component={TextAreaField} />
        <FastField name="start_date" required component={DateField} />
        <FastField name="end_date" component={DateField} />
      </FormSection>
      <FormSection title={t('sections.project_details')}>
        <LocationFields buildingSelectorAttributes={{ required: true }} {...props} />
        <FastField
          name="department_id"
          api={API.selectors.departments.index}
          selectionApi={API.selectors.departments.show}
          optionRenderer={htmlOptionRenderer('text')}
          component={AsyncSelectorField}
        />
        <FastField name="project_scope" component={TextAreaField} />
        <FastField name="comment" component={TextAreaField} />
        <FastField name="project_forecast" component={TextAreaField} />
        <FastField name="attachments" component={FilesField} />
        <FastField name="links" component={LinksField} />
      </FormSection>
      <FormSection title={t('sections.project_contractors')}>
        <FastField name="architect" component={TextField} />
        <FastField name="engineer" component={TextField} />
        <FastField name="general_contractor" component={TextField} />
        <FastField name="construction_supt" component={TextField} />
      </FormSection>
    </DefaultAttributesGroup>
  );
};
