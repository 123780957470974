import React from 'react';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../components/form/FormFooter';
import { DeletionLink } from '../../../components/links/DeletionLink';
import {
  defaultHandleSubmit,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { BrandButton } from '../../../components/buttons/BrandButton';

const t = key => I18n.t(`biological_agent_types.new_edit.${key}`);

export const Footer = props => {
  const {
    isSubmitting,
    handleSubmit,
    setFieldValue,
    biological_bug_species_count = 0,
    values: { id },
  } = props;

  const disabledDeletion = ![0, null].includes(biological_bug_species_count);

  return (
    <>
      <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
        <FormFooterLeftPart>
          <DeletionLink
            withIcon
            hidden={!id}
            disabled={disabledDeletion}
            hint={disabledDeletion ? t('footer.deletion_link_hint') : ''}
            modelName={I18n.t('activerecord.models.biological_agent_type')}
            onSubmit={async () => {
              const { ok, data } = await API.administration.biologicalAgentTypes.destroy(id);
              if (ok) {
                setInitialFlashMessageForNextPage(t('flash.success.destroy'));
                window.location.href = '/administration/biological_agent_types';
              } else {
                showBackendErrorMessage(t('flash.error.destroy'), data);
              }
            }}
          />
        </FormFooterLeftPart>
        <FormFooterRightPart>
          <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
            {t('footer.save')}
          </BrandButton>
        </FormFooterRightPart>
      </FormFooter>
    </>
  );
};
