import { withFormik } from 'formik';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { validationSchema } from './validationSchema';
import { Form } from './Form';
import { API } from '../../../services/api';

const t = key => I18n.t(`hsds.hazard_assessments.new_edit.${key}`);

const SpaceRiskAssessmentFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const spaceRiskAssessment = SpaceRiskAssessmentFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(spaceRiskAssessment.id)) {
      SpaceRiskAssessmentFunctions.create(spaceRiskAssessment, formikProps, values.afterSave);
    } else {
      SpaceRiskAssessmentFunctions.update(spaceRiskAssessment, formikProps, values.afterSave);
    }
  },
  create: async (spaceRiskAssessment, formikProps, afterSave) => {
    const { ok, data } = await API.spaceRiskAssessments.create({
      space_risk_assessment: spaceRiskAssessment,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/hsds/hazard_assessments/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.hsds.hazard_assessments.edit.title'));
      formikProps.resetForm({
        values: SpaceRiskAssessmentFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (spaceRiskAssessment, formikProps, afterSave) => {
    const { ok, data } = await API.spaceRiskAssessments.update(spaceRiskAssessment.id, {
      space_risk_assessment: spaceRiskAssessment,
    });
    if (ok) {
      formikProps.resetForm({
        values: SpaceRiskAssessmentFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: {
    afterSave: () => {},
  },
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'identifier',
    'primary_building_id',
    'primary_buildings_floor_id',
    'primary_lab_id',
    'space_name',
    'date_posted',
    'include_emergency_contacts',
    'emergency_contact_profile_id',
    'updated_at',
    'access_restriction_3',
    'permit_comments',
    'additional_information',
    'internal_notes',
    'logo_profile_id',
    'hsds_ppe_ids',
    'hsds_access_restriction_ids',
    'toxic_by_inhalation_rating_id',
    'toxic_oral_or_dermal_rating_id',
    'biohazard_rating_id',
    'carcinogen_rating_id',
    'chemical_storage_rating_id',
    'compressed_gas_rating_id',
    'corrosive_rating_id',
    'cryogenics_rating_id',
    'electrical_rating_id',
    'explosive_rating_id',
    'flammable_gas_rating_id',
    'flammable_liquid_rating_id',
    'flammable_solid_rating_id',
    'hearing_conservation_rating_id',
    'lasers_rating_id',
    'mri_nmr_rating_id',
    'oxidizers_rating_id',
    'pyrophorics_rating_id',
    'radioactive_rating_id',
    'reproductive_hazard_rating_id',
    'ultraviolet_light_rating_id',
    'animal_allergen_rating_id',
    'water_reactive_rating_id',
  ],
});

export const SpaceRiskAssessmentForm = withFormik({
  mapPropsToValues: props =>
    SpaceRiskAssessmentFunctions.prepareInitialValues(props.spaceRiskAssessment),
  handleSubmit: SpaceRiskAssessmentFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
