import React from 'react';
import { withFormik, FastField } from 'formik';
import { DefaultAttributesGroup } from '../../../../components/form/DefaultAttributesGroup';
import yup from '../../../../components/form/CustomYupValidator';
import { fieldDisplayModes } from '../../../../constants/fieldDisplayModes';
import { ModalSection } from '../../../../components/modals/ModalSection';
import { SelectorField } from '../../../../components/fields/SelectorField';
import { FormFooter, FormFooterRightPart } from '../../../../components/form/FormFooter';
import { CancellationLink } from '../../../../components/links/CancellationLink';
import { BrandButton } from '../../../../components/buttons/BrandButton';
import {
  showBackendErrorMessage,
  showSuccessMessage,
  defaultHandleSubmit,
} from '../../../../services/utils';
import { defaultShouldUpdate } from '../../../../services/fieldUtils';
import { API } from '../../../../services/api';
import { PersonFunctions } from './PersonFunctions';

const Container = ({ children }) => (
  <div className="row mx-0">
    <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12" />
    <div className="col-lg-9 col-md-8 col-sm-8 col-xs-12 pt-0 px-0">{children}</div>
  </div>
);

const t = (key, params = {}) =>
  I18n.t(`inventory.people.people.new_edit.modals.manage_user.${key}`, params);

const Form = props => {
  const {
    isSubmitting,
    setFieldValue,
    handleSubmit,
    modalRef,
    values: { sso_based },
  } = props;

  return (
    <ModalSection>
      <form>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
            shouldUpdate: defaultShouldUpdate,
            resourceName: I18n.t('resource_names.person'),
          }}
        >
          <FastField
            name="sso_based"
            label={t('sso_based')}
            required
            options={[
              {
                data: I18n.t(`activerecord.attributes.person.account_types.pwd_based`),
                value: false,
              },
              {
                data: I18n.t(`activerecord.attributes.person.account_types.sso_based`),
                value: true,
              },
            ]}
            component={SelectorField}
          />
          {!sso_based ? <Container>{t('body.email_warning')}</Container> : <div className="pb-4" />}
          <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
            <FormFooterRightPart>
              <CancellationLink onClick={() => modalRef.current.hide()} />
              <BrandButton onClick={e => defaultHandleSubmit(setFieldValue, handleSubmit, e)}>
                {t('submit.create')}
              </BrandButton>
            </FormFooterRightPart>
          </FormFooter>
        </DefaultAttributesGroup>
      </form>
    </ModalSection>
  );
};

export const ManageUsersModalContent = withFormik({
  mapPropsToValues: props => ({
    id: props.personId,
    sso_based: false,
  }),
  validationSchema: yup.object().shape({
    sso_based: yup
      .boolean()
      .nullable()
      .required(I18n.t('validations.required')),
  }),
  handleSubmit: async (values, formikProps) => {
    const { modalRef, resetForm, personId } = formikProps.props;
    const { sso_based } = values;
    const { ok, data } = await API.people.people.create_user(personId, { sso_based });
    if (ok) {
      resetForm({ values: PersonFunctions.prepareInitialValues(data.data.attributes) });
      modalRef.current.hide();
      modalRef.current.onSuccess();
      showSuccessMessage(t('flash.success.create'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.create'), data);
    }
  },
})(Form);
