import React, { useRef, useState } from 'react';
import { SmallLightButton } from '../../../../components/buttons/SmallLightButton';
import { isDefined } from '../../../../services/utils';
import { Modal } from '../../../../components/modals/Modal';
import { EmergencyContactProfileModalContent } from './index';
import { API } from '../../../../services/api';

const t = key => I18n.t(`hsds.emergency_contact_profiles.${key}`);

export const EmergencyContactProfileButtons = props => {
  const {
    name,
    currentUser,
    values: { emergency_contact_profile_id },
  } = props;

  const [emergencyContactProfile, setEmergencyContactProfile] = useState({});
  const [modalTitle, setModalTitle] = useState('');
  const emergencyContactProfileModalRef = useRef(null);
  const handleAddNewProfileButtonClick = async () => {
    const { ok, data } = await API.spaceRiskAssessments.emergencyContactProfiles.new();
    if (ok) {
      await setEmergencyContactProfile(data.data.attributes);
      await setModalTitle(t('title.new'));
      emergencyContactProfileModalRef.current.open();
    }
  };
  const handleEditProfileButtonClick = async () => {
    const { ok, data } = await API.spaceRiskAssessments.emergencyContactProfiles.show(
      emergency_contact_profile_id,
    );
    if (ok) {
      await setEmergencyContactProfile(data.data.attributes);
      await setModalTitle(t('title.edit'));
      emergencyContactProfileModalRef.current.open();
    }
  };
  return (
    <>
      <div className="d-flex flex-row">
        {isDefined(emergency_contact_profile_id) && (
          <SmallLightButton
            className={`btn btn-light btn-sm mr-3 ${name}_edit_profile_button`}
            onClick={handleEditProfileButtonClick}
          >
            {t('edit_profile_button')}
          </SmallLightButton>
        )}
        <SmallLightButton
          className={`btn btn-light btn-sm ${name}_new_profile_button`}
          onClick={handleAddNewProfileButtonClick}
        >
          {t('new_profile_button')}
        </SmallLightButton>
      </div>
      <Modal title={modalTitle} ref={emergencyContactProfileModalRef}>
        {modalProps => (
          <EmergencyContactProfileModalContent
            currentUser={currentUser}
            emergencyContactProfile={emergencyContactProfile}
            {...modalProps}
          />
        )}
      </Modal>
    </>
  );
};
