import React from 'react';
import { DefaultLink } from '../links/DefaultLink';
import { DeletionLink } from '../links/DeletionLink';
import { isDefined } from '../../services/utils';
import { SimpleListAdditionalElement } from './SimpleListAdditionalElement';

const linkStyle = { padding: '15px 0' };

const defineLinkId = (id, listRowKeyValue, variant) => {
  if (isDefined(id)) {
    return id;
  }
  if (isDefined(listRowKeyValue)) {
    return `${listRowKeyValue}-${variant}`;
  }
  return null;
};

export const SimpleListLink = ({ children, hidden, id, listRowKeyValue, ...props }) => {
  const linkId = defineLinkId(id, listRowKeyValue, 'view');

  return (
    <SimpleListAdditionalElement hidden={hidden} style={{ fontSize: '16px' }}>
      <DefaultLink {...props} id={linkId} style={linkStyle}>
        {children}
      </DefaultLink>
    </SimpleListAdditionalElement>
  );
};

export const SimpleListDeletionLink = ({ children, hidden, id, listRowKeyValue, ...props }) => {
  const linkId = defineLinkId(id, listRowKeyValue, 'delete');

  return (
    <SimpleListAdditionalElement hidden={hidden} style={{ fontSize: '16px' }}>
      <DeletionLink {...props} id={linkId} style={linkStyle} marginClass="">
        {children}
      </DeletionLink>
    </SimpleListAdditionalElement>
  );
};
