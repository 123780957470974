import React, { useState, useEffect } from 'react';
import { isDefined } from '../../services/utils';
import { API } from '../../services/api';
import { Loader } from '../../components/Loader';
import { IbcRegisrationForm } from './Form';

export const IbcRegistration = props => {
  const { id } = props;

  const [fetching, setFetching] = useState(true);
  const [ibcRegistration, setIbcRegistration] = useState({});
  useEffect(() => {
    (async () => {
      const api = API.biologicalSafety.ibcRegistrations[isDefined(id) ? 'show' : 'new'];
      const { ok, data } = await api(id);
      if (ok) {
        await setIbcRegistration(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <IbcRegisrationForm {...props} ibcRegistration={ibcRegistration} />;
};
export default IbcRegistration;
