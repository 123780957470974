import yup from '../../../../components/form/CustomYupValidator';

export const validationSchema = yup.object().shape({
  first_name: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  last_name: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  uid: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  email: yup
    .string()
    .email()
    .nullable()
    .required(I18n.t('validations.required')),
});
