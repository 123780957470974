import React, { useState } from 'react';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import { FieldWrapper } from './auxiliary/FieldWrapper';
import { fieldWrapperProps } from '../../services/fieldUtils';
import { isDefined } from '../../services/utils';
import { colors } from '../../assets/styles/colors';
import { getConvertedMoment, momentFormatter } from '../../services/dateFormatUtils';

export const DateRangeField = props => {
  const {
    field: { name, value },
    form: { setFieldValue, setFieldTouched },
    disabled,
    onChange,
    onBlur,
    placeholder,
    startDatePlaceholder = placeholder,
    endDatePlaceholder = placeholder,
  } = props;
  const [focused, setFocused] = useState(null);

  const defaultOnChange = async newValue => {
    await setFieldValue(name, newValue);
    await setFieldTouched(name, true);
  };
  const defaultOnBlur = async () => {
    await setFieldTouched(name, true);
  };

  const dates = isDefined(value) ? value.split(' - ') : ['', ''];
  const startDateValue = getConvertedMoment(dates[0], true);
  const endDateValue = getConvertedMoment(dates[1], true);

  return (
    <FieldWrapper {...fieldWrapperProps(props)}>
      <div className={`react-date-range-picker-wrapper ${isDefined(focused) ? 'focused' : ''}`}>
        <DateRangePicker
          disabled={disabled}
          startDate={startDateValue}
          endDate={endDateValue}
          startDatePlaceholderText={startDatePlaceholder}
          endDatePlaceholderText={endDatePlaceholder}
          onDatesChange={({ startDate, endDate }) => {
            const parsedStartDate = isDefined(startDate)
              ? momentFormatter.serverDateRangePart(startDate)
              : '';
            const parsedEndDate = isDefined(endDate)
              ? momentFormatter.serverDateRangePart(endDate)
              : '';
            const newValue = `${parsedStartDate} - ${parsedEndDate}`;
            // eslint-disable-next-line no-unused-expressions
            onChange ? onChange(newValue, defaultOnChange) : defaultOnChange(newValue);
          }}
          focusedInput={focused}
          onFocusChange={focusedInputName => {
            setFocused(focusedInputName);
            if (!focusedInputName) {
              // eslint-disable-next-line no-unused-expressions
              onBlur ? onBlur(value, defaultOnBlur) : defaultOnBlur();
            }
          }}
          isOutsideRange={() => false}
          customInputIcon={
            <span style={{ color: colors.inputIcon }} className="glyphicon glyphicon-calendar" />
          }
          appendToBody={false}
          noBorder
          small
        />
      </div>
    </FieldWrapper>
  );
};
