import React from 'react';
import { handleSubmitWithAfterSave, isDefined } from '../../../services/utils';
import { FormHeader } from '../../../components/form/FormHeader';
import { Overview } from './Overview';
import { Milestones } from './Milestones';
import { Footer } from './Footer';
import { Locations } from '../../../components/other/locations/Locations';
import { API } from '../../../services/api';

const t = key => I18n.t(`construction_projects.new_edit.${key}`);

export const Form = props => {
  const {
    values: { id, identifier },
    setFieldValue,
    handleSubmit,
  } = props;
  return (
    <form>
      <FormHeader
        title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)}
        investigable={
          isDefined(id) && {
            id,
            identifier,
            type: 'ConstructionProject',
          }
        }
      />
      <Overview {...props} />
      <Milestones {...props} />
      <Footer {...props} />
      <Locations
        api={API.constructionProjects.locations}
        id={id}
        title={t('sections.secondary_locations')}
        resourceName={I18n.t('resource_names.construction_project_location')}
        humanizedResourceName={I18n.t('activerecord.models.construction_project_location')}
        handleAdditionLinkOnClick={open =>
          handleSubmitWithAfterSave(setFieldValue, handleSubmit, open)
        }
        prepareValuesToSubmit={values => ({
          construction_project_location: values,
        })}
      />
    </form>
  );
};
