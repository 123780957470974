import yup from '../../../components/form/CustomYupValidator';

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  biological_agent_type_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  biological_bug_species_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  risk_group_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});
