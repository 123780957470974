import React from 'react';
import { LinkBase } from './auxiliary/LinkBase';
import { colors } from '../../assets/styles/colors';
import { fontSize } from '../../assets/styles/typography';

export const DarkLink = ({ onClick, href, children, hidden, style = {} }) => (
  <LinkBase
    onClick={onClick}
    hidden={hidden}
    href={href}
    style={{ color: colors.grayDark, fontSize: fontSize.textSm, ...style }}
  >
    {children}
  </LinkBase>
);
