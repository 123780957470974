import React from 'react';
import { FastField } from 'formik';
import { FormHeader } from '../../../components/form/FormHeader';
import { isDefined } from '../../../services/utils';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { Footer } from './Footer';
import { Species } from './Species';

const t = key => I18n.t(`biological_agent_types.new_edit.${key}`);

export const Form = props => {
  const {
    values: { id },
  } = props;

  return (
    <form>
      <FormHeader title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)} />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.biological_agent_type',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.biological_agent_type'),
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField disabled name="identifier" component={TextField} />
          <FastField name="name" required component={TextField} />
        </FormSection>
      </DefaultAttributesGroup>
      <Footer {...props} />
      <Species {...props} />
    </form>
  );
};
