import React from 'react';
import { FastField } from 'formik';
import styled from 'styled-components';
import { fieldDisplayModes } from '../../../../constants/fieldDisplayModes';
import { produceShouldUpdate } from '../../../../services/fieldUtils';
import { DateField } from '../../../../components/fields/DateField';
import { TextField } from '../../../../components/fields/TextField';
import { getConvertedMoment, momentFormatter } from '../../../../services/dateFormatUtils';
import { handleSubmitWithAfterSave, isDefined } from '../../../../services/utils';
import { DefaultLink } from '../../../../components/links/DefaultLink';

const CenteredCell = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const EmptyInspectButtonSpace = styled.div`
  width: 70px;
`;

const t = (key, attributes = {}) =>
  I18n.t(`inventory.equipment.equipment.new_edit.${key}`, attributes);

const setNextDueDate = (previousDate, frequency) => {
  const previousMoment = getConvertedMoment(previousDate, true);
  const nextMoment = previousMoment.add(frequency, 'days');

  return momentFormatter.serverDate(nextMoment);
};

export const ComplianceTimelineTableRow = props => {
  const { isSubmitting, inspectionType, resourceName, setFieldValue, handleSubmit, values } = props;

  const inspectionFrequency =
    inspectionType === 'internal'
      ? values.internal_inspection_frequency
      : values.external_inspection_frequency;

  return (
    <tr>
      <td>
        <CenteredCell>{t(`compliance_timeline.${inspectionType}_row`)}</CenteredCell>
      </td>
      <td>
        <CenteredCell>
          <FastField
            name={`last_${inspectionType}_inspection_date`}
            resourceName={resourceName}
            shouldUpdate={produceShouldUpdate('', '', [
              `last_${inspectionType}_inspection_date`,
              `${inspectionType}_inspection_frequency`,
            ])}
            component={DateField}
            displayMode={fieldDisplayModes.NO_WRAPPER}
            onChange={async (newValue, onChange) => {
              await onChange(newValue);
              if (isDefined(values.equipment_type_id) && isDefined(inspectionFrequency)) {
                await setFieldValue(
                  `next_${inspectionType}_inspection_date`,
                  setNextDueDate(newValue, inspectionFrequency),
                );
              }
            }}
          />
        </CenteredCell>
      </td>
      <td>
        <CenteredCell>
          <FastField
            name={`${inspectionType}_inspection_frequency`}
            resourceName={resourceName}
            component={TextField}
            disabled
            displayMode={fieldDisplayModes.NO_WRAPPER}
          />
        </CenteredCell>
      </td>
      <td>
        <CenteredCell>
          <FastField
            name={`next_${inspectionType}_inspection_date`}
            resourceName={resourceName}
            component={DateField}
            disabled
            displayMode={fieldDisplayModes.NO_WRAPPER}
          />
        </CenteredCell>
      </td>
      <td>
        <CenteredCell>
          {isDefined(values.id) ? (
            <DefaultLink
              disabled={isSubmitting}
              onClick={async () => {
                const redirect = () => {
                  window.location.href = `/inspections/new?investigable_id=${values.id}&investigable_type=Equipment`;
                };
                const afterSave = () => setTimeout(redirect, 1000);
                await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
              }}
            >
              {t('compliance_timeline.inspect_button')}
            </DefaultLink>
          ) : (
            <EmptyInspectButtonSpace />
          )}
        </CenteredCell>
      </td>
    </tr>
  );
};
