import React, { useState, useEffect } from 'react';
import { isDefined } from '../../services/utils';
import { API } from '../../services/api';
import { Loader } from '../../components/Loader';
import { WasteCafForm } from './Form';

export const WasteCaf = props => {
  const [fetching, setFetching] = useState(true);
  const [wasteCaf, setWasteCaf] = useState({});
  const { id, waste_generator_id } = props;

  useEffect(() => {
    (async () => {
      const api = isDefined(id)
        ? API.administration.wasteCafs.show
        : () => API.administration.wasteCafs.new({ waste_generator_id });

      const { ok, data } = await api(id);
      if (ok) {
        await setWasteCaf(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <WasteCafForm wasteCaf={wasteCaf} {...props} />;
};
export default WasteCaf;
