import React from 'react';
import { withFormik } from 'formik';
import { ModalSection } from '../../modals/ModalSection';
import { FormFooter, FormFooterRightPart } from '../../form/FormFooter';
import { BrandButton } from '../../buttons/BrandButton';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import yup from '../../form/CustomYupValidator';
import { EquipmentFields } from '../../fields/compositeFields/EquipmentFields';
import { CancellationLink } from '../../links/CancellationLink';
import {
  defaultHandleSubmit,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';

const t = key => I18n.t(`related_equipment_section.${key}`);

const validationSchema = yup.object().shape({
  equipmentable_type: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  equipmentable_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});

const AddEquipmentForm = props => {
  const {
    hide,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    resourceName,
    additionalApiParams,
  } = props;
  return (
    <form>
      <ModalSection>
        <EquipmentFields
          equipmentTypeName="equipmentable_type"
          equipmentIdName="equipmentable_id"
          defaultAttributes={{
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
            labelI18nKeyPrefix: 'related_equipment_section.form_fields',
            required: true,
            resourceName,
          }}
          additionalApiParams={additionalApiParams}
          {...props}
        />
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={hide} />
            <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
              {t('add_modal.add')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </ModalSection>
    </form>
  );
};

export const AddRelatedEquipmentModalContent = withFormik({
  mapPropsToValues: () => ({ equipmentable_type: '', equipmentable_id: '' }),
  handleSubmit: async (values, formikProps) => {
    const { hide, listRef, api, prepareValuesToSubmit } = formikProps.props;
    const { ok, data } = await api.create(prepareValuesToSubmit(values));
    if (ok) {
      formikProps.resetForm();
      hide();
      listRef.current.refresh();
      showSuccessMessage(t('flash.success.add'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.add'), data);
    }
  },
  validate: produceDefaultValidation(validationSchema),
})(AddEquipmentForm);
