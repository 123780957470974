import { withFormik } from 'formik';
import { Form } from './Form';
import { validationSchema } from './validationSchema';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { API } from '../../../services/api';

const t = key => I18n.t(`waste_collection_types.new_edit.${key}`);

const WasteCollectionTypesFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const wasteCollectionType = WasteCollectionTypesFunctions.prepareValuesToSubmit({ ...values });
    if (isDefined(wasteCollectionType.id)) {
      WasteCollectionTypesFunctions.update(wasteCollectionType, formikProps, values.afterSave);
    } else {
      WasteCollectionTypesFunctions.create(wasteCollectionType, formikProps, values.afterSave);
    }
  },
  create: async (waste_collection_type, formikProps, afterSave) => {
    const { ok, data } = await API.administration.wasteCollectionTypes.create({
      waste_collection_type,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/administration/waste_collection_types/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.administration.waste_collection_types.edit.title'));
      formikProps.resetForm({
        values: WasteCollectionTypesFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (waste_collection_type, formikProps, afterSave) => {
    const { ok, data } = await API.administration.wasteCollectionTypes.update(
      waste_collection_type.id,
      {
        waste_collection_type,
      },
    );
    if (ok) {
      formikProps.resetForm({
        values: WasteCollectionTypesFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t(`flash.success.save`));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t(`flash.error.save`), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: {
    afterSave: () => {},
  },
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'name',
    'active',
    'is_default',
    'include_waste_types_sp',
    'include_waste_types_cp',
  ],
});

export const WasteCollectionTypeForm = withFormik({
  validate: produceDefaultValidation(validationSchema),
  mapPropsToValues: props =>
    WasteCollectionTypesFunctions.prepareInitialValues(props.wasteCollectionType),
  handleSubmit: WasteCollectionTypesFunctions.handleSubmit,
})(Form);
