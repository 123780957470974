import yup from '../../../form/CustomYupValidator';

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  variant: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  position: yup
    .number()
    .transformNaNValue()
    .test('position', I18n.t('validations.out_of_range'), function _(position) {
      return (
        Number.isInteger(position) && position >= 1 && position <= this.parent.max_position_value
      );
    }),
  selector_values: yup
    .string()
    .nullable()
    .test('required selector values', I18n.t('validations.required'), function _(value) {
      if (this.parent.variant === 'selector') {
        return !!value;
      }
      return true;
    }),
});
