import yup from '../../../components/form/CustomYupValidator';

export const validationSchema = yup.object().shape({
  general_request_type_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  requested_date: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  creator_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  description: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  sub_status: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  submitType: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  auxiliaryCompletedSubStatus: yup
    .string()
    .nullable(true)
    .when('submitType', {
      is: submitType => submitType === 'resolve',
      then: yup
        .string()
        .nullable(true)
        .required(I18n.t('validations.required')),
    }),
  auxiliaryResolutionDate: yup
    .string()
    .nullable(true)
    .when('submitType', {
      is: submitType => submitType === 'resolve',
      then: yup
        .string()
        .nullable(true)
        .required(I18n.t('validations.required')),
    }),
});
