const allowedFields = {
  details: ['labels', 'department_id', 'owner_person_id', 'follow_up_contact', 'short_description'],
  response: ['response_date', 'response_date_zone', 'responder_person_id', 'responder_assessment'],
  summary: ['executive_summary'],
  corrective_actions: [
    'actions_taken',
    'corrective_actions_due_date',
    'corrective_actions_required',
    'corrective_actions_completion_date',
    'corrective_actions_recorded_by_person_id',
  ],
  preventative_actions: [
    'recurrence_prevention_required',
    'recurrence_prevention_due_date',
    'recurrence_prevention_comment',
    'recurrence_prevention_completion_date',
    'recurrence_prevention_recorded_by_person_id',
  ],
  internal_information: [
    'other_information',
    'impact_type_id',
    'impact_length',
    'incident_risk_rating_id',
    'cost_implication',
    'report_to_regulatory_agency',
  ],
};

export const shouldSectionBeVisible = (sectionName, incidentFieldsConfig) => {
  if (!incidentFieldsConfig) return false;

  const fields = allowedFields[sectionName];
  const sectionFieldsConfig = incidentFieldsConfig[sectionName];
  if (!fields || !sectionFieldsConfig) return false;

  return fields.some(field => sectionFieldsConfig[field]);
};

export const produceShouldFieldBeVisible = (sectionName, incidentFieldsConfig) => field => {
  if (!incidentFieldsConfig || !sectionName || !field) return false;

  const sectionFieldsConfig = incidentFieldsConfig[sectionName];
  if (!sectionFieldsConfig) return false;

  return sectionFieldsConfig[field];
};
