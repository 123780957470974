import React from 'react';
import { passDefaultAttributesToChildren } from '../../services/utils';

export const DefaultAttributesGroup = ({ children, defaultChildrenAttributes = false }) => (
  <>
    {defaultChildrenAttributes
      ? passDefaultAttributesToChildren(children, defaultChildrenAttributes)
      : children}
  </>
);
