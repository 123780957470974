import React from 'react';
import { FastField } from 'formik';
import { DefaultAttributesGroup } from '../../../../components/form/DefaultAttributesGroup';
import { fieldDisplayModes } from '../../../../constants/fieldDisplayModes';
import { ModalSection } from '../../../../components/modals/ModalSection';
import { defaultShouldUpdate, produceShouldUpdate } from '../../../../services/fieldUtils';
import { TextField } from '../../../../components/fields/TextField';
import { API } from '../../../../services/api';
import { htmlOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../../components/fields/AsyncSelectorField';
import { FormFooter, FormFooterRightPart } from '../../../../components/form/FormFooter';
import { BrandButton } from '../../../../components/buttons/BrandButton';
import { defaultHandleSubmit } from '../../../../services/utils';
import { CancellationLink } from '../../../../components/links/CancellationLink';

const t = key => I18n.t(`biological_safeties.new_edit.modals.biological_agent.${key}`);

export const BiologicalAgentForm = props => {
  const { setFieldValue, handleSubmit, isSubmitting, hide, values } = props;
  return (
    <form>
      <ModalSection>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
            labelI18nKeyPrefix: 'activerecord.attributes.biological_agent',
            shouldUpdate: defaultShouldUpdate,
            resourceName: I18n.t('resource_names.biological_agent'),
          }}
        >
          <FastField
            required
            name="biological_agent_type_id"
            api={API.selectors.biologicalAgentTypes.index}
            selectionApi={API.selectors.biologicalAgentTypes.show}
            optionRenderer={htmlOptionRenderer('text')}
            onChange={async (event, onChange) => {
              await onChange(event);
              await setFieldValue('biological_bug_species_id', '');
              await setFieldValue('biological_strain_id', '');
            }}
            component={AsyncSelectorField}
          />
          <FastField
            required
            name="biological_bug_species_id"
            api={API.selectors.biologicalBugSpecies.index}
            apiParams={{ with_biological_agent_type_id: values.biological_agent_type_id }}
            selectionApi={API.selectors.biologicalBugSpecies.show}
            optionRenderer={htmlOptionRenderer('text')}
            onChange={async (event, onChange) => {
              await onChange(event);
              await setFieldValue('biological_agent_type_id', event.data.biological_agent_type_id);
              await setFieldValue(
                'biological_strain_id',
                event.data.only_one_available_strain_id || '',
              );
            }}
            shouldUpdate={produceShouldUpdate([], [], ['biological_agent_type_id'])}
            component={AsyncSelectorField}
          />
          <FastField
            required
            name="biological_strain_id"
            api={API.selectors.biologicalStrains.index}
            apiParams={{
              with_biological_agent_type_id: values.biological_agent_type_id,
              with_biological_bug_species_id: values.biological_bug_species_id,
            }}
            selectionApi={API.selectors.biologicalStrains.show}
            optionRenderer={htmlOptionRenderer('text')}
            onChange={async (event, onChange) => {
              await onChange(event);
              await setFieldValue('biological_agent_type_id', event.data.biological_agent_type_id);
              await setFieldValue(
                'biological_bug_species_id',
                event.data.biological_bug_species_id,
              );
              if (event.data.bsl_id) await setFieldValue('bsl_id', event.data.bsl_id);
              if (event.data.risk_group_id)
                await setFieldValue('risk_group_id', event.data.risk_group_id);
              if (event.data.absl_id) await setFieldValue('absl_id', event.data.absl_id);
            }}
            shouldUpdate={produceShouldUpdate(
              [],
              [],
              ['biological_agent_type_id', 'biological_bug_species_id'],
            )}
            component={AsyncSelectorField}
          />
          <FastField name="comments" component={TextField} />
          <FastField
            required
            name="risk_group_id"
            api={API.selectors.riskGroups.index}
            selectionApi={API.selectors.riskGroups.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField
            required
            name="bsl_id"
            api={API.selectors.bsls.index}
            selectionApi={API.selectors.bsls.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField
            name="absl_id"
            api={API.selectors.absls.index}
            selectionApi={API.selectors.absls.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField
            name="replication_ability_id"
            api={API.selectors.replicationAbilities.index}
            selectionApi={API.selectors.replicationAbilities.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField
            required
            name="nih_applicability_id"
            api={API.selectors.nihApplicabilities.index}
            selectionApi={API.selectors.nihApplicabilities.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
        </DefaultAttributesGroup>
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={hide} />
            <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
              {t('footer.save')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </ModalSection>
    </form>
  );
};
