import React from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/styles/colors';

const Wrapper = styled.div`
  height: 36px;
  display: flex;
  vertical-align: center;
`;

const StyledButton = styled.button`
  margin: 5px;
  min-width: 110px;
  height: 26px;
  line-height: 26px;
  font-size: 12px;
  border-radius: 4px;
  color: ${colors.listButtonFontColor};
  background: none;
  cursor: pointer;
  border: none;
  &:hover {
    color: ${colors.listButtonHoverFontColor};
    background: ${colors.listButtonHoverBackground};
  }
  &:focus {
    outline: none;
  }
`;

const produceOnClick = onClick => e => {
  e.preventDefault();
  onClick(e);
};

export const ListButton = ({ children, onClick, ...props }) => (
  <Wrapper>
    <StyledButton onClick={produceOnClick(onClick)} {...props}>
      {children}
    </StyledButton>
  </Wrapper>
);
