import React from 'react';
import { passDefaultAttributesToChildren } from '../../services/utils';

export const IndentedFormSection = ({ children, defaultChildrenAttributes = false }) => (
  <div className="pl-4">
    {defaultChildrenAttributes
      ? passDefaultAttributesToChildren(children, defaultChildrenAttributes)
      : children}
  </div>
);
