import React from 'react';
import { FastField } from 'formik';
import { DefaultAttributesGroup } from '../../../../components/form/DefaultAttributesGroup';
import { fieldDisplayModes } from '../../../../constants/fieldDisplayModes';
import { ModalSection } from '../../../../components/modals/ModalSection';
import { TextField } from '../../../../components/fields/TextField';
import { FilesField } from '../../../../components/fields/FilesField';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../../components/form/FormFooter';
import { DeletionLink } from '../../../../components/links/DeletionLink';
import { API } from '../../../../services/api';
import {
  defaultHandleSubmit,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../../services/utils';
import { CancellationLink } from '../../../../components/links/CancellationLink';
import { BrandButton } from '../../../../components/buttons/BrandButton';

const t = key => I18n.t(`hsds.logo_profiles.${key}`);

export const LogoProfileForm = props => {
  const {
    values: { id, primary_logo_attachment, secondary_logo_attachment },
    hide,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    currentUser,
  } = props;
  const userIsAdmin = currentUser.app_admin || currentUser.org_admin;
  return (
    <form>
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          displayMode: fieldDisplayModes.WIDE_FORM_ROW,
          labelI18nKeyPrefix: 'activerecord.attributes.logo_profile',
          resourceName: I18n.t('resource_names.logo_profile'),
        }}
      >
        <ModalSection>
          <FastField name="name" required component={TextField} />
          <FastField
            name="primary_logo_attachment"
            component={FilesField}
            disabled={primary_logo_attachment.length > 0}
          />
          <FastField
            name="secondary_logo_attachment"
            component={FilesField}
            disabled={secondary_logo_attachment.length > 0}
          />
          <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
            <FormFooterLeftPart>
              <DeletionLink
                withIcon
                hidden={!(id && userIsAdmin)}
                modelName={I18n.t('activerecord.models.logo_profile')}
                onSubmit={async () => {
                  const { ok, data } = await API.spaceRiskAssessments.logoProfiles.destroy(id);
                  if (ok) {
                    setInitialFlashMessageForNextPage(t('flash.success.remove'));
                    hide();
                    window.location.reload();
                  } else {
                    showBackendErrorMessage(t('flash.error.remove'), data);
                  }
                }}
              />
            </FormFooterLeftPart>
            <FormFooterRightPart>
              <CancellationLink onClick={hide} />
              <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
                {t('footer.save')}
              </BrandButton>
            </FormFooterRightPart>
          </FormFooter>
        </ModalSection>
      </DefaultAttributesGroup>
    </form>
  );
};
