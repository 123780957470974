import React, { useMemo, useState, useEffect, useRef } from 'react';
import { find } from 'lodash';
import { getUuid } from '../../../../services/uuidHelper';
import { API } from '../../../services/api';

const t = key => I18n.t(`radiation_safety.rad_inventories.new_edit.${key}`);

const extractFromRow = (row, fieldName) =>
  find(row.cells, cell => cell.field === fieldName)?.content;

const setSubjectInSingularItemCase = async (row, setSubject, setSubjectActionUuid) => {
  const initialSetSubjectActionUuid = setSubjectActionUuid.current;
  const availableFields = row.cells.map(cell => cell.field);

  if (availableFields.includes('identifier') && availableFields.includes('rad_isotope_id')) {
    const identifier = extractFromRow(row, 'identifier') || '';
    const isotope = extractFromRow(row, 'rad_isotope_id') || '';
    setSubject(`${isotope} (${identifier})`);
  } else {
    setSubject('');
    const { ok, data } = await API.selectors.radInventoryItems.show(row.key);
    if (ok && setSubjectActionUuid.current === initialSetSubjectActionUuid) setSubject(data.text);
  }
};

const useSubject = selectedRows => {
  const [subject, setSubject] = useState('');
  const setSubjectActionUuid = useRef(getUuid);

  useEffect(() => {
    setSubjectActionUuid.current = getUuid();
    if (selectedRows.length === 1)
      setSubjectInSingularItemCase(selectedRows[0], setSubject, setSubjectActionUuid);
    else setSubject(selectedRows.length);
  }, [selectedRows]);

  return subject;
};

export const useMoveItemsModalSubtitle = (selectedRows, sourceInventoryName) => {
  const subjectLabel = useMemo(
    () =>
      selectedRows.length === 1
        ? t('modals.move_to_another_inventory.item_selected')
        : t('modals.move_to_another_inventory.items_selected_count'),
    [selectedRows],
  );
  const subject = useSubject(selectedRows);

  return useMemo(
    () => (
      <>
        <span>
          <b>
            {subjectLabel}
            {': '}
          </b>
          {subject}
        </span>
        <span className="pl-4">
          <b>
            {t('modals.move_to_another_inventory.source_inventory')}
            {': '}
          </b>
          {sourceInventoryName}
        </span>
      </>
    ),
    [subjectLabel, subject, sourceInventoryName],
  );
};
