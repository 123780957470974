import React from 'react';
import { FastField } from 'formik';
import { DefaultAttributesGroup } from '../../../../components/form/DefaultAttributesGroup';
import { fieldDisplayModes } from '../../../../constants/fieldDisplayModes';
import { ModalSection } from '../../../../components/modals/ModalSection';
import { produceShouldUpdate } from '../../../../services/fieldUtils';
import { API } from '../../../../services/api';
import { htmlOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../../components/fields/AsyncSelectorField';
import { DateField } from '../../../../components/fields/DateField';
import { NumericField } from '../../../../components/fields/NumericField';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../../components/form/FormFooter';
import { DeletionLink } from '../../../../components/links/DeletionLink';
import {
  defaultHandleSubmit,
  fieldValue,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../../services/utils';
import { CancellationLink } from '../../../../components/links/CancellationLink';
import { BrandButton } from '../../../../components/buttons/BrandButton';
import { NumericAndUnitFields } from '../../../../components/fields/compositeFields/NumericAndUnitFields';

const t = key => I18n.t(`radiation_safety.rad_permits.modals.rad_license_item_assignments.${key}`);

const setLicenseItemRelatedFields = async (values, setFieldValue) => {
  const {
    rad_item_type_id,
    remaining_activity,
    remaining_quantity,
    activity_unit_id,
    quantity_unit_id,
  } = values.data;

  await setFieldValue('radItemTypeId', fieldValue(rad_item_type_id));
  await setFieldValue('remainingLicenseActivity', fieldValue(remaining_activity));
  await setFieldValue('remainingLicenseQuantity', fieldValue(remaining_quantity));
  await setFieldValue('activityUnitId', fieldValue(activity_unit_id));
  await setFieldValue('quantityUnitId', fieldValue(quantity_unit_id));
};

export const RadLicenseItemAssignmentForm = props => {
  const {
    values: { id, radLicenseId, rad_license_item_id },
    hide,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    listRef,
    radPermitId,
  } = props;

  return (
    <form>
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          displayMode: fieldDisplayModes.WIDE_FORM_ROW,
          labelI18nKeyPrefix: 'activerecord.attributes.rad_license_item_assignment',
          resourceName: I18n.t('resource_names.rad_license_item_assignment'),
        }}
      >
        <ModalSection>
          <FastField
            name="radLicenseId"
            required
            disabled
            api={API.selectors.radLicenses.index}
            selectionApi={API.selectors.radLicenses.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
            label={t('additional_fields.rad_license_id')}
          />
          <FastField
            name="rad_license_item_id"
            required
            api={API.selectors.radLicenseItems.index}
            apiParams={{ rad_license_id: radLicenseId, with_remaining_limits: true }}
            selectionApi={API.selectors.radLicenseItems.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
            onChange={async (newSelection, onChange) => {
              await onChange(newSelection);
              await setLicenseItemRelatedFields(newSelection, setFieldValue);
            }}
          />
          <FastField
            name="radItemTypeId"
            disabled
            api={API.selectors.radItemTypes.index}
            selectionApi={API.selectors.radItemTypes.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
            label={t('additional_fields.rad_item_type_id')}
          />
          <FastField
            name="remainingLicenseActivity"
            disabled
            component={NumericAndUnitFields}
            label={t('additional_fields.remaining_license_activity')}
            shouldUpdate={produceShouldUpdate([], [], ['rad_license_item_id'])}
            placeholder={rad_license_item_id ? '∞' : undefined}
            unitName="activityUnitId"
            unitProps={{ placeholder: undefined }}
            unitApiParams={{ um_type: ['radioactivity'] }}
          />
          <FastField
            name="activity_limit"
            placeholder="∞"
            component={NumericAndUnitFields}
            unitName="activityUnitId"
            unitProps={{ disabled: true, required: false }}
            unitApiParams={{ um_type: ['radioactivity'] }}
          />
          <FastField
            name="remainingLicenseQuantity"
            disabled
            component={NumericAndUnitFields}
            label={t('additional_fields.remaining_license_quantity')}
            shouldUpdate={produceShouldUpdate([], [], ['rad_license_item_id'])}
            placeholder={rad_license_item_id ? '∞' : undefined}
            unitName="quantityUnitId"
            unitProps={{ placeholder: undefined }}
            unitApiParams={{ um_type: ['mass'] }}
          />
          <FastField
            name="quantity_limit"
            placeholder="∞"
            component={NumericAndUnitFields}
            unitName="quantityUnitId"
            unitProps={{ disabled: true, required: false }}
            unitApiParams={{ um_type: ['mass'] }}
          />
          <FastField name="purchase_number_limit" component={NumericField} />
          <FastField name="start_date" component={DateField} />
          <FastField name="closed_date" component={DateField} />

          <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
            <FormFooterLeftPart>
              <DeletionLink
                withIcon
                hidden={!id}
                modelName={I18n.t('activerecord.models.rad_license_item_assignment')}
                onSubmit={async () => {
                  const { ok, data } = await API.radiationSafety
                    .radLicenseItemAssignments(radPermitId)
                    .destroy(id);
                  if (ok) {
                    setInitialFlashMessageForNextPage(t('flash.success.remove'));
                    hide();
                    listRef.current.refresh();
                  } else {
                    showBackendErrorMessage(t('flash.error.remove'), data);
                  }
                }}
              />
            </FormFooterLeftPart>
            <FormFooterRightPart>
              <CancellationLink onClick={hide} />
              <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
                {t('footer.save')}
              </BrandButton>
            </FormFooterRightPart>
          </FormFooter>
        </ModalSection>
      </DefaultAttributesGroup>
    </form>
  );
};
