import React from 'react';
import { FastField } from 'formik';
import { DefaultAttributesGroup } from '../../../../components/form/DefaultAttributesGroup';
import { fieldDisplayModes } from '../../../../constants/fieldDisplayModes';
import { ModalSection } from '../../../../components/modals/ModalSection';
import { API } from '../../../../services/api';
import { htmlOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncSelectorField } from '../../../../components/fields/AsyncSelectorField';
import { TextField } from '../../../../components/fields/TextField';
import { CheckBoxField } from '../../../../components/fields/CheckBoxField';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../../components/form/FormFooter';
import { DeletionLink } from '../../../../components/links/DeletionLink';
import {
  defaultHandleSubmit,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../../services/utils';
import { CancellationLink } from '../../../../components/links/CancellationLink';
import { BrandButton } from '../../../../components/buttons/BrandButton';

const t = key => I18n.t(`hsds.hazard_assessments.new_edit.modals.hsds_emergency_contacts.${key}`);

export const HsdsEmergencyContactForm = props => {
  const {
    values: { id, category, office_phone, additional_emergency_phone },
    hide,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    currentUser,
    spaceRiskAssessmentId,
    listRef,
  } = props;
  const userIsAdmin = currentUser.app_admin || currentUser.org_admin;
  const tooLongTextWarning = text => {
    const maxLengthOfText = 14;
    return text?.length > maxLengthOfText
      ? I18n.t('hsds.hazard_assessments.new_edit.hints.too_long_text')
      : null;
  };
  return (
    <form>
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          displayMode: fieldDisplayModes.WIDE_FORM_ROW,
          labelI18nKeyPrefix: 'activerecord.attributes.hsds_emergency_contact',
          resourceName: I18n.t('resource_names.hsds_emergency_contact'),
        }}
      >
        <ModalSection>
          <FastField
            name="contact_person_id"
            required
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
          <FastField name="category" component={TextField} warning={tooLongTextWarning(category)} />
          <FastField
            name="office_phone"
            component={TextField}
            warning={tooLongTextWarning(office_phone)}
          />
          <FastField
            name="additional_emergency_phone"
            component={TextField}
            placeholder={t('hints.additional_emergency_phone')}
            warning={tooLongTextWarning(additional_emergency_phone)}
          />
          <FastField name="post_emergency_phone" component={CheckBoxField} />
          <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
            <FormFooterLeftPart>
              <DeletionLink
                withIcon
                hidden={!(id && userIsAdmin)}
                modelName={I18n.t('activerecord.models.hsds_emergency_contact')}
                onSubmit={async () => {
                  const { ok, data } = await API.spaceRiskAssessments
                    .hsdsEmergencyContacts(spaceRiskAssessmentId)
                    .destroy(id);
                  if (ok) {
                    setInitialFlashMessageForNextPage(t('flash.success.remove'));
                    hide();
                    listRef.current.refresh();
                  } else {
                    showBackendErrorMessage(t('flash.error.remove'), data);
                  }
                }}
              />
            </FormFooterLeftPart>
            <FormFooterRightPart>
              <CancellationLink onClick={hide} />
              <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
                {t('footer.save')}
              </BrandButton>
            </FormFooterRightPart>
          </FormFooter>
        </ModalSection>
      </DefaultAttributesGroup>
    </form>
  );
};
