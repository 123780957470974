import React, { useState, useEffect } from 'react';
import { isDefined } from '../../services/utils';
import { API } from '../../services/api';
import { Loader } from '../../components/Loader';
import { RadPurchaseForm } from './Form';

export const RadPurchase = props => {
  const [fetching, setFetching] = useState(true);
  const [radPurchase, setRadPurchase] = useState({});
  useEffect(() => {
    (async () => {
      const {
        params: { id, ref_rad_purchase_id, rad_permit_id },
      } = props;
      const api = isDefined(id)
        ? API.radiationSafety.radPurchases.show
        : () => API.radiationSafety.radPurchases.new({ ref_rad_purchase_id, rad_permit_id });
      const { ok, data } = await api(id);
      if (ok) {
        await setRadPurchase(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <RadPurchaseForm radPurchase={radPurchase} {...props} />;
};
export default RadPurchase;
