import React from 'react';
import { FastField } from 'formik';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { TextField } from '../../../components/fields/TextField';
import { DateField } from '../../../components/fields/DateField';

// eslint-disable-next-line consistent-return
const renderProperInput = answerType => {
  // eslint-disable-next-line default-case
  switch (answerType) {
    case 'checkbox':
      return CheckBoxField;
    case 'text':
      return TextField;
    case 'date':
      return DateField;
  }
};

const setProperDisplayMode = answerType => {
  if (answerType === 'checkbox') {
    return fieldDisplayModes.INPUT_FIRST_FORM_ROW;
  }
  return fieldDisplayModes.DEFAULT_FORM_ROW;
};

const findAnswerIndex = (answers, questionId) => {
  return answers.findIndex(answer => answer.swa_question_id === questionId);
};

const ChecklistQuestion = ({
  swaQuestions,
  swaQuestionAnswers,
  question,
  index,
  indentationLevel,
  disabled,
  resourceName,
}) => {
  const childrenQuestions = swaQuestions.filter(
    childQuestion => childQuestion.parent_question_id === question.id,
  );
  childrenQuestions.sort((a, b) => a.order - b.order);
  const hasChildrenQuestions = childrenQuestions && childrenQuestions.length !== 0;
  const shouldDisplayChildrenQuestions = hasChildrenQuestions && swaQuestionAnswers[index].answer;

  return (
    <>
      <div className={indentationLevel > 0 ? 'pl-4' : ''}>
        <div className={indentationLevel > 1 ? 'pl-4' : ''}>
          <FastField
            name={`swaQuestionAnswersAttributes.${index}.answer`}
            resourceName={resourceName}
            disabled={disabled}
            shouldUpdate={defaultShouldUpdate}
            label={question.question}
            displayMode={setProperDisplayMode(question.configuration.answer_type)}
            component={renderProperInput(question.configuration.answer_type)}
            hint={question.configuration.tooltip_text}
          />
        </div>
      </div>
      {shouldDisplayChildrenQuestions &&
        childrenQuestions.map(childQuestion => {
          const answerIndex = findAnswerIndex(swaQuestionAnswers, childQuestion.id);
          return (
            <ChecklistQuestion
              swaQuestions={swaQuestions}
              swaQuestionAnswers={swaQuestionAnswers}
              question={childQuestion}
              index={answerIndex}
              indentationLevel={indentationLevel + 1}
              disabled={disabled}
              resourceName={resourceName}
            />
          );
        })}
    </>
  );
};

export const Checklist = props => {
  const { swa_questions, swaQuestionAnswersAttributes: answers, is_completed } = props.values;

  const parentQuestions = swa_questions.filter(question => !question.parent_question_id);
  parentQuestions.sort((a, b) => a.order - b.order);
  return parentQuestions.map(question => {
    const answerIndex = findAnswerIndex(answers, question.id);
    return (
      <ChecklistQuestion
        swaQuestions={swa_questions}
        swaQuestionAnswers={answers}
        question={question}
        index={answerIndex}
        indentationLevel={0}
        disabled={is_completed}
        resourceName={props.resourceName}
      />
    );
  });
};
