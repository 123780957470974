import React from 'react';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../components/form/FormFooter';
import { API } from '../../../services/api';
import {
  defaultHandleSubmit,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../services/utils';
import { DeletionLink } from '../../../components/links/DeletionLink';
import { BrandButton } from '../../../components/buttons/BrandButton';

const t = key => I18n.t(`training.employee_trainings.new_edit.${key}`);

export const Footer = ({
  isSubmitting,
  setFieldValue,
  handleSubmit,
  values: { id },
  currentUser,
}) => {
  return (
    <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
      <FormFooterLeftPart>
        <DeletionLink
          withIcon
          hidden={!(id && (currentUser.app_admin || currentUser.org_admin))}
          modelName={I18n.t('activerecord.models.employee_training')}
          onSubmit={async () => {
            const { ok, data } = await API.employeeTrainings.employeeTrainings.destroy(id);
            if (ok) {
              setInitialFlashMessageForNextPage(t('flash.success.remove'));
              window.location.href = `/training/employee_trainings`;
            } else {
              showBackendErrorMessage(t('flash.error.remove'), data);
            }
          }}
        />
      </FormFooterLeftPart>
      <FormFooterRightPart>
        <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
          {t('footer.save')}
        </BrandButton>
      </FormFooterRightPart>
    </FormFooter>
  );
};
