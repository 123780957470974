import React from 'react';
import Select from 'react-select-next';
import { YesNoNaInput } from '../../../../../../components/inputs/YesNoNaInput';
import { RangeSelect } from '../../../../../../components/inputs/RangeSelect';
import { selectorCustomStyles } from '../../../../../../components/fields/auxiliary/selectorCustomStyles';

export const ConditionExpectedAnswer = ({
  index,
  answer,
  answerType,
  configuration,
  setFieldValue,
}) => {
  const inputId = `visibility_conditions-${index}-expected_answer`;

  switch (answerType) {
    case 'yes_no_na':
      return (
        <YesNoNaInput
          id={inputId}
          answer={answer}
          onChange={value => {
            setFieldValue(`visibilityConditions[${index}].leader_expected_answer`, value);
          }}
        />
      );
    case 'range_select':
      return (
        <RangeSelect
          id={inputId}
          onChange={value => {
            setFieldValue(`visibilityConditions[${index}].leader_expected_answer`, value);
          }}
          configuration={configuration}
          answer={answer}
        />
      );
    case 'select':
      // eslint-disable-next-line no-case-declarations
      const selectOptions = configuration.options_for_select.map(o => ({
        label: o,
        value: o,
      }));
      return (
        <Select
          inputId={inputId}
          value={selectOptions.find(o => o.value === answer)}
          options={selectOptions}
          styles={selectorCustomStyles}
          manuPlacement="auto"
          onChange={selection => {
            setFieldValue(`visibilityConditions[${index}].leader_expected_answer`, selection.value);
          }}
        />
      );
    default:
      return (
        <p>
          {I18n.t(
            'administration.inspection_types.new_edit.checklist.question_details_modal.cannot_set_expected_answer',
          )}
        </p>
      );
  }
};
