import React from 'react';
import { FastField } from 'formik';
import { DefaultAttributesGroup } from '../../../../components/form/DefaultAttributesGroup';
import { fieldDisplayModes } from '../../../../constants/fieldDisplayModes';
import { ModalSection } from '../../../../components/modals/ModalSection';
import { defaultShouldUpdate } from '../../../../services/fieldUtils';
import { API } from '../../../../services/api';
import { htmlOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../../components/fields/AsyncSelectorField';
import { TextField } from '../../../../components/fields/TextField';
import { CheckBoxField } from '../../../../components/fields/CheckBoxField';
import { Footer } from './Footer';
import { NumericAndUnitFields } from '../../../../components/fields/compositeFields/NumericAndUnitFields';

const t = key => I18n.t(`radiation_safety.rad_licenses.modals.rad_license_items.${key}`);

export const RadLicenseItemForm = props => {
  return (
    <form>
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          displayMode: fieldDisplayModes.WIDE_FORM_ROW,
          labelI18nKeyPrefix: 'activerecord.attributes.rad_license_item',
          resourceName: I18n.t('resource_names.rad_license_item'),
          shouldUpdate: defaultShouldUpdate,
        }}
      >
        <ModalSection>
          <FastField disabled name="identifier" component={TextField} />
          <FastField
            name="rad_isotope_id"
            required
            api={API.selectors.radIsotopes.index}
            selectionApi={API.selectors.radIsotopes.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField
            name="rad_item_type_id"
            required
            api={API.selectors.radItemTypes.index}
            selectionApi={API.selectors.radItemTypes.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField
            name="rad_form_id"
            required
            api={API.selectors.radForms.index}
            selectionApi={API.selectors.radForms.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField name="license_condition_ref_number" component={TextField} />
          <FastField
            name="max_activity"
            placeholder="∞"
            component={NumericAndUnitFields}
            unitName="activity_unit_id"
            unitProps={{ required: true }}
            unitApiParams={{ um_type: ['radioactivity'] }}
          />
          <FastField
            name="max_quantity"
            placeholder="∞"
            component={NumericAndUnitFields}
            unitName="quantity_unit_id"
            unitProps={{ required: true, hint: t('hints.quantity_unit') }}
            unitApiParams={{ um_type: ['mass'] }}
          />
          <FastField name="use" component={TextField} />
          <FastField name="active" component={CheckBoxField} />
          <Footer {...props} />
        </ModalSection>
      </DefaultAttributesGroup>
    </form>
  );
};
