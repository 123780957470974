import styled from 'styled-components';

export const ModalWarning = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: 700;
  margin: 3rem 0 1rem;
`;

export const AdditionalModalContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
  margin: 1rem 0 1rem;
`;
