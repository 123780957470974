import React from 'react';
import { FormSection } from '../../../components/form/FormSection';
import { isDefined } from '../../../services/utils';
import { API } from '../../../services/api';
import { IndexTableDefaultHandler } from '../../../components/tables/IndexTableDefaultHandler';

const t = key => I18n.t(`biological_strains.new_edit.${key}`);

export const IbcRegistrations = props => {
  const {
    values: { id },
  } = props;

  return (
    <>
      <FormSection>
        <IndexTableDefaultHandler
          title={t('lists.ibc_registrations.title')}
          api={API.biologicalSafety.ibcRegistrations.index}
          fixedFilters={{ with_biological_strain_id: id }}
          idToHrefFunction={_id => `/biological_safeties/${_id}/edit`}
          manageColumnsModel="BiologicalSafety"
          forceEmpty={!isDefined(id)}
        />
      </FormSection>
    </>
  );
};
