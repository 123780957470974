import React, { useState, useEffect } from 'react';
import { Loader } from '../../components/Loader';
import { API } from '../../services/api';
import { isDefined } from '../../services/utils';
import { SafeWorkAuthorizationMeasureForm } from './Form';

export const SafeWorkAuthorizationMeasure = props => {
  const { id, swaId } = props;

  const [fetching, setFetching] = useState(true);
  const [swa, setSwa] = useState({});
  const [measure, setMeasure] = useState({});
  useEffect(() => {
    (async () => {
      const { ok: swaOk, data: swaData } = await API.requests.safeWorkAuthorizations.show(swaId);
      if (swaOk) {
        setSwa(swaData.data.attributes);
      } else {
        return;
      }
      const swaMeasureApi = isDefined(id)
        ? API.requests.safeWorkAuthorizations.measures(swaId).show
        : API.requests.safeWorkAuthorizations.measures(swaId).new;
      const { ok: measureRequestOk, data: measureData } = await swaMeasureApi(id);
      if (measureRequestOk) {
        setMeasure(measureData.data.attributes);
        setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <SafeWorkAuthorizationMeasureForm {...props} swa={swa} swaMeasure={measure} />;
};
export default SafeWorkAuthorizationMeasure;
