import React, { useCallback, useRef, useState } from 'react';
import { IndexTableDefaultHandler } from '../../../components/tables/IndexTableDefaultHandler';
import { API } from '../../../services/api';
import { Paper } from '../../../components/form/Paper';
import { Modal } from '../../../components/modals/Modal';
import { MoveMultipleItemsToAnotherInventoryModalContent } from '../ItemsModalsContents/MoveMultipleItemsToAnotherInventoryModalContent';
import { SmallLightButton } from '../../../components/buttons/SmallLightButton';
import { MoveItemToAnotherInventory } from '../ItemsModalsContents/MoveItemToAnotherInventory';
import { handleSubmitWithAfterSave, isDefined } from '../../../services/utils';
import { useMoveItemsModalSubtitle } from './useMoveItemsModalSubtitle';
import { DefaultLink } from '../../../components/links/DefaultLink';

const t = key => I18n.t(`radiation_safety.rad_inventories.new_edit.${key}`);

export const RadInventoryItemsTable = props => {
  const {
    setFieldValue,
    handleSubmit,
    isSubmitting,
    values: { id: inventoryId, name, rad_permit_id },
  } = props;

  const tableRef = useRef();
  const moveMultipleModalRef = useRef();
  const [selectedRows, setSelectedRows] = useState([]);
  const modalSubtitle = useMoveItemsModalSubtitle(selectedRows, name);

  const getHref = useCallback(
    _id => `/radiation_safety/rad_inventories/${inventoryId}/rad_inventory_items/${_id}/edit`,
    [inventoryId],
  );

  return (
    <>
      <Paper>
        <IndexTableDefaultHandler
          ref={tableRef}
          title={t('sections.rad_inventory_items')}
          api={API.radiationSafety.radInventoryItems().index}
          fixedFilters={{ with_rad_inventory_id: inventoryId }}
          idToHrefFunction={getHref}
          onCheckboxesChange={rows => setSelectedRows(rows)}
          manageColumnsModel="RadInventoryItem"
          forceEmpty={!isDefined(inventoryId)}
          additionalLinks={[
            <SmallLightButton
              key="move_to_another_inventory"
              disabled={selectedRows.length === 0 || isSubmitting}
              onClick={() => {
                moveMultipleModalRef.current.open();
              }}
            >
              {t('buttons.move_to_another_inventory')}
            </SmallLightButton>,
            <DefaultLink
              disabled={isSubmitting}
              onClick={async () => {
                const redirect = newId => {
                  setTimeout(
                    (window.location.href = `/radiation_safety/rad_inventories/${newId}/rad_inventory_items/new`),
                    1000,
                  );
                };
                await handleSubmitWithAfterSave(setFieldValue, handleSubmit, redirect);
              }}
            >
              {t('buttons.add_rad_inventory_item')}
            </DefaultLink>,
          ]}
        />
      </Paper>
      <Modal
        title={t('modals.move_to_another_inventory.title')}
        subTitle={modalSubtitle}
        ref={moveMultipleModalRef}
      >
        {modalProps => {
          const moveModalProps = {
            ...modalProps,
            currentInventoryId: inventoryId,
            permitId: rad_permit_id,
            onSuccess: () => {
              tableRef.current.refresh();
            },
          };
          let component;
          if (selectedRows.length === 1) {
            moveModalProps.id = selectedRows[0].key;
            component = MoveItemToAnotherInventory;
          } else {
            moveModalProps.ids = selectedRows.map(row => row.key);
            component = MoveMultipleItemsToAnotherInventoryModalContent;
          }

          return React.createElement(component, moveModalProps);
        }}
      </Modal>
    </>
  );
};
