import React from 'react';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../components/form/FormFooter';
import { BrandButton } from '../../../components/buttons/BrandButton';
import { DeletionLink } from '../../../components/links/DeletionLink';
import { API } from '../../../services/api';
import {
  showBackendErrorMessage,
  handleSubmitWithAfterSave,
  setInitialFlashMessageForNextPage,
} from '../../../services/utils';
import { CancellationLink } from '../../../components/links/CancellationLink';

const t = key => I18n.t(`safe_work_authorization_measures.${key}`);

export const Footer = ({ isSubmitting, setFieldValue, handleSubmit, swaId, values: { id } }) => (
  <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
    <FormFooterLeftPart>
      <DeletionLink
        hidden={!id}
        withIcon
        modelName={I18n.t('activerecord.models.safe_work_authorization_measure')}
        onSubmit={async () => {
          const { ok, data } = await API.requests.safeWorkAuthorizations
            .measures(swaId)
            .destroy(id);
          if (ok) {
            setInitialFlashMessageForNextPage(t('flash.success.delete'));
            window.location.href = `/requests/safe_work_authorizations/${swaId}/edit`;
          } else {
            showBackendErrorMessage(t('flash.error.delete'), data);
          }
        }}
      />
    </FormFooterLeftPart>
    <FormFooterRightPart>
      <CancellationLink href={`/requests/safe_work_authorizations/${swaId}/edit`} />
      <BrandButton
        onClick={async () => {
          const afterSave = () => {
            setInitialFlashMessageForNextPage(t('flash.success.save'));
            window.location.href = `/requests/safe_work_authorizations/${swaId}/edit`;
          };
          await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
        }}
      >
        {t('footer.save')}
      </BrandButton>
    </FormFooterRightPart>
  </FormFooter>
);
