import React from 'react';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../../components/form/FormFooter';
import { DeletionLink } from '../../../../components/links/DeletionLink';
import { API } from '../../../../services/api';
import {
  defaultHandleSubmit,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { CancellationLink } from '../../../../components/links/CancellationLink';
import { BrandButton } from '../../../../components/buttons/BrandButton';

const t = key => I18n.t(`radiation_safety.rad_licenses.modals.rad_license_items.${key}`);

export const Footer = props => {
  const {
    values: { id },
    hide,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    listRef,
    radLicenseId,
  } = props;
  return (
    <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
      <FormFooterLeftPart>
        <DeletionLink
          withIcon
          hidden={!id}
          modelName={I18n.t('activerecord.models.rad_license_item')}
          onSubmit={async () => {
            const { ok, data } = await API.radiationSafety
              .radLicenseItems(radLicenseId)
              .destroy(id);
            if (ok) {
              hide();
              listRef.current.refresh();
              showSuccessMessage(t('flash.success.remove'));
            } else {
              showBackendErrorMessage(t('flash.error.remove'), data);
            }
          }}
        />
      </FormFooterLeftPart>
      <FormFooterRightPart>
        <CancellationLink onClick={hide} />
        <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
          {t('footer.save')}
        </BrandButton>
      </FormFooterRightPart>
    </FormFooter>
  );
};
