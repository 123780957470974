import React from 'react';
import styled from 'styled-components';
import range from 'lodash/range';
import { colors } from '../../assets/styles/colors';

const t = key => I18n.t(`views.pagination.${key}`);

const Container = styled.nav`
  display: flex;
  justify-content: flex-end;
`;

const NoniteractiveElement = styled.span`
  color: ${colors.selectedLink};
  font-size: 14px;
`;

const PageLink = styled.button`
  margin: 0 4px;
  font-size: 14px;
  padding: 0;
  background: none;
  border: none;
  color: ${colors.link};
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  ${props =>
    props.selected
      ? `
        color: ${colors.selectedLink};
        &:hover {
          cursor: default;
          text-decoration: none;
        }
      `
      : ''}
`;

export const IndexTablePaginator = ({ currentPage, totalPages, onPageChange }) => {
  const minPageDisplayed = currentPage > 6 ? currentPage - 4 : 1;
  const maxPageDisplayed = currentPage > totalPages - 6 ? totalPages : currentPage + 4;
  const generateChangePageCb = pageNumber => e => {
    e.preventDefault();
    e.stopPropagation();
    if (pageNumber === currentPage) return;

    onPageChange(pageNumber);
  };
  const generatePageLinkProps = pageNumber => ({
    onClick: generateChangePageCb(pageNumber),
  });
  return (
    <Container>
      {currentPage !== 1 ? (
        <>
          <PageLink {...generatePageLinkProps(1)}>{t('first')}</PageLink>
          <PageLink
            {...generatePageLinkProps(currentPage - 1)}
            dangerouslySetInnerHTML={{ __html: t('previous') }}
          />
        </>
      ) : null}
      {minPageDisplayed !== 1 ? (
        <NoniteractiveElement dangerouslySetInnerHTML={{ __html: t('truncate') }} />
      ) : null}
      {range(minPageDisplayed, maxPageDisplayed + 1).map(pageNumber => (
        <PageLink
          selected={pageNumber === currentPage}
          {...generatePageLinkProps(pageNumber)}
          key={pageNumber}
        >
          {pageNumber}
        </PageLink>
      ))}
      {maxPageDisplayed !== totalPages ? (
        <NoniteractiveElement dangerouslySetInnerHTML={{ __html: t('truncate') }} />
      ) : null}
      {currentPage !== totalPages && totalPages !== 0 ? (
        <>
          <PageLink
            {...generatePageLinkProps(currentPage + 1)}
            dangerouslySetInnerHTML={{ __html: t('next') }}
          />
          <PageLink {...generatePageLinkProps(totalPages)}>{t('last')}</PageLink>
        </>
      ) : null}
    </Container>
  );
};
