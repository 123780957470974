import { withFormik } from 'formik';
import { Form } from './Form';
import { validationSchema } from './validationSchema';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { API } from '../../../services/api';

const t = key => I18n.t(`equipment_types.new_edit.${key}`);

const EquipmentTypesFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const equipmentType = EquipmentTypesFunctions.prepareValuesToSubmit({ ...values });
    if (isDefined(equipmentType.id)) {
      EquipmentTypesFunctions.update(equipmentType, formikProps, values.afterSave);
    } else {
      EquipmentTypesFunctions.create(equipmentType, formikProps, values.afterSave);
    }
  },
  create: async (equipment_type, formikProps, afterSave) => {
    const { ok, data } = await API.administration.equipmentTypes.create({
      equipment_type,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/administration/equipment_types/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.administration.equipment_types.edit.title'));
      formikProps.resetForm({
        values: EquipmentTypesFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (equipment_type, formikProps, afterSave) => {
    const { ok, data } = await API.administration.equipmentTypes.update(equipment_type.id, {
      equipment_type,
    });
    if (ok) {
      formikProps.resetForm({
        values: EquipmentTypesFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t(`flash.success.save`));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t(`flash.error.save`), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: {
    afterSave: () => {},
  },
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'identifier',
    'equip_type_name',
    'is_active',
    'ehs_inspect_freq',
    'reg_inspect_freq',
  ],
});

export const EquipmentTypeForm = withFormik({
  validate: produceDefaultValidation(validationSchema),
  mapPropsToValues: props => EquipmentTypesFunctions.prepareInitialValues(props.equipmentType),
  handleSubmit: EquipmentTypesFunctions.handleSubmit,
})(Form);
