import Cookies from 'universal-cookie';
import { lmsSalutePortalApi } from '../../services/lmsApi';
import { API } from '../../services/api';
import { isDefined } from '../../services/utils';

export const fetchLmsToken = async () => {
  const { ok, data } = await API.lmsApiToken.show();
  const cookies = new Cookies();
  const oneDayInSec = 86400;

  cookies.set('lms_api_token', ok ? data.token : '', {
    path: '/',
    maxAge: oneDayInSec,
  });
};

export const initializeLmsApiIntegration = async () => {
  const cookies = new Cookies();

  if (!isDefined(cookies.get('lms_api_token'))) {
    await fetchLmsToken();
  }
};

export const getPersonLmsId = async (baseUrl, personId, firstConnection = true) => {
  const cookies = new Cookies();

  const { ok, data } = await lmsSalutePortalApi(
    baseUrl,
    cookies.get('lms_api_token'),
  ).userDetails.indexBySaluteId(personId);

  if (ok && data.length > 0 && !data.errorcode) {
    return data[0].id;
  }
  if (firstConnection) {
    await fetchLmsToken();
    await getPersonLmsId(baseUrl, personId, false);
  }
  return undefined;
};

const fetchUncompletedTrainings = async (baseUrl, personLMSId, firstConnection = true) => {
  const cookies = new Cookies();

  const { ok, data } = await lmsSalutePortalApi(
    baseUrl,
    cookies.get('lms_api_token'),
  ).trainings.index(personLMSId);

  if (ok && !data.errorcode) {
    return data.filter(training => !training.timecompleted);
  }
  if (firstConnection) {
    await fetchLmsToken();
    await fetchUncompletedTrainings(baseUrl, personLMSId, false);
  }
  return [];
};

export const countLmsTrainings = async (baseUrl, personId) => {
  let trainingsCount = 0;

  const personLMSId = await getPersonLmsId(baseUrl, personId);

  if (personLMSId) {
    const uncompletedTrainings = await fetchUncompletedTrainings(baseUrl, personLMSId);
    trainingsCount = uncompletedTrainings.length;
  }

  return trainingsCount;
};

export const determineOrganizationPrefix = shortName => {
  if (!isDefined(shortName)) {
    return null;
  }
  return shortName.substring(0, shortName.indexOf('_'));
};
