import React, { useState } from 'react';
import styled from 'styled-components';
import range from 'lodash/range';
import { Tab } from './Tab';
import { colors } from '../../../assets/styles/colors';
import { isDefined } from '../../../services/utils';

const Wrapper = styled.div`
  margin-top: 20px;
  &:last-child {
    margin-bottom: 24px;
  }
`;

const TabSelectionBar = styled.div`
  border-bottom: 1px solid ${colors.sectionSeparatorColor};
  display: flex;
`;

const TabTitle = styled.a`
  font-size: 18px;
  font-weight: 600;
  color: ${colors.grayDark};
  &:hover {
    text-decoration: none;
    color: ${colors.grayDark};
  }
  flex-basis: 0;
  flex-grow: 1;
  max-width: 285px;
  margin-bottom: -1px;
  ${props =>
    props.selected
      ? `
    background-color: white;
    border: 1px solid ${colors.sectionSeparatorColor};
    border-bottom: none;
  `
      : ``}}
`;

const TabTitleTopBorder = styled.div`
  height: 4px;
  min-width: 100%;
  margin: 0 -1px;
`;

const TabTitleContent = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem;
`;

const TabContainer = styled.div`
  background-color: white;
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: ${colors.sectionSeparatorColor};
  padding: 20px;
`;

const prepareTabs = children => {
  if (isDefined(children.length)) return children.filter(child => child.type === Tab);
  return children.type === Tab ? [children] : [];
};

export const TabsContainer = ({ children, useLocationHash = true }) => {
  const tabs = prepareTabs(children);
  const tabsIndices = range(0, tabs.length);
  const tabsNames = tabs.map(tab => tab.props.name);
  const tabsTitles = tabs.map(tab => tab.props.title);

  // eslint-disable-next-line no-restricted-globals
  const locationHash = isDefined(location.hash) ? location.hash.replace(/^#/i, '') : '';
  const initialTabIndex = useLocationHash
    ? tabsIndices.find(index => tabsNames[index] === locationHash) || 0
    : 0;
  const [activeTabIndex, setActiveTabIndex] = useState(initialTabIndex);

  return (
    <Wrapper>
      <TabSelectionBar>
        {tabsIndices.map(index => {
          const selected = index === activeTabIndex;
          return (
            <TabTitle
              key={tabsNames[index]}
              href={useLocationHash ? `#${tabsNames[index]}` : '#'}
              onClick={e => {
                if (!useLocationHash) e.preventDefault();
                setActiveTabIndex(index);
              }}
              selected={selected}
            >
              <TabTitleTopBorder className={selected ? 'primary-brand-bg' : ''} />
              <TabTitleContent>{tabsTitles[index]}</TabTitleContent>
            </TabTitle>
          );
        })}
      </TabSelectionBar>
      {tabs.map((tab, index) => (
        <TabContainer key={tabsNames[index]} hidden={index !== activeTabIndex}>
          {tab}
        </TabContainer>
      ))}
    </Wrapper>
  );
};
