import { withFormik } from 'formik';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { Form } from './Form';
import { API } from '../../../services/api';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`radiation_safety.rad_licenses.new_edit.${key}`);

const RadLicenseFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const radLicense = RadLicenseFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(radLicense.id)) {
      RadLicenseFunctions.create(radLicense, formikProps, values.afterSave);
    } else {
      RadLicenseFunctions.update(radLicense, formikProps, values.afterSave);
    }
  },
  create: async (radLicense, formikProps, afterSave) => {
    const { ok, data } = await API.radiationSafety.radLicenses.create({
      rad_license: radLicense,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/radiation_safety/rad_licenses/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.radiation_safety.rad_licenses.edit.title'));
      formikProps.resetForm({
        values: RadLicenseFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (radLicense, formikProps, afterSave) => {
    const { ok, data } = await API.radiationSafety.radLicenses.update(radLicense.id, {
      rad_license: radLicense,
    });
    if (ok) {
      formikProps.resetForm({
        values: RadLicenseFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: {
    afterSave: () => {},
  },
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'identifier',
    'license_number',
    'description',
    'institution',
    'address',
    'issue_date',
    'expiration_date',
    'active',
    'attachments',
    'links',
  ],
});

export const RadLicenseForm = withFormik({
  mapPropsToValues: props => RadLicenseFunctions.prepareInitialValues(props.radLicense),
  handleSubmit: RadLicenseFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
