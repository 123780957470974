import yup from '../../form/CustomYupValidator';

export const validationSchema = props => {
  const {
    buildingSelectorAttributes = { required: true },
    floorSelectorAttributes = {},
    spaceSelectorAttributes = {},
  } = props;
  const validationShape = {};
  if (buildingSelectorAttributes.required) {
    validationShape[[buildingSelectorAttributes.name || 'building_id']] = yup
      .string()
      .nullable()
      .required(I18n.t('validations.required'));
  }
  if (floorSelectorAttributes.required) {
    validationShape[[floorSelectorAttributes.name || 'buildings_floor_id']] = yup
      .string()
      .nullable()
      .required(I18n.t('validations.required'));
  }
  if (spaceSelectorAttributes.required) {
    validationShape[[spaceSelectorAttributes.name || 'lab_id']] = yup
      .string()
      .nullable()
      .required(I18n.t('validations.required'));
  }
  return yup.object().shape(validationShape);
};
