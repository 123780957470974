import React, { useState, useCallback } from 'react';
import { ModalSection } from './ModalSection';
import { ModalHint } from './ModalHint';
import { FormFooter, FormFooterRightPart } from '../form/FormFooter';
import { CancellationLink } from '../links/CancellationLink';
import { BrandButton } from '../buttons/BrandButton';
import { Loader } from '../Loader';

export const ConfirmationModalContent = props => {
  const { modalRef, onConfirm, hint, confirm } = props;
  const [isLoading, setIsLoading] = useState(false);

  const hide = useCallback(() => {
    modalRef.current.hide();
  }, [modalRef]);

  return (
    <ModalSection>
      <ModalHint>{hint}</ModalHint>
      <FormFooter>
        <FormFooterRightPart>
          <CancellationLink onClick={hide} />
          <BrandButton
            onClick={async () => {
              setIsLoading(true);
              await onConfirm();
              hide();
              setIsLoading(false);
            }}
          >
            {isLoading ? <Loader /> : confirm}
          </BrandButton>
        </FormFooterRightPart>
      </FormFooter>
    </ModalSection>
  );
};
