import { withFormik } from 'formik';
import { API } from '../../../../services/api';
import { BiologicalAgentForm } from './Form';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`biological_safeties.new_edit.modals.biological_agent.${key}`);

const BiologicalAgentFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const biologicalAgent = BiologicalAgentFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(biologicalAgent.id)) {
      BiologicalAgentFunctions.create(biologicalAgent, formikProps);
    } else {
      BiologicalAgentFunctions.update(biologicalAgent, formikProps);
    }
  },
  create: async (biologicalAgent, formikProps) => {
    const { ok, data } = await API.biologicalSafety
      .biologicalAgents(formikProps.props.ibcRegistrationId)
      .create({ biological_agent: biologicalAgent });
    if (ok) {
      formikProps.resetForm({
        values: BiologicalAgentFunctions.prepareInitialValues(data.data.attributes),
      });
      formikProps.props.hide();
      formikProps.props.tableRef.current.refresh();
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (biologicalAgent, formikProps) => {
    const { ok, data } = await API.biologicalSafety
      .biologicalAgents(formikProps.props.ibcRegistrationId)
      .update(biologicalAgent.id, { biological_agent: biologicalAgent });
    if (ok) {
      formikProps.resetForm({
        values: BiologicalAgentFunctions.prepareInitialValues(data.data.attributes),
      });
      formikProps.props.hide();
      formikProps.props.tableRef.current.refresh();
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: {},
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'biological_agent_type_id',
    'biological_bug_species_id',
    'biological_strain_id',
    'comments',
    'risk_group_id',
    'bsl_id',
    'absl_id',
    'replication_ability_id',
    'nih_applicability_id',
  ],
});

export const BiologicalAgentModalContent = withFormik({
  mapPropsToValues: props => BiologicalAgentFunctions.prepareInitialValues(props.biologicalAgent),
  handleSubmit: BiologicalAgentFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(BiologicalAgentForm);
