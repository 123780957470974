import yup from '../../../components/form/CustomYupValidator';
import { transformNaNValue } from '../../../../services/formHelper';

export const validationSchema = yup.object().shape({
  equip_type_name: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  ehs_inspect_freq: yup
    .number()
    .transform(transformNaNValue)
    .positive(I18n.t('validations.positive'))
    .required(I18n.t('validations.required')),
  reg_inspect_freq: yup
    .number()
    .transform(transformNaNValue)
    .positive(I18n.t('validations.positive')),
});
