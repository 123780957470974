import yup from '../../../components/form/CustomYupValidator';

const tooManySelectedItemsWarning = I18n.t(
  'hsds.hazard_assessments.new_edit.hints.too_many_selected_items',
);

export const validationSchema = yup.object().shape({
  primary_building_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  primary_lab_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  space_name: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  emergency_contact_profile_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  hsds_ppe_ids: yup.array().max(4, tooManySelectedItemsWarning),
  hsds_access_restriction_ids: yup.array().max(4, tooManySelectedItemsWarning),
});
