import React from 'react';
import { FastField, withFormik } from 'formik';
import styled from 'styled-components';
import _ from 'lodash';
import { ModalSection } from '../../../../components/modals/ModalSection';
import { FormFooter, FormFooterRightPart } from '../../../../components/form/FormFooter';
import { CancellationLink } from '../../../../components/links/CancellationLink';
import { BrandButton } from '../../../../components/buttons/BrandButton';
import {
  isDefined,
  defaultHandleSubmit,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  prepareFormFunctions,
} from '../../../../services/utils';
import { DefaultAttributesGroup } from '../../../../components/form/DefaultAttributesGroup';
import { fieldDisplayModes } from '../../../../constants/fieldDisplayModes';
import { defaultShouldUpdate, mapToSelectorOptions } from '../../../../services/fieldUtils';
import { API } from '../../../../services/api';
import { htmlOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../../components/fields/AsyncSelectorField';
import { SelectorField } from '../../../../components/fields/SelectorField';
import yup from '../../../../components/form/CustomYupValidator';
import { fontSize } from '../../../../assets/styles/typography';
import { colors } from '../../../../assets/styles/colors';

const t = (key, props = {}) =>
  I18n.t(`inventory.people.people.modals.group_memberships.${key}`, props);

const PersonFullNameSection = styled.div`
  font-size: ${fontSize.textMd};
  color: ${colors.inputTextColor};
  width: 100%;
  padding-bottom: 20px;
`;

const Form = props => {
  const {
    hide,
    isSubmitting,
    setFieldValue,
    handleSubmit,
    person: { full_name },
    currentUser: { org_admin },
    enums: { role },
  } = props;

  const groupMembershipId = _.get(props, 'groupMembership.id');
  const disabled = isDefined(groupMembershipId);
  return (
    <ModalSection>
      <PersonFullNameSection>
        <b>Person:</b>
        {` ${full_name}`}
      </PersonFullNameSection>
      <form>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
            labelI18nKeyPrefix: 'activerecord.attributes.group_membership',
            shouldUpdate: defaultShouldUpdate,
            resourceName: I18n.t('resource_names.group_membership'),
          }}
        >
          <FastField
            name="people_group_id"
            required
            disabled={disabled}
            api={
              org_admin
                ? API.selectors.peopleGroups.index
                : API.selectors.peopleGroups.indexOfManageable
            }
            selectionApi={API.selectors.peopleGroups.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField
            name="role"
            required
            options={mapToSelectorOptions(role, 'header', 'value')}
            component={SelectorField}
          />
        </DefaultAttributesGroup>
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={hide} />
            <BrandButton onClick={e => defaultHandleSubmit(setFieldValue, handleSubmit, e)}>
              {t('save')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </form>
    </ModalSection>
  );
};

const GroupMembershipFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const groupMembership = GroupMembershipFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(groupMembership.id)) {
      GroupMembershipFunctions.create(groupMembership, formikProps);
    } else {
      GroupMembershipFunctions.update(groupMembership, formikProps);
    }
  },
  create: async (values, formikProps) => {
    const { hide, listRef, personId } = formikProps.props;
    const { ok, data, status } = await API.people.people
      .group_memberships(personId)
      .create({ group_membership: values });
    if (ok) {
      formikProps.resetForm({
        values: GroupMembershipFunctions.prepareInitialValues(data.data.attributes),
      });
      hide();
      listRef.current.refresh();
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      if (status === 403) {
        showBackendErrorMessage(t('flash.error.not_authorized'), data);
      } else {
        showBackendErrorMessage(t('flash.error.update'), data);
      }
    }
  },
  update: async (values, formikProps) => {
    const { hide, listRef, personId } = formikProps.props;
    const { ok, data, status } = await API.people.people
      .group_memberships(personId)
      .update({ group_membership: values }, values.id);
    if (ok) {
      formikProps.resetForm({
        values: GroupMembershipFunctions.prepareInitialValues(data.data.attributes),
      });
      hide();
      listRef.current.refresh();
      showSuccessMessage(t('flash.success.update'));
    } else {
      formikProps.setSubmitting(false);
      if (status === 403) {
        showBackendErrorMessage(t('flash.error.not_authorized'), data);
      } else {
        showBackendErrorMessage(t('flash.error.update'), data);
      }
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: {},
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: ['id', 'person_id', 'group_membership_id', 'role', 'people_group_id'],
});

export const GroupMembershipModalContent = withFormik({
  mapPropsToValues: props => GroupMembershipFunctions.prepareInitialValues(props.groupMembership),
  handleSubmit: GroupMembershipFunctions.handleSubmit,
  validate: produceDefaultValidation(
    yup.object().shape({
      people_group_id: yup.string().required(I18n.t('validations.required')),
      role: yup.string().required(I18n.t('validations.required')),
    }),
  ),
})(Form);
