/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { reduce } from 'lodash';
import { Loader } from '../Loader';

const t = (key, plural = false) => `${I18n.t(`activerecord.models.${key}`)}${plural ? 's' : ''}`;

const RelatedItemsDropdown = ({ mainObject: { resource_name, id }, api, shouldRerender }) => {
  const [fetching, setFetching] = useState(true);
  const [relatedItems, setRelatedItems] = useState({});
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    (async () => {
      const { ok, data } = await api(id);
      if (ok) {
        setRelatedItems(data);
        setVisible(data.map(item => item.enabled).includes(true));
        setFetching(false);
      }
    })();
  }, [shouldRerender]);

  const newPath = (path, parameter) => `${path}/new?${resource_name}_id=${id}${parameter || ''}`;
  const indexPath = (path, parameter) =>
    `${path}?filterrific[with_${resource_name}_id][]=${id}${parameter || ''}`;

  const totalRelatedItems = () => reduce(relatedItems, (sum, { count }) => sum + count, 0);

  const renderBadge = count => (
    <span className={`badge badge-pill ${count === 0 ? 'badge-secondary' : 'badge-danger'} ml-1`}>
      {count}
    </span>
  );

  const Link = ({ enabled, hidden, path, children, badgeCount }) => {
    if (enabled)
      return (
        <a
          href={path}
          target="_blank"
          className="dropdown-item d-flex justify-content-between align-items-center"
          rel="noreferrer"
        >
          {children}
          {badgeCount !== undefined && renderBadge(badgeCount, enabled)}
        </a>
      );
    if (hidden) return <></>;

    return (
      <span className="disabled dropdown-item d-flex justify-content-between align-items-center">
        {children}
      </span>
    );
  };

  const renderRelatedItems = () =>
    relatedItems.map(({ count, resource, path, enabled, hidden }) => (
      <Link enabled={enabled} hidden={hidden} path={indexPath(path, '')} badgeCount={count}>
        {t(resource, true)}
      </Link>
    ));

  const renderNewRelatedItems = () =>
    relatedItems.map(({ resource, path, enabled, hidden }) => (
      <Link enabled={enabled} hidden={hidden} path={newPath(path, '')}>
        {t(resource)}
      </Link>
    ));

  return fetching ? (
    <Loader />
  ) : (
    visible && (
      <>
        <div className="dropdown">
          <a
            className="btn btn-light btn-sm btn-extended dropdown-toggle d-flex justify-content-between align-items-center"
            aria-expanded="false"
            aria-haspopup="true"
            data-toggle="dropdown"
            href="#"
            role="button"
          >
            {I18n.t('form.header.view_investigable_related')}
            {renderBadge(totalRelatedItems())}
          </a>
          <div className="dropdown-menu" aria-labelledby="dropdownMenu">
            {renderRelatedItems()}
          </div>
        </div>
        <div className="dropdown ml-3">
          <a
            className="btn btn-light btn-sm btn-extended dropdown-toggle"
            aria-expanded="false"
            aria-haspopup="true"
            data-toggle="dropdown"
            href="#"
            role="button"
          >
            {I18n.t('form.header.add_investigable_related')}
          </a>
          <div className="dropdown-menu" aria-labelledby="dropdownMenu">
            {renderNewRelatedItems()}
          </div>
        </div>
      </>
    )
  );
};

export default RelatedItemsDropdown;
