import React from 'react';
import styled from 'styled-components';
import { FormSectionTitle } from './FormSectionTitle';
import { isDefined } from '../../services/utils';

export const Container = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
`;

export const TitlePlaceholder = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100px;
`;

export const FormSectionHeader = ({ children }) => {
  let titleGiven = false;
  if (isDefined(children.length)) {
    titleGiven = children.some(ch => ch && ch.type === FormSectionTitle);
  } else {
    titleGiven = children.type === FormSectionTitle;
  }
  return (
    <Container>
      {!titleGiven && <TitlePlaceholder />}
      {children}
    </Container>
  );
};
