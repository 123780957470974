import { useEffect } from 'react';
import { some } from 'lodash';
import { applyHalfLife } from '../utils';

export const useRemainingActivity = props => {
  const {
    setFieldValue,
    errors,
    values: { half_life_days, remainingActivityBase, remainingActivityDecayStartAt },
  } = props;

  const errorKeys = Object.keys(errors);

  useEffect(() => {
    setFieldValue(
      'remainingActivity',
      some(['started_at', 'half_life_days', 'initial_activity'], key => errorKeys.includes(key))
        ? ''
        : applyHalfLife(remainingActivityBase, half_life_days, remainingActivityDecayStartAt),
    );
  }, [
    remainingActivityBase,
    half_life_days,
    remainingActivityDecayStartAt,
    JSON.stringify(errorKeys),
  ]);
};
