import React from 'react';
import { FastField } from 'formik';
import { FormHeader } from '../../../components/form/FormHeader';
import { isDefined } from '../../../services/utils';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate, produceShouldUpdate } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { Footer } from './Footer';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { IbcRegistrations } from './IbcRegistrations';
import { DefaultLink } from '../../../components/links/DefaultLink';

const t = key => I18n.t(`biological_strains.new_edit.${key}`);

export const Form = props => {
  const {
    setFieldValue,
    values: { id, biological_agent_type_id, biological_bug_species_id },
  } = props;

  return (
    <form>
      <FormHeader title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)} />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.biological_strain',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.biological_strain'),
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField disabled name="identifier" component={TextField} />
          <FastField
            name="biological_agent_type_id"
            required
            disabled={id}
            api={API.selectors.biologicalAgentTypes.index}
            selectionApi={API.selectors.biologicalAgentTypes.show}
            optionRenderer={htmlOptionRenderer('text')}
            onChange={async (event, onChange) => {
              await onChange(event);
              await setFieldValue('biological_bug_species_id', '');
            }}
            component={AsyncSelectorField}
            additionalContent={
              <DefaultLink
                hidden={!(id && biological_agent_type_id)}
                href={`/administration/biological_agent_types/${biological_agent_type_id}/edit`}
              >
                {t('links.agent_type_link')}
              </DefaultLink>
            }
          />
          <FastField
            name="biological_bug_species_id"
            required
            disabled={id}
            api={API.selectors.biologicalBugSpecies.index}
            apiParams={{ with_biological_agent_type_id: biological_agent_type_id }}
            selectionApi={API.selectors.biologicalBugSpecies.show}
            optionRenderer={htmlOptionRenderer('text')}
            onChange={async (event, onChange) => {
              await onChange(event);
              await setFieldValue('biological_agent_type_id', event.data.biological_agent_type_id);
            }}
            shouldUpdate={produceShouldUpdate([], [], ['biological_agent_type_id'])}
            component={AsyncSelectorField}
            additionalContent={
              <DefaultLink
                hidden={!(id && biological_bug_species_id)}
                href={`/administration/biological_bug_species/${biological_bug_species_id}/edit`}
              >
                {t('links.bug_species_link')}
              </DefaultLink>
            }
          />
          <FastField name="name" required component={TextField} />
          <FastField
            name="bsl_id"
            api={API.selectors.bsls.index}
            selectionApi={API.selectors.bsls.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField
            name="absl_id"
            api={API.selectors.absls.index}
            selectionApi={API.selectors.absls.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField
            name="risk_group_id"
            required
            api={API.selectors.riskGroups.index}
            selectionApi={API.selectors.riskGroups.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
        </FormSection>
      </DefaultAttributesGroup>
      <Footer {...props} />
      <IbcRegistrations {...props} />
    </form>
  );
};
