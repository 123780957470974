import React from 'react';
import styled from 'styled-components';
import { passDefaultAttributesToChildren } from '../../services/utils';
import { FormSectionHeader } from './FormSectionHeader';
import { FormSectionTitle } from './FormSectionTitle';
import { getFormikErrors } from '../../../services/formHelper';
import { Paper } from './Paper';
import InputLabelHint from '../fields/auxiliary/InputLabelHint';

const HintWrapper = styled.span`
  margin-left: 4px;
`;

export const FormSection = ({
  title,
  children,
  required,
  name,
  errors,
  touched,
  hint,
  defaultChildrenAttributes = false,
}) => (
  <Paper>
    {title && (
      <FormSectionHeader>
        <FormSectionTitle required={required} error={getFormikErrors(name, touched, errors)}>
          {title}
          {hint && (
            <HintWrapper>
              <InputLabelHint hint={hint} />
            </HintWrapper>
          )}
        </FormSectionTitle>
      </FormSectionHeader>
    )}
    {defaultChildrenAttributes
      ? passDefaultAttributesToChildren(children, defaultChildrenAttributes)
      : children}
  </Paper>
);
