import React from 'react';
import { FastField } from 'formik';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';

const t = key => I18n.t(`administration.waste_generators.new_edit.${key}`);

export const Overview = () => {
  return (
    <DefaultAttributesGroup
      defaultChildrenAttributes={{
        labelI18nKeyPrefix: 'activerecord.attributes.waste_generator',
        shouldUpdate: defaultShouldUpdate,
        resourceName: I18n.t('resource_names.waste_generator'),
      }}
    >
      <FormSection title={t('sections.overview')}>
        <FastField name="generator_full_name" required component={TextField} />
        <FastField name="generator_nickname" required component={TextField} />
        <FastField name="epa_id" component={TextField} />
        <FastField name="street_address" component={TextField} />
        <FastField name="city" component={TextField} />
        <FastField name="state" component={TextField} />
        <FastField name="zip" component={TextField} />
        <FastField name="generator_status" component={TextField} />
      </FormSection>
    </DefaultAttributesGroup>
  );
};
