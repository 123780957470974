import yup from '../../../components/form/CustomYupValidator';
import { getUtcMomentIgnoringTime } from '../../../services/dateFormatUtils';
import { compareTimes } from '../../../services/utils';

const t = (key, attributes = {}) => I18n.t(`activerecord.errors.models.${key}`, attributes);

export const validationSchema = yup.object().shape({
  responsible_person_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  safe_work_authorization_measure_type_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  start_date: yup
    .string()
    .typeError(I18n.t('validations.required'))
    .required(I18n.t('validations.required'))
    .test(
      'start_date_cannot_be_later_than_end_date',
      t('safe_work_authorization_measure.attributes.start_date.cannot_be_later_than', {
        date: I18n.t('activerecord.attributes.safe_work_authorization_measure.end_date'),
      }),
      function _(start_date) {
        return getUtcMomentIgnoringTime(start_date).isSameOrBefore(
          getUtcMomentIgnoringTime(this.parent.end_date),
        );
      },
    )
    .test(
      'cannot_be_more_than_3_months_earlier_than_end_date',
      t(
        'safe_work_authorization_measure.attributes.start_date.cannot_be_more_than_3_months_earlier_than_end_date',
      ),
      function _(start_date) {
        return getUtcMomentIgnoringTime(start_date)
          .add(3, 'months')
          .isSameOrAfter(getUtcMomentIgnoringTime(this.parent.end_date));
      },
    ),
  end_date: yup
    .string()
    .typeError(I18n.t('validations.required'))
    .required(I18n.t('validations.required')),
  start_time: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required'))
    .test(
      'start_time_cannot_be_later_than_end_time',
      t('safe_work_authorization_measure.attributes.start_time.cannot_be_later_than', {
        date: I18n.t('activerecord.attributes.safe_work_authorization_measure.end_time'),
      }),
      function _(start_time) {
        return compareTimes(start_time, this.parent.end_time);
      },
    ),
  end_time: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  swa_measure_date_time_entries_attributes: yup.array(
    yup.object().shape({
      checked: yup
        .boolean()
        .nullable()
        .required(I18n.t('validations.required')),
      start_time: yup
        .string()
        .nullable()
        .required(I18n.t('validations.required'))
        // an arrow function cannot be used beneath because 'this' does not work with it
        // see https://github.com/jquense/yup#mixedtestname-string-message-string--function-test-function-schema
        .test(
          'start_time_cannot_be_later_than_end_time',
          t('swa_measure_date_time_entry.attributes.start_time.cannot_be_later_than', {
            date: I18n.t('activerecord.attributes.swa_measure_date_time_entry.end_time'),
          }),
          function _(start_time) {
            // eslint-disable-next-line no-underscore-dangle
            return this.parent.checked && !this.parent._destroy
              ? compareTimes(start_time, this.parent.end_time)
              : true;
          },
        ),
      end_time: yup
        .string()
        .nullable()
        .required(I18n.t('validations.required')),
    }),
  ),
});
