import yup from '../../../../components/form/CustomYupValidator';

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  emergencyContacts: yup.array().of(
    yup.object().shape({
      contact_category: yup
        .string()
        .nullable()
        .required(I18n.t('validations.required')),
      contact_name: yup
        .string()
        .nullable()
        .required(I18n.t('validations.required')),
      contact_phone: yup
        .string()
        .nullable()
        .required(I18n.t('validations.required')),
    }),
  ),
});
