import { withFormik } from 'formik';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { Form } from './Form';
import { API } from '../../../services/api';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`administration.waste_cafs.new_edit.${key}`);

const wasteCafFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const wasteCaf = wasteCafFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(wasteCaf.id)) {
      wasteCafFunctions.create(wasteCaf, formikProps, values.afterSave);
    } else {
      wasteCafFunctions.update(wasteCaf, formikProps, values.afterSave);
    }
  },
  create: async (wasteCaf, formikProps, afterSave) => {
    const { ok, data } = await API.administration.wasteCafs.create({
      waste_caf: wasteCaf,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/administration/waste_cafs/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.administration.waste_cafs.edit.title'));
      formikProps.resetForm({
        values: wasteCafFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (wasteCaf, formikProps, afterSave) => {
    const { ok, data } = await API.administration.wasteCafs.update(wasteCaf.id, {
      waste_caf: wasteCaf,
    });
    if (ok) {
      formikProps.resetForm({
        values: wasteCafFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: {
    afterSave: () => {},
  },
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: ['id', 'caf_name', 'waste_generator_id', 'active', 'order_priority'],
});

export const WasteCafForm = withFormik({
  mapPropsToValues: props => wasteCafFunctions.prepareInitialValues(props.wasteCaf),
  handleSubmit: wasteCafFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
