import React, { useEffect, useState } from 'react';
import { isDefined } from '../../services/utils';
import { API } from '../../services/api';
import { Loader } from '../../components/Loader';
import { PermitTypeForm } from './Form';

export const PermitType = props => {
  const { id } = props;

  const [fetching, setFetching] = useState(true);
  const [permitType, setPermitType] = useState({});
  useEffect(() => {
    (async () => {
      const api = isDefined(id)
        ? API.administration.permitTypes.show
        : API.administration.permitTypes.new;
      const { ok, data } = await api(id);
      if (ok) {
        await setPermitType(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <PermitTypeForm {...props} permitType={permitType} />;
};
export default PermitType;
