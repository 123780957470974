import React from 'react';
import { FastField } from 'formik';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { API } from '../../../services/api';
import { defaultOptionRenderer } from '../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { FilesField } from '../../../components/fields/FilesField';
import { LinksField } from '../../../components/fields/LinksField';
import { TextAreaField } from '../../../components/fields/TextAreaField';

const t = key => I18n.t(`safe_work_authorization_measures.${key}`);

export const Overview = ({ defaultChildrenAttributes, setFieldValue }) => (
  <FormSection title={t('sections.overview')} defaultChildrenAttributes={defaultChildrenAttributes}>
    <FastField name="identifier" disabled component={TextField} />
    <FastField
      name="safe_work_authorization_measure_type_id"
      api={API.selectors.swaMeasureTypes.index}
      selectionApi={API.selectors.swaMeasureTypes.show}
      optionRenderer={defaultOptionRenderer('text')}
      component={AsyncSelectorField}
      required
      onChange={async event => {
        await setFieldValue('comments', event.data.comments);
        await setFieldValue('safe_work_authorization_measure_type_id', event.value);
      }}
    />
    <FastField
      name="responsible_person_id"
      required
      api={API.selectors.people.index}
      selectionApi={API.selectors.people.show}
      optionRenderer={htmlOptionRenderer('text')}
      headerRenderer={defaultOptionRenderer('full_name')}
      component={AsyncSelectorField}
    />
    <FastField name="attachments" component={FilesField} />
    <FastField name="links" component={LinksField} />
    <FastField name="comments" component={TextAreaField} />
  </FormSection>
);
