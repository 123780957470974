import React from 'react';
import { FormSection } from '../../../components/form/FormSection';
import { handleSubmitWithAfterSave, isDefined } from '../../../services/utils';
import { API } from '../../../services/api';
import { IndexTableDefaultHandler } from '../../../components/tables/IndexTableDefaultHandler';
import { DefaultLink } from '../../../components/links/DefaultLink';

const t = key => I18n.t(`biological_agent_types.new_edit.${key}`);

export const Species = props => {
  const {
    setFieldValue,
    handleSubmit,
    isSubmitting,
    values: { id },
  } = props;

  return (
    <>
      <FormSection>
        <IndexTableDefaultHandler
          title={t('lists.species.title')}
          api={API.administration.species.index}
          fixedFilters={{ with_biological_agent_type_id: id }}
          idToHrefFunction={_id => `/administration/biological_bug_species/${_id}/edit`}
          manageColumnsModel="BiologicalBugSpecies"
          forceEmpty={!isDefined(id)}
          additionalLinks={[
            <DefaultLink
              disabled={isSubmitting}
              onClick={async () => {
                const redirect = agentTypeId => {
                  setTimeout(
                    (window.location.href = `/administration/biological_bug_species/new?initial_biological_agent_type_id=${agentTypeId}`),
                    1000,
                  );
                };
                const afterSave = agentTypeId => redirect(agentTypeId);
                await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
              }}
            >
              {t('lists.species.add')}
            </DefaultLink>,
          ]}
        />
      </FormSection>
    </>
  );
};
