import { withFormik } from 'formik';
import { API } from '../../../../services/api';
import { RadLicenseItemForm } from './Form';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { validationSchema } from './valdiationSchema';

const t = key => I18n.t(`radiation_safety.rad_permits.lists.rad_license_item_assignments.${key}`);

const RadLicenseItemFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const radLicenseItem = RadLicenseItemFunctions.prepareValuesToSubmit({
      ...values,
    });
    if (!isDefined(radLicenseItem.id)) {
      RadLicenseItemFunctions.create(radLicenseItem, formikProps);
    } else {
      RadLicenseItemFunctions.update(radLicenseItem, formikProps);
    }
  },
  create: async (radLicenseItem, formikProps) => {
    const { ok, data } = await API.radiationSafety
      .radLicenseItems(formikProps.props.radLicenseId)
      .create({ rad_license_item: radLicenseItem });
    if (ok) {
      formikProps.resetForm({
        values: RadLicenseItemFunctions.prepareInitialValues(data.data.attributes),
      });
      formikProps.props.hide();
      formikProps.props.listRef.current.refresh();
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (radLicenseItem, formikProps) => {
    const { ok, data } = await API.radiationSafety
      .radLicenseItems(formikProps.props.radLicenseId)
      .update(radLicenseItem.id, {
        rad_license_item: radLicenseItem,
      });
    if (ok) {
      formikProps.resetForm({
        values: RadLicenseItemFunctions.prepareInitialValues(data.data.attributes),
      });
      formikProps.props.hide();
      formikProps.props.listRef.current.refresh();
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: () => ({}),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'identifier',
    'id',
    'rad_isotope_id',
    'rad_item_type_id',
    'license_condition_ref_number',
    'max_activity',
    'activity_unit_id',
    'max_quantity',
    'quantity_unit_id',
    'rad_form_id',
    'use',
    'active',
  ],
});

export const AddRadLicenseItemModalContent = withFormik({
  mapPropsToValues: props => RadLicenseItemFunctions.prepareInitialValues(props.radLicenseItem),
  handleSubmit: RadLicenseItemFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(RadLicenseItemForm);
