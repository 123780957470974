import React, { useRef } from 'react';
import { FormSection } from '../../form/FormSection';
import { FormSectionTitle } from '../../form/FormSectionTitle';
import { FormSectionHeader } from '../../form/FormSectionHeader';
import { FormSectionHeaderLink } from '../../form/FormSectionHeaderLinks';
import { showBackendErrorMessage, showSuccessMessage } from '../../../services/utils';
import { EmptyListPlaceholder } from '../../lists/EmptyListPlaceholder';
import { AsyncSimpleList } from '../../lists/AsyncSimpleList';
import { SimpleListDeletionLink } from '../../lists/SimpleListLinks';
import { AddRelatedEquipmentModalContent } from './AddRelatedEquipmentModalContent';
import { Modal } from '../../modals/Modal';

const t = key => I18n.t(`related_equipment_section.${key}`);

export const RelatedEquipment = props => {
  const {
    empty,
    api,
    resourceName,
    humanizedResourceName = t('resource_name'),
    disabled = false,
    handleAdditionLinkOnClick = open => open(),
  } = props;

  const listRef = useRef(null);
  const addEquipmentModalRef = useRef(null);
  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{t('title')}</FormSectionTitle>
          <FormSectionHeaderLink
            hidden={disabled}
            onClick={async () => {
              handleAdditionLinkOnClick(addEquipmentModalRef.current.open);
            }}
          >
            {t('add')}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        {empty ? (
          <EmptyListPlaceholder />
        ) : (
          <AsyncSimpleList
            ref={listRef}
            api={api.index}
            dataKey="data"
            resourceName={resourceName}
            mapRowHrefs={({ attributes: { equipmentable } }) => equipmentable.path}
            mapData={({ attributes: { equipmentable }, id: equipmentId }) => [
              [t('list_fields.type'), equipmentable.subtype],
              [t('list_fields.manufacturer'), equipmentable.manufacturer],
              [t('list_fields.model'), equipmentable.model],
              [t('list_fields.serial_no'), equipmentable.serial_no],
              [t('list_fields.building'), equipmentable.building],
              [t('list_fields.room_no'), equipmentable.room_no],
              <SimpleListDeletionLink
                key="remove"
                hidden={disabled}
                modelName={humanizedResourceName}
                onSubmit={async () => {
                  const { ok, data } = await api.destroy(equipmentId);
                  if (ok) {
                    listRef.current.refresh();
                    showSuccessMessage(t('flash.success.remove'));
                  } else {
                    showBackendErrorMessage(t('flash.error.remove'), data);
                  }
                }}
              >
                {t('remove')}
              </SimpleListDeletionLink>,
            ]}
          />
        )}
      </FormSection>
      <Modal title={t('add_modal.title')} ref={addEquipmentModalRef}>
        {modalProps => (
          <AddRelatedEquipmentModalContent listRef={listRef} {...modalProps} {...props} />
        )}
      </Modal>
    </>
  );
};
