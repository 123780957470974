import { withFormik } from 'formik';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { validationSchema } from './validationSchema';
import { Form } from './Form';

const t = key => I18n.t(`permit_types.new_edit.${key}`);

const PermitTypeFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const permitType = PermitTypeFunctions.prepareValuesToSubmit({ ...values });
    if (isDefined(permitType.id)) {
      PermitTypeFunctions.update(permitType, formikProps, values);
    } else {
      PermitTypeFunctions.create(permitType, formikProps, values);
    }
  },
  create: async (permitType, formikProps, { afterSave, maxPositionValue }) => {
    const { ok, data } = await API.administration.permitTypes.create({
      permit_type: permitType,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/administration/permit_types/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.administration.permit_types.edit.title'));
      formikProps.resetForm({
        values: PermitTypeFunctions.prepareInitialValues({
          maxPositionValue,
          ...data.data.attributes,
        }),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (permitType, formikProps, { afterSave, maxPositionValue }) => {
    const { ok, data } = await API.administration.permitTypes.update(permitType.id, {
      permit_type: permitType,
    });
    if (ok) {
      formikProps.resetForm({
        values: PermitTypeFunctions.prepareInitialValues({
          maxPositionValue,
          ...data.data.attributes,
        }),
      });
      afterSave(data.data.id);
      showSuccessMessage(t(`flash.success.save`));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: option => ({
    afterSave: () => {},
    maxPositionValue: option.maxPositionValue,
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: ['id', 'name', 'is_default', 'position'],
});

export const PermitTypeForm = withFormik({
  validate: produceDefaultValidation(validationSchema),
  mapPropsToValues: props =>
    PermitTypeFunctions.prepareInitialValues({
      maxPositionValue: props.maxPositionValue,
      ...props.permitType,
    }),
  handleSubmit: PermitTypeFunctions.handleSubmit,
})(Form);
