import React from 'react';
import styled from 'styled-components';
import { FormSection } from './FormSection';
import { DefaultLink } from '../links/DefaultLink';

const Title = styled.span`
  padding-right: 5px;
  font-weight: bold;
`;

export const RelatedEntityPanel = ({ title, identifier, viewPrompt, link }) => (
  <FormSection>
    <div className="d-flex flex-wrap">
      <Title>{title}</Title>
      <DefaultLink href={link}>{`#${identifier}`}</DefaultLink>
      <div className="d-flex flex-grow-1 justify-content-end">
        <DefaultLink href={link}>{viewPrompt}</DefaultLink>
      </div>
    </div>
  </FormSection>
);
