import React, { useEffect, useState } from 'react';
import { API } from '../../services/api';
import { isDefined } from '../../services/utils';
import { Loader } from '../../components/Loader';
import { WasteRequestForm } from './Form';

export const WasteRequest = props => {
  const { id } = props;

  const [fetching, setFetching] = useState(true);
  const [wasteRequest, setWasteRequest] = useState({});
  useEffect(() => {
    (async () => {
      const api = isDefined(id) ? API.requests.wasteRequests.show : API.requests.wasteRequests.new;
      const { ok, data } = await api(id);
      if (ok) {
        await setWasteRequest(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <WasteRequestForm {...props} wasteRequest={wasteRequest} />;
};
export default WasteRequest;
