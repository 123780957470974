import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding-right: 10px;
`;

export const FooterAdditionalInformation = props => {
  const { children } = props;
  return (
    <Wrapper>
      <i className="fas fa-check-circle" />
      <span>{` ${children}`}</span>
    </Wrapper>
  );
};
