import React, { useMemo } from 'react';
import { FastField } from 'formik';
import { ModalSection } from '../../../modals/ModalSection';
import { FormFooter, FormFooterRightPart } from '../../../form/FormFooter';
import { CancellationLink } from '../../../links/CancellationLink';
import { BrandButton } from '../../../buttons/BrandButton';
import { defaultHandleSubmit } from '../../../../services/utils';
import { DefaultAttributesGroup } from '../../../form/DefaultAttributesGroup';
import { fieldDisplayModes } from '../../../../constants/fieldDisplayModes';
import { defaultShouldUpdate, mapToSelectorOptions } from '../../../../services/fieldUtils';
import { SelectorField } from '../../../fields/SelectorField';
import { TextField } from '../../../fields/TextField';
import { TextAreaField } from '../../../fields/TextAreaField';
import { getSelectorVariantsOptions } from './getSelectorVariantsOptions';
import { NumericField } from '../../../fields/NumericField';

const t = (key, params) => I18n.t(`field_definitions_section.${key}`, params);

export const Form = props => {
  const {
    hide,
    isSubmitting,
    setFieldValue,
    handleSubmit,
    values: { variant, max_position_value },
  } = props;

  const selectorVariantsOptions = useMemo(getSelectorVariantsOptions, []);

  return (
    <ModalSection>
      <form>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
            labelI18nKeyPrefix: 'field_definitions_section.fields',
            shouldUpdate: defaultShouldUpdate,
            resourceName: I18n.t('resource_names.field_definition'),
          }}
        >
          <FastField
            required
            name="variant"
            options={mapToSelectorOptions(selectorVariantsOptions, 'header', 'value')}
            component={SelectorField}
          />
          <FastField
            required
            name="name"
            component={TextField}
            placeholder={t('placeholders.name')}
          />
          <FastField
            name="position"
            required
            roundPrecision={0}
            component={NumericField}
            warning={t('warnings.position', { max_position_value })}
          />
          <FastField
            name="placeholder"
            hidden={variant === 'boolean'}
            component={TextField}
            placeholder={t('placeholders.placeholder')}
          />
          <FastField
            required
            hidden={variant !== 'selector'}
            name="selector_values"
            component={TextAreaField}
            placeholder={t('placeholders.selector_values')}
          />
          <FastField name="tooltip" component={TextField} placeholder={t('placeholders.tooltip')} />
        </DefaultAttributesGroup>
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={hide} />
            <BrandButton onClick={e => defaultHandleSubmit(setFieldValue, handleSubmit, e)}>
              {t('modal.save')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </form>
    </ModalSection>
  );
};
