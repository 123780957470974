import React from 'react';
import { FastField } from 'formik';
import { DateField } from '../../../components/fields/DateField';
import { TimeField } from '../../../components/fields/TimeField';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';

const t = key => I18n.t(`activerecord.attributes.safe_work_authorization_measure.${key}`);

export const DateAndTimeFromSwa = ({ hidden }) => {
  if (hidden) return null;
  return (
    <DefaultAttributesGroup
      defaultChildrenAttributes={{
        resourceName: I18n.t('resource_names.safe_work_authorization_measure'),
      }}
    >
      <FastField
        name="swaStartDate"
        label={t('start_date')}
        required
        disabled
        component={DateField}
      />
      <FastField name="swaEndDate" label={t('end_date')} required disabled component={DateField} />
      <FastField
        name="swaStartTime"
        label={t('start_time')}
        required
        disabled
        component={TimeField}
      />
      <FastField name="swaEndTime" label={t('end_time')} required disabled component={TimeField} />
      <FastField name="swaWeekdays" label={t('weekdays')} disabled component={CheckBoxField} />
      <FastField name="swaSaturdays" label={t('saturdays')} disabled component={CheckBoxField} />
      <FastField name="swaSundays" label={t('sundays')} disabled component={CheckBoxField} />
      <FastField
        name="swaCustomDatesAndTimes"
        label={t('custom_dates_and_times')}
        disabled
        component={CheckBoxField}
      />
    </DefaultAttributesGroup>
  );
};
