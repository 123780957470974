import React from 'react';
import styled from 'styled-components';
import { FieldWrapper } from './auxiliary/FieldWrapper';
import { fontSize } from '../../assets/styles/typography';
import { colors } from '../../assets/styles/colors';
import { fieldPropDefaultTransformation, fieldWrapperProps } from '../../services/fieldUtils';
import { isDefined, saluteRound } from '../../services/utils';

const Input = styled.input`
  font-size: ${fontSize.textMd};
  color: ${colors.inputTextColor};
  width: 100%;
  padding: 0 8px;
  height: 36px;
  background: ${props => (props.disabled ? colors.disabled : colors.white)};
  background-clip: padding-box;
  border: 1px solid ${colors.inputBorderColor};
  border-radius: 4px;
  &:focus {
    border-color: ${colors.outline};
    border-width: 1;
    box-shadow: 0.005em 0.005em 0.005em ${colors.outline} inset,
      0.005em 0.005em 0.005em ${colors.outline};
    outline: 0;
  }
`;

export const NumericField = props => {
  const {
    field: { name, value, onBlur: fieldOnBlur },
    form: { setFieldValue },
    disabled,
    placeholder,
    resourceName,
    positiveOnly,
    id,
    onBlurRounding = true,
    onBlur: _onBlur = fieldOnBlur,
    inputRef,
    roundPrecision = window.SALUTE_DEFAULT_FLOAT_SCALE,
  } = props;
  const inputId = id || `${resourceName}-${name}`;
  const onBlur = e => {
    _onBlur(e);
    if (!onBlurRounding) return;
    // don't attempt to round numbers with scientific notation
    if (e.target.value.includes('e')) return;

    const roundedValue = saluteRound(value, roundPrecision);
    if (isDefined(roundedValue) && roundedValue !== value) {
      setFieldValue(name, roundedValue);
    }
  };

  return (
    <FieldWrapper {...fieldWrapperProps({ inputId, ...props })}>
      <Input
        {...fieldPropDefaultTransformation({ ...props, onBlur })}
        ref={inputRef}
        id={inputId}
        type="number"
        min={positiveOnly ? '0' : null}
        disabled={disabled}
        placeholder={placeholder}
      />
    </FieldWrapper>
  );
};
