import { TextField } from '../TextField';
import { DateField } from '../DateField';
import { DateTimeField } from '../DateTimeField';
import { TimeField } from '../TimeField';
import { NumericField } from '../NumericField';
import { SelectorField } from '../SelectorField';
import { TextAreaField } from '../TextAreaField';
import { CheckBoxField } from '../CheckBoxField';
import { JsonField } from '../JsonField';

export const renderCustomInput = variant => {
  switch (variant) {
    case 'text':
      return TextField;
    case 'date':
      return DateField;
    case 'date_and_time':
      return DateTimeField;
    case 'time':
      return TimeField;
    case 'numerical':
      return NumericField;
    case 'selector':
      return SelectorField;
    case 'text_area':
      return TextAreaField;
    case 'json':
      return JsonField;
    case 'boolean':
      return CheckBoxField;
    default:
      return null;
  }
};
