import React from 'react';
import styled from 'styled-components';
import { FormSection } from '../../../../components/form/FormSection';
import { FormSectionHeader } from '../../../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../../../components/form/FormSectionTitle';
import { isDefined } from '../../../../services/utils';
import { RelatedItem } from './RelatedItem';
import { EmptyListPlaceholder } from '../../../../components/lists/EmptyListPlaceholder';
import { breakpoints } from '../../../../assets/styles/grid';

const t = key => I18n.t(`inventory.people.people.${key}`);

const Grid = styled.div`
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: 1fr;
  @media only screen and (min-width: ${breakpoints.small}) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (min-width: ${breakpoints.medium}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media only screen and (min-width: ${breakpoints.large}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media only screen and (min-width: ${breakpoints.extraLarge}) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`;

export const RelatedItems = props => {
  const {
    values: { id, relatedItems },
  } = props;

  const empty = !relatedItems || relatedItems.length === 0;

  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{t('lists.related_items.title')}</FormSectionTitle>
        </FormSectionHeader>
        {empty && <EmptyListPlaceholder />}
        {!empty && isDefined(id) && (
          <Grid>
            {relatedItems &&
              Object.values(relatedItems).map(item => <RelatedItem key={item.label} item={item} />)}
          </Grid>
        )}
      </FormSection>
    </>
  );
};
