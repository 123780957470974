import React from 'react';
import styled from 'styled-components';
import { DefaultLink } from '../links/DefaultLink';
import { DeletionLink } from '../links/DeletionLink';

const Wrapper = styled.span`
  &:not(:last-child) {
    padding-right: 10px;
  }
`;

const StyledIcon = styled.span`
  margin-top: -2px;
`;

const exportLinkStyle = { display: 'flex', textDecoration: 'none', color: '#354052' };

export const FormSectionHeaderLink = ({ children, hidden, type, ...props }) => {
  // Used switch-case for more expandable approach, eg. easy to add link with PDF icon
  switch (type) {
    case 'excel_export':
      return (
        <Wrapper hidden={hidden}>
          <DefaultLink style={exportLinkStyle} {...props}>
            {children}
            &nbsp;
            <StyledIcon className="icon-excel report-icon" />
          </DefaultLink>
        </Wrapper>
      );
    default:
      return (
        <Wrapper hidden={hidden}>
          <DefaultLink {...props}>{children}</DefaultLink>
        </Wrapper>
      );
  }
};

export const FormSectionHeaderDeletionLink = ({ children, hidden, ...props }) => (
  <Wrapper hidden={hidden}>
    <DeletionLink {...props}>{children}</DeletionLink>
  </Wrapper>
);
