import React, { useRef } from 'react';
import get from 'lodash/get';
import { FormSection } from '../../form/FormSection';
import { FormSectionHeader } from '../../form/FormSectionHeader';
import { FormSectionTitle } from '../../form/FormSectionTitle';
import { FormSectionHeaderLink } from '../../form/FormSectionHeaderLinks';
import { isDefined, showBackendErrorMessage, showSuccessMessage } from '../../../services/utils';
import { EmptyListPlaceholder } from '../../lists/EmptyListPlaceholder';
import { AsyncSimpleList } from '../../lists/AsyncSimpleList';
import { SimpleListDeletionLink } from '../../lists/SimpleListLinks';
import { Modal } from '../../modals/Modal';
import { AddLocationModalContent } from './AddLocationModalContent';

const t = (key, props = {}) => I18n.t(`locations_section.${key}`, props);

export const Locations = props => {
  const {
    api,
    id,
    locationFieldsApiParams,
    prepareValuesToSubmit,
    disabled = false,
    title = t('title'),
    resourceName,
    humanizedResourceName = t('resource_name'),
    additionLabel = t('add', { resource_name: humanizedResourceName }),
    handleAdditionLinkOnClick = open => open(),
    buildingPresentationKey = 'building.building_name',
    floorPresentationKey = 'buildings_floor.floor_code',
    spacePresentationKey = 'lab.room_no',
    locationDetailsPresentationKey = 'location_details',
  } = props;
  const listRef = useRef(null);
  const addLocationModalRef = useRef(null);
  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{title}</FormSectionTitle>
          <FormSectionHeaderLink
            hidden={disabled}
            onClick={async () => {
              handleAdditionLinkOnClick(addLocationModalRef.current.open);
            }}
          >
            {additionLabel}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        {!isDefined(id) && <EmptyListPlaceholder />}
        {isDefined(id) && (
          <AsyncSimpleList
            ref={listRef}
            api={api(id).index}
            dataKey="data"
            resourceName={resourceName}
            mapData={({ attributes }) => [
              [t('building'), get(attributes, buildingPresentationKey)],
              [t('floor'), get(attributes, floorPresentationKey)],
              [t('space'), get(attributes, spacePresentationKey)],
              [t('location_details'), get(attributes, locationDetailsPresentationKey)],
              <SimpleListDeletionLink
                key="remove"
                hidden={disabled}
                modelName={humanizedResourceName}
                onSubmit={async () => {
                  const { ok, data } = await api(id).destroy(attributes.id);
                  if (ok) {
                    listRef.current.refresh();
                    showSuccessMessage(
                      t('flash.success.remove', { resource_name: humanizedResourceName }),
                    );
                  } else {
                    showBackendErrorMessage(
                      t('flash.error.remove', { resource_name: humanizedResourceName }),
                      data,
                    );
                  }
                }}
              >
                {t('remove')}
              </SimpleListDeletionLink>,
            ]}
          />
        )}
      </FormSection>
      <Modal
        title={t('add_modal.title', { resource_name: humanizedResourceName })}
        ref={addLocationModalRef}
      >
        {modalProps => (
          <AddLocationModalContent
            resourceName={resourceName}
            humanizedResourceName={humanizedResourceName}
            locationFieldsApiParams={locationFieldsApiParams || {}}
            prepareValuesToSubmit={prepareValuesToSubmit || (values => values)}
            listRef={listRef}
            {...modalProps}
            {...props}
          />
        )}
      </Modal>
    </>
  );
};
