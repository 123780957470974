import React, { useState } from 'react';
import styled from 'styled-components';
import { FieldWrapper } from './auxiliary/FieldWrapper';
import { fontSize } from '../../assets/styles/typography';
import { colors } from '../../assets/styles/colors';
import { fieldPropDefaultTransformation, fieldWrapperProps } from '../../services/fieldUtils';

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const Input = styled.input`
  font-size: ${fontSize.textMd};
  color: ${colors.inputTextColor};
  width: 100%;
  padding-left: 24px;
  padding-right: 8px;
  height: 36px;
  background: ${props => (props.disabled ? colors.disabled : colors.white)};
  background-clip: padding-box;
  border: 1px solid ${colors.inputBorderColor};
  border-radius: 4px;
  &:focus {
    border-color: ${colors.outline};
    border-width: 1;
    box-shadow: 0.005em 0.005em 0.005em ${colors.outline} inset,
      0.005em 0.005em 0.005em ${colors.outline};
    outline: 0;
  }
`;

const DollarIcon = styled.i.attrs({
  className: 'fa fa-sm fa-dollar-sign',
})`
  position: absolute;
  left: 8px;
  margin: auto 0;
  top: 10px;
`;

const formatCurrency = value => {
  const result = parseFloat(value).toFixed(2);
  // eslint-disable-next-line no-restricted-globals
  return isNaN(result) ? '' : result;
};

export const CurrencyField = props => {
  const {
    field,
    field: { name, value },
    form: { setFieldValue },
    disabled,
    onBlur,
    placeholder,
    resourceName,
    id,
  } = props;
  const [editionMode, setEditionMode] = useState(false);
  const [lastValidValue, setLastValidValue] = useState(formatCurrency(value));

  const inputId = id || `${resourceName}-${name}`;

  const onInput = e => {
    const { selectionStart, selectionEnd } = e.target;
    const input = e.target.value;
    // eslint-disable-next-line no-useless-escape
    const regex = /([^\d\.]|\..*\.|\..{3,}|^\.)/;
    if (Number(input) > 9999999999 || regex.test(input)) {
      e.target.value = lastValidValue;
      // handle caret
      e.target.setSelectionRange(selectionStart - 1, selectionEnd - 1);
    }
    setEditionMode(true);
    setLastValidValue(e.target.value);
  };
  const defaultOnBlur = async e => {
    await field.onBlur(e);
    await setFieldValue(name, e.target.value);
  };

  return (
    <FieldWrapper {...fieldWrapperProps({ inputId, ...props })}>
      <Container>
        <DollarIcon />
        <Input
          {...fieldPropDefaultTransformation(props)}
          id={inputId}
          value={editionMode ? value : formatCurrency(value)}
          disabled={disabled}
          onInput={onInput}
          placeholder={placeholder}
          onBlur={e => {
            e.target.value = formatCurrency(e.target.value);
            setEditionMode(false);
            // eslint-disable-next-line no-unused-expressions
            onBlur ? onBlur(e, defaultOnBlur) : defaultOnBlur(e);
          }}
        />
      </Container>
    </FieldWrapper>
  );
};
