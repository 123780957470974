import yup from '../../../components/form/CustomYupValidator';

export const validationSchema = yup.object().shape({
  IBC_registration_no: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  person_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  ibc_status_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  bsl_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});
