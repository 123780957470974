import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { fontSize } from '../../assets/styles/typography';
import { FilterPopoverForm } from './FilterPopoverForm';

const Header = styled(PopoverHeader)`
  width: 273px;
  padding: 0.5rem 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderTitle = styled.h3`
  margin-bottom: 0;
`;

const HeaderClose = styled.span`
  cursor: pointer;
`;

const Container = styled(PopoverBody)`
  width: 273px;
  padding: 1.5rem 0.75rem;
  font-size: ${fontSize.textSm};
`;

const ContainerTitle = styled.div`
  display: flex;
  padding-bottom: 0.25rem;
`;

export class FilterPopover extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggle = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render = () => {
    const { target, title } = this.props;
    const { isOpen } = this.state;
    return (
      <Popover placement="bottom" isOpen={isOpen} target={target} toggle={this.toggle}>
        <Header>
          <HeaderTitle>{I18n.t('helpers.filters.title')}</HeaderTitle>
          <HeaderClose className="icon icon-close" onClick={this.toggle} />
        </Header>
        <Container>
          <ContainerTitle>
            <span className="icon icon-search_red" />
            {title}
          </ContainerTitle>
          <FilterPopoverForm toggle={this.toggle} {...this.props} />
        </Container>
      </Popover>
    );
  };
}
