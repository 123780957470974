import React from 'react';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../components/form/FormFooter';
import { BrandButton } from '../../../components/buttons/BrandButton';
import {
  defaultHandleSubmit,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { DeletionLink } from '../../../components/links/DeletionLink';

const t = key => I18n.t(`administration.waste_cafs.new_edit.${key}`);

export const Footer = props => {
  const {
    setFieldValue,
    handleSubmit,
    isSubmitting,
    currentUser,
    values: { id },
  } = props;

  return (
    <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
      <FormFooterLeftPart>
        <DeletionLink
          withIcon
          hidden={!(id && (currentUser.app_admin || currentUser.org_admin))}
          modelName={I18n.t('activerecord.models.waste_caf')}
          onSubmit={async () => {
            const { ok, data } = await API.administration.wasteCafs.destroy(id);
            if (ok) {
              setInitialFlashMessageForNextPage(t('flash.success.destroy'));
              window.location.href = '/administration/waste_cafs';
            } else {
              showBackendErrorMessage(t('flash.error.destroy'), data);
            }
          }}
        />
      </FormFooterLeftPart>
      <FormFooterRightPart>
        <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
          {t('footer.save')}
        </BrandButton>
      </FormFooterRightPart>
    </FormFooter>
  );
};
