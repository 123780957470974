import React from 'react';
import styled from 'styled-components';
import { ModalSection } from '../../../../../../components/modals/ModalSection';
import { ModalWarning } from '../../../../../../components/modals/ModalWarning';
import { FormFooter, FormFooterRightPart } from '../../../../../../components/form/FormFooter';
import { CancellationLink } from '../../../../../../components/links/CancellationLink';
import { BrandButton } from '../../../../../../components/buttons/BrandButton';
import { defaultHandleSubmit } from '../../../../../../services/utils';
import { colors } from '../../../../../../assets/styles/colors';

const Icon = styled.i`
  font-size: 65px;
  color: ${colors.lightGray};
  margin: auto;
`;

const t = key =>
  I18n.t(`administration.inspection_types.new_edit.checklist.question_details_modal.${key}`);

export const RemoveAllConditionsModalContent = ({ hide, setFieldValue, handleSubmit }) => (
  <ModalSection>
    <div className="d-flex justify-center">
      <Icon className="saluteicons saluteicons-warning" />
    </div>
    <ModalWarning>{t('remove_all_conditions_warning')}</ModalWarning>
    <FormFooter>
      <FormFooterRightPart>
        <CancellationLink onClick={() => hide()} />
        <BrandButton
          onClick={async () => {
            await setFieldValue('visibilityConditions', []);
            await defaultHandleSubmit(setFieldValue, handleSubmit);
          }}
        >
          {t('remove_all_conditions')}
        </BrandButton>
      </FormFooterRightPart>
    </FormFooter>
  </ModalSection>
);
