import { withFormik } from 'formik';
import { API } from '../../../../services/api';
import { HsdsEmergencyContactForm } from './Form';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`hsds.hazard_assessments.new_edit.modals.hsds_emergency_contacts.${key}`);

const HsdsEmergencyContactFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const hsdsEmergencyContact = HsdsEmergencyContactFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(hsdsEmergencyContact.id)) {
      HsdsEmergencyContactFunctions.create(hsdsEmergencyContact, formikProps);
    } else {
      HsdsEmergencyContactFunctions.update(hsdsEmergencyContact, formikProps);
    }
  },
  create: async (hsdsEmergencyContact, formikProps) => {
    const { ok, data } = await API.spaceRiskAssessments
      .hsdsEmergencyContacts(formikProps.props.spaceRiskAssessmentId)
      .create({ hsds_emergency_contact: hsdsEmergencyContact });
    if (ok) {
      formikProps.resetForm({
        values: HsdsEmergencyContactFunctions.prepareInitialValues(data.data.attributes),
      });
      formikProps.props.hide();
      formikProps.props.listRef.current.refresh();
      showSuccessMessage(t('flash.success.create'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.create'), data);
    }
  },
  update: async (hsdsEmergencyContact, formikProps) => {
    const { ok, data } = await API.spaceRiskAssessments
      .hsdsEmergencyContacts(formikProps.props.spaceRiskAssessmentId)
      .update(hsdsEmergencyContact.id, { hsds_emergency_contact: hsdsEmergencyContact });
    if (ok) {
      formikProps.resetForm({
        values: HsdsEmergencyContactFunctions.prepareInitialValues(data.data.attributes),
      });
      formikProps.props.hide();
      formikProps.props.listRef.current.refresh();
      showSuccessMessage(t('flash.success.update'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.update'));
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: () => ({}),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'contact_person_id',
    'category',
    'office_phone',
    'additional_emergency_phone',
    'post_emergency_phone',
  ],
});

export const HsdsEmergencyContactsModalContent = withFormik({
  mapPropsToValues: props =>
    HsdsEmergencyContactFunctions.prepareInitialValues(props.hsdsEmergencyContact),
  handleSubmit: HsdsEmergencyContactFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(HsdsEmergencyContactForm);
