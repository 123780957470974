import React, { useLayoutEffect, useMemo, useRef, useState, useCallback, useEffect } from 'react';
import { FastField } from 'formik';
import { debounce } from 'lodash';
import styled from 'styled-components';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import { fieldWrapperProps } from '../../../services/fieldUtils';
import { FieldWrapper } from '../auxiliary/FieldWrapper';
import { NumericField } from '../NumericField';
import { API } from '../../../services/api';
import { AsyncSelectorField } from '../AsyncSelectorField';
import { htmlOptionRenderer } from '../selectorOptionRenderers/htmlOptionRenderer';
import { breakpoints } from '../../../assets/styles/grid';

const minWidthToFitInputWithUnit = 400;

const UnitFieldWrapper = styled.div`
  min-width: 270px;
  width: 270px;
  max-width: 270px;
  padding-left: 1rem;
`;

export const NumericAndUnitFields = props => {
  const { unitName, unitProps, disabled, required, displayMode, unitApiParams } = props;

  const smallModeInputRef = useRef(null);
  const inputWithUnitWrapperRef = useRef(null);
  const [smallMode, setSmallMode] = useState(false);

  const actualizeDisplay = useCallback(
    debounce(() => {
      // for small screens always use smallMode
      if (window.innerWidth < parseInt(breakpoints.small, 10)) {
        setSmallMode(true);
        return;
      }

      // for bigger rely on the part of space typically reserved for the input
      let relevantPartWidth;
      if (smallModeInputRef.current) relevantPartWidth = smallModeInputRef.current.clientWidth;
      else if (inputWithUnitWrapperRef.current)
        relevantPartWidth = inputWithUnitWrapperRef.current.clientWidth;
      if (relevantPartWidth === undefined) return;
      setSmallMode(relevantPartWidth < minWidthToFitInputWithUnit);
    }, 100),
    [],
  );

  useLayoutEffect(actualizeDisplay);
  useEffect(() => {
    window.addEventListener('resize', actualizeDisplay);
    return () => window.removeEventListener('resize', actualizeDisplay);
  }, [actualizeDisplay]);

  const finalUnitProps = useMemo(
    () => ({
      name: unitName,
      disabled,
      required,
      api: API.selectors.ums.index,
      apiParams: unitApiParams,
      selectionApi: API.selectors.ums.show,
      optionRendered: htmlOptionRenderer('label'),
      headerRendered: htmlOptionRenderer('header'),
      component: AsyncSelectorField,
      label: I18n.t('unit_field_label'),
      ...unitProps,
    }),
    [unitName, disabled, required, unitProps, unitApiParams],
  );

  if (smallMode)
    return (
      <div>
        <FastField {...props} component={NumericField} inputRef={smallModeInputRef} />
        <FastField displayMode={displayMode} {...finalUnitProps} />
      </div>
    );

  return (
    <div>
      <FieldWrapper {...fieldWrapperProps(props)} error={false} warning={false}>
        <div className="w-100 d-flex" ref={inputWithUnitWrapperRef}>
          <div className="flex-grow-1">
            <NumericField
              {...props}
              label={false}
              hint={false}
              required={false}
              bottomMargin={false}
              displayMode={fieldDisplayModes.GROWING_WIDTH_INPUT}
            />
          </div>
          <UnitFieldWrapper>
            <FastField
              displayMode={fieldDisplayModes.GROWING_WIDTH_INPUT}
              bottomMargin={false}
              {...finalUnitProps}
            />
          </UnitFieldWrapper>
        </div>
      </FieldWrapper>
    </div>
  );
};
