import React, { useRef } from 'react';
import { FastField } from 'formik';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../components/form/FormFooter';
import { LightButton } from '../../../components/buttons/LightButton';
import { BrandButton } from '../../../components/buttons/BrandButton';
import {
  defaultHandleSubmit,
  isDefined,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../services/utils';
import { Modal } from '../../../components/modals/Modal';
import { DateTimeField } from '../../../components/fields/DateTimeField';
import { ModalSection } from '../../../components/modals/ModalSection';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import { DeletionLink } from '../../../components/links/DeletionLink';
import { API } from '../../../services/api';
import { CancellationLink } from '../../../components/links/CancellationLink';
import { currentDateTime } from '../../../services/dateFormatUtils';

const t = (key, params = {}) => I18n.t(`requests.safe_work_authorizations.new_edit.${key}`, params);
const resolutionModalT = (key, params = {}) =>
  I18n.t(`requests.safe_work_authorizations.new_edit.modals.resolution.${key}`, params);

export const Footer = ({
  isSubmitting,
  setFieldValue,
  handleSubmit,
  values: { id, is_completed },
  currentUser,
  resource_name,
}) => {
  const resolutionModalRef = useRef(null);
  return (
    <>
      <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
        <FormFooterLeftPart>
          <DeletionLink
            withIcon
            hidden={!(id && (currentUser.app_admin || currentUser.org_admin))}
            modelName={resource_name}
            onSubmit={async () => {
              const { ok, data } = await API.requests.safeWorkAuthorizations.destroy(id);
              if (ok) {
                setInitialFlashMessageForNextPage(t('flash.success.remove', { resource_name }));
                window.location.href = `/requests/safe_work_authorizations`;
              } else {
                showBackendErrorMessage(t('flash.error.remove', { resource_name }), data);
              }
            }}
          />
        </FormFooterLeftPart>
        <FormFooterRightPart>
          <LightButton
            hidden={is_completed}
            onClick={async () => {
              await setFieldValue('submitType', 'save');
              await defaultHandleSubmit(setFieldValue, handleSubmit);
            }}
          >
            {t('footer.save')}
          </LightButton>
          <BrandButton
            hidden={is_completed || !isDefined(id)}
            onClick={async () => {
              await setFieldValue('resolution_date', currentDateTime());
              resolutionModalRef.current.open();
            }}
          >
            {t('footer.resolve')}
          </BrandButton>
          <LightButton
            hidden={!is_completed}
            onClick={async () => {
              await setFieldValue('submitType', 'reopen');
              await defaultHandleSubmit(setFieldValue, handleSubmit);
            }}
          >
            {t('footer.reopen')}
          </LightButton>
        </FormFooterRightPart>
      </FormFooter>
      <Modal title={resolutionModalT('title', { resource_name })} ref={resolutionModalRef}>
        <ModalSection>
          <FastField
            name="resolution_date"
            label={I18n.t('activerecord.attributes.safe_work_authorization.resolution_date')}
            displayMode={fieldDisplayModes.FULL_WIDTH_FORM_ROW}
            required
            component={DateTimeField}
          />
          <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
            <FormFooterRightPart>
              <CancellationLink onClick={() => resolutionModalRef.current.hide()} />
              <BrandButton
                onClick={async () => {
                  resolutionModalRef.current.hide();
                  await setFieldValue('submitType', 'resolve');
                  await defaultHandleSubmit(setFieldValue, handleSubmit);
                }}
              >
                {resolutionModalT('footer.resolve')}
              </BrandButton>
            </FormFooterRightPart>
          </FormFooter>
        </ModalSection>
      </Modal>
    </>
  );
};
