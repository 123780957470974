import get from 'lodash/get';
import { API } from '../../../services/api';
import { prepareCustomFieldsAnswers, showBackendErrorMessage } from '../../../services/utils';

const t = key => I18n.t(`requests.general_requests.new_edit.${key}`);

export const setGeneralRequestTypeData = async (newSelection, setFieldValue) => {
  await setFieldValue('generalRequestTypeName', get(newSelection, 'data.text'));

  const { ok, data } = await API.requests
    .generalRequestTypes(newSelection.value)
    .fieldDefinitions()
    .index();

  if (ok) {
    const definitions = await data.data.map(fieldDefinition => fieldDefinition.attributes);
    const answers = await prepareCustomFieldsAnswers('general_request', definitions);
    await setFieldValue('generalRequestFieldDefinitions', definitions);
    await setFieldValue('general_request_field_answers_attributes', answers);
  } else {
    await showBackendErrorMessage(t('flash.error.unexpected_error'));
  }
};
