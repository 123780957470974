const t = key => I18n.t(`field_definitions_section.${key}`);

export const getSelectorVariantsOptions = () =>
  ['text', 'date', 'date_and_time', 'time', 'numerical', 'selector', 'text_area', 'boolean'].map(
    key => ({
      value: key,
      id: key,
      header: t(`fields.variants.${key}`),
    }),
  );
