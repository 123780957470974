import React, { useRef } from 'react';
import styled from 'styled-components';
import { FastField, withFormik } from 'formik';
import { ListButton } from './ListButton';
import { colors } from '../../assets/styles/colors';
import { Modal } from '../modals/Modal';
import { defaultShouldUpdate } from '../../services/fieldUtils';
import { TextField } from '../fields/TextField';
import { FilesField } from '../fields/FilesField';
import { BrandButton } from './BrandButton';
import { FormFooter, FormFooterRightPart } from '../form/FormFooter';
import { CancellationLink } from '../links/CancellationLink';
import { defaultHandleSubmit, filterValues, isDefined } from '../../services/utils';
import { ModalSection } from '../modals/ModalSection';
import { fieldDisplayModes } from '../../constants/fieldDisplayModes';
import { DefaultAttributesGroup } from '../form/DefaultAttributesGroup';

const t = key => I18n.t(`comment_file_button.${key}`);

const StyledCommentSpan = styled.span`
  ${props =>
    props.active
      ? `color: ${colors.listButtonHoverFontColor}`
      : `color: ${colors.listButtonFontColor}`}
`;

const StyledPaperclipSpan = styled.span`
  font-size: 12px;
  line-height: 16px;
  ${props =>
    props.active
      ? `color: ${colors.listButtonHoverFontColor}`
      : `color: ${colors.listButtonFontColor}`}
`;

const StyledButton = styled.button`
  min-width: 110px;
  height: 26px;
  line-height: 26px;
  border-radius: 4px;
  background: ${colors.listButtonHoverBackground};
  cursor: pointer;
  border: none;
  &:hover {
    background: ${colors.commentFileButtonHoverBackground};
  }
  &:focus {
    outline: none;
  }
`;

const produceOnClick = onClick => e => {
  e.preventDefault();
  onClick(e);
};

const PureButton = ({ onClick, initialComment, initialAttachments, resourceName, ...props }) => {
  const preparedOnClick = produceOnClick(onClick);
  const commentPresent = isDefined(initialComment);
  const filePresent = initialAttachments.length > 0;
  const buttonId = `${resourceName}-comment-btn`;
  if (!filePresent && !commentPresent) {
    return (
      <ListButton id={buttonId} onClick={preparedOnClick} {...props}>
        {t('label')}
      </ListButton>
    );
  }
  return (
    <StyledButton id={buttonId} onClick={preparedOnClick} {...props}>
      <div className="row m-0">
        <div className="col-6">
          <StyledCommentSpan active={commentPresent} className="saluteicons saluteicons-comment" />
        </div>
        <div className="col-6">
          <StyledPaperclipSpan active={filePresent} className="saluteicons saluteicons-paperclip" />
        </div>
      </div>
    </StyledButton>
  );
};

const Form = props => {
  const { setFieldValue, handleSubmit, hide, resourceName } = props;
  return (
    <ModalSection>
      <form>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
            labelI18nKeyPrefix: 'comment_file_button',
            shouldUpdate: defaultShouldUpdate,
            resourceName,
          }}
        >
          <FastField name="comment" component={TextField} />
          <FastField name="attachments" component={FilesField} />
        </DefaultAttributesGroup>
        <FormFooter>
          <FormFooterRightPart>
            <CancellationLink onClick={hide}>{t('cancel')}</CancellationLink>
            <BrandButton
              onClick={e => {
                defaultHandleSubmit(setFieldValue, handleSubmit, e).then(() => hide());
              }}
            >
              {t('confirm')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </form>
    </ModalSection>
  );
};

const ModalContent = withFormik({
  mapPropsToValues: props => ({
    comment: props.initialComment,
    attachments: props.initialAttachments,
  }),
  handleSubmit: (values, formikProps) => {
    formikProps.props.onConfirm(filterValues(values, ['comment', 'attachments']));
  },
})(Form);

export const CommentFileButton = props => {
  const modalRef = useRef(null);
  return (
    <>
      <PureButton {...props} onClick={() => modalRef.current.open()} />
      <Modal title={t('modal_title')} ref={modalRef}>
        {modalProps => <ModalContent {...modalProps} {...props} />}
      </Modal>
    </>
  );
};

CommentFileButton.defaultProps = {
  initialComment: '',
  initialAttachments: [],
  onConfirm: () => {},
};
