import React, { PureComponent, useRef, useState } from 'react';
import { FieldArray } from 'formik';
import ReactS3Uploader from 'react-s3-uploader';
import _ from 'lodash';
import styled from 'styled-components';
import { FieldWrapper } from './auxiliary/FieldWrapper';
import { fontSize } from '../../assets/styles/typography';
import { colors } from '../../assets/styles/colors';
import { fieldWrapperProps } from '../../services/fieldUtils';
import { UploadIcon } from '../../../stateless_components/icons/UploadIcon';
import { ErrorMessage } from '../../../stateless_components/inputs/elements/ErrorMessage';
import { ProgressBar } from '../../../stateless_components/other/ProgressBar';
import { DownloadIcon } from '../../../stateless_components/icons/DownloadIcon';
import { bytesToMb } from '../../../services/fileSizeHelper';
import { Modal } from '../modals/Modal';
import { ModalSection } from '../modals/ModalSection';
import { TextInput } from '../inputs/TextInput';
import { FormFooter } from '../form/FormFooter';
import { CancellationLink } from '../links/CancellationLink';
import { BrandButton } from '../buttons/BrandButton';
import { breakpointsForMaxWidth } from '../../assets/styles/grid';

export const FilesFieldContainer = styled.div`
  width: 100%;
`;

export const StyledAttachmentRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 3px;
  padding-bottom: ${props => (props.descriptionIsPresent ? '3px' : '10px')};
  width: 100%;
  @media only screen and (max-width: ${breakpointsForMaxWidth.small}) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const StyledAttachmentNameSpan = styled.span`
  width: 100%;
  vertical-align: middle;
  display: inline-block;
  padding-right: 20px;
  font-weight: bold;
`;

export const DescriptionLinkButton = styled.button`
  background: transparent;
  border: 0;
  padding: 0px 7px;
  color: ${colors.download};
  cursor: pointer;
  min-width: 100px;
  height: 30px;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
  }
  @media only screen and (max-width: ${breakpointsForMaxWidth.small}) {
    padding: 0;
    text-align: right;
  }
`;

export const StyledAttachmentLink = styled.a`
  color: ${colors.download};
  min-width: 80px;
  margin-left: 10px;
  height: 30px;
  @media only screen and (max-width: ${breakpointsForMaxWidth.small}) {
    margin-left: 0;
    text-align: right;
  }
`;

export const StyledRemoveAttachmentButton = styled.button`
  background: transparent;
  border: 0;
  padding: 0px 7px;
  margin-left: 10px;
  color: ${colors.delete};
  cursor: pointer;
  height: 30px;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
  }
  @media only screen and (max-width: ${breakpointsForMaxWidth.small}) {
    padding: 0;
    margin-left: 0;
    text-align: right;
  }
`;

export const StyledAttachmentDescriptionRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0 3px;
  padding-bottom: 10px;
  width: 100%;
`;

export const StyledAttachmentsTable = styled.div`
  font-size: ${fontSize.textXsm};
  flex-direction: column;
  width: 100%;
`;

export const StyledMockUploadButton = styled.div`
  padding: 0.25rem 1rem;
  border: 1px solid ${colors.inputBorderColor};
  border-radius: 0.2rem;
  cursor: pointer;
  &:focus,
  &:hover {
    background: ${colors.inputBorderColor};
  }
`;

export const StyledMockUploadButtonTitle = styled.span`
  padding-left: 10px;
`;

const DescriptionAttachmentModalContent = props => {
  const { id, description, hide, addDescriptionToAttachment } = props;
  const [descriptionValue, setDescriptionValue] = useState(description);

  return (
    <ModalSection>
      <form>
        <TextInput
          id={`comment-${id}-text-input`}
          placeholder={I18n.t('file_uploader.description_placeholder')}
          value={descriptionValue}
          onChange={e => {
            setDescriptionValue(e.target.value);
          }}
        />
        <FormFooter>
          <CancellationLink onClick={hide} />
          <BrandButton
            onClick={async () => {
              await addDescriptionToAttachment(descriptionValue);
              hide();
            }}
          >
            {I18n.t('file_uploader.save')}
          </BrandButton>
        </FormFooter>
      </form>
    </ModalSection>
  );
};

const AttachmentRow = props => {
  const {
    id,
    pathString,
    description,
    filename,
    disabled,
    removeAttachment,
    addDescriptionToAttachment,
  } = props;
  const descriptionAttachmentModalRef = useRef(null);
  const descriptionIsPresent = description && description.length !== 0;
  const describeAction = descriptionIsPresent ? 'edit_description' : 'add_description';

  return (
    <>
      <StyledAttachmentRow descriptionIsPresent={descriptionIsPresent}>
        <StyledAttachmentNameSpan id={`${id}-name`}>{filename}</StyledAttachmentNameSpan>
        {!disabled && (
          <DescriptionLinkButton
            id={`${id}-description-button`}
            type="button"
            onClick={() => descriptionAttachmentModalRef.current.open()}
          >
            {I18n.t(`file_uploader.${describeAction}`)}
          </DescriptionLinkButton>
        )}
        <StyledAttachmentLink id={`${id}-link`} download={filename} href={pathString}>
          <DownloadIcon>{I18n.t('file_uploader.download_file')}</DownloadIcon>
        </StyledAttachmentLink>
        {!disabled && (
          <StyledRemoveAttachmentButton
            id={`${id}-remove-button`}
            type="button"
            onClick={removeAttachment}
          >
            {I18n.t('file_uploader.delete_file')}
          </StyledRemoveAttachmentButton>
        )}
      </StyledAttachmentRow>
      {descriptionIsPresent && (
        <StyledAttachmentDescriptionRow>{description}</StyledAttachmentDescriptionRow>
      )}
      <Modal title={I18n.t(`file_uploader.${describeAction}`)} ref={descriptionAttachmentModalRef}>
        {modalProps => (
          <DescriptionAttachmentModalContent
            {...modalProps}
            description={description}
            addDescriptionToAttachment={addDescriptionToAttachment}
          />
        )}
      </Modal>
    </>
  );
};

export class FilesField extends PureComponent {
  constructor(props) {
    super(props);
    this.maxFileSizeInMb = I18n.t('config.max_attachment_size_in_mb');
    this.state = {
      showUploadButton: true,
      progressValue: 0,
      errorOccurred: false,
      error: '',
      showProgressBar: false,
    };
  }

  defaultOnChange = async e => {
    const { value, arrayHelpers, arrayHelpersMethod } = e;
    await arrayHelpers[arrayHelpersMethod](value);
    this.fileRef.value = null;
  };

  onUploadStart = (file, callback) => {
    if (bytesToMb(file.size) > this.maxFileSizeInMb) {
      this.setOnSizeExceededError();
    } else {
      callback(file);
      this.setState({
        errorOccurred: false,
        error: '',
        showUploadButton: false,
        progressValue: 0,
        showProgressBar: true,
      });
    }
  };

  onUploadProgress = progressValue => {
    this.setState({ progressValue });
  };

  setOnSizeExceededError = () => {
    this.setState({
      showProgressBar: false,
      errorOccurred: true,
      error: `${I18n.t('file_uploader.errors.too_large_file')}${this.maxFileSizeInMb}${I18n.t(
        'file_uploader.errors.too_large_file_unit',
      )}`,
    });
  };

  onUploadError = () => {
    this.setState({
      showUploadButton: true,
      showProgressBar: true,
      errorOccurred: true,
      error: I18n.t('file_uploader.errors.supported_extensions_info'),
    });
  };

  onUploadSuccess = (signedUrl, arrayHelpers) => {
    const { onChange } = this.props;
    const downloadPath = { url: signedUrl.signedUrl.split('?')[0] };
    this.setState({
      showUploadButton: true,
      progressValue: 0,
      showProgressBar: false,
    });
    const event = {
      value: downloadPath,
      arrayHelpers,
      arrayHelpersMethod: 'push',
    };
    // eslint-disable-next-line no-unused-expressions
    onChange ? onChange(event, this.defaultOnChange.bind(this)) : this.defaultOnChange(event);
  };

  onRemoveAttachment = (valueIndex, arrayHelpers, pathString, filename) => {
    const { onChange } = this.props;
    const event = {
      value: valueIndex,
      arrayHelpers,
      arrayHelpersMethod: 'remove',
      pathString,
      filename,
    };
    // eslint-disable-next-line no-unused-expressions
    onChange ? onChange(event, this.defaultOnChange.bind(this)) : this.defaultOnChange(event);
  };

  addDescriptionToAttachment = async (valueIndex, description, arrayHelpers, pathString) => {
    await arrayHelpers.replace(valueIndex, {
      url: pathString,
      description,
    });
    this.fileRef.value = null;
  };

  setInputRef = ref => {
    this.fileRef = ref;
  };

  prepareAttachmentsData() {
    const {
      field: { value: initialCollection },
    } = this.props;

    return initialCollection.map((attachment, valueIndex) => {
      const { url: pathString, description } = attachment;
      const path = new URL(pathString).pathname.split('/');
      const filename = decodeURIComponent(_.last(path));

      return { pathString, filename, description, valueIndex };
    });
  }

  renderAttachments(arrayHelpers, fieldId) {
    const { disabled, sortPredicate } = this.props;
    const {
      error,
      errorOccurred,
      progressValue,
      showProgressBar,
      showUploadButton,
      uploadPending,
      uploadingErrorOccurred,
    } = this.state;

    let attachments = this.prepareAttachmentsData();
    if (sortPredicate) attachments = attachments.sort(sortPredicate);

    return (
      <FilesFieldContainer>
        <StyledAttachmentsTable>
          {attachments.map(({ pathString, filename, description, valueIndex }) => (
            <AttachmentRow
              pathString={pathString}
              description={description}
              filename={filename}
              disabled={disabled}
              id={`${fieldId}[${valueIndex}]`}
              key={pathString}
              removeAttachment={() => {
                this.onRemoveAttachment(valueIndex, arrayHelpers, pathString, filename);
              }}
              addDescriptionToAttachment={async _description => {
                await this.addDescriptionToAttachment(
                  valueIndex,
                  _description,
                  arrayHelpers,
                  pathString,
                );
              }}
            />
          ))}
        </StyledAttachmentsTable>
        {!disabled && (
          <>
            <ReactS3Uploader
              id={fieldId}
              signingUrl="/file_upload"
              signingUrlMethod="GET"
              preprocess={this.onUploadStart}
              onProgress={this.onUploadProgress}
              onError={this.onUploadError}
              onFinish={signedUrl => this.onUploadSuccess(signedUrl, arrayHelpers)}
              autoUpload
              contentDisposition="attachment"
              scrubFilename={filename => encodeURIComponent(filename.trim())}
              hidden
              inputRef={this.setInputRef}
              multiple
            />
            <ProgressBar
              progressValue={progressValue}
              hidden={!showProgressBar}
              progressText={I18n.t('file_uploader.progress_text')}
              errorOccurred={errorOccurred}
            />
            <ErrorMessage hidden={!errorOccurred}>{error}</ErrorMessage>
            <label htmlFor={fieldId} hidden={!showUploadButton}>
              <StyledMockUploadButton>
                <UploadIcon />
                <StyledMockUploadButtonTitle>
                  {I18n.t('file_uploader.upload_file')}
                </StyledMockUploadButtonTitle>
              </StyledMockUploadButton>
            </label>
            <ProgressBar
              progressValue={progressValue}
              hidden={!uploadPending}
              progressText={I18n.t('file_uploader.progress_text')}
              errorOccurred={uploadingErrorOccurred}
            />
          </>
        )}
      </FilesFieldContainer>
    );
  }

  render() {
    const {
      field: { name },
      resourceName,
      id,
    } = this.props;
    const inputId = id || `${resourceName}-${name}`;
    return (
      <FieldWrapper {...fieldWrapperProps({ inputId, ...this.props })}>
        <FieldArray
          name={name}
          render={arrayHelpers => this.renderAttachments(arrayHelpers, inputId)}
        />
      </FieldWrapper>
    );
  }
}
