import { some, compact } from 'lodash';
import yup from '../../../components/form/CustomYupValidator';
import {
  currentDateTime,
  dateFormatter,
  newMoment,
  getConvertedMoment,
} from '../../../services/dateFormatUtils';
import { produceShouldFieldBeVisible } from './OptionalSections/helpers';

const t = (key, params = {}) =>
  I18n.t(`requests.incident_requests.new_edit.validations.${key}`, params);

export const validationSchema = yup.object().shape({
  datetime_reported: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required'))
    .test(
      'datetime_reported_cannot_be_later_than_response_date',
      t('datetime_reported_cannot_be_later_than_response_date'),
      function _(reportedDatetime) {
        const responseDatetime = this.parent.response_date;

        return reportedDatetime && responseDatetime
          ? getConvertedMoment(reportedDatetime).isSameOrBefore(
              getConvertedMoment(responseDatetime),
              'minute',
            )
          : true;
      },
    ),
  reporting_person_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  datetime_of_occurrence: yup
    .string()
    .nullable()
    .test(
      'datetime_of_occurrence_cannot_be_later_than_datetime_reported',
      t('datetime_of_occurrence_cannot_be_later_than_datetime_reported'),
      // an arrow function cannot be used beneath because 'this' does not work with it
      // see https://github.com/jquense/yup#mixedtestname-string-message-string--function-test-function-schema
      function _(datetimeOfOccurrence) {
        const datetimeReported = this.parent.datetime_reported;

        return datetimeOfOccurrence && datetimeReported
          ? getConvertedMoment(datetimeOfOccurrence).isSameOrBefore(
              getConvertedMoment(datetimeReported),
              'minute',
            )
          : true;
      },
    )
    .test(
      'datetime_of_occurrence_cannot_be_later_than_corrective_actions_due_date',
      t('datetime_of_occurrence_cannot_be_later_than_corrective_actions_due_date'),
      function _(datetimeOfOccurrence) {
        const correctiveActionsDueDate = this.parent.corrective_actions_due_date;

        return datetimeOfOccurrence && correctiveActionsDueDate
          ? getConvertedMoment(datetimeOfOccurrence).isSameOrBefore(
              getConvertedMoment(correctiveActionsDueDate, true),
              'minute',
            )
          : true;
      },
    )
    .test(
      'datetime_of_occurrence_cannot_be_later_than_corrective_actions_completion_date',
      t('datetime_of_occurrence_cannot_be_later_than_corrective_actions_completion_date'),
      function _(datetimeOfOccurrence) {
        const correctiveActionsCompletionDate = this.parent.corrective_actions_completion_date;

        return datetimeOfOccurrence && correctiveActionsCompletionDate
          ? getConvertedMoment(datetimeOfOccurrence).isSameOrBefore(
              getConvertedMoment(correctiveActionsCompletionDate, true),
              'minute',
            )
          : true;
      },
    )
    .test(
      'datetime_of_occurrence_cannot_be_later_than_recurrence_prevention_due_date',
      t('datetime_of_occurrence_cannot_be_later_than_recurrence_prevention_due_date'),
      function _(datetimeOfOccurrence) {
        const recurrencePreventionDueDate = this.parent.recurrence_prevention_due_date;

        return datetimeOfOccurrence && recurrencePreventionDueDate
          ? getConvertedMoment(datetimeOfOccurrence).isSameOrBefore(
              getConvertedMoment(recurrencePreventionDueDate, true),
              'minute',
            )
          : true;
      },
    )
    .test(
      'datetime_of_occurrence_cannot_be_later_than_recurrence_prevention_completion_date',
      t('datetime_of_occurrence_cannot_be_later_than_recurrence_prevention_completion_date'),
      function _(datetimeOfOccurrence) {
        const recurrencePreventionCompletionDate = this.parent
          .recurrence_prevention_completion_date;

        return datetimeOfOccurrence && recurrencePreventionCompletionDate
          ? getConvertedMoment(datetimeOfOccurrence).isSameOrBefore(
              getConvertedMoment(recurrencePreventionCompletionDate, true),
              'minute',
            )
          : true;
      },
    )
    .test(
      'datetime_of_occurrence_cannot_be_later_than_current_time',
      t('datetime_of_occurrence_cannot_be_later_than_current_time', {
        current_time: dateFormatter.dateTime(currentDateTime()),
      }),
      function _(datetimeOfOccurrence) {
        return datetimeOfOccurrence
          ? getConvertedMoment(datetimeOfOccurrence).isSameOrBefore(newMoment())
          : true;
      },
    )
    .required(I18n.t('validations.required')),
  incident_issue: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  building_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  submitType: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  issue_type_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  related_incident_id: yup
    .string()
    .nullable(true)
    .when('submitType', {
      is: submitType => submitType === 'resolveAndAssignIncident',
      then: yup
        .string()
        .nullable(true)
        .required(t('required_to_assign_incident')),
    }),
  assigned_person_id: yup
    .string()
    .nullable(true)
    .when('submitType', {
      is: submitType => submitType === 'resolveAndCreateIncident',
      then: yup
        .string()
        .nullable(true)
        .required(t('required_to_create_incident')),
    }),
  incident_people_group_id: yup
    .string()
    .nullable(true)
    .when('submitType', {
      is: submitType => submitType === 'resolveAndCreateIncident',
      then: yup
        .string()
        .nullable(true)
        .required(t('required_to_create_incident')),
    }),
  event_type_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  corrective_actions_due_date: yup
    .string()
    .nullable()
    .test(
      'corrective_actions_due_date_required_when_corrective_actions_present',
      t('corrective_actions_due_date_required_when_corrective_actions_present'),
      function _(correctiveActionsDueDate) {
        const { incidentFieldsConfig } = this.parent;
        const shouldBeVisible = produceShouldFieldBeVisible(
          'corrective_actions',
          incidentFieldsConfig,
        );
        const correctiveActionsPresent = some(
          compact(
            [
              'corrective_actions_required',
              'actions_taken',
              'corrective_actions_completion_date',
              'corrective_actions_recorded_by_person_id',
            ].map(field => (shouldBeVisible(field) ? this.parent[field] : false)),
          ),
        );
        return correctiveActionsDueDate || !correctiveActionsPresent;
      },
    ),
  recurrence_prevention_due_date: yup
    .string()
    .nullable()
    .test(
      'recurrence_prevention_due_date_required_when_recurrence_prevention_present',
      t('recurrence_prevention_due_date_required_when_recurrence_prevention_present'),
      function _(recurrencePreventionDueDate) {
        const { incidentFieldsConfig } = this.parent;
        const shouldBeVisible = produceShouldFieldBeVisible(
          'preventative_actions',
          incidentFieldsConfig,
        );
        const recurrencePreventionPresent = some(
          compact(
            [
              'recurrence_prevention_required',
              'recurrence_prevention_comment',
              'recurrence_prevention_completion_date',
              'recurrence_prevention_recorded_by_person_id',
            ].map(field => (shouldBeVisible(field) ? this.parent[field] : false)),
          ),
        );
        return recurrencePreventionDueDate || !recurrencePreventionPresent;
      },
    ),
});
