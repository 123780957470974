import React, { useRef, useCallback } from 'react';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../components/form/FormFooter';
import { DeletionLink } from '../../../components/links/DeletionLink';
import { API } from '../../../services/api';
import {
  handleSubmitWithAfterSave,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../services/utils';
import { BrandButton } from '../../../components/buttons/BrandButton';
import { LightButton } from '../../../components/buttons/LightButton';
import { FooterAdditionalInformation } from '../../../components/form/FooterAdditionalInformation';
import { Modal } from '../../../components/modals/Modal';
import { ConfirmationModalContent } from '../../../components/modals/ConfirmationModalContent';

const t = key => I18n.t(`requests.waste_collection_candidates.new_edit.${key}`);

export const Footer = props => {
  const {
    values: { id, chemical_item_created, candidate_edited },
    setFieldValue,
    handleSubmit,
    isSubmitting,
    currentUser,
    wasteRequestId,
  } = props;

  const userIsAdmin = currentUser.app_admin || currentUser.org_admin;
  const createCollectionModalRef = useRef(null);
  const createCollectionAgainModalRef = useRef(null);

  const save = useCallback(async () => {
    const afterSave = () => {
      setInitialFlashMessageForNextPage(t('flash.success.save'));
      window.location.href = `/requests/waste_requests/${wasteRequestId}/edit`;
    };
    await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
  }, [setFieldValue, handleSubmit]);

  const saveAndCreateCollection = useCallback(async () => {
    await setFieldValue('createCollection', true);
    const afterSave = () => {
      setFieldValue('createCollection', false);
      setInitialFlashMessageForNextPage(t('flash.success.save_and_create_collection'));
      window.location.href = `/requests/waste_requests/${wasteRequestId}/edit`;
    };
    await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
  }, [setFieldValue, handleSubmit]);

  return (
    <>
      <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
        <FormFooterLeftPart>
          <DeletionLink
            withIcon
            hidden={!(id && userIsAdmin)}
            modelName={I18n.t('activerecord.models.waste_chemical_item_candidate')}
            onSubmit={async () => {
              const { ok, data } = await API.requests.wasteRequests
                .wasteCollectionCandidates(wasteRequestId)
                .destroy(id);
              if (ok) {
                setInitialFlashMessageForNextPage(t('flash.success.destroy'));
                window.location.href = `/requests/waste_requests/${wasteRequestId}/edit`;
              } else {
                showBackendErrorMessage(t('flash.error.destroy'), data);
              }
            }}
          />
        </FormFooterLeftPart>
        <FormFooterRightPart>
          {chemical_item_created && (
            <FooterAdditionalInformation>
              {t('footer.collection_created')}
            </FooterAdditionalInformation>
          )}
          {!chemical_item_created && (
            <LightButton
              onClick={async () => {
                createCollectionModalRef.current.open();
              }}
            >
              {t('footer.create_collection')}
            </LightButton>
          )}
          {chemical_item_created && candidate_edited && (
            <LightButton
              onClick={async () => {
                createCollectionAgainModalRef.current.open();
              }}
            >
              {t('footer.create_collection_again')}
            </LightButton>
          )}
          <BrandButton onClick={save}>{t('footer.save')}</BrandButton>
        </FormFooterRightPart>
      </FormFooter>
      <Modal ref={createCollectionModalRef} title={t('modals.create_collection.title')}>
        <ConfirmationModalContent
          modalRef={createCollectionModalRef}
          hint={t('modals.create_collection.hint')}
          confirm={t('modals.create_collection.confirm')}
          onConfirm={saveAndCreateCollection}
        />
      </Modal>
      <Modal ref={createCollectionAgainModalRef} title={t('modals.create_collection_again.title')}>
        <ConfirmationModalContent
          modalRef={createCollectionAgainModalRef}
          hint={t('modals.create_collection_again.hint')}
          confirm={t('modals.create_collection_again.confirm')}
          onConfirm={saveAndCreateCollection}
        />
      </Modal>
    </>
  );
};
