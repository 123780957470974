import React from 'react';
import { FastField } from 'formik';
import get from 'lodash/get';
import { FormHeader } from '../../../components/form/FormHeader';
import { isDefined } from '../../../services/utils';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { TextField } from '../../../components/fields/TextField';
import { FormSection } from '../../../components/form/FormSection';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { LocationFields } from '../../../components/fields/compositeFields/LocationFields';
import { DateTimeField } from '../../../components/fields/DateTimeField';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { Footer } from './Footer';
import { AsyncMultiSelectorField } from '../../../components/fields/AsyncMultiSelectorField';
import { RadInventoryItemsTable } from './RadInventoryItemsTable';

const t = key => I18n.t(`radiation_safety.rad_inventories.new_edit.${key}`);

export const Form = props => {
  const {
    setFieldValue,
    values: { id },
  } = props;

  return (
    <form>
      <FormHeader
        title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)}
        auditsLink={isDefined(id) ? `/radiation_safety/rad_inventories/${id}/audits` : ''}
      />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.rad_inventory',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.rad_inventory'),
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField name="identifier" disabled component={TextField} />
          <FastField name="name" required component={TextField} />
          <FastField
            name="rad_permit_id"
            required
            disabled={!!id}
            api={API.selectors.radPermits.index}
            selectionApi={API.selectors.radPermits.show}
            optionRenderer={htmlOptionRenderer('text')}
            onChange={async (newSelection, onChange) => {
              await onChange(newSelection);
              const licenseId = get(newSelection, 'data.rad_license_id');
              await setFieldValue('rad_license_id', licenseId);
            }}
            component={AsyncSelectorField}
          />
          <FastField name="updated_at" disabled component={DateTimeField} />
          <FastField
            name="owner_ids"
            required
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncMultiSelectorField}
          />
          <LocationFields buildingSelectorAttributes={{ required: true }} {...props} />
          <FastField name="location_comments" component={TextField} />
        </FormSection>
      </DefaultAttributesGroup>
      <Footer {...props} />
      <RadInventoryItemsTable {...props} />
    </form>
  );
};
