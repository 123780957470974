import React, { useRef } from 'react';
import { API } from '../../../services/api';
import { FormSection } from '../../../components/form/FormSection';
import { IndexTableDefaultHandler } from '../../../components/tables/IndexTableDefaultHandler';
import { handleSubmitWithAfterSave, isDefined } from '../../../services/utils';
import { AddRadInventoryItemModalContent } from './RadInventoryItemModalsContents/AddRadInventoryItemModalContent';
import { DefaultLink } from '../../../components/links/DefaultLink';
import { Modal } from '../../../components/modals/Modal';

const t = key => I18n.t(`radiation_safety.rad_permits.tabs.rad_inventory_items.${key}`);
const modalsTranslations = key =>
  I18n.t(`radiation_safety.rad_permits.modals.add_rad_inventory_item.${key}`);

export const RadInventoryItemsTable = ({
  isSubmitting,
  setFieldValue,
  handleSubmit,
  values: { id: radPermitId },
}) => {
  const addRadInventoryItemModalRef = useRef();

  return (
    <>
      <FormSection>
        <IndexTableDefaultHandler
          title={t('title')}
          api={API.radiationSafety.radInventoryItems().index}
          fixedFilters={{ with_rad_permit_id: radPermitId }}
          idToHrefFunction={(_id, rowData) =>
            `/radiation_safety/rad_inventories/${rowData.rad_inventory_id}/rad_inventory_items/${_id}/edit`
          }
          manageColumnsModel="RadInventoryItem"
          forceEmpty={!isDefined(radPermitId)}
          additionalLinks={[
            <DefaultLink
              disabled={isSubmitting}
              onClick={async () => {
                const afterSave = () => {
                  addRadInventoryItemModalRef.current.open();
                };
                await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
              }}
            >
              {t('link')}
            </DefaultLink>,
          ]}
        />
      </FormSection>
      <Modal title={modalsTranslations('title')} ref={addRadInventoryItemModalRef}>
        {modalProps => (
          <AddRadInventoryItemModalContent {...modalProps} radPermitId={radPermitId} />
        )}
      </Modal>
    </>
  );
};
