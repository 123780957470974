import React, { useEffect, useState } from 'react';
import { API } from '../../services/api';
import { EquipmentTypeForm } from './Form';
import { Loader } from '../../components/Loader';
import { isDefined } from '../../services/utils';

export const EquipmentType = props => {
  const { id } = props;

  const [fetching, setFetching] = useState(true);
  const [equipmentType, setEquipmentType] = useState({});
  useEffect(() => {
    (async () => {
      const api = isDefined(id)
        ? API.administration.equipmentTypes.show
        : API.administration.equipmentTypes.new;
      const { ok, data } = await api(id);
      if (ok) {
        await setEquipmentType(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <EquipmentTypeForm {...props} equipmentType={equipmentType} />;
};
export default EquipmentType;
