import React from 'react';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { FormHeader } from '../../../components/form/FormHeader';
import { handleSubmitWithAfterSave, isDefined } from '../../../services/utils';
import { Footer } from './Footer';
import { RelatedEntityPanel } from '../../../components/form/RelatedEntityPanel';
import { Overview } from './Overview';
import { DateAndTime } from './DateAndTime';
import { RelatedEquipment } from '../../../components/other/relatedEquipment';
import { API } from '../../../services/api';

const t = (key, params = {}) => I18n.t(`safe_work_authorization_measures.${key}`, params);

export const Form = props => {
  const {
    swaId,
    setFieldValue,
    handleSubmit,
    swa_resource_name,
    values: { id, swaIdentifier, swaCompleted },
  } = props;

  return (
    <form>
      <FormHeader title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)} />
      <RelatedEntityPanel
        title={t('related_swa.title', { swa_resource_name })}
        identifier={swaIdentifier}
        viewPrompt={t('related_swa.view')}
        link={`/requests/safe_work_authorizations/${swaId}/edit`}
      />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.safe_work_authorization_measure',
          shouldUpdate: defaultShouldUpdate,
          disabled: swaCompleted,
          resourceName: I18n.t('resource_names.safe_work_authorization_measure'),
        }}
      >
        <Overview {...props} />
        <DateAndTime {...props} />
        {!swaCompleted && <Footer {...props} />}
        <RelatedEquipment
          empty={!id}
          api={API.requests.safeWorkAuthorizations.measures(swaId).equipment(id)}
          handleAdditionLinkOnClick={open =>
            handleSubmitWithAfterSave(setFieldValue, handleSubmit, open)
          }
          prepareValuesToSubmit={values => ({
            safe_work_authorization_measure_equipment: values,
          })}
        />
      </DefaultAttributesGroup>
    </form>
  );
};
