import React from 'react';
import { FieldWrapper } from './auxiliary/FieldWrapper';
import { fieldPropDefaultTransformation, fieldWrapperProps } from '../../services/fieldUtils';
import { TextInput } from '../inputs/TextInput';

export const TextField = props => {
  const {
    field: { name },
    disabled,
    placeholder,
    resourceName,
    id,
  } = props;
  const inputId = id || `${resourceName}-${name}`;
  return (
    <FieldWrapper {...fieldWrapperProps({ inputId, ...props })}>
      <TextInput
        {...fieldPropDefaultTransformation(props)}
        id={inputId}
        disabled={disabled}
        placeholder={placeholder}
      />
    </FieldWrapper>
  );
};
