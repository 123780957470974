import React from 'react';
import { passDefaultAttributesToChildren } from '../../services/utils';

export const FormRowWithoutField = ({
  children,
  className = '',
  style = {},
  defaultChildrenAttributes = false,
}) => (
  <div className={`pb-4 ${className}`} style={style}>
    {defaultChildrenAttributes
      ? passDefaultAttributesToChildren(children, defaultChildrenAttributes)
      : children}
  </div>
);
