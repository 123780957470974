import React, { useEffect, useState } from 'react';
import { API } from '../../services/api';
import { isDefined } from '../../services/utils';
import { Loader } from '../../components/Loader';
import { SpeciesForm } from './Form';

export const Species = props => {
  const { id } = props;

  const [fetching, setFetching] = useState(true);
  const [species, setSpecies] = useState({});
  useEffect(() => {
    (async () => {
      const { searchParams } = new URL(document.location);
      const api = isDefined(id)
        ? () => API.administration.species.show(id)
        : () =>
            API.administration.species.new({
              initial_biological_agent_type_id: searchParams.get(
                'initial_biological_agent_type_id',
              ),
            });
      const { ok, data } = await api();
      if (ok) {
        await setSpecies(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <SpeciesForm {...props} species={species} />;
};
export default Species;
