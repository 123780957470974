import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { FastField, Formik, useFormikContext } from 'formik';
import { fieldDisplayModes } from '../../constants/fieldDisplayModes';
import { TextField } from './TextField';
import { DateField } from './DateField';
import { DateTimeField } from './DateTimeField';
import { TimeField } from './TimeField';
import { NumericField } from './NumericField';
import { SelectorField } from './SelectorField';
import { TextAreaField } from './TextAreaField';
import { CheckBoxField } from './CheckBoxField';

// This funciton might seem redundant as a very similar one is defined in
// app/javascript/salute_portal/components/fields/auxiliary/renderCustomInput.jsx
// However, that one makes use of JsonField, which is defined in this file.
// This is a circular dependency, so we can't use that function here.
// Especially as rendering nested JsonFields is not to be supported.
const renderProperInput = variant => {
  switch (variant) {
    case 'text':
      return TextField;
    case 'date':
      return DateField;
    case 'date_and_time':
      return DateTimeField;
    case 'time':
      return TimeField;
    case 'numerical':
      return NumericField;
    case 'selector':
      return SelectorField;
    case 'text_area':
      return TextAreaField;
    case 'boolean':
      return CheckBoxField;
    default:
      return null;
  }
};

export const JsonField = props => {
  const {
    field: { name, value },
    form: { setFieldValue },
    config,
    resourceName,
    id,
  } = props;

  const UpdateValue = () => {
    const { values: localValues } = useFormikContext();
    useEffect(() => {
      if (!_.isEmpty(localValues)) {
        setFieldValue(name, localValues);
      }
    }, [localValues]);
    return null;
  };

  const initialValues = Object.fromEntries(
    config.map(field => [field.name, _.get(value, field.name, '')]),
  );

  const inputId = id || `${resourceName}-${name}`;

  return (
    <Formik initialValues={initialValues}>
      <Grid container>
        <Grid item xs={12}>
          {config?.map(field => (
            <FastField
              {...field}
              id={inputId}
              key={field.name}
              name={field.name}
              label={field.label}
              required={field.required}
              hint={field.hint}
              placeholder={field.placeholder}
              component={renderProperInput(field.type)}
              isClearable
              displayMode={fieldDisplayModes.WIDE_FORM_ROW}
            />
          ))}
        </Grid>
        <UpdateValue />
      </Grid>
    </Formik>
  );
};
