import React from 'react';
import { API } from '../../../services/api';
import { IndexTableDefaultHandler } from '../../../components/tables/IndexTableDefaultHandler';
import { handleSubmitWithAfterSave, isDefined } from '../../../services/utils';
import { DefaultLink } from '../../../components/links/DefaultLink';

const t = key => I18n.t(`inventory.locations.campuses.new_edit.tabs.spaces.${key}`);

export const AssociatedSpacesTab = ({
  isSubmitting,
  setFieldValue,
  handleSubmit,
  values: { id },
  locationsManageEnabled,
}) => (
  <IndexTableDefaultHandler
    api={API.locations.spaces.index}
    fixedFilters={{ with_campus_id: id || 0 }}
    idToHrefFunction={_id => `/inventory/locations/spaces/${_id}/edit`}
    manageColumnsModel="Lab"
    forceEmpty={!isDefined(id)}
    additionalLinks={[
      locationsManageEnabled && (
        <DefaultLink
          disabled={isSubmitting}
          onClick={async () => {
            const redirect = newId => () => {
              window.location.href = `/inventory/locations/spaces/new?campus_id=${newId}`;
            };
            const afterSave = newId => setTimeout(redirect(newId), 1000);
            await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
          }}
        >
          {t('link')}
        </DefaultLink>
      ),
    ]}
  />
);
