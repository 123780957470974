import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { FormSectionTitle } from '../../form/FormSectionTitle';
import { FormSection } from '../../form/FormSection';
import { FormSectionHeader } from '../../form/FormSectionHeader';
import { FormSectionHeaderLink } from '../../form/FormSectionHeaderLinks';
import { AsyncSimpleList } from '../../lists/AsyncSimpleList';
import { EmptyListPlaceholder } from '../../lists/EmptyListPlaceholder';
import { SimpleListDeletionLink, SimpleListLink } from '../../lists/SimpleListLinks';
import { showBackendErrorMessage, showSuccessMessage } from '../../../services/utils';
import { Modal } from '../../modals/Modal';
import { FieldDefinitionModalContent } from './modalForm';
import { getUuid } from '../../../../services/uuidHelper';

const t = (key, params) => I18n.t(`field_definitions_section.${key}`, params);

export const FieldDefinitions = props => {
  const {
    api,
    empty = false,
    disabled = false,
    changesDisabled = disabled,
    title = t('title'),
    resourceName: resource_name = t('resource_name'),
    handleAdditionLinkOnClick = open => open(),
    handleEditionLinkOnClick = handleAdditionLinkOnClick,
  } = props;
  const listRef = useRef(null);
  const addModalRef = useRef(null);
  const editModalRef = useRef(null);
  const [fetching, setFetching] = useState(false);
  const [newFieldDefinition, setNewFieldDefinition] = useState(null);
  const [fieldDefinition, setFieldDefinition] = useState(null);

  // Some async actions e.g. handleAdditionLinkOnClick might cause api value change as their
  // side effect.
  // That is why it is useful to keep api in a ref handle to be able to access its newest value.
  const apiRef = useRef(null);
  useEffect(() => {
    apiRef.current = api;
  }, [api]);

  const fetchAdditionalData = useCallback(async (apiMethod, callback) => {
    setFetching(true);
    const { ok, data } = await apiMethod();
    setFetching(false);
    if (ok) await callback(data.data.attributes);
    else showBackendErrorMessage(I18n.t('flash_message.generic_500_error_msg'));
    return ok;
  }, []);

  const remapDataUuid = useMemo(getUuid, [
    api,
    disabled,
    changesDisabled,
    resource_name,
    handleAdditionLinkOnClick,
    handleEditionLinkOnClick,
    fetching,
  ]);

  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{title}</FormSectionTitle>
          <FormSectionHeaderLink
            hidden={disabled}
            disabled={changesDisabled || fetching}
            onClick={() => {
              handleAdditionLinkOnClick(() =>
                // In some cases apiRef value is updated after the click, useTimeout ensures
                // the component will react to that change before running the callback.
                setTimeout(async () => {
                  const ok = await fetchAdditionalData(apiRef.current.new, setNewFieldDefinition);
                  if (ok) addModalRef.current.open();
                }),
              );
            }}
          >
            {t('add', { resource_name })}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        {empty ? (
          <EmptyListPlaceholder />
        ) : (
          <AsyncSimpleList
            ref={listRef}
            api={api.index}
            resourceName={I18n.t('resource_names.field_definition')}
            dataKey="data"
            remapDataUuid={remapDataUuid}
            isSortable
            reorderItemApi={api.reorder}
            mapData={({ attributes }) => [
              [t('fields.variant'), t(`fields.variants.${attributes.variant}`)],
              [t('fields.name'), attributes.name],
              [t('fields.placeholder'), attributes.placeholder],
              [t('fields.tooltip'), attributes.tooltip],
              <SimpleListLink
                key="edit"
                hidden={disabled}
                disabled={changesDisabled || fetching}
                onClick={async () => {
                  handleEditionLinkOnClick(() =>
                    setTimeout(async () => {
                      const ok = await fetchAdditionalData(
                        () => apiRef.current.show(attributes.id),
                        setFieldDefinition,
                      );
                      if (ok) editModalRef.current.open();
                    }),
                  );
                }}
              >
                {t('edit')}
              </SimpleListLink>,
              <SimpleListDeletionLink
                key="delete"
                hidden={disabled}
                disabled={changesDisabled || fetching}
                modelName={resource_name}
                onSubmit={async () => {
                  const { ok, data } = await api.destroy(attributes.id);
                  if (ok) {
                    listRef.current.refresh();
                    showSuccessMessage(t('flash.success.remove', { resource_name }));
                  } else {
                    showBackendErrorMessage(t('flash.error.remove', { resource_name }), data);
                  }
                }}
              >
                {t('remove')}
              </SimpleListDeletionLink>,
            ]}
          />
        )}
      </FormSection>
      <Modal title={t('modal.add_title', { resource_name })} ref={addModalRef}>
        {modalProps => (
          <FieldDefinitionModalContent
            {...modalProps}
            api={api}
            resource_name={resource_name}
            fieldDefinition={newFieldDefinition}
            listRef={listRef}
          />
        )}
      </Modal>
      <Modal title={t('modal.edit_title', { resource_name })} ref={editModalRef}>
        {modalProps => (
          <FieldDefinitionModalContent
            {...modalProps}
            api={api}
            resource_name={resource_name}
            fieldDefinition={fieldDefinition}
            listRef={listRef}
          />
        )}
      </Modal>
    </>
  );
};
