import React from 'react';
import { FastField, withFormik } from 'formik';
import { fieldDisplayModes } from '../../../../constants/fieldDisplayModes';
import { ModalSection } from '../../../../components/modals/ModalSection';
import { DefaultAttributesGroup } from '../../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../../services/fieldUtils';
import { API } from '../../../../services/api';
import { htmlOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../../components/fields/AsyncSelectorField';
import { FormFooter, FormFooterRightPart } from '../../../../components/form/FormFooter';
import { CancellationLink } from '../../../../components/links/CancellationLink';
import { BrandButton } from '../../../../components/buttons/BrandButton';
import { defaultHandleSubmit, produceDefaultValidation } from '../../../../services/utils';
import yup from '../../../../components/form/CustomYupValidator';

const t = (key, props = {}) =>
  I18n.t(`radiation_safety.rad_permits.modals.add_rad_inventory_item.${key}`, props);

const Form = props => {
  const { hide, isSubmitting, setFieldValue, handleSubmit, radPermitId } = props;
  return (
    <ModalSection>
      <form>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
            shouldUpdate: defaultShouldUpdate,
            resourceName: I18n.t('resource_names.rad_inventory_item'),
          }}
        >
          <FastField
            name="rad_inventory_id"
            label={t('rad_inventory_id')}
            required
            api={API.selectors.radInventories.index}
            apiParams={{ with_rad_permit_id: radPermitId }}
            selectionApi={API.selectors.radInventories.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
        </DefaultAttributesGroup>
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={hide} />
            <BrandButton
              onClick={async () => {
                await defaultHandleSubmit(setFieldValue, handleSubmit);
              }}
            >
              {t('save')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </form>
    </ModalSection>
  );
};

const validationSchema = yup.object().shape({
  rad_inventory_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});

export const AddRadInventoryItemModalContent = withFormik({
  mapPropsToValues: () => ({
    rad_inventory_id: null,
  }),
  handleSubmit: async (values, formikProps) => {
    const { hide, tableRef, radPermitId } = formikProps.props;
    window.location.href = `/radiation_safety/rad_inventories/${values.rad_inventory_id}/rad_inventory_items/new?rad_permit_id=${radPermitId}`;
    formikProps.resetForm();
    hide();
    tableRef.current.refresh();
  },
  validate: produceDefaultValidation(validationSchema),
})(Form);
