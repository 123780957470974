import React, { useCallback, useEffect, useState, useMemo } from 'react';
import kebabCase from 'lodash/kebabCase';
import styled from 'styled-components';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { EmptyListPlaceholder } from './EmptyListPlaceholder';
import { SimpleListTitle } from './SimpleListTItle';
import { SimpleListHeader } from './SimpleListHeader';
import { isDefined, showBackendErrorMessage, showSuccessMessage } from '../../services/utils';
import { SimpleListRow } from './SimpleListRow';
import { fontSize, fontWeights } from '../../assets/styles/typography';
import { SortableSimpleListRow } from './SortableSimpleListRow';

const maximumElementsCountForSortable = 100;

export const Cell = styled.div`
  margin-top: 0.75rem;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100px;
  padding-right: 10px;
  font-size: ${fontSize.textSm};
  overflow: hidden;
  word-break: break-word;
`;

export const CellValue = styled.div`
  font-weight: ${fontWeights.headings};
  font-size: 16px;
`;

const renderCellComponent = (listRowKeyValue, columnData) => {
  if (React.isValidElement(columnData)) {
    return React.cloneElement(columnData, { listRowKeyValue });
  }
  return columnData;
};

const mapRow = (rowHrefs, rowsSelectable, resourceName, isSortable) => (rowData, rowIndex) => {
  const { id, data } = rowData;
  const keyValue = `${resourceName || 'simple'}-list-row[${id}]`;
  const rowHref = rowsSelectable ? rowHrefs[rowIndex] : null;

  const mapRowData = () =>
    data.map((columnData, columnDataIndex) => {
      if (Array.isArray(columnData)) {
        const cellKeyValue = `${keyValue}-${kebabCase(columnData[0])}`;
        return (
          <Cell id={cellKeyValue} key={cellKeyValue}>
            <div>{columnData[0]}</div>
            <CellValue className={`${isDefined(columnData[1]) ? '' : 'text-muted'}`}>
              {isDefined(columnData[1]) ? columnData[1] : I18n.t('blank_value')}
            </CellValue>
          </Cell>
        );
      }
      if (typeof columnData === 'string') {
        return (
          <span id={`${keyValue}[${columnDataIndex}]`} key={columnData}>
            {columnData}
          </span>
        );
      }
      return renderCellComponent(keyValue, columnData);
    });

  if (isSortable) {
    return (
      <SortableSimpleListRow
        id={id}
        key={keyValue}
        keyValue={keyValue}
        href={rowHref}
        rowIndex={rowIndex}
      >
        {mapRowData()}
      </SortableSimpleListRow>
    );
  }

  return (
    <SimpleListRow key={keyValue} keyValue={keyValue} href={rowHref}>
      {mapRowData()}
    </SimpleListRow>
  );
};

export const SimpleList = ({
  title,
  rowHrefs,
  data,
  ids,
  resourceName,
  isSortable: isSortableProp,
  reorderItemApi,
  reorderItemApiAdditionalParams,
  onReorderItems = () => {},
}) => {
  // necessary for big collection - to avoid erroneous display of EmptyListPlaceholder
  const [ready, setReady] = useState(false);

  const elementsCount = useMemo(() => (Array.isArray(data) ? data.length : 0), [data]);
  const isSortable = useMemo(
    () => elementsCount < maximumElementsCountForSortable && isSortableProp,
    [isSortableProp, elementsCount],
  );

  const mapDataToItems = useCallback(
    () =>
      data.map((e, index) => {
        const id = `${ids && ids.length > 0 ? ids[index] : index}`;
        return { id, data: e };
      }),
    [data],
  );
  const [items, setItems] = useState([]);
  useEffect(() => {
    setReady(false);
    setItems(mapDataToItems());
    setReady(true);
  }, [data]);

  const empty = !items || items.length === 0;
  const rowsSelectable = !empty && rowHrefs && rowHrefs.length === items.length;
  const mappingFunc = mapRow(rowHrefs, rowsSelectable, resourceName, isSortable);

  const sensors = isSortable
    ? useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates,
        }),
      )
    : undefined;

  const reorderItems = async (initialItems, activeId, oldIndex, newIndex) => {
    setItems(arrayMove(items, oldIndex, newIndex));

    const { ok } = await reorderItemApi(activeId, {
      new_position: newIndex + 1,
      ...reorderItemApiAdditionalParams,
    });

    if (ok) {
      onReorderItems({ oldIndex, newIndex });
      showSuccessMessage(I18n.t('simple_list.success.reorder'));
    } else {
      setItems(initialItems);
      showBackendErrorMessage(I18n.t('simple_list.error.reorder'));
    }
  };

  const handleDragEnd = ({ active, over }) => {
    if (isSortable && reorderItemApi && active.id !== over.id) {
      const oldIndex = Array.prototype.findIndex.call(items, e => e.id === active.id);
      const newIndex = Array.prototype.findIndex.call(items, e => e.id === over.id);
      reorderItems(items, active.id, oldIndex, newIndex);
    }
  };

  const renderedItems = useMemo(() => items.map(mappingFunc), [items, mappingFunc.toString()]);
  const renderSortableList = () => (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <ul className="p-0 m-0">{renderedItems}</ul>
      </SortableContext>
    </DndContext>
  );
  const renderNonSortableList = () => <ul className="p-0 m-0">{renderedItems}</ul>;

  return (
    <>
      {title && (
        <SimpleListHeader>
          <SimpleListTitle>{title}</SimpleListTitle>
        </SimpleListHeader>
      )}
      <div>
        {ready && empty && <EmptyListPlaceholder />}
        {!empty && (isSortable ? renderSortableList() : renderNonSortableList())}
      </div>
    </>
  );
};
