import React from 'react';
import { FastField } from 'formik';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';
import { NumericField } from '../../../components/fields/NumericField';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { DefaultLink } from '../../../components/links/DefaultLink';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';

const t = key => I18n.t(`administration.waste_cafs.new_edit.${key}`);

export const Overview = props => {
  const {
    values: { id, waste_generator_id },
  } = props;

  return (
    <DefaultAttributesGroup
      defaultChildrenAttributes={{
        labelI18nKeyPrefix: 'activerecord.attributes.waste_caf',
        shouldUpdate: defaultShouldUpdate,
        resourceName: I18n.t('resource_names.waste_caf'),
      }}
    >
      <FormSection title={t('sections.overview')}>
        <FastField name="caf_name" required component={TextField} />
        <FastField
          name="waste_generator_id"
          api={API.selectors.wasteGenerators.index}
          selectionApi={API.selectors.wasteGenerators.show}
          optionRenderer={htmlOptionRenderer('text')}
          headerRenderer={defaultOptionRenderer('generator_full_name')}
          component={AsyncSelectorField}
          isClearable
          additionalContent={
            <DefaultLink
              hidden={!(id && waste_generator_id)}
              href={`/administration/waste_generators/${waste_generator_id}/edit`}
            >
              {t('links.waste_generator_link')}
            </DefaultLink>
          }
        />
        <FastField name="order_priority" required positiveOnly component={NumericField} />
        <FastField name="active" component={CheckBoxField} />
      </FormSection>
    </DefaultAttributesGroup>
  );
};
