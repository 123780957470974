import { withFormik } from 'formik';
import { Form } from './Form';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`event_types.new_edit.${key}`);

const EventTypeFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const eventType = EventTypeFunctions.prepareValuesToSubmit({ ...values });
    if (isDefined(eventType.id)) {
      EventTypeFunctions.update(eventType, formikProps, values);
    } else {
      EventTypeFunctions.create(eventType, formikProps, values);
    }
  },
  prepareValuesToSubmit: values => {
    values.incident_fields_config = values.incidentFieldsConfig;
    return EventTypeFunctions.applyBackendValuesWhitelist(values);
  },
  create: async (eventType, formikProps, { afterSave, maxPositionValue }) => {
    const { ok, data } = await API.administration.eventTypes.create({
      event_type: eventType,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/administration/event_types/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.administration.event_types.edit.title'));
      formikProps.resetForm({
        values: EventTypeFunctions.prepareInitialValues({
          maxPositionValue,
          ...data.data.attributes,
        }),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (eventType, formikProps, { afterSave, maxPositionValue }) => {
    const { ok, data } = await API.administration.eventTypes.update(eventType.id, {
      event_type: eventType,
    });
    if (ok) {
      formikProps.resetForm({
        values: EventTypeFunctions.prepareInitialValues({
          maxPositionValue,
          ...data.data.attributes,
        }),
      });
      afterSave(data.data.id);
      showSuccessMessage(t(`flash.success.save`));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t(`flash.error.save`), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: option => ({
    afterSave: () => {},
    maxPositionValue: option.maxPositionValue,
    incidentFieldsSectionsWithDueDateHint: option.incident_fields_sections_with_due_date_fields,
    incidentFieldsConfig: option.editable_incident_fields_config,
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'name',
    'is_default',
    'position',
    'active',
    'incident_fields_config',
  ],
});

export const EventTypeForm = withFormik({
  validate: produceDefaultValidation(validationSchema),
  mapPropsToValues: props =>
    EventTypeFunctions.prepareInitialValues({
      maxPositionValue: props.maxPositionValue,
      ...props.eventType,
    }),
  handleSubmit: EventTypeFunctions.handleSubmit,
})(Form);
