import { getUtcMoment, getUtcMomentIgnoringTime, momentFormatter } from '../dateFormatUtils';
import { isFlagTrue } from '../utils';
import { entriesToDictionary } from './entriesToDictionary';

const areTimesEqual = (first, second) => {
  const firstDate = getUtcMoment(first);
  const secondDate = getUtcMoment(second);
  return firstDate.format('LTS') === secondDate.format('LTS');
};

export const producePrepareEntries = entriesAttributesName => (values, additionalArgs = {}) => {
  const {
    start_date,
    end_date,
    weekdays,
    saturdays,
    sundays,
    start_time,
    end_time,
    [entriesAttributesName]: previousEntries = [],
  } = values;
  const {
    reset = false,
    // previousStartTime should be specified only if start_time was changed
    previousStartTime = null,
    // previousEndTime should be specified only if end_time was changed
    previousEndTime = null,
  } = additionalArgs;

  const result = [];
  const previousEntriesDictionary = entriesToDictionary(previousEntries);
  let currentMoment = getUtcMomentIgnoringTime(start_date, true);
  const endMoment = getUtcMomentIgnoringTime(end_date, true);
  const weekdaysCodes = [1, 2, 3, 4, 5];
  const saturdayCode = 6;
  const sundayCode = 7;

  while (currentMoment <= endMoment) {
    const dayCode = currentMoment.isoWeekday();
    if (
      (isFlagTrue(weekdays) && weekdaysCodes.includes(dayCode)) ||
      (isFlagTrue(saturdays) && dayCode === saturdayCode) ||
      (isFlagTrue(sundays) && dayCode === sundayCode)
    ) {
      const currentDate = momentFormatter.serverDate(currentMoment);
      let previousEntry = previousEntriesDictionary[currentDate];
      // Delete entries with handled dates from previousEntriesDictionary
      // Values remaining in the dictionary are going to be mark with a _destroy flag
      if (previousEntry) delete previousEntriesDictionary[currentDate];
      else previousEntry = {};

      let entry = {
        id: previousEntry.id,
        checked: true,
        date: currentDate,
        start_time,
        end_time,
        _destroy: false,
      };
      if (!reset) {
        entry = { ...entry, ...previousEntry, _destroy: false };
        const {
          start_time: previousEntryStartTime,
          end_time: previousEntryEndTime,
        } = previousEntry;
        // start_time should be updated only if user has not changed it manually
        if (previousStartTime && areTimesEqual(previousEntryStartTime, previousStartTime)) {
          entry = { ...entry, start_time };
        }
        // end_time should be updated only if user has not changed it manually
        if (previousEndTime && areTimesEqual(previousEntryEndTime, previousEndTime)) {
          entry = { ...entry, end_time };
        }
      }
      result.push({ ...entry });
    }
    currentMoment = currentMoment.add(1, 'days');
  }
  Object.entries(previousEntriesDictionary).forEach(([_, entryToDestroy]) => {
    result.push({ ...entryToDestroy, _destroy: true });
  });
  return result;
};
