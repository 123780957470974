import React from 'react';
import { handleSubmitWithAfterSave } from '../../../services/utils';
import { API } from '../../../services/api';
import { Locations } from '../../../components/other/locations/Locations';

const t = key => I18n.t(`training.employee_trainings.new_edit.${key}`);

export const EmployeeTrainingLocations = props => {
  const {
    isSubmitting,
    setFieldValue,
    handleSubmit,
    values: { id },
  } = props;

  return (
    <Locations
      api={API.employeeTrainings.employeeTrainingLocations}
      id={id}
      disabled={isSubmitting}
      title={t('lists.locations.title')}
      resourceName={I18n.t('resource_names.employee_training_location')}
      humanizedResourceName={I18n.t('activerecord.models.employee_training_location')}
      handleAdditionLinkOnClick={open =>
        handleSubmitWithAfterSave(setFieldValue, handleSubmit, open)
      }
      prepareValuesToSubmit={values => ({
        employee_training_location: values,
      })}
    />
  );
};
