import React from 'react';
import { FastField } from 'formik';
import { FormHeader } from '../../../components/form/FormHeader';
import { isDefined } from '../../../services/utils';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { Footer } from './Footer';
import { FilesField } from '../../../components/fields/FilesField';
import { TabsContainer } from '../../../components/other/tabs/TabsContainer';
import { Tab } from '../../../components/other/tabs/Tab';
import { AssociatedBuildingsTab } from './AssociatedBuildingsTab';
import { AssociatedFloorsTab } from './AssociatedFloorsTab';
import { AssociatedSpacesTab } from './AssociatedSpacesTab';
import { LinksField } from '../../../components/fields/LinksField';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';

const t = key => I18n.t(`inventory.locations.campuses.new_edit.${key}`);

export const Form = props => {
  const {
    values: { id, name },
    locationsManageEnabled,
    locationsEditEnabled,
  } = props;
  return (
    <form>
      <FormHeader
        title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)}
        investigable={isDefined(id) && { type: 'Campus', id, identifier: name }}
      />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.campus',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.campus'),
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField
            name="name"
            disabled={!locationsManageEnabled}
            required
            component={TextField}
          />
          <FastField name="address" disabled={!locationsManageEnabled} component={TextField} />
          <FastField name="city" disabled={!locationsManageEnabled} component={TextField} />
          <FastField name="state" disabled={!locationsManageEnabled} component={TextField} />
          <FastField name="zip_code" disabled={!locationsManageEnabled} component={TextField} />
          <FastField
            name="responsible_person_id"
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
            disabled={!locationsEditEnabled}
            isClearable
          />
          <FastField name="attachments" component={FilesField} disabled={!locationsEditEnabled} />
          <FastField name="links" component={LinksField} disabled={!locationsEditEnabled} />
        </FormSection>
      </DefaultAttributesGroup>
      {locationsEditEnabled && <Footer {...props} />}
      <TabsContainer>
        <Tab name="buildings" title={t('tabs.buildings.title')}>
          <AssociatedBuildingsTab {...props} />
        </Tab>
        <Tab name="floors" title={t('tabs.floors.title')}>
          <AssociatedFloorsTab {...props} />
        </Tab>
        <Tab name="spaces" title={t('tabs.spaces.title')}>
          <AssociatedSpacesTab {...props} />
        </Tab>
      </TabsContainer>
    </form>
  );
};
