import React from 'react';
import { FastField } from 'formik';
import { FormHeader } from '../../../components/form/FormHeader';
import { isDefined } from '../../../services/utils';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { DateTimeField } from '../../../components/fields/DateTimeField';
import { DateField } from '../../../components/fields/DateField';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { defaultOptionRenderer } from '../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncMultiSelectorField } from '../../../components/fields/AsyncMultiSelectorField';
import { LocationFields } from '../../../components/fields/compositeFields/LocationFields';
import { FilesField } from '../../../components/fields/FilesField';
import { Comments } from '../../../components/other/comments/Comments';
import { Footer } from './Footer';
import { WasteRequestContainersList } from './WasteRequestContainers/WasteRequestContainersList';
import { WasteCollectionCandidatesList } from './WasteCollectionCandidates/WasteCollectionCandidatesList';
import { LinksField } from '../../../components/fields/LinksField';

const t = (key, params = {}) => I18n.t(`requests.waste_requests.new_edit.${key}`, params);

export const Form = props => {
  const {
    values: { status, id, building_id },
    values,
    setFieldValue,
    resource_name,
  } = props;

  const isCompleted = status === 'completed';

  const afterBuildingChange = event => {
    if (values.waste_generator_id !== event.data.waste_generator_id) {
      setFieldValue('waste_generator_id', event.data.waste_generator_id);
    }
  };

  return (
    <form>
      <FormHeader
        title={t(`title.${isDefined(id) ? 'edit' : 'new'}`, { resource_name })}
        auditsLink={isDefined(id) ? `/requests/waste_requests/${id}/audits` : ''}
      />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          resourceName: I18n.t('resource_names.waste_request'),
          labelI18nKeyPrefix: 'activerecord.attributes.waste_request',
          shouldUpdate: defaultShouldUpdate,
          disabled: isCompleted,
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField name="identifier" disabled component={TextField} />
          <FastField name="requested_date" required component={DateTimeField} />
          <FastField
            name="creator_id"
            required
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
          <FastField
            name="responsible_person_id"
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
          <FastField
            name="also_notified_person_ids"
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncMultiSelectorField}
          />
          <FastField name="contact_number" component={TextField} />
          <FastField name="alternative_contact_number" component={TextField} />
          <LocationFields
            buildingSelectorAttributes={{ required: true }}
            afterBuildingChange={afterBuildingChange}
            {...props}
          />
          <FastField name="location_details" component={TextField} />
          <FastField
            name="waste_generator_id"
            required
            api={API.selectors.wasteGenerators.index}
            apiParams={{ building_id }}
            selectionApi={API.selectors.wasteGenerators.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('generator_full_name')}
            onChange={(event, onChange) => {
              onChange(event);
              if (building_id !== event.data.building_id) {
                setFieldValue('building_id', event.data.building_id);
              }
            }}
            component={AsyncSelectorField}
          />
          <FastField name="request_attachments" component={FilesField} />
          <FastField name="request_links" component={LinksField} />
        </FormSection>
        <FormSection title={t('sections.resolution')}>
          <FastField
            name="ehs_assignee_id"
            required
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
          <FastField hidden={isCompleted} name="due_date" component={DateField} />
        </FormSection>
        {id && (
          <FormSection title={t('sections.comments')}>
            <Comments api={API.requests.wasteRequests.comments(id)} disabled={false} />
          </FormSection>
        )}
      </DefaultAttributesGroup>
      <Footer {...props} />
      <WasteCollectionCandidatesList {...props} />
      <WasteRequestContainersList {...props} />
    </form>
  );
};
