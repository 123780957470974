import yup from '../../../../components/form/CustomYupValidator';

export const validationSchema = yup.object().shape({
  quantity: yup
    .number()
    .transform(value => (Number.isNaN(value) ? undefined : value))
    .typeError(I18n.t('validations.numeric'))
    .min(0, I18n.t('validations.greater_or_equal_than', { number: 0 }))
    .required(I18n.t('validations.required')),
  container_type_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});
