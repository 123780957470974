import React, { useRef } from 'react';
import get from 'lodash/get';
import { FormSection } from '../../../components/form/FormSection';
import { FormSectionHeader } from '../../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../../components/form/FormSectionTitle';
import { FormSectionHeaderLink } from '../../../components/form/FormSectionHeaderLinks';
import { AsyncSimpleList } from '../../../components/lists/AsyncSimpleList';
import { API } from '../../../services/api';
import { SimpleListDeletionLink } from '../../../components/lists/SimpleListLinks';
import {
  handleSubmitWithAfterSave,
  isDefined,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';
import { EmptyListPlaceholder } from '../../../components/lists/EmptyListPlaceholder';
import { dateFormatter } from '../../../services/dateFormatUtils';

const t = (key, params = {}) => I18n.t(`requests.safe_work_authorizations.new_edit.${key}`, params);

export const Measures = props => {
  const {
    isSubmitting,
    setFieldValue,
    handleSubmit,
    resource_name,
    values: { id, is_completed, start_date, end_date },
  } = props;
  const listRef = useRef(null);
  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{t('lists.measures.title')}</FormSectionTitle>
          <FormSectionHeaderLink
            disabled={isSubmitting}
            hidden={is_completed}
            onClick={async () => {
              const afterSave = newId => {
                setInitialFlashMessageForNextPage(t('flash.success.save', { resource_name }));
                window.location.href = `/requests/safe_work_authorizations/${newId}/safe_work_authorization_measures/new`;
              };
              await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
            }}
          >
            {t('lists.measures.add_measure')}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        {!isDefined(id) && <EmptyListPlaceholder />}
        {isDefined(id) && (
          <AsyncSimpleList
            ref={listRef}
            api={API.requests.safeWorkAuthorizations.measures(id).index}
            dataKey="data"
            mapRowHrefs={({ id: measureId }) =>
              `/requests/safe_work_authorizations/${id}/safe_work_authorization_measures/${measureId}/edit`
            }
            resourceName={I18n.t('resource_names.safe_work_authorization_measure')}
            mapData={({ attributes }) => [
              [t('lists.measures.measure_type'), attributes.measure_type],
              [
                t('lists.measures.start_date'),
                dateFormatter.date(
                  attributes.use_swa_dates_and_times ? start_date : attributes.start_date,
                ),
              ],
              [
                t('lists.measures.end_date'),
                dateFormatter.date(
                  attributes.use_swa_dates_and_times ? end_date : attributes.end_date,
                ),
              ],
              [
                t('lists.measures.responsible_person'),
                get(attributes, 'responsible_person.data.full_name'),
              ],
              [t('lists.measures.attachments_count'), attributes.attachments_count],
              [
                t('lists.measures.equipment_count'),
                attributes.safe_work_authorization_measure_equipments_count,
              ],
              <SimpleListDeletionLink
                key="delete"
                modelName={t('lists.measures.model_name')}
                hidden={is_completed}
                onSubmit={async () => {
                  const { ok, data } = await API.requests.safeWorkAuthorizations
                    .measures(id)
                    .destroy(attributes.id);
                  if (ok) {
                    listRef.current.refresh();
                    showSuccessMessage(t('lists.measures.delete.flash.success'));
                  } else {
                    showBackendErrorMessage(t('lists.measures.delete.flash.error'), data);
                  }
                }}
              >
                {t('lists.measures.delete.title')}
              </SimpleListDeletionLink>,
            ]}
          />
        )}
      </FormSection>
    </>
  );
};
