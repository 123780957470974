import React, { useRef } from 'react';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../components/form/FormFooter';
import { BrandButton } from '../../../components/buttons/BrandButton';
import { LightButton } from '../../../components/buttons/LightButton';
import {
  defaultHandleSubmit,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
  handleSubmitWithAfterSave,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { DeletionLink } from '../../../components/links/DeletionLink';
import { AddRadInventoryItemModalContent } from './AddRadInventoryItemModalContent';
import { Modal } from '../../../components/modals/Modal';

const t = key => I18n.t(`radiation_safety.rad_purchases.new_edit.${key}`);
const modalsTranslations = key =>
  I18n.t(`radiation_safety.rad_purchases.modals.create_rad_inventory_item.${key}`);

const modalSubtitle = identifier => (
  <span>
    <b>
      {modalsTranslations('rad_purchase')}
      {': '}
    </b>
    {identifier}
  </span>
);

export const Footer = props => {
  const {
    setFieldValue,
    handleSubmit,
    isSubmitting,
    currentUser,
    values: { id, rad_permit_id, identifier },
  } = props;
  const addRadInventoryItemModalRef = useRef();
  return (
    <>
      <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
        <FormFooterLeftPart>
          <DeletionLink
            withIcon
            hidden={!(id && (currentUser.app_admin || currentUser.org_admin))}
            modelName={I18n.t('activerecord.models.rad_purchase')}
            onSubmit={async () => {
              const { ok, data } = await API.radiationSafety.radPurchases.destroy(id);
              if (ok) {
                setInitialFlashMessageForNextPage(t('flash.success.remove'));
                window.location.href = `/radiation_safety/rad_purchases`;
              } else {
                showBackendErrorMessage(t('flash.error.remove'), data);
              }
            }}
          />
        </FormFooterLeftPart>
        <FormFooterRightPart>
          <LightButton
            hidden={!id}
            onClick={async () => {
              await setFieldValue('submitType', 'createSimilar');
              await defaultHandleSubmit(setFieldValue, handleSubmit);
            }}
          >
            {t('footer.create_similar')}
          </LightButton>
          <LightButton
            hidden={!id}
            onClick={async () => {
              const afterSave = () => {
                addRadInventoryItemModalRef.current.open();
              };
              await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
            }}
          >
            {t('footer.create_inventory_item')}
          </LightButton>
          <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
            {t('footer.save')}
          </BrandButton>
        </FormFooterRightPart>
      </FormFooter>
      <Modal
        title={modalsTranslations('title')}
        subTitle={modalSubtitle(identifier)}
        ref={addRadInventoryItemModalRef}
      >
        {modalProps => (
          <AddRadInventoryItemModalContent
            {...modalProps}
            radPermitId={rad_permit_id}
            radPurchaseId={id}
          />
        )}
      </Modal>
    </>
  );
};
