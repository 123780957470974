import yup from '../../../components/form/CustomYupValidator';
import { getUtcMomentIgnoringTime } from '../../../services/dateFormatUtils';

const t = key => I18n.t(`inventory.equipment.equipment.new_edit.validations.${key}`);

export const validationSchema = yup.object().shape({
  equipment_type_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  manufacturer: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  model: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  serial_no: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  date_installed: yup
    .string()
    .nullable()
    .test(
      'date_installed_cannot_be_later_than_date_removed',
      t('date_installed_cannot_be_later_than_date_removed'),
      // an arrow function cannot be used beneath because 'this' does not work with it
      // see https://github.com/jquense/yup#mixedtestname-string-message-string--function-test-function-schema
      function _(dateInstalled) {
        const dateRemoved = this.parent.date_removed;

        return dateInstalled && dateRemoved
          ? getUtcMomentIgnoringTime(dateInstalled).isSameOrBefore(
              getUtcMomentIgnoringTime(dateRemoved),
            )
          : true;
      },
    )
    .required(I18n.t('validations.required')),
  building_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  responsible_person1_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});
