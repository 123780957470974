import React, { useEffect, useState } from 'react';
import { API } from '../../../../services/api';
import { isDefined } from '../../../../services/utils';
import { Loader } from '../../../../components/Loader';
import { RadUserForm } from './Form/index';

export const RadUser = props => {
  const { id, radPermitId, listRef, hide } = props;
  const [fetching, setFetching] = useState(true);
  const [radUser, setRadUser] = useState({});

  useEffect(() => {
    (async () => {
      const apiBase = API.radiationSafety.radUsers(radPermitId);
      const api = isDefined(id) ? apiBase.show : apiBase.new;
      const { ok, data } = await api(id);
      if (ok) {
        await setRadUser(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <RadUserForm radPermitId={radPermitId} radUser={radUser} listRef={listRef} hide={hide} />;
};
