import React from 'react';
import { LinkBase } from './auxiliary/LinkBase';
import { fontSize } from '../../assets/styles/typography';

export const SmallLink = ({ onClick, href, children, hidden, style = {} }) => (
  <LinkBase
    onClick={onClick}
    hidden={hidden}
    href={href}
    style={{ fontSize: fontSize.textXsm, ...style }}
  >
    {children}
  </LinkBase>
);
