import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../assets/styles/grid';
import { passDefaultAttributesToChildren } from '../../services/utils';

const FooterContainer = styled.div`
  margin-top: 10px;
  padding-bottom: 20px;
  @media only screen and (max-width: ${breakpoints.small}) {
    flex-direction: column;
    & > * {
      flex-direction: column;
    }
  }
`;

const FooterLeftPartContainer = styled.div`
  & > * {
    margin-right: 0.5rem;
    margin-top: 1rem;
  }
  @media only screen and (max-width: ${breakpoints.small}) {
    & > * {
      margin-right: 0;
      width: 100%;
    }
  }
`;

const FooterRightPartContainer = styled.div`
  & > *:not(:first-child) {
    margin-left: 0.5rem;
  }
  & > * {
    margin-top: 10px;
  }
  @media only screen and (max-width: ${breakpoints.small}) {
    & > *:not(:first-child) {
      margin-left: 0;
    }
    & > * {
      width: 100%;
    }
  }
`;

// FormFooter's children are expected only to be of type FormFooterLeftPart
// or/and FormFooterRightPart
export const FormFooter = ({ children, defaultChildrenAttributes = false }) => (
  <FooterContainer className="d-flex flex-wrap justify-content-end align-items-center">
    {defaultChildrenAttributes
      ? passDefaultAttributesToChildren(children, defaultChildrenAttributes)
      : children}
  </FooterContainer>
);

export const FormFooterLeftPart = ({ children, defaultChildrenAttributes = false }) => (
  <FooterLeftPartContainer className="d-flex flex-wrap flex-grow-1 justify-content-start align-items-center">
    {defaultChildrenAttributes
      ? passDefaultAttributesToChildren(children, defaultChildrenAttributes)
      : children}
  </FooterLeftPartContainer>
);

export const FormFooterRightPart = ({ children, defaultChildrenAttributes = false }) => (
  <FooterRightPartContainer className="d-flex flex-wrap justify-content-end align-items-center">
    {defaultChildrenAttributes
      ? passDefaultAttributesToChildren(children, defaultChildrenAttributes)
      : children}
  </FooterRightPartContainer>
);
