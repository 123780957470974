import React from 'react';
import { FastField } from 'formik';
import { FormHeader } from '../../../components/form/FormHeader';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate, mapToSelectorOptions } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';
import { SelectorField } from '../../../components/fields/SelectorField';
import { FormFooter, FormFooterRightPart } from '../../../components/form/FormFooter';
import { LightButton } from '../../../components/buttons/LightButton';
import { defaultHandleSubmit } from '../../../services/utils';

const t = key => I18n.t(`settings.index.${key}`);

export const Form = props => {
  const {
    enums: { fixedTimeZone },
  } = props;

  const { setFieldValue, handleSubmit, values } = props;
  return (
    <form>
      <FormHeader title={t('title')} />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.user',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.user'),
        }}
      >
        <FormSection title={t('sections.time_zone')}>
          <FastField name="use_browser_time_zone" component={CheckBoxField} />
          <FastField
            name="fixed_time_zone"
            hidden={values.use_browser_time_zone}
            options={mapToSelectorOptions(fixedTimeZone, 'header', 'value')}
            component={SelectorField}
          />
          <FastField
            name="browserTimeZone"
            disabled
            label={I18n.t('activerecord.attributes.user.browser_time_zone')}
            hidden={!values.use_browser_time_zone}
            options={mapToSelectorOptions([{ value: values.browserTimeZone }], 'value', 'value')}
            component={SelectorField}
          />
        </FormSection>
      </DefaultAttributesGroup>
      <FormFooter>
        <FormFooterRightPart>
          <LightButton onClick={async () => defaultHandleSubmit(setFieldValue, handleSubmit)}>
            {t('footer.save')}
          </LightButton>
        </FormFooterRightPart>
      </FormFooter>
    </form>
  );
};
