import React from 'react';
import styled from 'styled-components';
import { FormSection } from '../../../../components/form/FormSection';
import { ComplianceTimelineTableRow } from './ComplianceTimelineTableRow';

const TableContainer = styled.div`
  width: 100%;
  overflow-x: clip;
`;

const Table = styled.table`
  margin: 0 auto;
  width: 100%;
`;

const t = (key, attributes = {}) =>
  I18n.t(`inventory.equipment.equipment.new_edit.${key}`, attributes);

export const ComplianceTimeline = props => {
  const complianceTimelineHeaderNames = [
    '',
    t('compliance_timeline.header.date_last_inspected'),
    t('compliance_timeline.header.frequency'),
    t('compliance_timeline.header.due_date'),
    '',
  ];

  return (
    <FormSection title={t('sections.compliance_timeline')}>
      <TableContainer>
        <Table className="border table table-default table-sm table-striped">
          <thead>
            <tr>
              {complianceTimelineHeaderNames.map(name => (
                <th>{name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <ComplianceTimelineTableRow inspectionType="internal" {...props} />
            <ComplianceTimelineTableRow inspectionType="external" {...props} />
          </tbody>
        </Table>
      </TableContainer>
    </FormSection>
  );
};
