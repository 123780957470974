import React from 'react';
import styled from 'styled-components';
import { fontSize, fontWeights } from '../../assets/styles/typography';
import { colors } from '../../../Style/colors';

export const Container = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100px;
`;

export const TitleContainer = styled.h3`
  font-size: ${fontSize.textLg};
  font-weight: ${fontWeights.headers};
  ${({ required }) =>
    required &&
    `&:after {
      content: " *";
      color: #e32;
    }`}
`;

export const FormSectionError = styled.span`
  color: ${colors.required};
  font-size: ${fontSize.textSm};
`;

export const FormSectionTitle = ({ children, required, error }) => (
  <Container>
    <TitleContainer required={required}>{children}</TitleContainer>
    {error && <FormSectionError>{error}</FormSectionError>}
  </Container>
);
