import React from 'react';
import { FormSection } from '../../../components/form/FormSection';
import { FormSectionHeader } from '../../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../../components/form/FormSectionTitle';
import { SimpleList } from '../../../components/lists/SimpleList';
import { FormSectionHeaderLink } from '../../../components/form/FormSectionHeaderLinks';
import { handleSubmitWithAfterSave } from '../../../services/utils';

const t = key => I18n.t(`administration.waste_generators.new_edit.lists.waste_cafs.${key}`);

export const WasteCafs = props => {
  const {
    values: { id, wasteCafs },
    isSubmitting,
    setFieldValue,
    handleSubmit,
  } = props;

  return (
    <FormSection>
      <FormSectionHeader>
        <FormSectionTitle>{t('title')}</FormSectionTitle>
        <FormSectionHeaderLink
          disabled={isSubmitting}
          onClick={async () => {
            const redirect = () => {
              window.location.href = `/administration/waste_cafs/new?waste_generator_id=${id}`;
            };
            const afterSave = () => setTimeout(redirect, 1000);
            await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
          }}
        >
          {t('add')}
        </FormSectionHeaderLink>
      </FormSectionHeader>
      <SimpleList
        rowHrefs={wasteCafs.map(waste_caf => waste_caf.path)}
        resourceName={I18n.t('resource_names.waste_caf')}
        data={wasteCafs.map(waste_caf => [
          [t('caf_name'), waste_caf.caf_name],
          [t('order_priority'), waste_caf.order_priority],
        ])}
      />
    </FormSection>
  );
};
