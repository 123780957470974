import React, { useRef, useState } from 'react';
import { FilterPopover } from './FilterPopover';
import { randomTagIdSuffix } from '../../../services/uuidHelper';

export const TableHeader = props => {
  const { children, isFilterable } = props;
  const popoverRef = useRef(null);
  const [headerId] = useState(`header${randomTagIdSuffix()}`);
  const onClick = () => {
    if (isFilterable) {
      popoverRef.current.toggle();
    }
  };
  return (
    <th id={headerId} className="sorting-table-header" onClick={onClick}>
      {children}
      {isFilterable && <FilterPopover ref={popoverRef} target={headerId} {...props} />}
    </th>
  );
};
