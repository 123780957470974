import { isEmpty } from 'lodash';
import { withFormik } from 'formik';
import { Form } from './Form';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { validationSchema } from './validationSchema';

const t = (key, params) => I18n.t(`field_definitions_section.${key}`, params);

export const FieldDefinitionsFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const fieldDefinition = FieldDefinitionsFunctions.prepareValuesToSubmit(values);
    if (!isDefined(fieldDefinition.id))
      FieldDefinitionsFunctions.create(fieldDefinition, formikProps);
    else FieldDefinitionsFunctions.update(fieldDefinition, formikProps);
  },
  create: async (values, formikProps) => {
    const { hide, listRef, api, resource_name } = formikProps.props;
    const { ok, data } = await api.create({ field_definition: values });
    if (ok) {
      formikProps.resetForm({ fieldDefinition: data.data.attributes });
      hide();
      listRef.current.refresh();
      showSuccessMessage(t('flash.success.save', { resource_name }));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save', { resource_name }), data);
    }
  },
  update: async (values, formikProps) => {
    const { hide, listRef, api, resource_name } = formikProps.props;
    const { ok, data } = await api.update(values.id, { field_definition: values });
    if (ok) {
      formikProps.resetForm({ fieldDefinition: data.data.attributes });
      hide();
      listRef.current.refresh();
      showSuccessMessage(t('flash.success.save', { resource_name }));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save', { resource_name }), data);
    }
  },
  joinSelectorValues: selectorValues => (isEmpty(selectorValues) ? null : selectorValues.join(';')),
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: {},
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'name',
    'position',
    'tooltip',
    'placeholder',
    'variant',
    'selector_values',
    'max_position_value',
  ],
  preprocessBackendValues: backendValues => ({
    ...backendValues,
    selector_values: FieldDefinitionsFunctions.joinSelectorValues(backendValues.selector_values),
  }),
});

export const FieldDefinitionModalContent = withFormik({
  validate: values => produceDefaultValidation(validationSchema)(values),
  mapPropsToValues: props => FieldDefinitionsFunctions.prepareInitialValues(props.fieldDefinition),
  handleSubmit: FieldDefinitionsFunctions.handleSubmit,
})(Form);
