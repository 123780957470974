import React from 'react';
import { FastField, withFormik } from 'formik';
import { get, isEmpty } from 'lodash';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import { ModalSection } from '../../../components/modals/ModalSection';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { FormFooter, FormFooterRightPart } from '../../../components/form/FormFooter';
import { CancellationLink } from '../../../components/links/CancellationLink';
import { BrandButton } from '../../../components/buttons/BrandButton';
import {
  defaultHandleSubmit,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';
import yup from '../../../components/form/CustomYupValidator';

const t = (key, props = {}) => I18n.t(`radiation_safety.rad_inventories.new_edit.${key}`, props);

const Form = props => {
  const { hide, isSubmitting, setFieldValue, handleSubmit, currentInventoryId, permitId } = props;
  return (
    <ModalSection>
      <div style={{ paddingBottom: '2rem' }}>
        {t('modals.move_to_another_inventory.move_multiple_hint')}
      </div>
      <form>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
            shouldUpdate: defaultShouldUpdate,
            resourceName: I18n.t('resource_names.rad_inventory'),
          }}
        >
          <FastField
            name="target_rad_inventory_id"
            label={t('modals.move_to_another_inventory.target_rad_inventory_id')}
            required
            api={API.selectors.radInventories.index}
            apiParams={{ without_id: currentInventoryId, with_rad_permit_id: permitId }}
            selectionApi={API.selectors.radInventories.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
        </DefaultAttributesGroup>
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={hide} />
            <BrandButton onClick={e => defaultHandleSubmit(setFieldValue, handleSubmit, e)}>
              {t('modals.move_to_another_inventory.move')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </form>
    </ModalSection>
  );
};

export const MoveMultipleItemsToAnotherInventoryModalContent = withFormik({
  mapPropsToValues: props => ({
    ids: props.ids,
    target_rad_inventory_id: null,
  }),
  validationSchema: yup.object().shape({
    target_rad_inventory_id: yup
      .string()
      .nullable()
      .required(I18n.t('validations.required')),
  }),
  handleSubmit: async (values, formikProps) => {
    const { hide, onSuccess, currentInventoryId } = formikProps.props;
    const api = API.radiationSafety.radInventoryItems(currentInventoryId);
    const { ok, data } = await api.moveMultipleToAnotherInventory(values);
    if (ok) {
      formikProps.resetForm();
      hide();
      onSuccess();
      showSuccessMessage(t('flash.success.move_to_another_inventory'));
    } else {
      formikProps.setSubmitting(false);
      let message = t('flash.error.move_to_another_inventory');
      const identifiers = get(data, 'erroneous_identifiers');
      if (!isEmpty(identifiers))
        message = (
          <div>
            <div>{message}</div>
            <div>{t('flash.error.erroneous_items', { identifiers: identifiers.slice(0, 5) })}</div>
          </div>
        );
      showBackendErrorMessage(message);
    }
  },
})(Form);
