import React from 'react';
import styled from 'styled-components';
import { LinkBase } from './auxiliary/LinkBase';
import { breakpoints } from '../../../Style/grid';

const Wrapper = styled.div`
  display: flex;
  padding: 0 10px;
  @media only screen and (max-width: ${breakpoints.small}) {
    justify-content: center;
  }
`;

export const CancellationLink = ({ onClick, hidden, href, style = {} }) => (
  <Wrapper>
    <LinkBase onClick={onClick} hidden={hidden} href={href} style={style}>
      {I18n.t('shared_modal.cancel')}
    </LinkBase>
  </Wrapper>
);
