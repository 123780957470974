import { entriesToDictionary } from './entriesToDictionary';

export const overwriteEntries = (previousEntries, newEntries) => {
  const previousEntriesDictionary = entriesToDictionary(previousEntries);
  const result = [];

  newEntries.forEach(newEntry => {
    const { checked, date, start_time, end_time } = newEntry;
    const { id } = previousEntriesDictionary[date] || {};
    delete previousEntriesDictionary[date];
    result.push({ id, checked, date, start_time, end_time });
  });
  Object.values(previousEntriesDictionary).forEach(previousEntry => {
    result.push({ ...previousEntry, _destroy: true });
  });
  return result;
};
