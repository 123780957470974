import React, { useEffect, useState } from 'react';
import { API } from '../../services/api';
import { isDefined } from '../../services/utils';
import { Loader } from '../../components/Loader';
import { GeneralRequestTypeForm } from './Form';

export const GeneralRequestType = props => {
  const { id } = props;

  const [fetching, setFetching] = useState(true);
  const [generalRequestType, setGeneralRequestType] = useState({});
  useEffect(() => {
    (async () => {
      const api = isDefined(id)
        ? API.administration.generalRequestTypes.show
        : API.administration.generalRequestTypes.new;
      const { ok, data } = await api(id);
      if (ok) {
        await setGeneralRequestType(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <GeneralRequestTypeForm {...props} generalRequestType={generalRequestType} />;
};
export default GeneralRequestType;
