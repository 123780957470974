import React from 'react';
import { FastField } from 'formik';
import { DefaultAttributesGroup } from '../../../../../components/form/DefaultAttributesGroup';
import { fieldDisplayModes } from '../../../../../constants/fieldDisplayModes';
import { ModalSection } from '../../../../../components/modals/ModalSection';
import { API } from '../../../../../services/api';
import { htmlOptionRenderer } from '../../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncSelectorField } from '../../../../../components/fields/AsyncSelectorField';
import { DateField } from '../../../../../components/fields/DateField';
import { CheckBoxField } from '../../../../../components/fields/CheckBoxField';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../../../components/form/FormFooter';
import { DeletionLink } from '../../../../../components/links/DeletionLink';
import {
  defaultHandleSubmit,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../../../services/utils';
import { CancellationLink } from '../../../../../components/links/CancellationLink';
import { BrandButton } from '../../../../../components/buttons/BrandButton';
import { FilesField } from '../../../../../components/fields/FilesField';
import { sortFilesZA } from '../../../../../services/fieldUtils';

const t = key => I18n.t(`radiation_safety.rad_permits.modals.rad_users.${key}`);

export const RadUserInnerForm = props => {
  const {
    values: { id },
    hide,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    listRef,
    radPermitId,
  } = props;
  return (
    <form>
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          displayMode: fieldDisplayModes.WIDE_FORM_ROW,
          labelI18nKeyPrefix: 'activerecord.attributes.rad_user',
          resourceName: I18n.t('resource_names.rad_user'),
        }}
      >
        <ModalSection>
          <FastField
            name="person_id"
            required
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            apiParams={{ without_rad_permit_id: radPermitId }}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
          <FastField
            name="rad_user_type_id"
            required
            api={API.selectors.radUserTypes.index}
            selectionApi={API.selectors.radUserTypes.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField name="approval_date" required component={DateField} />
          <FastField name="active" component={CheckBoxField} />
          <FastField
            name="attachments"
            disabled={false}
            component={FilesField}
            sortPredicate={sortFilesZA}
          />
          <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
            <FormFooterLeftPart>
              <DeletionLink
                withIcon
                hidden={!id}
                modelName={I18n.t('activerecord.models.rad_user')}
                onSubmit={async () => {
                  const { ok, data } = await API.radiationSafety.radUsers(radPermitId).destroy(id);
                  if (ok) {
                    setInitialFlashMessageForNextPage(t('flash.success.remove'));
                    hide();
                    listRef.current.refresh();
                  } else {
                    showBackendErrorMessage(t('flash.error.remove'), data);
                  }
                }}
              />
            </FormFooterLeftPart>
            <FormFooterRightPart>
              <CancellationLink onClick={hide} />
              <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
                {t('footer.save')}
              </BrandButton>
            </FormFooterRightPart>
          </FormFooter>
        </ModalSection>
      </DefaultAttributesGroup>
    </form>
  );
};
