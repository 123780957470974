import yup from '../../../components/form/CustomYupValidator';

export const validationSchema = yup.object().shape({
  caf_name: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  order_priority: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});
