import React, { useRef } from 'react';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../components/form/FormFooter';
import { DeletionLink } from '../../../components/links/DeletionLink';
import { API } from '../../../services/api';
import {
  defaultHandleSubmit,
  isDefined,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../services/utils';
import { BrandButton } from '../../../components/buttons/BrandButton';
import { LightButton } from '../../../components/buttons/LightButton';
import { Modal } from '../../../components/modals/Modal';
import { ConfirmationModalContent } from '../../../components/modals/ConfirmationModalContent';

const t = key => I18n.t(`radiation_safety.rad_inventory_items.new_edit.${key}`);

export const Footer = props => {
  const {
    isSubmitting,
    handleSubmit,
    setFieldValue,
    values: { id, radInventoryId, disposed },
  } = props;

  const disposalModalRef = useRef();
  const reopenModalRef = useRef();

  return (
    <>
      <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
        <FormFooterLeftPart>
          <DeletionLink
            withIcon
            hidden={!id}
            modelName={I18n.t('activerecord.models.rad_inventory_item')}
            onSubmit={async () => {
              const { ok, data } = await API.radiationSafety
                .radInventoryItems(radInventoryId)
                .destroy(id);
              if (ok) {
                setInitialFlashMessageForNextPage(t('flash.success.delete'));
                window.location.href = `/radiation_safety/rad_inventories/${radInventoryId}/edit`;
              } else {
                showBackendErrorMessage(t('flash.error.delete'), data);
              }
            }}
          />
        </FormFooterLeftPart>
        <FormFooterRightPart>
          <LightButton
            hidden={disposed}
            onClick={async () => {
              await setFieldValue('submitType', 'save');
              await defaultHandleSubmit(setFieldValue, handleSubmit);
            }}
          >
            {t('footer.save')}
          </LightButton>
          <BrandButton
            hidden={disposed || !isDefined(id)}
            onClick={() => disposalModalRef.current.open()}
          >
            {t('footer.dispose')}
          </BrandButton>
          <LightButton hidden={!disposed} onClick={() => reopenModalRef.current.open()}>
            {t('footer.reopen')}
          </LightButton>
        </FormFooterRightPart>
      </FormFooter>
      <Modal ref={disposalModalRef} title={t('modals.dispose.title')}>
        <ConfirmationModalContent
          modalRef={disposalModalRef}
          hint={t('modals.dispose.hint')}
          confirm={t('modals.dispose.confirm')}
          onConfirm={async () => {
            await setFieldValue('submitType', 'dispose');
            await defaultHandleSubmit(setFieldValue, handleSubmit);
          }}
        />
      </Modal>
      <Modal ref={reopenModalRef} title={t('modals.reopen.title')}>
        <ConfirmationModalContent
          modalRef={reopenModalRef}
          hint={t('modals.reopen.hint')}
          confirm={t('modals.reopen.confirm')}
          onConfirm={async () => {
            await setFieldValue('submitType', 'reopen');
            await defaultHandleSubmit(setFieldValue, handleSubmit);
          }}
        />
      </Modal>
    </>
  );
};
