import React from 'react';
import { Field, withFormik } from 'formik';
import { ModalSection } from '../../modals/ModalSection';
import { LocationFields } from '../../fields/compositeFields/LocationFields';
import { TextField } from '../../fields/TextField';
import { FormFooter, FormFooterRightPart } from '../../form/FormFooter';
import { CancellationLink } from '../../links/CancellationLink';
import { BrandButton } from '../../buttons/BrandButton';
import {
  defaultHandleSubmit,
  filterValues,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import { DefaultAttributesGroup } from '../../form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { validationSchema } from './validationSchema';

const t = (key, props = {}) => I18n.t(`locations_section.${key}`, props);

const defaultValues = props => {
  const {
    buildingSelectorAttributes = {},
    floorSelectorAttributes = {},
    spaceSelectorAttributes = {},
    locationDetailsInputAttributes = {},
  } = props;
  return {
    [buildingSelectorAttributes.name || 'building_id']: '',
    [floorSelectorAttributes.name || 'buildings_floor_id']: '',
    [spaceSelectorAttributes.name || 'lab_id']: '',
    [locationDetailsInputAttributes.name || 'location_details']: '',
  };
};

const Form = props => {
  const {
    hide,
    isSubmitting,
    setFieldValue,
    handleSubmit,
    locationFieldsApiParams,
    resourceName,
    buildingSelectorAttributes = { required: true },
    floorSelectorAttributes = {},
    spaceSelectorAttributes = {},
    locationDetailsInputAttributes = {},
  } = props;
  return (
    <ModalSection>
      <form>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
            labelI18nKeyPrefix: 'locations_section',
            shouldUpdate: defaultShouldUpdate,
            resourceName,
          }}
        >
          <LocationFields
            {...props}
            apiParams={locationFieldsApiParams}
            buildingSelectorAttributes={buildingSelectorAttributes}
            floorSelectorAttributes={floorSelectorAttributes}
            spaceSelectorAttributes={spaceSelectorAttributes}
          />
          <Field
            name="location_details"
            {...locationDetailsInputAttributes}
            component={TextField}
            displayMode={fieldDisplayModes.WIDE_FORM_ROW}
          />
        </DefaultAttributesGroup>
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={hide} />
            <BrandButton onClick={e => defaultHandleSubmit(setFieldValue, handleSubmit, e)}>
              {t('add_modal.save')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </form>
    </ModalSection>
  );
};

export const AddLocationModalContent = withFormik({
  mapPropsToValues: props => defaultValues(props),
  handleSubmit: async (values, formikProps) => {
    const {
      api,
      id,
      hide,
      humanizedResourceName,
      prepareValuesToSubmit,
      listRef,
    } = formikProps.props;
    const { ok, data } = await api(id).create(
      prepareValuesToSubmit(filterValues(values, Object.keys(defaultValues(formikProps.props)))),
    );
    if (ok) {
      formikProps.resetForm();
      hide();
      listRef.current.refresh();
      showSuccessMessage(t('flash.success.add', { resource_name: humanizedResourceName }));
    } else {
      // Show error received from api
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.add', { resource_name: humanizedResourceName }), data);
    }
  },
  validate: (values, props) => produceDefaultValidation(validationSchema(props))(values),
})(Form);
