import React from 'react';
import { StyledParagraph } from '../styles';
import { isDefined } from '../../../../../../services/utils';

export const ConditionsExplanation = ({
  questionName,
  visibilityConditions,
  logicalOperatorType,
}) => {
  const getLeaderAnswer = condition => {
    if (condition.answer_type === 'yes_no_na') {
      const yesNoNaAnswersDict = {
        '1': I18n.t('true'),
        '2': I18n.t('false'),
        '3': I18n.t('blank_value'),
      };
      return yesNoNaAnswersDict[condition.leader_expected_answer];
    }
    return condition.leader_expected_answer;
  };
  const sanitizeQuestion = question => (isDefined(question) ? question : '(Choose the Question)');
  const sanitizeAnswer = answer => (isDefined(answer) ? answer : '(Choose the Answer)');

  return (
    visibilityConditions.length > 0 && (
      <StyledParagraph>
        <>
          <b>{questionName}</b>
          {' will be shown only if '}
          {visibilityConditions.map((condition, index) => (
            <>
              {index !== 0 ? logicalOperatorType.toUpperCase() : ''}
              {' the answer to '}
              <b>{sanitizeQuestion(condition.leader_finding_definition_name)}</b>
              {' is '}
              <b>
                <i>{sanitizeAnswer(getLeaderAnswer(condition))}</i>
              </b>
              {index === visibilityConditions.length - 1 ? '.' : ' '}
            </>
          ))}
        </>
      </StyledParagraph>
    )
  );
};
