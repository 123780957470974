import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { lmsSalutePortalApi } from '../../../services/lmsApi';
import { Loader } from '../../../components/Loader';
import { API } from '../../../services/api';
import { EmployeeTrainingNeedsList } from './EmployeeTrainingNeedsList';
import {
  determineOrganizationPrefix,
  fetchLmsToken,
  initializeLmsApiIntegration,
} from '../LmsApiUtils';
import { isDefined } from '../../../services/utils';

export const EmployeeTrainingNeeds = props => {
  const { personId, lmsTrainingsApiConfig, organizationTrainingTypes } = props;
  const [fetching, setFetching] = useState(true);
  const [personLMSDetails, setPersonLMSDetails] = useState(null);
  const [employeeTrainingNeeds, setEmployeeTrainingNeeds] = useState({});
  const [trainingTypes, setTrainingTypes] = useState({});
  const cookies = new Cookies();

  const parseTrainingNeedsFromLMSApi = trainingNeedsData =>
    trainingNeedsData.map(training => ({
      id: training.id,
      name: training.fullname,
      completionDate: training.timecompleted ? new Date(training.timecompleted * 1000) : null,
      completed: !!training.timecompleted,
    }));

  const parseTrainingTypesFromLMSApi = personLMSData => {
    const { checkboxOrgPrefix } = lmsTrainingsApiConfig;
    if (!isDefined(checkboxOrgPrefix)) {
      return [];
    }

    const result = personLMSData.customfields.map((trainingType, index) => {
      if (
        trainingType.type === 'checkbox' &&
        determineOrganizationPrefix(trainingType.shortname) === checkboxOrgPrefix
      ) {
        return {
          id: index,
          name: trainingType.name,
          value: trainingType.value,
          shortName: trainingType.shortname,
        };
      }
      return null;
    });
    return result.filter(trainingType => !!trainingType);
  };

  const parseTrainingNeedsFromSaluteApi = trainingNeedsData =>
    trainingNeedsData.map(training => ({
      id: training.id,
      name: training.attributes.trainingName,
      last_training_at: training.attributes.lastTrainingAt,
      expires_at: training.attributes.expiresAt,
      trainingType: training.attributes.trainingType.attributes,
    }));

  const recallLmsApi = async () => {
    await fetchLmsToken();
    // eslint-disable-next-line no-use-before-define
    await callLmsApi(false);
  };

  const callLmsApi = async (firstConnection = true) => {
    const { baseUrl } = lmsTrainingsApiConfig;

    await initializeLmsApiIntegration();
    const lmsApiToken = cookies.get('lms_api_token');

    const { ok: personLmsOk, data: personLmsData } = await lmsSalutePortalApi(
      baseUrl,
      lmsApiToken,
    ).userDetails.indexBySaluteId(personId);

    if (personLmsOk && !personLmsData.errorcode) {
      const { ok: trainingNeedsOk, data: trainingNeedsData } = await lmsSalutePortalApi(
        baseUrl,
        lmsApiToken,
      ).trainings.index(personLmsData[0].id);

      if (trainingNeedsOk && !trainingNeedsData.errorcode) {
        setPersonLMSDetails(personLmsData[0]);
        setTrainingTypes(parseTrainingTypesFromLMSApi(personLmsData[0]));
        setEmployeeTrainingNeeds(parseTrainingNeedsFromLMSApi(trainingNeedsData));
        setFetching(false);
      } else if (firstConnection) await recallLmsApi();
    } else if (firstConnection) await recallLmsApi();
  };

  const callSaluteApi = async () => {
    const { ok, data } = await API.people.employeeTrainingNeeds(personId).show();

    if (ok) {
      setEmployeeTrainingNeeds(parseTrainingNeedsFromSaluteApi(data.data));
      setTrainingTypes(organizationTrainingTypes);
      setFetching(false);
    }
  };

  const fetchEmployeeTrainingNeeds = async () => {
    if (lmsTrainingsApiConfig.enabled) {
      await callLmsApi();
    } else {
      await callSaluteApi();
    }
  };

  useEffect(() => {
    (async () => {
      await fetchEmployeeTrainingNeeds();
    })();
  }, []);

  if (fetching) {
    return <Loader />;
  }

  return (
    <EmployeeTrainingNeedsList
      personLMSDetails={personLMSDetails}
      employeeTrainingNeeds={employeeTrainingNeeds}
      trainingTypes={trainingTypes}
      fetchEmployeeTrainingNeeds={fetchEmployeeTrainingNeeds}
      {...props}
    />
  );
};
export default EmployeeTrainingNeeds;
