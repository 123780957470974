import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../../../assets/styles/colors';

const NewElementBtnContainer = ({ children }) => (
  <div className="w-100 row mx-0 mb-1">
    <div className="col-1" />
    <div className="col-8 row">
      <div className="col-lg-4 col-md-4 col-sm-3 col-xs-12" />
      <div className="col-lg-8 col-md-8 col-sm-9 col-xs-12 px-0">{children}</div>
    </div>
  </div>
);

const StyledNewElementBtn = styled.button`
  width: 100%;
  background: ${colors.grayBackgroundColor};
  color: ${colors.link};
  border-radius: 4px;
  border: none;
  padding: 10px;
  &:hover {
    background: ${colors.hoverGrayBackgroundColor};
  }
  &:focus {
    border: none;
    box-shadow: none;
    outline: 0;
  }
`;

export const NewChecklistElementBtn = ({ elementType, addElement, children }) => (
  <NewElementBtnContainer>
    <StyledNewElementBtn
      id={`${I18n.t('resource_names.inspection_type')}_new_${elementType}_btn`}
      type="button"
      onClick={addElement}
    >
      {children}
    </StyledNewElementBtn>
  </NewElementBtnContainer>
);
