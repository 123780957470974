import React from 'react';
import styled from 'styled-components';
import { isEmpty, compact } from 'lodash';
import { FastField, Formik } from 'formik';
import { MultiTextSelectorField } from '../fields/MultiTextSelectorField';
import { fieldDisplayModes } from '../../constants/fieldDisplayModes';
import { colors } from '../../assets/styles/colors';
import { fontSize } from '../../assets/styles/typography';
import { SmallLink } from '../links/SmallLink';
import { DateRangeField } from '../fields/DateRangeField';
import { MultiSelectorField } from '../fields/MultiSelectorField';
import { AsyncMultiSelectorField } from '../fields/AsyncMultiSelectorField';
import { asyncSelectorDefaultConfig } from '../../services/asyncSelectorDefaultConfig';
import { isDefined } from '../../services/utils';

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.5rem;
`;

const ApplyChangesButton = styled.button`
  color: ${colors.white};
  border: 1px solid ${colors.success};
  background: ${colors.success};
  border-radius: 4px;
  font-size: ${fontSize.textXsm};
  cursor: pointer;
  margin-left: 10px;
  &:hover {
    background: ${colors.successHover};
  }
  &:focus {
    outline: none;
  }
`;

const getInitialFilterValue = type => {
  if (type === 'date') {
    return '';
  }
  if (type === 'datetime') {
    return '';
  }
  return [];
};

const getFilterInputConfig = type => {
  if (type === 'text')
    return {
      component: MultiTextSelectorField,
    };
  if (type === 'date')
    return {
      component: DateRangeField,
    };
  if (type === 'datetime')
    return {
      component: DateRangeField,
    };
  if (type === 'boolean')
    return {
      component: MultiSelectorField,
      options: [
        { value: true, data: 'Yes' },
        { value: false, data: 'No' },
      ],
    };
  return {
    component: AsyncMultiSelectorField,
    ...asyncSelectorDefaultConfig(type),
  };
};

export const FilterPopoverForm = props => {
  const { toggle, fieldName, onFiltersChange, initialValue, filterInputType } = props;
  return (
    <Formik
      initialValues={{
        filter: initialValue || getInitialFilterValue(filterInputType),
        submitType: 'apply',
      }}
      onSubmit={values => {
        if (values.submitType === 'apply') {
          onFiltersChange(fieldName, values.filter);
        } else {
          onFiltersChange(fieldName, null);
        }
        toggle();
      }}
    >
      {formik => {
        const {
          setFieldValue,
          submitForm,
          values: { filter },
        } = formik;
        return (
          <form>
            <FastField
              name="filter"
              displayMode={fieldDisplayModes.NO_WRAPPER}
              {...formik}
              {...getFilterInputConfig(filterInputType)}
            />
            <Footer>
              <SmallLink
                onClick={async () => {
                  await setFieldValue('submitType', 'clear');
                  submitForm();
                }}
              >
                {I18n.t('helpers.filters.clear')}
              </SmallLink>
              <ApplyChangesButton
                type="submit"
                onClick={async e => {
                  e.preventDefault();
                  const submitType =
                    !isDefined(filter) || isEmpty(compact(filter)) ? 'clear' : 'apply';
                  await setFieldValue('submitType', submitType);
                  submitForm();
                }}
              >
                {I18n.t('helpers.filters.apply')}
              </ApplyChangesButton>
            </Footer>
          </form>
        );
      }}
    </Formik>
  );
};
