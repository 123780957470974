import { withFormik } from 'formik';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { Form } from './Form';
import { API } from '../../../services/api';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`construction_projects.new_edit.${key}`);

const ConstructionProjectFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const constructionProject = ConstructionProjectFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(constructionProject.id)) {
      ConstructionProjectFunctions.create(constructionProject, formikProps, values.afterSave);
    } else {
      ConstructionProjectFunctions.update(constructionProject, formikProps, values.afterSave);
    }
  },
  create: async (constructionProject, formikProps, afterSave) => {
    const { ok, data } = await API.constructionProjects.create({
      construction_project: constructionProject,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/construction_projects/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.construction_projects.edit.title'));
      formikProps.resetForm({
        values: ConstructionProjectFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (constructionProject, formikProps, afterSave) => {
    const { ok, data } = await API.constructionProjects.update(constructionProject.id, {
      construction_project: constructionProject,
    });
    if (ok) {
      formikProps.resetForm({
        values: ConstructionProjectFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: {
    afterSave: () => {},
  },
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'identifier',
    'status',
    'project_name',
    'project_type',
    'project_manager_id',
    'budget',
    'project_schedule',
    'start_date',
    'end_date',
    'building_id',
    'buildings_floor_id',
    'lab_id',
    'department_id',
    'project_scope',
    'comment',
    'project_forecast',
    'attachments',
    'links',
    'architect',
    'engineer',
    'general_contractor',
    'construction_supt',
    'construction_project_milestones_attributes',
  ],
  // avoid default identifier handling
  preprocessBackendValues: v => v,
});

export const ConstructionProjectForm = withFormik({
  mapPropsToValues: props =>
    ConstructionProjectFunctions.prepareInitialValues(props.constructionProject),
  handleSubmit: ConstructionProjectFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
