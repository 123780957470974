import React, { useRef, useState } from 'react';
import { FormSection } from '../../../components/form/FormSection';
import { FormSectionHeader } from '../../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../../components/form/FormSectionTitle';
import {
  handleSubmitWithAfterSave,
  isDefined,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';
import { EmptyListPlaceholder } from '../../../components/lists/EmptyListPlaceholder';
import { API } from '../../../services/api';
import { AsyncSimpleList } from '../../../components/lists/AsyncSimpleList';
import { AttendeeModalContent } from './Attendee';
import { Modal } from '../../../components/modals/Modal';
import { FormSectionHeaderLink } from '../../../components/form/FormSectionHeaderLinks';
import { SimpleListDeletionLink } from '../../../components/lists/SimpleListLinks';

const t = key => I18n.t(`training.employee_trainings.new_edit.${key}`);

export const Attendees = props => {
  const {
    isSubmitting,
    setFieldValue,
    handleSubmit,
    currentUser,
    values: { id },
  } = props;
  const modalRef = useRef(null);
  const listRef = useRef(null);
  const [modalTitle, setModalTitle] = useState('');

  const openModal = async () => {
    const afterSave = async () => {
      await setModalTitle(t(`modals.attendee.title.new`));
      await modalRef.current.open();
    };
    await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
  };

  const exportAttendees = employeeTrainingId => {
    window.open(API.employeeTrainings.employeeTrainings.attendees(employeeTrainingId), '_blank');
  };

  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{t('lists.attendees.title')}</FormSectionTitle>
          <FormSectionHeaderLink onClick={() => exportAttendees(id)} type="excel_export">
            {t('lists.attendees.report_send')}
          </FormSectionHeaderLink>
          <FormSectionHeaderLink disabled={isSubmitting} onClick={() => openModal()}>
            {t('lists.attendees.add_attendee')}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        {!isDefined(id) && <EmptyListPlaceholder />}
        {isDefined(id) && (
          <AsyncSimpleList
            ref={listRef}
            api={API.employeeTrainings.employeeTrainingPeople(id).index}
            dataKey="data"
            resourceName={I18n.t('resource_names.employee_training_person')}
            mapRowHrefs={({ attributes }) =>
              `/inventory/people/people/${attributes.attendee.data.id}/edit`
            }
            mapData={({ attributes }) => [
              ['', attributes.attendee.data.full_name],
              [t('lists.attendees.email'), attributes.attendee.data.email],
              [t('lists.attendees.job_title'), attributes.attendee.data.job_title],
              [t('lists.attendees.department'), attributes.attendee.data.department],
              <SimpleListDeletionLink
                key="delete"
                modelName={t('lists.attendees.model_name')}
                onSubmit={async () => {
                  const { ok, data } = await API.employeeTrainings
                    .employeeTrainingPeople(id)
                    .destroy(attributes.id);
                  if (ok) {
                    listRef.current.refresh();
                    showSuccessMessage(t('lists.attendees.delete.flash.success'));
                  } else {
                    showBackendErrorMessage(t('lists.attendees.delete.flash.error'), data);
                  }
                }}
              >
                {t('lists.attendees.delete.title')}
              </SimpleListDeletionLink>,
            ]}
          />
        )}
      </FormSection>
      <Modal title={modalTitle} ref={modalRef}>
        {modalProps => (
          <AttendeeModalContent
            employeeTrainingId={id}
            currentUser={currentUser}
            listRef={listRef}
            {...modalProps}
          />
        )}
      </Modal>
    </>
  );
};
