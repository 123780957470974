import React, { useRef, useState } from 'react';
import { API } from '../../../services/api';
import { FormSection } from '../../../components/form/FormSection';
import { Modal } from '../../../components/modals/Modal';
import { IndexTableDefaultHandler } from '../../../components/tables/IndexTableDefaultHandler';
import { handleSubmitWithAfterSave, isDefined } from '../../../services/utils';
import { DefaultLink } from '../../../components/links/DefaultLink';
import { AddRadLicenseItemModalContent } from './RadLicenseItems';

const t = key => I18n.t(`radiation_safety.rad_licenses.tabs.rad_license_items.${key}`);
const modalTranslation = key =>
  I18n.t(`radiation_safety.rad_licenses.modals.rad_license_items.${key}`);

export const RadLicenseItemsTable = props => {
  const {
    isSubmitting,
    setFieldValue,
    handleSubmit,
    values: { id: radLicenseId },
  } = props;

  const listRef = useRef(null);
  const addRadLicenseItemModalRef = useRef();
  const [radLicenseItem, setRadLicenseItem] = useState({});
  const [modalTitle, setModalTitle] = useState('');

  return (
    <>
      <FormSection>
        <IndexTableDefaultHandler
          title={t('title')}
          ref={listRef}
          api={API.radiationSafety.radLicenseItems(radLicenseId).index}
          fixedFilters={{ with_rad_license_id: radLicenseId }}
          onRowClick={async ({ id: radLicenseItemId }) => {
            const afterSave = async () => {
              const { ok, data } = await API.radiationSafety
                .radLicenseItems(radLicenseId)
                .show(radLicenseItemId);
              if (ok) {
                await setRadLicenseItem(data.data.attributes);
                await setModalTitle(modalTranslation('title.edit'));
                await addRadLicenseItemModalRef.current.open();
              }
            };
            await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
          }}
          manageColumnsModel="RadLicenseItem"
          forceEmpty={!isDefined(radLicenseId)}
          additionalLinks={[
            <DefaultLink
              disabled={isSubmitting}
              onClick={async () => {
                const afterSave = async newId => {
                  const { ok, data } = await API.radiationSafety.radLicenseItems(newId).new();
                  if (ok) {
                    await setRadLicenseItem(data.data.attributes);
                    await setModalTitle(modalTranslation('title.new'));
                    await addRadLicenseItemModalRef.current.open();
                  }
                };
                await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
              }}
            >
              {t('link')}
            </DefaultLink>,
          ]}
        />
      </FormSection>
      <Modal title={modalTitle} ref={addRadLicenseItemModalRef}>
        {modalProps => (
          <AddRadLicenseItemModalContent
            radLicenseId={radLicenseId}
            radLicenseItem={radLicenseItem}
            listRef={listRef}
            {...modalProps}
            {...props}
          />
        )}
      </Modal>
    </>
  );
};
