import React from 'react';
import { FieldHeader, StyledParagraph } from '../styles';

const t = key =>
  I18n.t(`administration.inspection_types.new_edit.checklist.question_details_modal.${key}`);

export const LogicalOperatorType = ({ onClick, logicalOperatorType }) => (
  <>
    <FieldHeader>{t('logical_operator')}</FieldHeader>
    <div className="checklist_questions_table mb-2">
      <div className="row row-yes-no row-no-margin">
        <div className="yes-no-container btn-group-toggle" data-toggle="buttons">
          {['and', 'or'].map(_logicalOperatorType => (
            <button
              id="visibility_condition_yes_operator_button"
              type="button"
              onClick={() => onClick(_logicalOperatorType)}
              className={`btn btn-sm yes-no-btn plain-btn ${
                logicalOperatorType === _logicalOperatorType ? 'active' : ''
              }`}
            >
              <input
                autoComplete="off"
                type="radio"
                value={_logicalOperatorType}
                checked={logicalOperatorType === _logicalOperatorType}
              />
              <span className="txt">{t(`logical_operator_types.${_logicalOperatorType}`)}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
    <StyledParagraph>{t(`${logicalOperatorType}_operator_explanation`)}</StyledParagraph>
  </>
);
