import React from 'react';
import { FastField, FieldArray } from 'formik';
import { DefaultAttributesGroup } from '../../../../components/form/DefaultAttributesGroup';
import { fieldDisplayModes } from '../../../../constants/fieldDisplayModes';
import { ModalSection } from '../../../../components/modals/ModalSection';
import { TextField } from '../../../../components/fields/TextField';
import { FormSectionTitle } from '../../../../components/form/FormSectionTitle';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../../components/form/FormFooter';
import { CancellationLink } from '../../../../components/links/CancellationLink';
import { BrandButton } from '../../../../components/buttons/BrandButton';
import {
  defaultHandleSubmit,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { DefaultLink } from '../../../../components/links/DefaultLink';
import { FullWidthFormRow } from '../../../../components/fields/auxiliary/fieldWrapperVariants/FullWidthFormRow';
import { API } from '../../../../services/api';
import { DeletionLink } from '../../../../components/links/DeletionLink';

const t = key => I18n.t(`hsds.emergency_contact_profiles.${key}`);
const translatedContactsAttributes = key =>
  I18n.t(`activerecord.attributes.emergency_contact.${key}`);
const CONTACTS_MIN_LIMIT = 1;
const CONTACTS_MAX_LIMIT = 5;

export const EmergencyContactProfileForm = props => {
  const {
    values: { id, emergencyContacts },
    hide,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    currentUser,
    errors,
  } = props;
  const enableToAddNewContact = _emergencyContacts => {
    let filledAllFields = true;
    _emergencyContacts.forEach(contact => {
      if (!(contact.contact_category && contact.contact_name && contact.contact_phone))
        filledAllFields = false;
    });
    return filledAllFields && _emergencyContacts.length < CONTACTS_MAX_LIMIT;
  };
  const userIsAdmin = currentUser.app_admin || currentUser.org_admin;
  return (
    <form>
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          displayMode: fieldDisplayModes.WIDE_FORM_ROW,
          labelI18nKeyPrefix: 'activerecord.attributes.emergency_contact_profile',
          resourceName: I18n.t('resource_names.emergency_contact_profile'),
        }}
      >
        <ModalSection>
          <FastField name="name" required component={TextField} />
          <FieldArray
            name="emergencyContacts"
            render={arrayHelpers => (
              <>
                {emergencyContacts.map((contact, index) => (
                  <>
                    <DefaultAttributesGroup
                      defaultChildrenAttributes={{
                        displayMode: fieldDisplayModes.WIDE_FORM_ROW,
                        required: true,
                        resourceName: I18n.t('resource_names.emergency_contact_profile'),
                      }}
                    >
                      <div className="ml-3">
                        <FormSectionTitle>
                          {`${t('emergency_contact')} ${index + 1}`}
                        </FormSectionTitle>
                      </div>
                      <FastField
                        label={translatedContactsAttributes('contact_category')}
                        name={`emergencyContacts.${index}.contact_category`}
                        component={TextField}
                        error={errors[`emergencyContacts[${index}].contact_category`]}
                      />
                      <FastField
                        label={translatedContactsAttributes('contact_name')}
                        name={`emergencyContacts.${index}.contact_name`}
                        component={TextField}
                        error={errors[`emergencyContacts[${index}].contact_name`]}
                      />
                      <FastField
                        label={translatedContactsAttributes('contact_phone')}
                        name={`emergencyContacts.${index}.contact_phone`}
                        component={TextField}
                        error={errors[`emergencyContacts[${index}].contact_phone`]}
                      />
                    </DefaultAttributesGroup>
                    <FullWidthFormRow>
                      <DeletionLink
                        withIcon
                        modal={false}
                        hidden={emergencyContacts.length <= CONTACTS_MIN_LIMIT}
                        onSubmit={() => {
                          arrayHelpers.remove(index);
                          showSuccessMessage(t('flash.success.remove_contact'));
                        }}
                      >
                        {t('remove_contact_link')}
                      </DeletionLink>
                    </FullWidthFormRow>
                  </>
                ))}
                {emergencyContacts.length === CONTACTS_MAX_LIMIT && (
                  <FullWidthFormRow>{t('contacts_limit_reached')}</FullWidthFormRow>
                )}
                {enableToAddNewContact(emergencyContacts) && (
                  <FullWidthFormRow>
                    <DefaultLink
                      onClick={() =>
                        arrayHelpers.push({
                          contact_category: '',
                          contact_name: '',
                          contact_phone: '',
                        })
                      }
                    >
                      {t('add_new_contact_link')}
                    </DefaultLink>
                  </FullWidthFormRow>
                )}
              </>
            )}
          />
          <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
            <FormFooterLeftPart>
              <DeletionLink
                withIcon
                hidden={!(id && userIsAdmin)}
                modelName={I18n.t('activerecord.models.emergency_contact_profile')}
                onSubmit={async () => {
                  const {
                    ok,
                    data,
                  } = await API.spaceRiskAssessments.emergencyContactProfiles.destroy(id);
                  if (ok) {
                    setInitialFlashMessageForNextPage(t('flash.success.remove'));
                    hide();
                    window.location.reload();
                  } else {
                    showBackendErrorMessage(t('flash.error.remove'), data);
                  }
                }}
              />
            </FormFooterLeftPart>
            <FormFooterRightPart>
              <CancellationLink onClick={hide} />
              <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
                {t('footer.save')}
              </BrandButton>
            </FormFooterRightPart>
          </FormFooter>
        </ModalSection>
      </DefaultAttributesGroup>
    </form>
  );
};
