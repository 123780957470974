import React from 'react';
import styled from 'styled-components';
import { FieldWrapper } from './auxiliary/FieldWrapper';
import { fontSize } from '../../assets/styles/typography';
import { colors } from '../../assets/styles/colors';
import { fieldPropDefaultTransformation, fieldWrapperProps } from '../../services/fieldUtils';

const Input = styled.input`
  font-size: ${fontSize.textMd};
  color: ${colors.inputTextColor};
  padding: 0 8px;
  height: 36px;
  background: ${props => (props.disabled ? colors.disabled : colors.white)};
  background-clip: padding-box;
  border: 1px solid ${colors.inputBorderColor};
  border-radius: 4px;
  &:focus {
    border: none;
    box-shadow: none;
    outline: 0;
  }
`;

export const CheckBoxField = props => {
  const {
    id,
    field: { name, value },
    disabled,
    resourceName,
  } = props;
  const inputId = id || `${resourceName}-${name}`;
  return (
    <FieldWrapper {...fieldWrapperProps({ inputId, ...props })}>
      <Input
        {...fieldPropDefaultTransformation(props)}
        type="checkbox"
        id={inputId}
        checked={String(value) === 'true'}
        disabled={disabled}
      />
    </FieldWrapper>
  );
};
