import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import styled from 'styled-components';
import { fontSize } from '../../../assets/styles/typography';
import { colors } from '../../../assets/styles/colors';
import { ModalSection } from '../../../components/modals/ModalSection';
import { InputFirstGrowingWidthLabel } from '../../../components/fields/auxiliary/fieldWrapperVariants/InputFirstGrowingWidthLabel';
import { FormFooter, FormFooterRightPart } from '../../../components/form/FormFooter';
import { CancellationLink } from '../../../components/links/CancellationLink';
import { BrandButton } from '../../../components/buttons/BrandButton';
import { Loader } from '../../../components/Loader';
import { lmsSalutePortalApi } from '../../../services/lmsApi';
import { showErrorMessage, showSuccessMessage } from '../../../services/utils';
import { API } from '../../../services/api';
import { determineOrganizationPrefix, fetchLmsToken } from '../LmsApiUtils';

const t = (key, params) =>
  I18n.t(
    `inventory.people.employee_training_needs.update_training_requirements_modal.${key}`,
    params,
  );

const TrainingTypeCheckbox = styled.input.attrs({ id: props => props.inputId })`
  font-size: ${fontSize.textMd};
  color: ${colors.inputTextColor};
  padding: 0 8px;
  height: 36px;
  background: ${colors.white};
  background-clip: padding-box;
  border: 1px solid ${colors.inputBorderColor};
  border-radius: 4px;
  &:focus {
    border: none;
    box-shadow: none;
    outline: 0;
  }
`;

const NotificationSection = styled.div`
  font-size: ${fontSize.textMd};
  color: ${colors.grayBackgroundColor};
  padding: 8px;
  background: ${colors.warning};
  background-clip: padding-box;
  margin-bottom: 16px;
`;

export const UpdateTrainingNeedsModal = props => {
  const {
    personLMSDetails,
    lmsTrainingsApiConfig,
    trainingTypes,
    employeeTrainingNeedTypeIds,
    hide,
    personId,
    personManagers,
    fetchEmployeeTrainingNeeds,
  } = props;
  const {
    enabled: lmsTrainingsApiEnabled,
    baseUrl: lmsTrainingsApiBaseUrl,
    checkboxOrgPrefix: lmsCheckboxOrgPrefix,
  } = lmsTrainingsApiConfig;
  const [checkboxStates, setCheckboxStates] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const cookies = new Cookies();

  const computeCheckboxValuesByTrainingNeeds = () =>
    trainingTypes.reduce((previousType, currentType) => {
      previousType[currentType.id] = employeeTrainingNeedTypeIds.includes(currentType.id);
      return previousType;
    }, {});

  const readCheckboxValuesFromLMSTrainingTypes = () => {
    const trueValue = '1';
    const result = [];
    trainingTypes.forEach(trainingType => {
      result[trainingType.id] = {
        value: trainingType.value === trueValue,
        shortName: trainingType.shortName,
      };
    });
    return result;
  };

  useEffect(() => {
    if (lmsTrainingsApiEnabled) {
      setCheckboxStates(readCheckboxValuesFromLMSTrainingTypes());
    } else {
      setCheckboxStates(computeCheckboxValuesByTrainingNeeds());
    }
    setLoading(false);
  }, []);

  const defineCheckboxLabel = (name, description) => (lmsTrainingsApiEnabled ? name : description);

  const onCheck = trainingTypeId => {
    if (lmsTrainingsApiEnabled) {
      const { value: currentValue, shortName } = checkboxStates[trainingTypeId];
      setCheckboxStates({
        ...checkboxStates,
        [trainingTypeId]: { value: !currentValue, shortName },
      });
    } else {
      setCheckboxStates({ ...checkboxStates, [trainingTypeId]: !checkboxStates[trainingTypeId] });
    }
  };

  const renderCheckbox = trainingType => {
    const checkboxValue = lmsTrainingsApiEnabled
      ? checkboxStates[trainingType.id].value
      : checkboxStates[trainingType.id];

    const checkboxProps = {
      name: 'trainingType',
      checked: checkboxValue,
      inputId: `training-type-${trainingType.id}`,
      type: 'checkbox',
      label: defineCheckboxLabel(trainingType.name, trainingType.description),
    };

    return (
      <InputFirstGrowingWidthLabel {...checkboxProps}>
        <TrainingTypeCheckbox {...checkboxProps} onChange={() => onCheck(trainingType.id)} />
      </InputFirstGrowingWidthLabel>
    );
  };

  const onSaveModalSuccess = () => {
    setSubmitting(false);
    hide();
    showSuccessMessage(t('flash.success.save'));
    fetchEmployeeTrainingNeeds();
  };

  const onSaveModalFailure = () => {
    setSubmitting(false);
    showErrorMessage(t('flash.error.save'));
  };

  const buildLMSPostRequestBody = () => {
    const result = new FormData();
    const trueValue = '1';
    const falseValue = '0';

    result.append('users[0][id]', personLMSDetails.id);

    Object.entries(checkboxStates).forEach(([checkboxId, checkboxEntity]) => {
      if (determineOrganizationPrefix(checkboxEntity.shortName) === lmsCheckboxOrgPrefix) {
        const valueKey = `users[0][customfields][${checkboxId}][value]`;
        const typeKey = `users[0][customfields][${checkboxId}][type]`;

        result.append(valueKey, checkboxEntity.value ? trueValue : falseValue);
        result.append(typeKey, checkboxEntity.shortName);
      }
    });

    return result;
  };

  const buildSalutePostRequestBody = () =>
    Object.keys(checkboxStates).reduce((previousValue, currentValue) => {
      if (checkboxStates[currentValue]) {
        previousValue.push(currentValue);
      }
      return previousValue;
    }, []);

  const callSaluteApi = async () => {
    const requestBody = buildSalutePostRequestBody();
    const { ok } = await API.people.employeeTrainingNeeds(personId).update(requestBody);

    if (ok) {
      onSaveModalSuccess();
    } else {
      onSaveModalFailure();
    }
  };

  const callLmsApi = async (firstConnection = true) => {
    const requestBody = buildLMSPostRequestBody();
    const { ok, data } = await lmsSalutePortalApi(
      lmsTrainingsApiBaseUrl,
      cookies.get('lms_api_token'),
    ).userDetails.update(requestBody);

    if (ok && !data?.errorCode) {
      onSaveModalSuccess();
    } else if (firstConnection) {
      await fetchLmsToken();
      await callLmsApi(false);
    } else {
      onSaveModalFailure();
    }
  };

  const onSaveModal = async () => {
    if (lmsTrainingsApiEnabled) {
      await callLmsApi();
    } else {
      await callSaluteApi();
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <ModalSection>
      {personManagers.length > 0 && (
        <NotificationSection>
          <p style={{ marginBottom: 0 }}>
            {t('notification.other_managers_present', {
              rosters_count: personManagers.length,
            })}
            <b>{personManagers.join(', ')}</b>
            <br />
            {t('notification.confirmation_info')}
          </p>
        </NotificationSection>
      )}
      {trainingTypes.map(renderCheckbox)}
      <FormFooter defaultChildrenAttributes={{ disabled: submitting }}>
        <FormFooterRightPart>
          <CancellationLink onClick={hide} />
          <BrandButton
            onClick={async () => {
              setSubmitting(true);
              await onSaveModal();
            }}
          >
            {t('save')}
          </BrandButton>
        </FormFooterRightPart>
      </FormFooter>
    </ModalSection>
  );
};
