import React, { useCallback, useState, useRef, useEffect } from 'react';
import { TrixEditor } from 'react-trix';
import { FieldWrapper } from './auxiliary/FieldWrapper';
import { fieldWrapperProps } from '../../services/fieldUtils';

export const RichTextField = props => {
  const {
    field: { name, value },
    form: { setFieldValue, setFieldTouched },
    disabled,
    placeholder,
    onChange,
  } = props;
  const wrapperRef = useRef(null);

  const [focused, setFocused] = useState(false);

  const defaultOnChange = useCallback(
    async newValue => {
      await setFieldValue(name, newValue);
      await setFieldTouched(name, true);
    },
    [setFieldValue, setFieldTouched, name],
  );
  const internalOnChange = useCallback(
    newValue => (onChange ? onChange(newValue, defaultOnChange) : defaultOnChange(newValue)),
    [onChange, defaultOnChange],
  );

  useEffect(() => {
    const toolbar = wrapperRef.current.getElementsByTagName('trix-toolbar')[0];
    toolbar.style.display = focused ? 'block' : 'none';
  }, [focused]);

  return (
    <FieldWrapper {...fieldWrapperProps(props)}>
      <div
        ref={wrapperRef}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
        className={`sp-portal-pack-trix-wrapper ${disabled ? 'trix-editor-disabled' : ''}`}
      >
        <TrixEditor value={value} placeholder={placeholder} onChange={internalOnChange} />
      </div>
    </FieldWrapper>
  );
};
