import React from 'react';
import { isDefined } from '../../../services/utils';
import { FormHeader } from '../../../components/form/FormHeader';
import { Footer } from './Footer';
import { Overview } from './Overview';
import { Buildings } from './Buildings';
import { WasteCafs } from './WasteCafs';

const t = key => I18n.t(`administration.waste_generators.new_edit.${key}`);

export const Form = props => {
  const {
    values: { id },
  } = props;

  return (
    <form>
      <FormHeader title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)} />
      <Overview {...props} />
      <Footer {...props} />
      <WasteCafs {...props} />
      <Buildings {...props} />
    </form>
  );
};
