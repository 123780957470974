import React, { useState } from 'react';
import styled from 'styled-components';
import { FastField } from 'formik';
import { ModalSection } from '../../../../../components/modals/ModalSection';
import { DefaultAttributesGroup } from '../../../../../components/form/DefaultAttributesGroup';
import { fieldDisplayModes } from '../../../../../constants/fieldDisplayModes';
import { API } from '../../../../../services/api';
import { defaultShouldUpdate } from '../../../../../services/fieldUtils';
import { AsyncSelectorField } from '../../../../../components/fields/AsyncSelectorField';
import { htmlOptionRenderer } from '../../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { FormFooter, FormFooterRightPart } from '../../../../../components/form/FormFooter';
import { DefaultLink } from '../../../../../components/links/DefaultLink';
import { BrandButton } from '../../../../../components/buttons/BrandButton';
import { isDefined } from '../../../../../services/utils';

export const StyledSubtitle = styled.p`
  margin-bottom: 2rem;
`;

const t = key =>
  I18n.t(`administration.inspection_types.new_edit.checklist.add_category_modal.${key}`);

export const NewCategoryModalContent = props => {
  const {
    arrayHelpers,
    hide,
    values: { inspectionFindingDefinitionsPerCategory },
  } = props;
  const [newCategory, setNewCategory] = useState({});

  const addCategory = () => {
    arrayHelpers.push(newCategory);
    hide();
  };

  return (
    <ModalSection>
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          displayMode: fieldDisplayModes.WIDE_FORM_ROW,
          resourceName: I18n.t('resource_names.inspection_type'),
          shouldUpdate: defaultShouldUpdate,
        }}
      >
        <StyledSubtitle>{t('subtitle')}</StyledSubtitle>
        <FastField
          name="new_category_section"
          label={t('category_section_field')}
          api={API.selectors.inspectionFindingCategories.index}
          apiParams={{
            without_ids: inspectionFindingDefinitionsPerCategory
              .map(category => category.category_id)
              .join(),
          }}
          optionRenderer={htmlOptionRenderer('text')}
          onChange={async (event, onChange) => {
            await onChange(event);
            await setNewCategory({
              category_id: event.data.id,
              category_name: event.data.category_name,
              category_description: event.data.category_description,
              inspection_finding_definitions_types: [
                {
                  id: '',
                  inspection_finding_definition_id: '',
                  question_order: '',
                },
              ],
            });
          }}
          component={AsyncSelectorField}
        />
      </DefaultAttributesGroup>
      <FormFooter>
        <FormFooterRightPart>
          <DefaultLink
            onClick={() => {
              hide();
            }}
          >
            {t('footer.cancel')}
          </DefaultLink>
          <BrandButton disabled={!isDefined(newCategory.category_id)} onClick={addCategory}>
            {t('footer.add_category')}
          </BrandButton>
        </FormFooterRightPart>
      </FormFooter>
    </ModalSection>
  );
};
