import React, { useEffect, useRef, useState } from 'react';
import { FormSection } from '../../../components/form/FormSection';
import { FormSectionHeader } from '../../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../../components/form/FormSectionTitle';
import { FormSectionHeaderLink } from '../../../components/form/FormSectionHeaderLinks';
import { AsyncSimpleList } from '../../../components/lists/AsyncSimpleList';
import { Modal } from '../../../components/modals/Modal';
import { SimpleListDeletionLink, SimpleListLink } from '../../../components/lists/SimpleListLinks';
import {
  handleSubmitWithAfterSave,
  isDefined,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';
import { EmptyListPlaceholder } from '../../../components/lists/EmptyListPlaceholder';
import { RadUser } from './RadUser/index';
import { dateFormatter } from '../../../services/dateFormatUtils';
import { getUuid } from '../../../../services/uuidHelper';
import { API } from '../../../services/api';

const t = key => I18n.t(`radiation_safety.rad_permits.lists.rad_users.${key}`);
const modalTranslation = key => I18n.t(`radiation_safety.rad_permits.modals.rad_users.${key}`);

// every time the form stops being submitted, we should refresh the list of Rad Users
// as a new one might have been created
const useRadUsersTableKey = isSubmitting => {
  const isSubmittingRef = useRef(isSubmitting);
  const [tableKey, setTableKey] = useState('initial-key');
  useEffect(() => {
    if (isSubmittingRef.current !== isSubmitting && !isSubmitting) setTableKey(getUuid());
    isSubmittingRef.current = isSubmitting;
  }, [isSubmitting]);
  return tableKey;
};

export const RadUsers = props => {
  const {
    isSubmitting,
    handleSubmit,
    setFieldValue,
    values: { id: radPermitId },
  } = props;
  const listRef = useRef(null);
  const radUserModalRef = useRef(null);
  const [radUserId, setRadUserId] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const radUsersTableKey = useRadUsersTableKey(isSubmitting);

  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{t('title')}</FormSectionTitle>
          <FormSectionHeaderLink
            disabled={isSubmitting}
            onClick={async () => {
              await setModalTitle(modalTranslation('title.new'));
              await setRadUserId(null);
              await handleSubmitWithAfterSave(
                setFieldValue,
                handleSubmit,
                radUserModalRef.current.open,
              );
            }}
          >
            {t('add_rad_user')}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        {!isDefined(radPermitId) && <EmptyListPlaceholder />}
        {isDefined(radPermitId) && (
          <AsyncSimpleList
            key={radUsersTableKey}
            api={API.radiationSafety.radUsers(radPermitId).index}
            ref={listRef}
            dataKey="data"
            resourceName={I18n.t('resource_names.rad_user')}
            mapData={({ attributes }) => [
              [t('person_name'), attributes.person.full_name],
              [t('rad_user_type'), attributes.rad_user_type.name],
              [t('approval_date'), dateFormatter.date(attributes.approval_date)],
              [t('status'), attributes.active ? t('statuses.true') : t('statuses.false')],
              <SimpleListLink
                key="edit"
                disabled={isSubmitting}
                onClick={async () => {
                  setModalTitle(modalTranslation('title.edit'));
                  setRadUserId(attributes.id);
                  await handleSubmitWithAfterSave(
                    setFieldValue,
                    handleSubmit,
                    radUserModalRef.current.open,
                  );
                }}
              >
                {t('edit')}
              </SimpleListLink>,
              <SimpleListDeletionLink
                key="delete"
                modelName={t('model_name')}
                onSubmit={async () => {
                  const { ok, data } = await API.radiationSafety
                    .radUsers(radPermitId)
                    .destroy(attributes.id);
                  if (ok) {
                    listRef.current.refresh();
                    showSuccessMessage(t('flash.success.remove'));
                  } else {
                    showBackendErrorMessage(t('flash.error.remove'), data);
                  }
                }}
              >
                {t('remove.title')}
              </SimpleListDeletionLink>,
            ]}
          />
        )}
      </FormSection>
      <Modal title={modalTitle} ref={radUserModalRef}>
        {modalProps => (
          <RadUser
            {...modalProps}
            key={radUserId}
            radPermitId={radPermitId}
            id={radUserId}
            listRef={listRef}
          />
        )}
      </Modal>
    </>
  );
};
