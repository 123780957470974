import { withFormik } from 'formik';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { validationSchema } from './validationSchema';
import { SimpleDictionaryOptionForm } from './Form';

const t = key => I18n.t(`administration.simple_dictionaries.edit.${key}`);

const SimpleDictionaryOptionFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const dictionaryOption = SimpleDictionaryOptionFunctions.prepareValuesToSubmit({
      ...values,
    });
    if (isDefined(dictionaryOption.id)) {
      SimpleDictionaryOptionFunctions.update(dictionaryOption, formikProps);
    } else {
      SimpleDictionaryOptionFunctions.create(dictionaryOption, formikProps);
    }
  },
  prepareValuesToSubmit: values => {
    const result = SimpleDictionaryOptionFunctions.applyBackendValuesWhitelist(values);
    if (isDefined(values.customFields)) {
      values.customFields.forEach(field => {
        result[field.name] = field.value;
      });
    }
    return result;
  },
  create: async (dictionaryOption, formikProps) => {
    const { ok, data } = await API.administration.simpleDictionaryOptions.create({
      dictionary: formikProps.props.dictionary,
      dictionary_option: dictionaryOption,
    });
    if (ok) {
      formikProps.resetForm({
        values: SimpleDictionaryOptionFunctions.prepareInitialValues(data.data.attributes),
      });
      formikProps.props.hide();
      formikProps.props.listRef.current.refresh();
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (dictionaryOption, formikProps) => {
    const { ok, data } = await API.administration.simpleDictionaryOptions.update(
      dictionaryOption.id,
      {
        dictionary: formikProps.props.dictionary,
        dictionary_option: dictionaryOption,
      },
    );
    if (ok) {
      formikProps.resetForm({
        values: SimpleDictionaryOptionFunctions.prepareInitialValues(data.data.attributes),
      });
      formikProps.props.hide();
      formikProps.props.listRef.current.refresh();
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: option => ({
    customFields: option.custom_fields,
    maxPositionValue: option.maxPositionValue,
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: ['id', 'name', 'position'],
});

export const SimpleDictionaryOptionModalContent = withFormik({
  mapPropsToValues: props =>
    SimpleDictionaryOptionFunctions.prepareInitialValues({
      ...props.dictionaryOption,
      maxPositionValue: props.maxPositionValue,
    }),
  handleSubmit: SimpleDictionaryOptionFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(SimpleDictionaryOptionForm);
