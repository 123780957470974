import styled from 'styled-components';
import { fontWeights } from '../../assets/styles/typography';

export const SimpleListTitle = styled.div`
  margin-top: 0.75rem;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100px;
  font-weight: ${fontWeights.headings};
`;
