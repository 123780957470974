import React, { useState, useEffect } from 'react';
import { isDefined } from '../../services/utils';
import { API } from '../../services/api';
import { Loader } from '../../components/Loader';
import { PermitForm } from './Form';

export const Permit = props => {
  const { id } = props;

  const [fetching, setFetching] = useState(true);
  const [permit, setPermit] = useState({});
  useEffect(() => {
    (async () => {
      const api = isDefined(id) ? API.permits.show : API.permits.new;
      const { ok, data } = await api(id);
      if (ok) {
        await setPermit(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <PermitForm {...props} permit={permit} />;
};
export default Permit;
