import { create } from 'apisauce';

// The LMS API works as follow:
// There are three endpoints for listing users' details, listing users' trainings and updating
// users' details
// The first action returns user's details found by a given field (by the Salute id). We read
// his/her "id" from the LMS and "customfields" that are checkboxes in the LMS user's profile.
// The second action returns user's courses details with date completion. A user is found by his/her
// "id" from the LMS.
// The third action is used for updating the "customfields" values that are checkboxes in the LMS
// user's profile. A user is found by his/her "id" from the LMS.
//
// The request url must contain: wstoken, moodlewsrestformat and wsfunction that corresponds
// to action:
// - 'core_user_get_users_by_field' - list users' details
// - 'local_sync_course_enrollment' - list users' trainings
// - 'core_user_update_users' - update users' details
//
// Example url for listing users' details:
// https://staging.lms.salutesafety.com/webservice/rest/server.php?wstoken=<token>&moodlewsrestformat=json&wsfunction=core_user_get_users_by_field&field=username&values[0]=<username>
// Example url for listing users' trainings:
// https://staging.lms.salutesafety.com/webservice/rest/server.php?wstoken=<token>&moodlewsrestformat=json&wsfunction=local_sync_course_enrollment&userid=<id>
// Example url for updating user's details:
// https://staging.lms.salutesafety.com/webservice/rest/server.php?wstoken=<token>&moodlewsrestformat=json&wsfunction=core_user_update_users&users[0][id]=<id>&users[0][customfields][1][value]=1&users[0][customfields][1][type]=checkbox

export const lmsSalutePortalApi = (baseUrl, wsToken) => {
  const api = create({
    baseURL: baseUrl,
    timeout: 30000,
  });

  const urlConfig = {
    endpoint: '/server.php',
    wsToken,
    format: 'json',
  };

  const postHeaders = {
    'Content-Type': 'multipart/form-data',
  };

  const wsFunctionName = {
    indexUserDetails: 'core_user_get_users_by_field',
    indexUserTrainings: 'local_sync_course_enrollment',
    updateUserDetails: 'core_user_update_users',
  };

  const buildUrl = action => {
    const tokenParam = `wstoken=${urlConfig.wsToken}`;
    const formatParam = `moodlewsrestformat=${urlConfig.format}`;
    const functionNameParam = `wsfunction=${wsFunctionName[action]}`;

    return `${urlConfig.endpoint}?${tokenParam}&${formatParam}&${functionNameParam}`;
  };

  return {
    userDetails: {
      indexBySaluteId: personId =>
        api.get(`${buildUrl('indexUserDetails')}&field=username&values[0]=${personId}`),
      indexByLmsId: personLMSId =>
        api.get(`${buildUrl('indexUserDetails')}&field=id&values[0]=${personLMSId}`),
      update: params =>
        api.post(`${buildUrl('updateUserDetails')}`, params, {
          headers: postHeaders,
        }),
    },
    trainings: {
      index: personLMSId => api.get(`${buildUrl('indexUserTrainings')}&userid=${personLMSId}`),
    },
  };
};
