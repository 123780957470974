import { withFormik } from 'formik';
import { Form } from './Form';
import { API } from '../../../services/api';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { validationSchema } from './validationSchema';

const t = (key, params = {}) => I18n.t(`requests.safe_work_authorizations.new_edit.${key}`, params);

const SafeWorkAuthorizationFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const swa = SafeWorkAuthorizationFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(swa.id)) {
      SafeWorkAuthorizationFunctions.create(swa, formikProps, values.afterSave);
    } else {
      SafeWorkAuthorizationFunctions.update(swa, formikProps, values.submitType, values.afterSave);
    }
  },
  prepareValuesToSubmit: values => {
    values.swa_question_answers_attributes = values.swaQuestionAnswersAttributes.map(
      questionAnswer => {
        questionAnswer.answer = SafeWorkAuthorizationFunctions.parseSwaQuestionAnswer(
          questionAnswer,
          'submit',
        );
        return questionAnswer;
      },
    );
    return SafeWorkAuthorizationFunctions.applyBackendValuesWhitelist(values);
  },
  create: async (swa, formikProps, afterSave) => {
    const { resource_name } = formikProps.props;
    const { ok, data } = await API.requests.safeWorkAuthorizations.create({
      safe_work_authorization: swa,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/requests/safe_work_authorizations/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(t('title.edit', { resource_name }));
      formikProps.resetForm({
        values: SafeWorkAuthorizationFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save', { resource_name }));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save', { resource_name }), data);
    }
  },
  update: async (swa, formikProps, submitType, afterSave) => {
    const { resource_name } = formikProps.props;
    const { ok, data } = await API.requests.safeWorkAuthorizations[
      submitType === 'save' ? 'update' : submitType
    ](swa.id, { safe_work_authorization: swa });
    if (ok) {
      formikProps.resetForm({
        values: SafeWorkAuthorizationFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save', { resource_name }));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save', { resource_name }), data);
    }
  },
  parseSwaQuestionAnswer: (answer, action = 'show') => {
    if (answer.answer_type === 'checkbox') {
      if (action === 'submit') {
        return answer.answer ? 'yes' : 'no';
      }
      return answer.answer === 'yes';
    }
    return answer.answer;
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: safeWorkAuthorization => ({
    submitType: 'save',
    afterSave: () => {},
    inspections: (safeWorkAuthorization.inspections_attributes || []).map(({ data }) => data),
    swaQuestionAnswersAttributes: safeWorkAuthorization.swa_question_answers_attributes.map(
      questionAnswer => {
        questionAnswer.answer = SafeWorkAuthorizationFunctions.parseSwaQuestionAnswer(
          questionAnswer,
        );
        return questionAnswer;
      },
    ),
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'is_completed',
    'identifier',
    'date_requested',
    'requesting_person_id',
    'responsible_person_id',
    'also_notified_person_ids',
    'external_contact',
    'external_phone_number',
    'external_email',
    'cost_center',
    'project',
    'request_description',
    'start_date',
    'end_date',
    'dates_and_times_additional_information',
    'hot_work',
    'soldering',
    'brazing',
    'arc_welding',
    'cutting',
    'grinding',
    'flammable_gas',
    'will_generate_dust',
    'describe_containment_measures_taken',
    'other',
    'specify_other',
    'torch_operator_name',
    'torch_operator_certificate',
    'torch_operator_certificate_expiration_date',
    'fire_guard_name',
    'fire_guard_certificate',
    'fire_guard_certificate_expiration_date',
    'fire_and_life_systems_impairment',
    'fire_detection_system',
    'fire_notification_system',
    'fire_suppression_system',
    'smoke_control_devices',
    'icra_review',
    'breach_of_smoke_compartments',
    'egress_path',
    'temporary_stairways_and_exists_closure',
    'disable_safety_equipment',
    'utility_shutdown',
    'electric_system',
    'water_system',
    'ventilation_system',
    'medical_gas_system',
    'natural_gas_system',
    'vacuum_system',
    'phone_line',
    'generators',
    'other_system',
    'other_system_description',
    'patient_care_impact',
    'patient_care_impact_description',
    'request_attachments',
    'request_links',
    'assigned_person_id',
    'priority',
    'sub_status',
    'internal_notes',
    'internal_attachments',
    'internal_links',
    'safe_work_authorization_construction_projects',
    'safe_work_authorization_locations_attributes',
    'swa_question_answers_attributes',
    'swa_questions',
    'resolution_date',
    'start_time',
    'end_time',
    'weekdays',
    'saturdays',
    'sundays',
    'custom_dates_and_times',
    'swa_date_time_entries_attributes',
    'label_ids',
  ],
});

export const SafeWorkAuthorizationForm = withFormik({
  mapPropsToValues: props =>
    SafeWorkAuthorizationFunctions.prepareInitialValues(props.safeWorkAuthorization),
  handleSubmit: SafeWorkAuthorizationFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
