import { withFormik } from 'formik';
import { Form } from './Form';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { validationSchema } from './validationSchema';

const t = (key, params = {}) => I18n.t(`general_request_types.new_edit.${key}`, params);

const GeneralRequestTypeFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const generalRequestType = GeneralRequestTypeFunctions.prepareValuesToSubmit({ ...values });
    if (isDefined(generalRequestType.id)) {
      GeneralRequestTypeFunctions.update(generalRequestType, formikProps, values);
    } else {
      GeneralRequestTypeFunctions.create(generalRequestType, formikProps, values);
    }
  },
  create: async (generalRequestType, formikProps, { afterSave, maxPositionValue }) => {
    const { resource_name } = formikProps.props;
    const { ok, data } = await API.administration.generalRequestTypes.create({
      general_request_type: generalRequestType,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/administration/general_request_types/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('general_request_types.new_edit.title.edit', { resource_name }));
      formikProps.resetForm({
        values: GeneralRequestTypeFunctions.prepareInitialValues({
          maxPositionValue,
          ...data.data.attributes,
        }),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save', { resource_name }));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save', { resource_name }), data);
    }
  },
  update: async (generalRequestType, formikProps, { afterSave, maxPositionValue }) => {
    const { resource_name } = formikProps.props;
    const { ok, data } = await API.administration.generalRequestTypes.update(
      generalRequestType.id,
      { general_request_type: generalRequestType },
    );
    if (ok) {
      formikProps.resetForm({
        values: GeneralRequestTypeFunctions.prepareInitialValues({
          maxPositionValue,
          ...data.data.attributes,
        }),
      });
      afterSave(data.data.id);
      showSuccessMessage(t(`flash.success.save`, { resource_name }));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t(`flash.error.save`, { resource_name }), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: option => ({
    afterSave: () => {},
    maxPositionValue: option.maxPositionValue,
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: ['id', 'name', 'is_default', 'position', 'active'],
});

export const GeneralRequestTypeForm = withFormik({
  validate: produceDefaultValidation(validationSchema),
  mapPropsToValues: props =>
    GeneralRequestTypeFunctions.prepareInitialValues({
      maxPositionValue: props.maxPositionValue,
      ...props.generalRequestType,
    }),
  handleSubmit: GeneralRequestTypeFunctions.handleSubmit,
})(Form);
