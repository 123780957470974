import React from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { CustomFields } from '../../../components/fields/compositeFields/CustomFields';
import { FormSection } from '../../../components/form/FormSection';
import { isDefined } from '../../../services/utils';
import { Loader } from '../../../components/Loader';

const t = (key, params) => I18n.t(`inventory.equipment.equipment.new_edit.${key}`, params);

const LoaderWrapper = styled.div`
  margin-top: 20px;
`;

export const EquipmentTypeDetails = props => {
  const {
    loading,
    values: { equipmentTypeName, equipmentFieldDefinitions },
  } = props;

  if (loading)
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );

  const renderTypeDetails = isDefined(equipmentTypeName) && !isEmpty(equipmentFieldDefinitions);
  if (!renderTypeDetails) return <></>;

  return (
    <FormSection
      title={t('sections.equipment_type_details', {
        equipment_type: equipmentTypeName,
      })}
    >
      <CustomFields entityName="equipment" {...props} />
    </FormSection>
  );
};
