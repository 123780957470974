import { withFormik } from 'formik';
import { API } from '../../../../../services/api';
import { RadUserInnerForm } from './Form';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../../services/utils';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`radiation_safety.rad_permits.lists.rad_users.${key}`);

const RadUserFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const radUser = RadUserFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(radUser.id)) {
      RadUserFunctions.create(radUser, formikProps);
    } else {
      RadUserFunctions.update(radUser, formikProps);
    }
  },
  create: async (radUser, formikProps) => {
    const { ok, data } = await API.radiationSafety
      .radUsers(formikProps.props.radPermitId)
      .create({ rad_user: radUser });
    if (ok) {
      formikProps.resetForm({
        values: RadUserFunctions.prepareInitialValues(data.data.attributes),
      });
      formikProps.props.hide();
      formikProps.props.listRef.current.refresh();
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (radUser, formikProps) => {
    const { ok, data } = await API.radiationSafety
      .radUsers(formikProps.props.radPermitId)
      .update(radUser.id, {
        rad_user: radUser,
      });
    if (ok) {
      formikProps.resetForm({
        values: RadUserFunctions.prepareInitialValues(data.data.attributes),
      });
      formikProps.props.hide();
      formikProps.props.listRef.current.refresh();
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'));
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: () => ({}),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'person_id',
    'rad_user_type_id',
    'approval_date',
    'active',
    'rad_permit_id',
    'attachments',
  ],
  preprocessBackendValues: backendValues => {
    if (!backendValues.id) {
      backendValues.status = true;
    }
    return {
      ...backendValues,
    };
  },
});

export const RadUserForm = withFormik({
  mapPropsToValues: props => RadUserFunctions.prepareInitialValues(props.radUser),
  handleSubmit: RadUserFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(RadUserInnerForm);
