import yup from '../../../components/form/CustomYupValidator';
import { transformNaNValue } from '../../../../services/formHelper';
import {
  currentDateTime,
  dateFormatter,
  getConvertedMoment,
  newMoment,
} from '../../../services/dateFormatUtils';

const t = (key, params = {}) =>
  I18n.t(`radiation_safety.rad_inventory_items.new_edit.validations.${key}`, params);

export const validationSchema = yup.object().shape({
  rad_permit_id: yup
    .number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required')),
  rad_license_item_assignment_id: yup
    .number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required')),
  rad_inventory_item_status_id: yup
    .number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required')),
  half_life_days: yup
    .number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required')),
  started_at: yup
    .string()
    .nullable()
    .test(
      'started_at_cannot_be_later_than_current_time',
      t('started_at_cannot_be_later_than_current_time', {
        current_time: dateFormatter.dateTime(currentDateTime()),
      }),
      function _(started_at) {
        return started_at ? getConvertedMoment(started_at).isSameOrBefore(newMoment()) : true;
      },
    )
    .required(I18n.t('validations.required')),
  initial_quantity: yup
    .number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required')),
  initial_activity: yup
    .number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required')),
});
