import React from 'react';
import { FastField } from 'formik';
import styled from 'styled-components';
import { IndexTable } from '../../../components/tables/IndexTable';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import { DateField } from '../../../components/fields/DateField';
import { FormSection } from '../../../components/form/FormSection';
import { CommentFileButton } from '../../../components/buttons/CommentFileButton';

const modelT = key => I18n.t(`activerecord.attributes.construction_project_milestone.${key}`);
const controllerT = key => I18n.t(`construction_projects.new_edit.${key}`);

const CenteredCell = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Milestones = props => {
  const {
    values: { construction_project_milestones_attributes },
    setFieldValue,
  } = props;
  const resourceName = I18n.t('resource_names.construction_project');
  return (
    <FormSection title={controllerT('sections.milestones')}>
      <IndexTable
        headersData={[
          { field: 'name', title: modelT('name') },
          { field: 'applicable', title: modelT('applicable') },
          { field: 'target_date', title: modelT('target_date') },
          { field: 'date_completed', title: modelT('date_completed') },
          { field: 'comment', title: modelT('comment') },
        ]}
        data={construction_project_milestones_attributes.map((milestone, index) => ({
          rowKey: milestone.id,
          cells: [
            { field: 'name', content: milestone.name },
            {
              field: 'applicable',
              content: (
                <CenteredCell>
                  <FastField
                    name={`construction_project_milestones_attributes.${index}.applicable`}
                    resourceName={resourceName}
                    shouldUpdate={defaultShouldUpdate}
                    component={CheckBoxField}
                    displayMode={fieldDisplayModes.NO_WRAPPER}
                  />
                </CenteredCell>
              ),
            },
            {
              field: 'target_date',
              content: (
                <CenteredCell>
                  <FastField
                    name={`construction_project_milestones_attributes.${index}.target_date`}
                    resourceName={resourceName}
                    shouldUpdate={defaultShouldUpdate}
                    component={DateField}
                    displayMode={fieldDisplayModes.NO_WRAPPER}
                    withPortal
                  />
                </CenteredCell>
              ),
            },
            {
              field: 'date_completed',
              content: (
                <CenteredCell>
                  <FastField
                    name={`construction_project_milestones_attributes.${index}.date_completed`}
                    resourceName={resourceName}
                    shouldUpdate={defaultShouldUpdate}
                    component={DateField}
                    displayMode={fieldDisplayModes.NO_WRAPPER}
                    withPortal
                  />
                </CenteredCell>
              ),
            },
            {
              field: 'comment',
              content: (
                <CenteredCell>
                  <CommentFileButton
                    onConfirm={({ comment, attachments }) => {
                      setFieldValue(
                        `construction_project_milestones_attributes.${index}.comment`,
                        comment,
                      );
                      setFieldValue(
                        `construction_project_milestones_attributes.${index}.attachments`,
                        attachments,
                      );
                    }}
                    initialComment={milestone.comment}
                    initialAttachments={milestone.attachments}
                    resourceName={`${resourceName}-construction_project_milestones_attributes[${index}]`}
                  />
                </CenteredCell>
              ),
            },
          ],
        }))}
      />
    </FormSection>
  );
};
