import yup from '../../../components/form/CustomYupValidator';

export const validationSchema = yup.object().shape({
  identifier: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  status: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  project_name: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  project_type: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  project_manager_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  start_date: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  building_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});
