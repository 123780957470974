import React, { useRef, useState, useCallback } from 'react';
import { FormSection } from '../../components/form/FormSection';
import { FormSectionHeader } from '../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../components/form/FormSectionTitle';
import { FormSectionHeaderLink } from '../../components/form/FormSectionHeaderLinks';
import { API } from '../../services/api';
import { showBackendErrorMessage, showSuccessMessage } from '../../services/utils';
import { AsyncSimpleList } from '../../components/lists/AsyncSimpleList';
import { Modal } from '../../components/modals/Modal';
import { SimpleDictionaryOptionModalContent } from './SimpleDictionaryOption';
import { DeletionModalContent } from '../../components/modals/DeletionModalContent';
import { FormHeader } from '../../components/form/FormHeader';
import { produceMapData } from './produceMapData';

const t = key => I18n.t(`administration.simple_dictionaries.edit.${key}`);

const SimpleDictionary = props => {
  const {
    dictionary,
    dictionariesNamesTranslationPath,
    listTitle,
    renderLockedList,
    lockedListTitle,
  } = props;

  const listRef = useRef(null);
  const modalRef = useRef(null);
  const deletionModalRef = useRef(null);
  const [dictionaryOption, setDictionaryOption] = useState({});
  const [modalTitle, setModalTitle] = useState({});
  const [idToDelete, setIdToDelete] = useState(null);
  const elementsCount = useRef(0);
  const [maxPositionValue, setMaxPositionValue] = useState(0);

  const mapData = useCallback(
    locked =>
      produceMapData({
        elementsCount,
        dictionary,
        setDictionaryOption,
        setModalTitle,
        setMaxPositionValue,
        modalRef,
        setIdToDelete,
        deletionModalRef,
        locked,
      }),
    [elementsCount, dictionary],
  );

  return (
    <>
      <FormHeader title={I18n.t(`${dictionariesNamesTranslationPath}.${dictionary}`)} />
      {renderLockedList ? (
        <FormSection>
          <FormSectionHeader>
            <FormSectionTitle>{lockedListTitle}</FormSectionTitle>
          </FormSectionHeader>
          <AsyncSimpleList
            api={() =>
              API.administration.simpleDictionaryOptions.index({ dictionary, locked: true })
            }
            dataKey="data"
            resourceName={I18n.t('resource_names.simple_dictionary_locked_options')}
            mapData={mapData(true)}
          />
        </FormSection>
      ) : (
        <></>
      )}
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{listTitle}</FormSectionTitle>
          <FormSectionHeaderLink
            onClick={async () => {
              const { ok, data } = await API.administration.simpleDictionaryOptions.new({
                dictionary,
              });
              if (ok) {
                setDictionaryOption(data.data.attributes);
                setModalTitle(t('new_option_modal_title'));
                setMaxPositionValue(elementsCount.current + 1);
                await modalRef.current.open();
              }
            }}
          >
            {t('add_option')}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        <AsyncSimpleList
          ref={listRef}
          api={() => API.administration.simpleDictionaryOptions.index({ dictionary })}
          dataKey="data"
          resourceName={I18n.t('resource_names.simple_dictionary_options')}
          isSortable
          reorderItemApi={API.administration.simpleDictionaryOptions.reorder}
          reorderItemApiAdditionalParams={{ dictionary }}
          onDataFetched={fetchedData => {
            elementsCount.current = fetchedData.length;
          }}
          mapData={mapData(false)}
        />
      </FormSection>
      <Modal title={modalTitle} ref={modalRef}>
        {modalProps => (
          <SimpleDictionaryOptionModalContent
            dictionary={dictionary}
            dictionaryOption={dictionaryOption}
            listRef={listRef}
            maxPositionValue={maxPositionValue}
            {...modalProps}
          />
        )}
      </Modal>
      <Modal ref={deletionModalRef}>
        <DeletionModalContent
          modalRef={deletionModalRef}
          modelName={t('dictionary_option')}
          onSubmit={async () => {
            const { ok, data } = await API.administration.simpleDictionaryOptions.destroy(
              idToDelete,
              {
                dictionary,
              },
            );
            if (ok) {
              listRef.current.refresh();
              showSuccessMessage(t('flash.success.remove'));
            } else {
              showBackendErrorMessage(t('flash.error.remove'), data);
            }
            deletionModalRef.current.hide();
          }}
        />
      </Modal>
    </>
  );
};
export default SimpleDictionary;
