import yup from '../../../../../components/form/CustomYupValidator';

export const validationSchema = yup.object().shape({
  visibilityConditions: yup.array(
    yup.object().shape({
      leader_expected_answer: yup.string().required(I18n.t('validations.required')),
      leader_id: yup.string().required(I18n.t('validations.required')),
    }),
  ),
});
