import React from 'react';
import { FastField } from 'formik';
import { FormHeader } from '../../../components/form/FormHeader';
import { handleSubmitWithAfterSave, isDefined } from '../../../services/utils';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { Footer } from './Footer';
import { NumericField } from '../../../components/fields/NumericField';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';
import { FieldDefinitions } from '../../../components/other/fieldDefinitions';
import { API } from '../../../services/api';

const t = (key, params = {}) => I18n.t(`general_request_types.new_edit.${key}`, params);

export const Form = props => {
  const {
    setFieldValue,
    handleSubmit,
    isSubmitting,
    resource_name,
    values: { id, maxPositionValue },
  } = props;

  return (
    <form>
      <FormHeader title={t(`title.${isDefined(id) ? 'edit' : 'new'}`, { resource_name })} />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.general_request_type',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.general_request_type'),
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField name="name" required component={TextField} />
          <FastField name="is_default" hint={t('hints.is_default')} component={CheckBoxField} />
          <FastField
            name="position"
            required
            roundPrecision={0}
            component={NumericField}
            warning={t('warnings.position', { max_position_value: maxPositionValue })}
          />
          <FastField name="active" component={CheckBoxField} />
        </FormSection>
      </DefaultAttributesGroup>
      <Footer {...props} />
      <FieldDefinitions
        title={t('lists.field_definitions.title', { resource_name })}
        empty={!id}
        api={API.administration.generalRequestTypes.fieldDefinitions(id)}
        changesDisabled={isSubmitting}
        handleAdditionLinkOnClick={open =>
          handleSubmitWithAfterSave(setFieldValue, handleSubmit, open)
        }
      />
    </form>
  );
};
