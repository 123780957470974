import React from 'react';
import get from 'lodash/get';
import { Field } from 'formik';
import snakeCase from 'lodash/snakeCase';
import { SelectorField } from '../SelectorField';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../AsyncSelectorField';

const equipmentNames = ['Equipment', 'PfE'];
const apiPaths = {
  Equipment: API.selectors.equipments.index,
  PfE: API.selectors.portableFireExtinguishers.index,
};
const equipmentWithoutType = ['PfE'];

export const EquipmentFields = ({
  equipmentTypeName = 'equipment_type',
  equipmentIdName = 'equipment_id',
  equipmentTypeText = 'equipment_type_text',
  defaultChildrenAttributes = {},
  defaultAttributes = defaultChildrenAttributes,
  values,
  setFieldValue,
  additionalApiParams,
}) => {
  const equipmentTypeValue = get(values, equipmentTypeText);
  const equipmentTypeNameValue = get(values, equipmentTypeName);
  return (
    <>
      <Field
        {...defaultChildrenAttributes}
        {...defaultAttributes}
        name={equipmentTypeName}
        options={equipmentNames.map(name => ({
          value: name,
          data: I18n.t(`activerecord.models.${snakeCase(name)}`),
        }))}
        onChange={async (e, defaultOnChange) => {
          await setFieldValue(equipmentIdName, '');
          await setFieldValue(equipmentTypeText, '');
          defaultOnChange(e);
        }}
        component={SelectorField}
      />
      {equipmentTypeNameValue === 'Equipment' && (
        <Field
          {...defaultChildrenAttributes}
          {...defaultAttributes}
          name={equipmentTypeText}
          api={API.selectors.equipmentTypes.index}
          optionRenderer={htmlOptionRenderer('text')}
          onChange={async (e, defaultOnChange) => {
            await setFieldValue(equipmentIdName, '');
            defaultOnChange({ value: e.data.id, data: e.data.id });
          }}
          component={AsyncSelectorField}
        />
      )}
      {(equipmentWithoutType.includes(equipmentTypeNameValue) || equipmentTypeValue) && (
        <Field
          {...defaultChildrenAttributes}
          {...defaultAttributes}
          name={equipmentIdName}
          api={apiPaths[equipmentTypeNameValue]}
          apiParams={{ type: equipmentTypeValue, ...additionalApiParams }}
          optionRenderer={htmlOptionRenderer('text')}
          component={AsyncSelectorField}
        />
      )}
    </>
  );
};
