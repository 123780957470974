import React from 'react';
import { sortBy, capitalize, lowerCase } from 'lodash';
import { isDefined } from '../../services/utils';
import { SimpleListRowAdditionalText } from '../../components/lists/SimpleListRowAdditionalText';
import { SimpleListDeletionLink, SimpleListLink } from '../../components/lists/SimpleListLinks';
import { API } from '../../services/api';

const t = key => I18n.t(`administration.simple_dictionaries.edit.${key}`);

export const produceMapData = props => rowProps => {
  const {
    locked,
    elementsCount,
    dictionary,
    setDictionaryOption,
    setModalTitle,
    setMaxPositionValue,
    modalRef,
    setIdToDelete,
    deletionModalRef,
  } = props;
  const {
    id,
    attributes: { name, custom_fields = [] },
  } = rowProps;

  const isDefault = custom_fields.find(field => field.name === 'is_default')?.value;
  const isDefaultSupported = isDefault !== undefined;

  const additionalCustomFields = custom_fields.filter(field => field.name !== 'is_default');

  const additionalCustomFieldsLabelValuePairs = additionalCustomFields.map(field => {
    const fieldType = field.type;
    let fieldValue = field.value;
    if (fieldType === 'boolean') {
      fieldValue = fieldValue ? I18n.t('true') : I18n.t('false');
    } else if (fieldType === 'selector' && isDefined(field.translated_enum_value)) {
      fieldValue = field.translated_enum_value;
    } else if (fieldType === 'json') {
      const formattedValue = sortBy(
        Object.entries(fieldValue).map(([key, value]) =>
          isDefined(value) ? `${capitalize(lowerCase(key))}: ${value}` : null,
        ),
      );
      fieldValue = formattedValue.map(row => <div>{row}</div>);
    }
    return [field.label, fieldValue];
  });

  return [
    [t('form_fields.name'), name],
    ...additionalCustomFieldsLabelValuePairs,
    isDefaultSupported ? (
      // in case additional text is can be displayed in at least one row;
      // it should be displayed in all of them - display of middle columns might
      // be incorrect otherwise
      <SimpleListRowAdditionalText style={{ width: '60px' }}>
        {isDefault ? t('is_default') : ''}
      </SimpleListRowAdditionalText>
    ) : null,
    locked ? null : (
      <SimpleListLink
        key="view"
        onClick={async () => {
          const { ok, data } = await API.administration.simpleDictionaryOptions.show(id, {
            dictionary,
          });
          if (ok) {
            await setDictionaryOption(data.data.attributes);
            await setModalTitle(t('edit_option_modal_title'));
            await setMaxPositionValue(elementsCount.current);
            await modalRef.current.open();
          }
        }}
      >
        {t('edit_option')}
      </SimpleListLink>
    ),
    locked ? null : (
      <SimpleListDeletionLink
        key="remove"
        // we use one modal for all options instead - to improve the performance
        modal={false}
        onSubmit={async () => {
          setIdToDelete(id);
          deletionModalRef.current.open();
        }}
      >
        {t('remove_option')}
      </SimpleListDeletionLink>
    ),
  ];
};
