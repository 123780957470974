import { withFormik } from 'formik';
import { API } from '../../../../services/api';
import { RadLicenseItemAssignmentForm } from './Form';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`radiation_safety.rad_permits.lists.rad_license_item_assignments.${key}`);

const RadLicenseItemAssignmentFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const radLicenseItemAssignment = RadLicenseItemAssignmentFunctions.prepareValuesToSubmit({
      ...values,
    });
    if (!isDefined(radLicenseItemAssignment.id)) {
      RadLicenseItemAssignmentFunctions.create(radLicenseItemAssignment, formikProps);
    } else {
      RadLicenseItemAssignmentFunctions.update(radLicenseItemAssignment, formikProps);
    }
  },
  create: async (radLicenseItemAssignment, formikProps) => {
    const { ok, data } = await API.radiationSafety
      .radLicenseItemAssignments(formikProps.props.radPermitId)
      .create({ rad_license_item_assignment: radLicenseItemAssignment });
    if (ok) {
      formikProps.resetForm({
        values: RadLicenseItemAssignmentFunctions.prepareInitialValues(data.data.attributes),
      });
      formikProps.props.hide();
      formikProps.props.listRef.current.refresh();
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (radLicenseItemAssignment, formikProps) => {
    const { ok, data } = await API.radiationSafety
      .radLicenseItemAssignments(formikProps.props.radPermitId)
      .update(radLicenseItemAssignment.id, {
        rad_license_item_assignment: radLicenseItemAssignment,
      });
    if (ok) {
      formikProps.resetForm({
        values: RadLicenseItemAssignmentFunctions.prepareInitialValues(data.data.attributes),
      });
      formikProps.props.hide();
      formikProps.props.listRef.current.refresh();
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: initialValues => ({
    remainingLicenseActivity: initialValues.license_item_remaining_activity,
    activityUnitId: initialValues.activity_unit_id,
    remainingLicenseQuantity: initialValues.license_item_remaining_quantity,
    quantityUnitId: initialValues.quantity_unit_id,
    radItemTypeId: initialValues.rad_item_type_id,
    radLicenseId: initialValues.radLicenseId,
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'purchase_number_limit',
    'start_date',
    'closed_date',
    'quantity_limit',
    'activity_limit',
    'rad_permit_id',
    'rad_license_item_id',
  ],
});

export const AddRadLicenseItemAssignmentModalContent = withFormik({
  mapPropsToValues: ({ radLicenseItemAssignment, radLicenseId }) =>
    RadLicenseItemAssignmentFunctions.prepareInitialValues({
      ...radLicenseItemAssignment,
      radLicenseId,
    }),
  handleSubmit: RadLicenseItemAssignmentFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(RadLicenseItemAssignmentForm);
