import { withFormik } from 'formik';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { Form } from './Form';
import { API } from '../../../services/api';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`administration.waste_generators.new_edit.${key}`);

const WasteGeneratorFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const wasteGenerator = WasteGeneratorFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(wasteGenerator.id)) {
      WasteGeneratorFunctions.create(wasteGenerator, formikProps, values.afterSave);
    } else {
      WasteGeneratorFunctions.update(wasteGenerator, formikProps, values.afterSave);
    }
  },
  create: async (wasteGenerator, formikProps, afterSave) => {
    const { ok, data } = await API.administration.wasteGenerators.create({
      waste_generator: wasteGenerator,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/administration/waste_generators/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.administration.waste_generators.edit.title'));
      formikProps.resetForm({
        values: WasteGeneratorFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (wasteGenerator, formikProps, afterSave) => {
    const { ok, data } = await API.administration.wasteGenerators.update(wasteGenerator.id, {
      waste_generator: wasteGenerator,
    });
    if (ok) {
      formikProps.resetForm({
        values: WasteGeneratorFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: wasteGenerator => ({
    afterSave: () => {},
    buildings: wasteGenerator.buildings_attributes || [],
    wasteCafs: wasteGenerator.waste_cafs_attributes || [],
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'generator_nickname',
    'generator_full_name',
    'epa_id',
    'street_address',
    'city',
    'state',
    'zip',
    'generator_status',
  ],
});

export const WasteGeneratorForm = withFormik({
  mapPropsToValues: props => WasteGeneratorFunctions.prepareInitialValues(props.wasteGenerator),
  handleSubmit: WasteGeneratorFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
