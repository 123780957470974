import React from 'react';
import { API } from '../../../services/api';
import { FormSection } from '../../../components/form/FormSection';
import { IndexTableDefaultHandler } from '../../../components/tables/IndexTableDefaultHandler';
import { handleSubmitWithAfterSave, isDefined } from '../../../services/utils';
import { DefaultLink } from '../../../components/links/DefaultLink';

const t = key => I18n.t(`radiation_safety.rad_permits.tabs.rad_purchases.${key}`);

export const RadPurchasesTable = ({
  isSubmitting,
  setFieldValue,
  handleSubmit,
  values: { id: radPermitId },
}) => {
  return (
    <>
      <FormSection>
        <IndexTableDefaultHandler
          title={t('title')}
          api={API.radiationSafety.radPurchases.index}
          fixedFilters={{ with_rad_permit_id: radPermitId }}
          idToHrefFunction={_id => `/radiation_safety/rad_purchases/${_id}/edit`}
          manageColumnsModel="RadPurchase"
          forceEmpty={!isDefined(radPermitId)}
          additionalLinks={[
            <DefaultLink
              disabled={isSubmitting}
              onClick={async () => {
                const redirect = _id => () => {
                  window.location.href = `/radiation_safety/rad_purchases/new?rad_permit_id=${_id}`;
                };
                const afterSave = _id => setTimeout(redirect(_id), 1000);
                await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
              }}
            >
              {t('link')}
            </DefaultLink>,
          ]}
        />
      </FormSection>
    </>
  );
};
