import React, { useCallback } from 'react';
import produce from 'immer';
import { sortBy, snakeCase } from 'lodash';
import { FastField, withFormik } from 'formik';
import { fieldDisplayModes } from '../../constants/fieldDisplayModes';
import { ModalSection } from '../modals/ModalSection';
import {
  defaultHandleSubmit,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../services/utils';
import { defaultShouldUpdate } from '../../services/fieldUtils';
import { DefaultAttributesGroup } from '../form/DefaultAttributesGroup';
import { CheckBoxField } from '../fields/CheckBoxField';
import { FormFooter, FormFooterRightPart } from '../form/FormFooter';
import { CancellationLink } from '../links/CancellationLink';
import { BrandButton } from '../buttons/BrandButton';
import { API } from '../../services/api';
import { DefaultLink } from '../links/DefaultLink';

const t = key => I18n.t(`form.header.manage_columns_modal.${key}`);

const Form = props => {
  const {
    hide,
    model,
    setFieldValue,
    setValues,
    handleSubmit,
    isSubmitting,
    values,
    values: { columns, dynamicColumns },
  } = props;

  const produceUpdateAll = useCallback(
    newValue => () => {
      setValues(
        produce(values, draftState => {
          draftState.columns.forEach(column => {
            column.visible = newValue;
          });
          draftState.dynamicColumns.forEach(column => {
            column.visible = newValue;
          });
        }),
      );
    },
    [values, setValues],
  );

  const noneSelected = [...columns, ...dynamicColumns].every(col => !col.visible);

  return (
    <ModalSection>
      <DefaultLink onClick={produceUpdateAll(true)}>{t('select_all')}</DefaultLink>
      <span style={{ paddingLeft: '1rem' }}>
        <DefaultLink onClick={produceUpdateAll(false)}>{t('deselect_all')}</DefaultLink>
      </span>
      <form style={{ paddingTop: '0.5rem' }}>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            displayMode: fieldDisplayModes.INPUT_FIRST_GROWING_WIDTH_LABEL,
            shouldUpdate: defaultShouldUpdate,
            resourceName: I18n.t('resource_names.cfg_index_page'),
          }}
        >
          {columns.map(({ name }, index) => (
            <FastField
              key={name}
              name={`columns.${index}.visible`}
              label={I18n.t(`activerecord.attributes.${snakeCase(model)}.${name}`)}
              component={CheckBoxField}
            />
          ))}
          {dynamicColumns.map(({ name }, index) => (
            <FastField
              key={name}
              name={`dynamicColumns.${index}.visible`}
              label={I18n.t(`activerecord.attributes.${snakeCase(model)}.${name}`)}
              component={CheckBoxField}
            />
          ))}
        </DefaultAttributesGroup>
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={hide} />
            <BrandButton
              disabled={noneSelected}
              onClick={e => defaultHandleSubmit(setFieldValue, handleSubmit, e)}
            >
              {t('save')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </form>
    </ModalSection>
  );
};

const prepareValuesToSubmit = ({ columns, dynamicColumns }) => {
  const mapper = column => ({ id: column.id, visible: column.visible });

  return {
    cfg_index_page_columns_attributes: columns.map(mapper),
    cfg_index_page_dynamic_columns_attributes: dynamicColumns.map(mapper),
  };
};

export const ManageColumnsModalContent = withFormik({
  mapPropsToValues: props => ({
    columns: sortBy(props.columns, 'order'),
    dynamicColumns: sortBy(props.dynamicColumns, 'order'),
  }),
  handleSubmit: async (values, formikProps) => {
    const { model, hide, onSuccess } = formikProps.props;
    const { ok, data } = await API.cfgIndexPages.update(model, prepareValuesToSubmit(values));
    if (ok) {
      formikProps.resetForm();
      hide();
      onSuccess();
      showSuccessMessage(t('flash.success'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error'), data);
    }
  },
})(Form);
