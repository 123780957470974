import React, { useState, useEffect } from 'react';
import { Loader } from '../../components/Loader';
import { API } from '../../services/api';
import { SettingsForm } from './Form';

export const IncidentRequest = props => {
  const { id } = props;

  const [fetching, setFetching] = useState(true);
  const [settings, setSettings] = useState({});
  useEffect(() => {
    (async () => {
      const { ok, data } = await API.settings.index(id);
      if (ok) {
        await setSettings(data);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <SettingsForm {...props} settings={settings} />;
};
export default IncidentRequest;
