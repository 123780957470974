import React from 'react';
import styled from 'styled-components';
import { SimpleListAdditionalElement } from '../../../../components/lists/SimpleListAdditionalElement';
import { DefaultLink } from '../../../../components/links/DefaultLink';

const t = key =>
  I18n.t(`requests.waste_requests.new_edit.lists.waste_collection_candidates.${key}`);

export const DivJustifyEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CandidateStatusHandler = props => {
  const {
    candidateId,
    isWasteRequestCompleted,
    chemicalItemCreated,
    candidateEdited,
    relevantCandidateIdRef,
    createCollectionModalRef,
    createCollectionAgainModalRef,
  } = props;

  const renderCreateAgainLink = !isWasteRequestCompleted && chemicalItemCreated && candidateEdited;

  return (
    <SimpleListAdditionalElement style={{ width: '138px' }}>
      <DivJustifyEnd>
        {chemicalItemCreated ? (
          t('collection_created')
        ) : (
          <DefaultLink
            key="create_collection"
            hidden={isWasteRequestCompleted}
            onClick={() => {
              relevantCandidateIdRef.current = candidateId;
              createCollectionModalRef.current.open();
            }}
            style={{ fontSize: '16px' }}
          >
            {t('create_collection')}
          </DefaultLink>
        )}
      </DivJustifyEnd>
      {renderCreateAgainLink ? (
        <DivJustifyEnd>
          <DefaultLink
            onClick={() => {
              relevantCandidateIdRef.current = candidateId;
              createCollectionAgainModalRef.current.open();
            }}
            style={{ fontSize: '16px' }}
          >
            {t('create_collection_again')}
          </DefaultLink>
        </DivJustifyEnd>
      ) : (
        <></>
      )}
    </SimpleListAdditionalElement>
  );
};
