import yup from '../../../../../components/form/CustomYupValidator';

export const validationSchema = yup.object().shape({
  person_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  rad_user_type_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  approval_date: yup
    .string()
    .typeError(I18n.t('validations.date'))
    .required(I18n.t('validations.required')),
});
