import React from 'react';
import { FastField } from 'formik';
import { isDefined } from '../../../services/utils';
import { FormHeader } from '../../../components/form/FormHeader';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate, produceShouldUpdate } from '../../../services/fieldUtils';
import { LinksField } from '../../../components/fields/LinksField';
import { FilesField } from '../../../components/fields/FilesField';
import { FormSection } from '../../../components/form/FormSection';
import { API } from '../../../services/api';
import { getConvertedMoment } from '../../../services/dateFormatUtils';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { TextField } from '../../../components/fields/TextField';
import { defaultOptionRenderer } from '../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { DateTimeField } from '../../../components/fields/DateTimeField';
import { DateField } from '../../../components/fields/DateField';
import { Attendees } from './Attendees';
import { EmployeeTrainingLocations } from './EmployeeTrainingLocations';
import { Footer } from './Footer';

const t = key => I18n.t(`training.employee_trainings.new_edit.${key}`);

export const Form = props => {
  const {
    values: { id },
    setFieldValue,
  } = props;

  return (
    <form>
      <FormHeader title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)} />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.employee_training',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.employee_training'),
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField disabled name="identifier" component={TextField} />
          <FastField
            required
            name="training_format_id"
            api={API.selectors.trainingFormats.index}
            selectionApi={API.selectors.trainingFormats.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('label')}
            component={AsyncSelectorField}
          />
          <FastField
            name="training_type_id"
            api={API.selectors.trainingTypes.index}
            selectionApi={API.selectors.trainingTypes.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('label')}
            component={AsyncSelectorField}
            required
          />
          <FastField
            name="training_date"
            onChange={value => {
              setFieldValue('training_date', value);
              if (isDefined(value)) {
                const expirationDateValue = getConvertedMoment(value).add(1, 'Y');
                setFieldValue('expiration_date', expirationDateValue);
              }
            }}
            shouldUpdate={produceShouldUpdate(['highlighted'])}
            component={DateTimeField}
          />
          <FastField name="expiration_date" component={DateField} />
          <FastField name="trainer_name" component={TextField} />
          <FastField name="link" disabled component={TextField} />
          <FastField name="attachments" component={FilesField} />
          <FastField name="details_links" component={LinksField} />
        </FormSection>
      </DefaultAttributesGroup>
      <Footer {...props} />
      <EmployeeTrainingLocations {...props} />
      <Attendees {...props} />
    </form>
  );
};
