import yup from '../../../../components/form/CustomYupValidator';

export const validationSchema = yup.object().shape({
  rad_isotope_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  rad_item_type_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  activity_unit_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  quantity_unit_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  rad_form_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});
