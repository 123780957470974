import yup from '../../../components/form/CustomYupValidator';
import { isDefined } from '../../../services/utils';

export const validationSchema = yup.object().shape({
  waste_collection_type_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  waste_chemical_name_candidate: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  container_qty: yup
    .number()
    .transform(value => (Number.isNaN(value) ? undefined : value))
    .required(I18n.t('validations.required'))
    .typeError(I18n.t('validations.numeric'))
    .min(0, I18n.t('validations.greater_or_equal_than', { number: 0 })),
  container_size: yup
    .number()
    .transform(value => (Number.isNaN(value) ? undefined : value))
    .required(I18n.t('validations.required'))
    .typeError(I18n.t('validations.numeric'))
    .min(0, I18n.t('validations.greater_or_equal_than', { number: 0 })),
  container_size_um_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  responsible_person_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  waste_chemical_name_id: yup
    .string()
    .nullable()
    .test('waste_chemical_name_id', I18n.t('validations.required'), function _(
      waste_chemical_name_id,
    ) {
      if (this.parent.include_waste_types_sp && !isDefined(waste_chemical_name_id)) return false;

      return true;
    }),
});
