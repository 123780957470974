import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../../assets/styles/colors';
import { fontSize } from '../../../../assets/styles/typography';
import { DefaultLink } from '../../../../components/links/DefaultLink';
import {
  handleSubmitWithAfterSave,
  isDefined,
  defaultHandleSubmit,
} from '../../../../services/utils';

const t = (key, params = {}) => I18n.t(`inventory.people.people.new_edit.${key}`, params);

const Container = ({ children }) => (
  <div className="row mx-0">
    <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12" />
    <div className="col-lg-9 col-md-8 col-sm-8 col-xs-12 pb-2 pt-0 px-0">{children}</div>
  </div>
);

const Warning = styled.div`
  font-size: ${fontSize.textSm};
  color: ${colors.gray};
`;

const AdditionalButton = ({ button, action, children, disabled }) => (
  <Container>
    <DefaultLink
      id={`${I18n.t('resource_names.person')}_new_${button}_btn`}
      onClick={action}
      disabled={disabled}
    >
      {children}
    </DefaultLink>
  </Container>
);

export const AdditionalButtonsSection = props => {
  const {
    isSubmitting,
    values: { id, hasUser, fromImport, sso_based },
    handleSubmit,
    setFieldValue,
    currentUser: { org_admin },
    resetPasswordModalRef,
    manageUserModalRef,
  } = props;

  if (fromImport || !isDefined(id))
    return (
      <Container>
        <Warning className="col-sm-12 col-md-12 col-lg-5 col-xs-12 px-0">
          <i className="fas fa-exclamation-triangle fa-sm mr-1" />
          <span>{t('additional_messages.from_import')}</span>
        </Warning>
      </Container>
    );
  if (!hasUser) {
    return (
      <AdditionalButton
        button="create_account"
        disabled={!org_admin || isSubmitting}
        action={async () => {
          await handleSubmitWithAfterSave(
            setFieldValue,
            handleSubmit,
            manageUserModalRef.current.open,
          );
        }}
      >
        {t('modals.manage_user.title.create')}
      </AdditionalButton>
    );
  }

  return (
    <>
      {!sso_based && (
        <AdditionalButton
          button="reset_password"
          disabled={!org_admin || isSubmitting}
          action={async () => {
            await handleSubmitWithAfterSave(
              setFieldValue,
              handleSubmit,
              resetPasswordModalRef.current.open,
            );
          }}
        >
          {t('modals.reset_password.title.reset_password')}
        </AdditionalButton>
      )}
      <AdditionalButton
        button="switch_type"
        disabled={!org_admin || isSubmitting}
        action={async () => {
          await setFieldValue('sso_based', !sso_based);
          await setFieldValue('submitType', 'switchType');
          await defaultHandleSubmit(setFieldValue, handleSubmit);
        }}
      >
        {t(`additional_buttons.${sso_based ? 'switch_to_pwd_based' : 'switch_to_sso_based'}`)}
      </AdditionalButton>
    </>
  );
};
